import { Link, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import UserDetails from "components/UserDetails";
import ChangingTheNarrative from "./ChangingTheNarative";
import HowToUseHubspot from "./HowToUseHubspot";
import HowToUseLinkedIn from "./HowToUseLinkedIn";
import TheBestFreelanceJobs from "./TheBestFreelanceJobs";
import TheHiddenRisks from "./TheHiddenRisks";
import TheThreeBiggest from "./TheThreeBiggest";
import WhatYouNeed from "./WhatYouNeed";
import WhyWouldIHire from "./WhyWouldIHire";
import HowToHireGeorgiaTechStudents from "./HowToHireGeorgiaTechStudents";
import HowToHireEmoryStudents from "./HowToHireEmoryStudents";

type Props = {
  user: any;
};

const BlogComponent = (blogKey: string) => {
  const mapper: any = {
    "the-best-freelance-jobs-for-college-students": TheBestFreelanceJobs,
    "changing-the-narrative-4-ways-interns-will-transform-your-startup":
      ChangingTheNarrative,
    "why-would-i-hire-a-college-student-as-a-freelancer": WhyWouldIHire,
    "how-to-use-hubspot-to-get-qualified-leads": HowToUseHubspot,
    "how-to-use-linkedin-sales-navigator-to-find-target-customers":
      HowToUseLinkedIn,
    "the-3-biggest-talent-acquisition-mistakes-startups-are-marking-in-2023":
      TheThreeBiggest,
    "the-hidden-risks-of-diy-intern-recruting-and-how-it-can-negatively-impact-your-business":
      TheHiddenRisks,
    "what-you-need-to-know-before-starting-your-talent-acquisition-journey":
      WhatYouNeed,
    "how-to-hire-georgia-tech-students": HowToHireGeorgiaTechStudents,
    "how-to-hire-emory-students": HowToHireEmoryStudents,
  };

  return mapper[blogKey] || null;
};

export default function CommunityBlogsContainer({ user }: Props) {
  let Component = null;

  const { blogKey } = useParams();

  if (blogKey) {
    Component = BlogComponent(blogKey);
  }

  return (
    <div className="flex">
      <div className="w-full max-w-[300px] min-h-screen hidden lg:flex flex-col justify-between items-center border-l-[1px] border-r-[1px] border-[#0000001A]">
        <div className="w-full">
          <Link to="/community" className="h-16 flex items-center ml-4">
            <div className="w-8 h-8 bg-rw-blue text-white rounded-md flex justify-center items-center">
              <ArrowBackOutlinedIcon />
            </div>

            <div className="text-2xl font-semibold p-4">Blog</div>
          </Link>

          <hr />
        </div>
      </div>

      <div className="w-full">
        <div className="hidden lg:flex justify-end items-center px-4 h-16">
          {user?._id && <UserDetails user={user} />}
        </div>

        <hr />

        <div className="flex justify-center min-h-screen">
          <div className="">
            {/* @ts-ignore */}
            {Component && <Component />}
          </div>
        </div>
      </div>
    </div>
  );
}
