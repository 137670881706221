import SocialLinks from "../SocialLinks";
import Images from "assets/images";

export default function HowToUseHubspot() {
  return (
    <main className="w-full min-h-screen">
      <div className="my-8">
        <div className="flex justify-center font-semibold mt-9 mb-3 text-xs text-rw-baby-blue-dark break-words">
          For Students
        </div>
        <div className="text-zinc-700 text-3xl md:text-4xl font-semibold flex justify-center text-center mb-5">
          <div className="w-11/12 lg:w-3/5">
            How To Use HubSpot to Get Qualified Leads
          </div>
        </div>
        <div className="flex gap-4 justify-center mb-7 flex-wrap">
          <div className="text-zinc-600 font-semibold">By Runway Team</div>
          <div>|</div> <div className="text-zinc-600">April 19th, 2023</div>
        </div>
        <div className="flex justify-center mb-10">
          <img
            src={Images.HowToUseHubspot}
            className="w-11/12 lg:w-3/5"
            width={50}
            height={50}
            alt=""
          />
        </div>
        <div className="flex justify-center">
          <div className="w-11/12 lg:w-3/5">
            <div className="text-zinc-600 md:text-lg leading-7">
              HubSpot Sales Professional is a powerful sales automation tool
              that can help you streamline your sales process and improve your
              lead generation efforts. One of the key features of HubSpot Sales
              Professional is the ability to set up workflows that can
              automatically send out email campaigns and cold calls in order to
              get product demos. In this blog, we&apos;ll take a look at how you
              can use HubSpot Sales Professional to set up these workflows.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-loose">
              <br />
            </div>
            <div className="text-zinc-600 text-xl md:text-2xl font-semibold leading-loose">
              Step by Step Process
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Step 1<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Define Your Sales Process Before you can set up workflows in
              HubSpot Sales Professional, you need to define your sales process.
              This includes identifying your target audience, creating a list of
              potential leads, and outlining the steps you need to take to move
              them through the sales funnel. Once you have a clear understanding
              of your sales process, you can begin to create workflows that will
              automate some of these steps.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Step 2<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Create Your Email Campaign The first step in setting up a workflow
              in HubSpot Sales Professional is to create your email campaign.
              You can do this by selecting the &quot;Email&quot; option from the
              main menu and then clicking on &quot;Create an Email.&quot; From
              here, you can choose a template, add your message, and customize
              your email to suit your needs.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              <br />
              Step 3<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Create Your Cold Call Script In addition to email campaigns, you
              can also use HubSpot Sales Professional to set up cold call
              workflows. To do this, you&apos;ll need to create a cold call
              script that outlines the key points you want to cover during your
              call. You can then use this script to guide your sales team
              through the process of making cold calls to potential leads.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Step 4<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Set Up Your Workflows Once you have your email campaign and cold
              call script in place, it&apos;s time to set up your workflows. To
              do this, go to the &quot;Workflows&quot; section of HubSpot Sales
              Professional and click on &quot;Create Workflow.=&quot; From here,
              you can select the trigger that will activate your workflow (such
              as when a lead fills out a form on your website), choose the
              actions you want to take (such as sending an email or making a
              cold call), and set up any necessary conditions or delays.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Step 5<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Monitor Your Results Once your workflows are up and running,
              it&apos;s important to monitor your results to see how
              they&apos;re performing. HubSpot Sales Professional provides a
              wealth of data and analytics that can help you track your progress
              and identify areas for improvement. Use this information to refine
              your workflows and optimize your sales process over time.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
              <br />
              In conclusion, HubSpot Sales Professional is a powerful tool for
              automating your sales process and improving your lead generation
              efforts. By following these steps, you can set up workflows that
              will automatically send out email campaigns and cold calls in
              order to get product demos, helping you to close more deals and
              grow your business.
            </div>
            <div className="bg-zinc-600 mb-5 h-0.5 mt-20" />
            <div className="text-center text-zinc-600 text-2xl font-semibold">
              Share Article
            </div>
            <SocialLinks
              text="How To Use HubSpot to Get Qualified Leads"
              url="https://www.joinrunway.io/blogs/how-to-use-hubspot-to-get-qualified-leads/"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
