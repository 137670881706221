import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useSearchParams, useNavigate } from "react-router-dom";
import humanize from "humanize-string";
import Button from "components/Button";
import AppLoader from "components/AppLoader";
import ExploreProjectsFilter from "components/ExploreProjectsFilter";
import SearchProjects from "components/SearchProjects";
import ActiveProjectsFilter from "components/ActiveProjectsFilter";
import UserDetails from "components/UserDetails";
import { useMergeState } from "utils/custom-hooks";
import {
  AccountType,
  BudgetType,
  ProjectStatus,
  StudentProjectStatus,
} from "utils/constants";
import { timesAgo, wrapFullName } from "utils/common";
import { listProjectsCareerFair } from "api";

type Props = {
  user: any;
};

const studentsCount = (studentProjects: Array<any>, user: any) => {
  const appliedStudents = studentProjects.filter(
    (studentProject) => !studentProject.suggested
  ).length;

  let message = "";

  if (user.accountType === AccountType.BUSINESS) {
    message = appliedStudents === 1 ? "Candidate" : "Candidates";
  }

  if (user.accountType === AccountType.STUDENT) {
    message = appliedStudents === 1 ? "Applicant" : "Applicants";
  }

  return `${appliedStudents} ${message}`;
};

const isRequestedToInterview = (studentProjects: Array<any>, user: any) =>
  studentProjects.some((elem) => elem?.student?._id === user?._id);

const getFinishedStudents = (studenProjects: Array<any>) =>
  studenProjects.filter(
    (elem: any) => elem?.status === StudentProjectStatus.FINISHED
  );

const getProjectRate = (project: any) => {
  if (project.budgetType === BudgetType.FULL_TIME) {
    return "FULL TIME POSITION";
  }

  if (project.budgetType === BudgetType.RETAINER) {
    return `$${project.studentRetainerAmount}`;
  }

  if (project.budgetType === BudgetType.HOURLY_RATE) {
    return `${project.studentHourlyRate} / HR`;
  }
};

export default function ProjectsListCareerFairInternalContainer({
  user,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const projectStatus = searchParams.getAll("types");

  const isExplore = true;

  const [state, setState] = useMergeState({
    isLoading: false,

    projects: [],
    status: [],
    types: [],
    search: "",

    shouldShowFilterMenu: false,
  });

  const handleOpenFilterMenu = () => {
    setState({ shouldShowFilterMenu: true });
  };

  const handleCloseFilterMenu = () => {
    setState({ shouldShowFilterMenu: false, status: "", type: "" });
    setSearchParams({ status: "", type: "" }, { replace: true });
  };

  const handleViewProject = (projectId: string) => {
    navigate(`/projects/details?id=${projectId}&explore=${isExplore}`);
  };

  const init = async ({
    status = [],
    types = [],
  }: {
    status?: Array<string>;
    types?: Array<string>;
  }) => {
    try {
      setState({ isLoading: true });

      const params: any = {
        types,
      };

      const response = await listProjectsCareerFair(params);

      setState({
        projects: response?.data,
        status,
        types,
      });
      setSearchParams({ types }, { replace: true });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleChangeExploreFilter = async (value: string) => {
    try {
      const updatedTypes = [...state.types];

      const index = updatedTypes.findIndex((elem) => elem === value);

      if (index === -1) {
        updatedTypes.push(value);
      } else {
        updatedTypes.splice(index, 1);
      }

      setSearchParams({ types: updatedTypes }, { replace: true });
      setState({ types: updatedTypes });
      await init({ types: updatedTypes });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleChangeActiveFilter = async (value: string) => {
    try {
      const updatedStatus = [...state.status];

      const index = updatedStatus.findIndex((elem) => elem === value);

      if (index === -1) {
        updatedStatus.push(value);
      } else {
        updatedStatus.splice(index, 1);
      }

      setSearchParams({ status: updatedStatus }, { replace: true });
      setState({ status: updatedStatus });
      await init({ status: updatedStatus });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleClearFilter = async () => {
    handleCloseFilterMenu();
    await init({ status: [], types: [] });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ search: event?.target?.value });
  };

  const handleSearchSubmit = async () => {
    try {
      const params: any = {
        search: state?.search,
        types: state?.types,
      };

      const response = await listProjectsCareerFair(params);

      if (response?.success) {
        setState({ projects: response?.data });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    if (!state.search) return;
    const timeoutId = setTimeout(async () => {
      await handleSearchSubmit();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [state.search, 500]);

  useEffect(() => {
    let status: Array<string> = [];

    if (projectStatus) {
      status = projectStatus;
    }
    // init({ status, types: user.profileTypes });
    init({ status, types: status.length ? status : user.profileTypes });
  }, []);

  return (
    <div>
      {state?.isLoading ? (
        <AppLoader isLoading={state?.isLoading} />
      ) : (
        <div className="flex">
          <div className="w-full max-w-[300px] min-h-screen hidden lg:flex flex-col justify-between items-center border-l-[1px] border-r-[1px] border-[#0000001A]">
            <div className="w-full">
              <div className="h-16">
                <div className="text-2xl font-semibold p-4">
                  ATDC Career Fair
                </div>
              </div>

              <hr />

              {/* web filters */}
              <div>
                {isExplore ? (
                  <ExploreProjectsFilter
                    types={state?.types}
                    onChange={handleChangeExploreFilter}
                    showFilterMenu={state?.shouldShowFilterMenu}
                    onCloseFilter={handleCloseFilterMenu}
                    onOpenFilter={handleOpenFilterMenu}
                    onClearFilter={handleClearFilter}
                  />
                ) : (
                  <ActiveProjectsFilter
                    user={user}
                    status={state?.status}
                    onChange={handleChangeActiveFilter}
                    showFilterMenu={state?.shouldShowFilterMenu}
                    onCloseFilter={handleCloseFilterMenu}
                    onOpenFilter={handleOpenFilterMenu}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="hidden lg:flex justify-between items-center px-4 h-16">
              {!state?.shouldShowFilterMenu && (
                <div className="w-1/3">
                  <SearchProjects
                    search={state?.search}
                    onChange={handleSearchChange}
                  />
                </div>
              )}

              {user?._id && <UserDetails user={user} />}
            </div>

            <hr />

            <div className="flex justify-center min-h-screen">
              <div className="px-4 w-full bg-rw-blue-lightest">
                <div className="h-16">
                  <div className="hidden md:block text-2xl font-semibold py-4">
                    {state?.projects?.length} Project Results
                  </div>

                  <div className="block md:hidden text-2xl font-semibold py-4">
                    ATDC Career Fair
                  </div>
                </div>

                {/* mobile filters */}
                <div className="block lg:hidden">
                  {isExplore ? (
                    <ExploreProjectsFilter
                      types={state?.types}
                      onChange={handleChangeExploreFilter}
                      showFilterMenu={state?.shouldShowFilterMenu}
                      onCloseFilter={handleCloseFilterMenu}
                      onOpenFilter={handleOpenFilterMenu}
                      onClearFilter={handleClearFilter}
                    />
                  ) : (
                    <ActiveProjectsFilter
                      user={user}
                      status={state?.status}
                      onChange={handleChangeActiveFilter}
                      showFilterMenu={state?.shouldShowFilterMenu}
                      onCloseFilter={handleCloseFilterMenu}
                      onOpenFilter={handleOpenFilterMenu}
                    />
                  )}
                </div>

                {!state?.shouldShowFilterMenu && (
                  <div className="">
                    {state?.projects?.map((project: any) => (
                      <div
                        key={project?._id}
                        className="border-[1px] border-[#E3E3E3] rounded-md cursor-pointer my-4 bg-white"
                        onClick={() => handleViewProject(project?._id)}
                      >
                        <div className="p-4">
                          <div className="text-xl font-semibold">
                            {project?.title}
                          </div>

                          <div className="text-[#48484880] text-xs font-medium">
                            {isExplore &&
                              `${humanize(project?.type || "")} Project • `}
                            Posted {timesAgo(project?.createdAt)}
                          </div>

                          <div className="mt-4">
                            {!isExplore && (
                              <div className="flex flex-wrap items-center gap-4">
                                {project?.status === ProjectStatus.ACTIVE && (
                                  <div className="text-rw-blue text-sm font-semibold bg-rw-blue-light rounded-md px-2 py-1 w-fit">
                                    LISTED PROJECT
                                  </div>
                                )}

                                {project?.status ===
                                  ProjectStatus.IN_PROGRESS && (
                                  <div className="text-rw-green text-sm font-semibold bg-rw-green-light rounded-md px-2 py-1 w-fit">
                                    ONGOING PROJECT
                                  </div>
                                )}

                                {project?.status === ProjectStatus.FINISHED && (
                                  <div className="text-rw-red-dark text-sm font-semibold bg-rw-red-light rounded-md px-2 py-1 w-fit">
                                    FINISHED PROJECT
                                  </div>
                                )}

                                {user?.accountType === AccountType.BUSINESS && (
                                  <div className="text-rw-blue text-sm font-semibold bg-rw-blue-light rounded-md px-2 py-1 w-fit">
                                    {project?.type}
                                  </div>
                                )}

                                {project?.status === ProjectStatus.ACTIVE &&
                                  user?.accountType === AccountType.STUDENT &&
                                  isRequestedToInterview(
                                    project?.studentProjects,
                                    user
                                  ) && (
                                    <div className="text-sm font-semibold text-rw-yellow-dark bg-rw-yellow-lightest rounded-md px-2 py-1 w-fit">
                                      REQUESTED TO INTERVIEW
                                    </div>
                                  )}
                              </div>
                            )}

                            {isExplore && (
                              <div className="flex flex-wrap items-center gap-4">
                                {project?.skills?.map((skill: string) => (
                                  <div
                                    key={skill}
                                    className="bg-rw-blue-light rounded-md flex justify-center items-center text-rw-blue text-sm font-semibold w-fit px-3 py-1"
                                  >
                                    {skill}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>

                        <hr />

                        <div className="flex justify-between items-center p-3">
                          {isExplore && (
                            <div className="flex items-center">
                              <span className="bg-rw-blue rounded-full w-10 h-10 flex justify-center items-center text-sm text-white font-semibold">
                                {String(project?.business?.name)
                                  .trim()
                                  .charAt(0)
                                  .toUpperCase()}
                              </span>
                              <span className="text-lg font-semibold ml-2">
                                {project?.business?.name}
                              </span>
                            </div>
                          )}

                          {isExplore && (
                            <div className="bg-rw-blue-lightest rounded-md flex justify-center items-center font-semibold p-2">
                              {getProjectRate(project)}
                            </div>
                          )}

                          {!isExplore &&
                            project?.status !== ProjectStatus.FINISHED && (
                              <div className="bg-rw-blue-lightest rounded-md flex justify-center items-center font-semibold p-2">
                                {project?.status === ProjectStatus.ACTIVE &&
                                  studentsCount(project?.studentProjects, user)}

                                {/* {project?.status ===
                                  ProjectStatus.IN_PROGRESS && "$234.34 Due"} */}
                              </div>
                            )}

                          {!isExplore &&
                            project?.status === ProjectStatus.FINISHED &&
                            getFinishedStudents(project?.studentProjects).map(
                              (studentProject: any) => (
                                <div className="bg-rw-blue-lightest rounded-md flex justify-center items-center font-semibold p-2">
                                  {wrapFullName(
                                    studentProject?.student?.firstName,
                                    studentProject?.student?.lastName
                                  )}
                                </div>
                              )
                            )}

                          {!isExplore && (
                            <div>
                              <Button
                                className="font-semibold px-8"
                                onClick={() => handleViewProject(project?._id)}
                              >
                                View Project
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
