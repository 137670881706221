import React from "react";

type Props = {
  data: any;
  index?: number;
  showCheckBox?: boolean;
  setInterviewWindow?: (index: number) => void;
};

const InterviewTimesBox = ({
  data,
  index = -1,
  showCheckBox = true,
  setInterviewWindow = () => {},
}: Props) => {
  const generateIndex = (value: number) => {
    const slots = [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ];
    const newVal = (value / 60 - 9) * 2;
    return slots[newVal];
  };

  return (
    <div className="col-12 col-md-6 col-lg-3 mb-3 mb-lg-0">
      <div className="interview_window p-0 bg_light">
        <div className="d-flex justify-content-between p-3 bg_white">
          <p className="font_14 fw-bold text_primary">{data?.name}</p>
          {showCheckBox && (
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                onClick={() => setInterviewWindow(index)}
                value=""
                id="flexCheckDefault"
              />
            </div>
          )}
        </div>

        <div className="p-3 border_r_8px bg_light">
          {data && data.monday.enabled && (
            <div className="d-flex justify-content-between mb-1 flex-wrap">
              <p className="text_primary fw-bold font_14">Monday</p>
              <p className="text_rgba_50 font_14 fw-bold">
                {generateIndex(data.monday.times[0])} -{" "}
                {generateIndex(data.monday.times[1])}
              </p>
            </div>
          )}
          {data && data.tuesday.enabled && (
            <div className="d-flex justify-content-between mb-1 flex-wrap">
              <p className="text_primary fw-bold font_14">Tuesday</p>
              <p className="text_rgba_50 font_14 fw-bold">
                {generateIndex(data.tuesday.times[0])} -{" "}
                {generateIndex(data.tuesday.times[1])}
              </p>
            </div>
          )}
          {data && data.wednesday.enabled && (
            <div className="d-flex justify-content-between mb-1 flex-wrap">
              <p className="text_primary fw-bold font_14">Wednesday</p>
              <p className="text_rgba_50 font_14 fw-bold">
                {generateIndex(data.wednesday.times[0])} -{" "}
                {generateIndex(data.wednesday.times[1])}
              </p>
            </div>
          )}
          {data && data.thursday.enabled && (
            <div className="d-flex justify-content-between mb-1 flex-wrap">
              <p className="text_primary fw-bold font_14">Thursday</p>
              <p className="text_rgba_50 font_14 fw-bold">
                {generateIndex(data.thursday.times[0])} -{" "}
                {generateIndex(data.thursday.times[1])}
              </p>
            </div>
          )}
          {data && data.friday.enabled && (
            <div className="d-flex justify-content-between mb-1 flex-wrap">
              <p className="text_primary fw-bold font_14">Friday</p>
              <p className="text_rgba_50 font_14 fw-bold">
                {generateIndex(data.friday.times[0])} -{" "}
                {generateIndex(data.friday.times[1])}
              </p>
            </div>
          )}
          {data && data.saturday.enabled && (
            <div className="d-flex justify-content-between mb-1 flex-wrap">
              <p className="text_primary fw-bold font_14">Saturday</p>
              <p className="text_rgba_50 font_14 fw-bold">
                {generateIndex(data.saturday.times[0])} -{" "}
                {generateIndex(data.saturday.times[1])}
              </p>
            </div>
          )}
          {data && data.sunday.enabled && (
            <div className="d-flex justify-content-between mb-1 flex-wrap">
              <p className="text_primary fw-bold font_14">Sunday</p>
              <p className="text_rgba_50 font_14 fw-bold">
                {generateIndex(data.sunday.times[0])} -{" "}
                {generateIndex(data.sunday.times[1])}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InterviewTimesBox;
