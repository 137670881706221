import { Dialog, DialogContent } from "@mui/material";
import { titleCaseText } from "utils/common";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  open: boolean;
  onClose: any;
  resultDescriptions: any;
  type: string;
};

export default function TestBreakDownDialog({
  open,
  onClose,
  resultDescriptions,
  type,
}: Props) {
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogContent className="w-[100%] md:w-96 border-[1px] border-solid border-rw-gray-gainsboro">
          <div className="flex mb-6 justify-center text-base md:text-lg font-semibold text-center text-rw-gray-0">
            {titleCaseText(type)} Personalities
          </div>
          <CloseIcon
            className="absolute right-6 top-5 text-rw-blue cursor-pointer"
            onClick={onClose}
          />
          <div className="flex jusfiy-center mb-[30px] text-rw-black-raisin text-center text-xs sm:text-sm">
            This test is designed to help identify a students natural work style
            in a work environment.
          </div>
          {Object.keys(resultDescriptions).map((personalityType: string) => {
            return (
              <div className="flex gap-2 mb-8 justify-between">
                <div className="min-w-12 min-h-12 w-12 h-12">
                  <img src={resultDescriptions[personalityType].icon} />
                </div>
                <div className="flex flex-col items-end sm:w-fit w-[100%]">
                  <div className="text-[#414141] w-[100%] mb-2 text-xs sm:text-base md:text-lg font-semibold">
                    {personalityType}
                  </div>
                  <div className="text-rw-black-raisin text-xs w-[100%] sm:w-64">
                    {resultDescriptions[personalityType].text}
                  </div>
                </div>
              </div>
            );
          })}
        </DialogContent>
      </Dialog>
    </div>
  );
}
