import SocialLinks from "../SocialLinks";
import Images from "assets/images";

export default function TheThreeBiggest() {
  return (
    <main className="w-full min-h-screen">
      <meta
        name="The 3 Biggest Talent Acquisition Mistakes Startups Are Making in 2023"
        content="Are you a visionary startup founder racing towards success? Talent 
acquisition can make or break your business. Discover the pitfalls to avoid in 2023, from 
job fair frenzy to DIY recruiting. Learn how to secure top-tier interns from prestigious 
universities using Runway's AI Matching Algorithm. Maximize your time and investment 
with the three most qualified interns in seconds. Propel your startup to new heights with 
the right talent on board!"
      />

      <div className="my-8">
        <div className="flex justify-center font-semibold mt-9 mb-3 text-xs text-rw-baby-blue-dark break-words">
          For Employers
        </div>
        <div className="text-zinc-700 text-3xl md:text-4xl font-semibold flex justify-center text-center mb-5">
          <div className="w-11/12 lg:w-3/5">
            The 3 Biggest Talent Acquisition Mistakes Startups Are Making in
            2023
          </div>
        </div>
        <div className="flex gap-4 justify-center mb-7 flex-wrap">
          <div className="text-zinc-600 font-semibold">By Runway Team</div>
          <div>|</div> <div className="text-zinc-600">July 31st, 2023</div>
        </div>
        <div className="flex justify-center mb-10">
          <img
            src={Images.TheThreeBiggest}
            className="w-11/12 lg:w-3/5"
            width={50}
            height={50}
            alt=""
          />
        </div>
        <div className="flex justify-center">
          <div className="w-11/12 lg:w-3/5">
            <div className="text-zinc-600 md:text-lg leading-7">
              If you&apos;re a visionary, startup founder who is tirelessly
              building your business and balancing on the thin tightrope between
              success and failure, we get you. You&apos;ve got big dreams and
              limited time to make them happen. Every decision you make can
              either propel your business to new heights or send it crashing
              down. One crucial aspect that often determines which way the wind
              blows is top-tier talent acquisition to help grow your business
              rapidly without breaking the bank.
              <br />
              <br />
              In 2023, startups are facing unprecedented challenges in finding
              top-tier talent for their teams. While there&apos;s no shortage of
              mistakes being made along the way, some pitfalls have proven
              particularly treacherous.
              <br />
              <br />
            </div>
            <span className="text-zinc-600 md:text-lg font-bold leading-7">
              Mistake #1:{" "}
            </span>
            <span className="text-zinc-600 md:text-lg leading-7">
              Job Fair Frenzy on a Shoestring Budget
              <br />
            </span>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <li>
              <span className="text-zinc-600 md:text-lg leading-7">
                When it comes to talent acquisition, startups often make the
                mistake of attending job fairs they simply can&apos;t afford.
                Frankly, shelling out precious capital on expensive event booths
                and swag might seem like a good idea for exposure, but in
                reality, it&apos;s more champagne wishes than dollar bill
                realities. As an ambitious entrepreneur short on resources,
                spending valuable hours sifting through countless, unvetted,
                unqualified resumes doesn&apos;t align with success or
                scalability.
                <br />
              </span>
            </li>
            <li>
              <span className="text-zinc-600 md:text-lg font-semibold leading-7">
                Your Action Item To Avoid Mistake #1:
              </span>
              <span className="text-zinc-600 md:text-lg leading-7">
                {" "}
                Stop attending job fairs and instead acquire interns from the
                talent pool of the pros with vetted students and new grads from
                only the top universities in the nation.
                <br />
              </span>
            </li>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <span className="text-zinc-600 md:text-lg font-bold leading-7">
              Mistake #2:{" "}
            </span>
            <span className="text-zinc-600 md:text-lg leading-7">
              College Job Boards with Low-Quality Returns
              <br />
            </span>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <li>
              <span className="text-zinc-600 md:text-lg leading-7">
                Posting your openings to college job boards may initially sound
                like an efficient strategy—but here&apos;s the truth bomb: there
                is no targeting or vetting available, such as an AI Matching
                Algorithm or a Skill-Based Personality Assessment. Also, in
                reality, your shining opportunity will probably be lost amidst
                cluttered bulletin boards among the hundreds of other companies
                vying for attention. If you do get oh-so-lucky enough to land
                some applications, you&apos;ll likely end up swimming through a
                sea of underperforming, unqualified interns that will cost you
                more than you want to pay in training hours and capital. Hours
                spent sifting through resumes could be better invested elsewhere
                – remember, time is money! Don’t let this mistake rob your
                business of moving strides forward this week.
                <br />
              </span>
            </li>
            <li>
              <span className="text-zinc-600 md:text-lg font-semibold leading-7">
                Your Action Item To Avoid Mistake #2:
              </span>
              <span className="text-zinc-600 md:text-lg leading-7">
                {" "}
                Use less than half the time you’d spend posting to a college job
                board to sign up for Runway so you can get access to our
                prestigious talent pool, where we will vet candidates from only
                the top universities in the nation via our proprietary AI
                Matching Algorithm and our Skill-Based Personality Assessment.
                In seconds we’ll deliver only the top 3 candidates so you can be
                sure you’re getting the best intern available in the United
                States for your project. Use the other half of the time you’ll
                be saving to devote to more important things in your business.
                <br />
              </span>
            </li>
            <span className="text-zinc-600 md:text-lg font-bold leading-7">
              <br />
              Mistake #3:{" "}
            </span>
            <span className="text-zinc-600 md:text-lg leading-7">
              The DIY Delusion
              <br />
            </span>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>

            <li>
              <span className="text-zinc-600 md:text-lg leading-7">
                The DIY Mistake is more damaging than all others combined.
                Topping our list as the absolute worst mistake is attempting
                everything alone; forging ahead into unfamiliar waters rather
                than seeking expert guidance when it matters most. Thinking you
                can handle talent acquisition yourself may seem appealing when
                funds run low – after all; nobody knows your company culture
                better than you do! However noble this notion may be though
                startups and small businesses risk missing out entirely on
                exceptional matches who bring valuable expertise from
                prestigious institutions as they just don’t have the exposure to
                top tier students as the pros. What makes matters worse is
                they’re losing time they could be focusing on other areas of
                their business that require their personal expertise. Don’t be
                the startup or small business making this mistake!
                <br />
              </span>
            </li>
            <li>
              <span className="text-zinc-600 md:text-lg font-semibold leading-7">
                Your Action Item To Avoid Mistake #3:
              </span>
              <span className="text-zinc-600 md:text-lg leading-7">
                {" "}
                Don’t do it yourself. Just refuse to. Let the pros here at
                Runway help your business take off by supplying you with the
                three most qualified interns in the entire nation, from top tier
                universities, in a matter of seconds, so you can get on to the
                important stuff.
                <br />
              </span>
            </li>

            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
              Startup life demands resilience but don&apos;t let avoidable
              mistakes threaten your journey to the top. Remember that job fairs
              will never be worth the budget, college job boards will only lend
              low quality returns (if any at all), and the idea that you should
              focus on such a mundane task as recruiting while putting the rest
              of your company on hold is a delusion. When seeking talent well
              worth the investment, be sure to explore smarter, more efficient
              avenues such as Runway. We are designed exclusively for
              discovering top-tier college students majoring in engineering,
              software development, business or graphic design from esteemed
              schools boasting acceptance rates below 20%. Let us do the heavy
              lifting for you so you can see your business soar to new heights!
            </div>
            <div className="bg-zinc-600 mb-5 h-0.5 mt-20" />
            <div className="text-center text-zinc-600 text-2xl font-semibold">
              Share Article
            </div>
            <SocialLinks
              text="The 3 Biggest Talent Acquisition Mistakes Startups Are Making in
            2023"
              url="https://www.joinrunway.io/blogs/the-3-biggest-talent-acquisition-mistakes-startups-are-marking-in-2023/"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
