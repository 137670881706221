import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";

type Props = {
  showAudioTestModal: boolean;
  hideModal: () => void;
  onContinue: () => void;
};

const TestMicrophone = ({
  showAudioTestModal,
  hideModal,
  onContinue,
}: Props) => {
  const [audioFrequency, setAudioFrequency] = useState(0);
  const [microPhoneList, setMicrophoneList] = useState<string[]>([]);

  const checkMicrophoneAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log("Microphone access granted!");
      // You can proceed with further actions here.
    } catch (error) {
      console.error("Microphone access denied:", error);
    }
  };
  let stream: any;
  let timer: any;

  async function getMicrophoneInfo(closeStream?: boolean) {
    try {
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const audioContext = new AudioContext();
      const sourceNode = audioContext.createMediaStreamSource(stream);

      // Get the microphone name
      const microphoneName = stream.getAudioTracks()[0].label;
      const temp: string[] = [];
      stream.getAudioTracks().map((da: any) => temp.push(da.label));
      //   console.log(temp[0], " Temp");
      setMicrophoneList([...temp]);
      //   console.log(stream.getAudioTracks(), ",all tracks");
      console.log("Microphone name:", microphoneName);

      // Analyser node for frequency analysis
      const analyserNode = audioContext.createAnalyser();
      sourceNode.connect(analyserNode);

      // Get frequency data
      const dataArray = new Uint8Array(analyserNode.frequencyBinCount);
      const writeFaq = () => {
        analyserNode.getByteFrequencyData(dataArray);

        // Calculate average frequency
        const averageFrequency =
          dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length;
        // console.log("Average frequency:", averageFrequency, "Hz");
        setAudioFrequency(averageFrequency);
      };
      timer = setInterval(writeFaq, 200);
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  }

  useEffect(() => {
    checkMicrophoneAccess();
    getMicrophoneInfo();
    return () => {
      clearInterval(timer);
      stream.getAudioTracks().forEach((track: any) => track.stop());
    };
  }, []);

  return (
    <Modal show={showAudioTestModal} centered onHide={() => hideModal()}>
      <Modal.Body
        style={{
          padding: 0,
          paddingBottom: 0,
          margin: 0,
        }}
      >
        <div className="modal-content p-4 border_r_8px">
          <div className="modal-body p-0">
            <p className="fw-bold text-center text_black fs-4 mb-3">
              Test your microphone
            </p>
            <select className="select_style">
              {microPhoneList.map((da: any, index: number) => (
                <option key={index}>{da}</option>
              ))}
            </select>
            <div className="d-flex justify-content-between mt-4">
              {Array(16)
                .fill(0)
                .map((da: any, index: number) => (
                  <span
                    key={index}
                    className={`input_level ${
                      (audioFrequency / 28) * 16 >= index ? "active" : ""
                    }`}
                  />
                ))}
            </div>
          </div>
          <div className="modal-footer border-0 p-0 mt-5">
            <button
              className="btn bg_secondary text_secondary text_white w-100"
              data-bs-target="#important_pointsToggle3"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              onClick={() => onContinue()}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TestMicrophone;
