import ErrorMessage from "components/ErrorMessage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const toolbarOptions = [
  [
    "bold",
    "italic",
    "underline",
    // "strike"
  ], // toggled buttons
  // ["blockquote", "code-block"],

  // [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: "ordered" }, { list: "bullet" }],
  // [{ script: "sub" }, { script: "super" }], // superscript/subscript
  // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ direction: "rtl" }], // text direction

  // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  // [{ font: [] }],
  // [{ align: [] }],

  // ["clean"], // remove formatting button
];

type Props = {
  value: string;
  placeholder?: string;
  onChange: (value: string) => void;
  error: string;
};

export default function RichTextEditorField({
  value,
  placeholder,
  onChange,
  error,
}: Props) {
  return (
    <div>
      <ReactQuill
        theme="snow"
        placeholder={placeholder}
        // className="file_editor"
        value={value}
        onChange={onChange}
        modules={{
          toolbar: toolbarOptions,
        }}
      />

      {error && <ErrorMessage message={error} />}
    </div>
  );
}
