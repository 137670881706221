import Images from "assets/images";

type Props = {
  isLoading: boolean;
};

export default function AppLoader({ isLoading }: Props) {
  return isLoading ? (
    <div className="w-full h-screen flex justify-center mt-10">
      <img src={Images.AppLoaderGif} className="w-20 h-20" />
    </div>
  ) : null;
}
