import { useSearchParams } from "react-router-dom";
import BusinessProjectDetails from "components/Projects/BusinessProjectDetails";
import StudentProjectDetailsInternal from "components/Projects/StudentProjectDetails/internal";
import StudentProjectDetailsExternal from "components/Projects/StudentProjectDetails/external";
import { AccountType } from "utils/constants";
import { useWindowSize } from "utils/custom-hooks";
import ProjectDetailsMobileView from "./projectDetailMobileView";
import ProjectDetails from "../../MyProjects/details";

type Props = {
  isLoggedIn: boolean;
  user: any;
  manuBarExpend: boolean;
  manuBarToogle: () => void;
  setUser: (user: any) => void;
};

export default function ProjectsDetailsContainer({
  isLoggedIn,
  user,
  manuBarExpend,
  manuBarToogle,
  setUser,
}: Props) {
  const [searchParams] = useSearchParams();

  const currentWidth = useWindowSize().width;
  const isDesktopView = currentWidth > 650;
  const isExplore = searchParams.get("explore") || "";

  return !isDesktopView && isExplore ? (
    <ProjectDetailsMobileView
      isLoggedIn={isLoggedIn}
      user={user}
      setUser={setUser}
    />
  ) : isLoggedIn ? (
    <ProjectDetails
      user={user}
      manuBarExpend={manuBarExpend}
      manuBarToogle={manuBarToogle}
    />
  ) : null;
  // return !isDesktopView && isExplore ? (
  //   <ProjectDetailsMobileView
  //     isLoggedIn={isLoggedIn}
  //     user={user}
  //     setUser={setUser}
  //   />
  // ) : isLoggedIn ? (
  //   <div>
  //     {user?.accountType === AccountType.BUSINESS && (
  //       <BusinessProjectDetails user={user} />
  //     )}

  //     {(isExplore || user?.accountType === AccountType.STUDENT) && (
  //       <StudentProjectDetailsInternal
  //         user={user}
  //         setUser={setUser}
  //         isExplore={isExplore}
  //       />
  //     )}
  //   </div>
  // ) : (
  //   <StudentProjectDetailsExternal
  //     user={user}
  //     setUser={setUser}
  //     isExplore={isExplore}
  //   />
  // );
}
