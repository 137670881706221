import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FormattedNumber } from "react-intl";
// eslint-disable-next-line import/no-extraneous-dependencies
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
// import Checkbox from "@mui/material/Checkbox";
import Slider from "@mui/material/Slider";
import Radio from "@mui/material/Radio";
// import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import CancelIcon from "@mui/icons-material/Cancel";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
// import HowItWorks from "components/HowItWorks";
import TextField from "components/TextField";
import Button from "components/Button";
import MatchingStudentsLoader from "components/MatchingStudentsLoader";
import RichTextEditorField from "components/RichTextEditorField";
import ErrorMessage from "components/ErrorMessage";
import QuoteSummary from "components/Projects/QuoteSummary";
import PersonalityDefinitionsDialog from "components/Projects/PersonalityDefinitionsDialog";
import PromoCodeDialog from "components/Projects/PromoCodeDialog";
// import UserDetails from "components/UserDetails";
import { useMergeState } from "utils/custom-hooks";
import {
  StudentTypes,
  // SampleSkills,
  HourlyRate,
  ProjectDurationType,
  BudgetType,
  ProjectBudgetTypes,
  ProjectTemplates,
  ProjectType,
} from "utils/constants";
import {
  toBase64,
  calculateProjectCost,
  scrollToTop,
  getStudentHourlyRate,
  getStudentRetainerAmount,
  getPersonalityRanking,
  // wrapFullName,
  // wrapNameTwoLetters,
  shouldShowPremiumFeatures,
} from "utils/common";
import {
  createProject,
  getSkills,
  getProjectDetails,
  updateProject,
  saveProjectAsDraft,
  applyPromoCode,
  listProjects,
} from "api";
import Images from "assets/images";
import { wrapHTMLContent } from "utils/string";
import AppLoader from "components/AppLoader";
import "../../../assets/css/style.css";

const getSuggestedRate = (studentType: string) => HourlyRate[studentType];

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 36,
    width: 36,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const StepLabels = {
  TYPE: "TYPE",
  PROJECT_TEMPLATE: "PROJECT_TEMPLATE",
  TITLE: "TITLE",
  SKILLS: "SKILLS",
  DETAILS: "DETAILS",
  DOCUMENT: "DOCUMENT",
  STUDENT_PERSONALITY: "STUDENT_PERSONALITY",
  BUDGET_TYPE: "BUDGET_TYPE",
  BUDGET: "BUDGET",
  CONFIRM: "CONFIRM",
};

const Steps = [
  {
    id: 1,
    label: StepLabels.TYPE,
    title: "Project Type",
    show: true,
  },
  {
    id: 2,
    label: StepLabels.PROJECT_TEMPLATE,
    title: "Project Details",
    show: false,
  },
  {
    id: 3,
    label: StepLabels.TITLE,
    title: "Project Details",
    show: true,
  },
  {
    id: 4,
    label: StepLabels.SKILLS,
    title: "Project Details",
    show: false,
  },
  {
    id: 5,
    label: StepLabels.DETAILS,
    title: "Project Details",
    show: true,
  },
  {
    id: 6,
    label: StepLabels.DOCUMENT,
    title: "Project Details",
    show: true,
  },
  {
    id: 7,
    label: StepLabels.STUDENT_PERSONALITY,
    title: "Personality Details",
    show: false,
  },
  {
    id: 8,
    label: StepLabels.BUDGET_TYPE,
    title: "Personality Details",
    show: false,
  },
  {
    id: 9,
    label: StepLabels.BUDGET,
    title: "Personality Details",
    show: true,
  },
  {
    id: 10,
    label: StepLabels.CONFIRM,
    title: "Confirm Project",
    show: true,
  },
];

type Props = {
  user: any;
  manuBarExpend: boolean;
  isEditProject?: boolean;
  projectDetails?: any;
  manuBarToogle: () => void;
  handleBackNavigation?: (data: any) => void;
};

export default function CreateProjectsContainer({
  user,
  manuBarExpend,
  isEditProject = false,
  projectDetails = null,
  manuBarToogle,
  handleBackNavigation = () => {},
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const isPostNewProject = pathname === "/projects/details" && !isEditProject;

  const projectId = searchParams.get("id");
  const isSaveAsDraft = useRef(false);

  const [state, setState] = useMergeState({
    skipGetStarted: projectId ? true : false,

    // currentStep: projectId ? Steps[6] : Steps[0],
    currentStep: Steps[0],

    type: "",

    // project details
    title: "",
    keySkills: "",
    skills: [],
    description: "",
    keyResponsibilities: "",
    qualifications: "",
    companySite: "",
    docs: [],

    // student personality
    studentPersonality: {},
    personalityRanking: [],

    budgetType: BudgetType.HOURLY_RATE,

    // budget
    hourlyRate: 0,
    hoursPerWeek: 20,
    durationType: ProjectDurationType.UNTIL_ENDED,
    duration: 1,
    retainerAmount: 0,
    skillList: [],

    // promo code
    promo: {},

    isEditMode: false,
    isProjectSubmitting: false,
    shouldShowPersonalityDefinitionsDialog: false,
    shouldShowPromoCodeDialog: false,
    isApplyingPromoCode: false,

    errors: {},

    skillsList: [],
    skillSet: [],
    newSkill: "",
    newSkillId: -1,
    isBtnDisable: false,
  });

  const percetages: any = {
    1: {
      percentage: "100%",
    },
    2: {
      percentage: "25%",
    },
    3: {
      percentage: "50%",
    },
    4: {
      percentage: "100%",
    },
    5: {
      percentage: "50%",
    },
    6: {
      percentage: "100%",
    },
    7: {
      percentage: "25%",
    },
    8: {
      percentage: "50%",
    },
    9: {
      percentage: "100%",
    },
  };

  const progressBarPer = useRef<any>([
    {
      id: 1,
      steps: ["TYPE"],
      activeTab: {
        isActive: false,
        percentage: "",
      },
    },
    {
      id: 2,
      steps: ["PROJECT_TEMPLATE", "TITLE", "SKILLS"],
      activeTab: {
        isActive: false,
        percentage: "",
      },
    },
    {
      id: 5,
      steps: ["DETAILS", "DOCUMENT"],
      activeTab: {
        isActive: false,
        percentage: "",
      },
    },
    {
      id: 7,
      steps: ["STUDENT_PERSONALITY", "BUDGET_TYPE", "BUDGET"],
      activeTab: {
        isActive: false,
        percentage: "",
      },
    },
  ]);

  const handleOpenPromoCodeDialog = () => {
    setState({ shouldShowPromoCodeDialog: true });
  };

  const handleClosePromoCodeDialog = () => {
    setState({ shouldShowPromoCodeDialog: false });
  };

  const handleApplyPromoCode = async (promoCode: string) => {
    try {
      setState({ isApplyingPromoCode: true });

      const response = await applyPromoCode({ promoCode });

      setState({
        promo: response?.data,
      });

      handleClosePromoCodeDialog();

      enqueueSnackbar(response?.message, { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isApplyingPromoCode: false });
    }
  };

  const docsRef = useRef<any>();

  const handleDocsRef = () => {
    docsRef?.current?.click();
  };

  const handleDocsChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (!files?.length) {
      return;
    }

    const docFiles = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // eslint-disable-next-line
      const base64 = await toBase64(file);

      docFiles.push({
        fileName: file?.name,
        contentType: file?.type,
        base64,
      });
    }

    setState({ docs: docFiles });

    event.target.value = "";
  };

  const handleRemoveDoc = (doc: any) => {
    const updatedDocs = [...state.docs];

    const index = updatedDocs.findIndex(
      (elem) => elem?.fileName === doc?.fileName
    );

    updatedDocs.splice(index, 1);

    setState({ docs: updatedDocs });
  };

  const handleSkipGetStarted = () => {
    setState({ skipGetStarted: true });
  };

  const handleChange = (event: any) => {
    if (event?.target?.name === "retainerAmount" && event?.target?.value < 0)
      return;
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const handleChangeDescription = (description: string) => {
    setState({ description });
  };

  const handleChangeKeyResponsibilities = (keyResponsibilities: string) => {
    setState({ keyResponsibilities });
  };

  const handleChangeQualifications = (qualifications: string) => {
    setState({ qualifications });
  };

  const getStudentPersonality = (type: string) =>
    StudentTypes.find((elem) => elem.value === type);

  const handleChangeStudentType = (type: string) => {
    const studentPersonality = getStudentPersonality(type);

    const personalityRanking = getPersonalityRanking(studentPersonality?.value);

    setState({
      studentPersonality,
      personalityRanking,
      type,
      hourlyRate: getSuggestedRate(type),
    });
  };

  const handleChangeDurationType = (event: any) => {
    setState({
      durationType: event?.target?.value,
    });
  };

  // const handleChangeKeySkills = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const keySkills = event?.target?.value;

  //   const keySkillsArray = keySkills?.split(",");

  //   const skills = keySkillsArray.map((elem: string) =>
  //     String(elem)?.trim()?.toUpperCase()
  //   );

  //   setState({
  //     keySkills,
  //     skills,
  //     errors: {
  //       keySkills: "",
  //     },
  //   });
  // };

  const handleChangeKeySkills = (skill: string) => {
    const filteredSkills = state.skills.filter(
      (item: string) => item !== skill
    );
    if (filteredSkills.length === state.skills.length) {
      filteredSkills.push(skill);
    }

    setState({
      skills: filteredSkills,
      errors: {
        keySkills: "",
      },
    });
  };

  const handleChangeHourlyRate = (
    event: Event,
    newValue: number | number[]
  ) => {
    setState({ hourlyRate: newValue });
  };

  const handleChangeHoursPerWeek = (
    event: Event,
    newValue: number | number[]
  ) => {
    setState({ hoursPerWeek: newValue });
  };

  const handleChangeDuration = (event: Event, newValue: number | number[]) => {
    setState({ duration: newValue });
  };

  // const handleReturnToProjects = () => {
  //   navigate("/projects");
  // };

  const handleChangeBudgetType = (budgetType: string) => {
    setState({
      budgetType,
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (state?.currentStep?.label === StepLabels.TYPE) {
      if (!state?.type) {
        errors = { ...errors, type: "Student Type is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.TITLE) {
      if (!state?.title) {
        errors = { ...errors, title: "Title is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.SKILLS) {
      if (!state?.skills || state?.skills.length === 0) {
        errors = { ...errors, keySkills: "Skills is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.DETAILS) {
      // if (!state?.title) {
      //   errors = { ...errors, title: "Title is required." };
      //   isValid = false;
      // }

      // if (!state?.keySkills) {
      //   errors = { ...errors, keySkills: "Skills is required." };
      //   isValid = false;
      // }

      if (!state?.description) {
        errors = { ...errors, description: "Description is required." };
        isValid = false;
      }

      if (!state?.keyResponsibilities) {
        errors = {
          ...errors,
          keyResponsibilities: "Key Responsibilities is required.",
        };
        isValid = false;
      }

      if (!state?.qualifications) {
        errors = { ...errors, qualifications: "Qualifications is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.BUDGET_TYPE) {
      if (!state?.budgetType) {
        errors = { ...errors, budgetType: "Please select a budget type." };
        isValid = false;
      }
    }

    setState({ errors });

    return isValid;
  };

  const getNextStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) + 1];

  const getPrevStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) - 1];

  const handleContinue = async () => {
    if (!isFormValid()) {
      return;
    }

    const currentStep =
      Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id)];
    if (currentStep.label === "TITLE") {
      setState({ isBtnDisable: true });
      const response = await getSkills({ title: state.title });
      if (response.success) {
        let skill_list = response.data.skills;
        let skillsData: any = [];
        const splitData = (data: any) =>
          data.split(/\d+/).map((item: any) => item.replace(/\./g, "").trim());
        if (skill_list.find((skill: any) => skill.includes("\n"))) {
          skill_list.map((item: any) => {
            skillsData = [...skillsData, ...splitData(item)];
          });
          skill_list = skillsData;
        }
        setState({
          skillList: skill_list,
          newSkillId: skill_list.length,
          isBtnDisable: false,
        });
      } else {
        setState({
          isBtnDisable: false,
        });
      }
    }
    let progressBarPerCopy = progressBarPer.current.slice();
    progressBarPerCopy = progressBarPerCopy.map((item: any) =>
      item.steps.includes(currentStep.label)
        ? {
            ...item,
            activeTab: {
              isActive: true,
              percentage: percetages[currentStep.id].percentage,
            },
          }
        : item
    );

    progressBarPer.current = progressBarPerCopy;

    const nextStep = getNextStep();

    setState({ currentStep: nextStep });

    scrollToTop();
  };

  const handleBack = () => {
    if (isEditProject && state?.currentStep?.label === StepLabels.TYPE) {
      return handleBackNavigation(null);
    }
    if (!isEditProject && state?.currentStep?.label === StepLabels.TYPE) {
      return handleBackNavigation(null);
      // navigate("/dashboard");
    }
    const prevCurrentStep =
      Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) - 2];
    const currentStep =
      Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id)];
    if (currentStep.id === 1) return;

    const prevStep = getPrevStep();
    let progressBarPerCopy = progressBarPer.current.slice();
    progressBarPerCopy = progressBarPerCopy.map((item: any) =>
      item.id === prevStep.id
        ? {
            ...item,
            activeTab: {
              isActive: false,
              percentage: "0%",
            },
          }
        : prevCurrentStep && item.steps.includes(prevCurrentStep.label)
        ? {
            ...item,
            activeTab: {
              isActive: true,
              percentage: percetages[prevCurrentStep.id].percentage,
            },
          }
        : item
    );
    progressBarPer.current = progressBarPerCopy;
    if (currentStep.label === "SKILLS") {
      setState({
        currentStep: prevStep,
        skills: [],
      });
    } else {
      setState({ currentStep: prevStep });
    }
  };

  const updateProgressBar = (step: any) => {
    const label = Steps[step - 1]?.label;
    const labels = ["PROJECT_TEMPLATE", "DETAILS", "STUDENT_PERSONALITY"];
    let progressBarPerCopy = progressBarPer.current.slice();
    progressBarPerCopy = progressBarPerCopy.map((item: any) =>
      item.steps.includes(label)
        ? {
            ...item,
            activeTab: {
              isActive: true,
              percentage:
                step > 1 && !labels.includes(label)
                  ? percetages[step - 1].percentage
                  : "0%",
            },
          }
        : item.id <= step
        ? {
            ...item,
            activeTab: {
              isActive: true,
              percentage: "100%",
            },
          }
        : {
            ...item,
            activeTab: {
              isActive: false,
              percentage: "0%",
            },
          }
    );
    progressBarPer.current = progressBarPerCopy;
  };
  const handleEditStep = (stepLabel: string) => {
    const currentStep = Steps.find((step) => step?.label === stepLabel);
    updateProgressBar(currentStep?.id);
    setState({
      currentStep,
    });
  };

  const handleOpenPersonalityDefinitionsDialog = () => {
    setState({ shouldShowPersonalityDefinitionsDialog: true });
  };

  const handleClosePersonalityDefinitionsDialog = () => {
    setState({ shouldShowPersonalityDefinitionsDialog: false });
  };

  const handleSelectProjectTemplate = (projectTemplate: any) => {
    const studentPersonality = getStudentPersonality(projectTemplate?.type);

    const personalityRanking = getPersonalityRanking(projectTemplate?.type);

    const payload = {
      type: projectTemplate?.type,

      // project details
      title: projectTemplate?.title,
      keySkills: projectTemplate?.skills,
      skills: projectTemplate?.skills,
      description: projectTemplate?.description,
      keyResponsibilities: projectTemplate?.keyResponsibilities,
      qualifications: projectTemplate?.qualifications,
      companySite: projectTemplate?.companySite,
      docs: projectTemplate?.docs,

      // student personality
      studentPersonality,
      personalityRanking,

      // budget
      hourlyRate: projectTemplate?.hourlyRate,
      hoursPerWeek: projectTemplate?.hoursPerWeek,
      durationType: projectTemplate?.durationType,
      duration: projectTemplate?.duration,
      retainerAmount: projectTemplate?.retainerAmount,
      budgetType: projectTemplate?.budgetType,
    };

    setState(payload);

    handleContinue();
  };

  const defaultProjectTitle = async () => {
    const params: any = {
      status: ["DRAFT"],
      types: [],
      explore: "",
      business: user?.business?._id,
    };
    const response = await listProjects(params);
    if (response.success) {
      let list: any = response.data;
      list = list.filter((item: any) => item.title.includes("Draft Project"));
      return list.length ? `Draft Project (${list.length})` : "Draft Project";
    }
    return "Draft Project";
  };

  const handleSubmit = async (flag: any) => {
    const status = flag === "save as draft" ? "DRAFT" : "ACTIVE";
    isSaveAsDraft.current = flag === "save as draft" ? true : false;
    const payload: any = {
      isATV: user?.business?.atv?.isATV,
      type: state?.type,
      status: isEditProject ? "IN_PROGRESS" : status,
      title: state?.title,
      skills: state?.skills,
      description: state?.description,
      keyResponsibilities: state?.keyResponsibilities,
      qualifications: state?.qualifications,
      companySite: state?.companySite,
      docs: state?.docs,
      hourlyRate: state?.hourlyRate,
      hoursPerWeek: state?.hoursPerWeek,
      studentHourlyRate: +Number(
        getStudentHourlyRate(
          state.hourlyRate,
          shouldShowPremiumFeatures(user),
          state.promo
        )
      ),
      durationType: state?.durationType,
      duration: state?.duration,
      budgetType: state?.budgetType,
      retainerAmount: +Number(state.retainerAmount).toFixed(2),
      studentRetainerAmount: +Number(
        getStudentRetainerAmount(
          state?.retainerAmount,
          shouldShowPremiumFeatures(user),
          state?.promo
        )
      ),
      studentPersonalityRanking: state.personalityRanking.map(
        (elem: any) => elem.title
      ),
      appliedPromoCode: state?.promo?._id,
    };

    try {
      setState({ isProjectSubmitting: true });

      let response = null;

      if (!state?.isEditMode) {
        response = await createProject(payload);
      } else if (state?.isEditMode && isPostNewProject) {
        response = await createProject(payload);
      } else {
        payload.id = projectId;
        response = await updateProject(payload);
      }

      if (response?.success) {
        isSaveAsDraft.current = false;
        setState({ isProjectSubmitting: false });
        if (status === "DRAFT") {
          enqueueSnackbar("Project saved in Drafts", { variant: "success" });
          navigate("/projects");
        } else {
          enqueueSnackbar(response?.message, { variant: "success" });
          if (isEditProject) handleBackNavigation(response);
          else if (isPostNewProject) handleBackNavigation(null);
          else
            navigate(
              `/projects/details?id=${response?.data?._id}&showMatchedCandidates=true`
            );
        }
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleSaveAsDraft = async (flag: any) => {
    if (state?.type === "") {
      return enqueueSnackbar("No Changes made, Project not saved in Drafts", {
        variant: "error",
      });
    }
    const status = "DRAFT";
    isSaveAsDraft.current = true;
    const budgetType = state?.budgetType || "HOURLY_RATE";
    const title = state?.title || (await defaultProjectTitle());

    const payload: any = {
      isATV: user?.business?.atv?.isATV,
      type: state?.type,
      status,
      step: state.currentStep.id,
      title,
      skills: state?.skills,
      description: state?.description,
      keyResponsibilities: state?.keyResponsibilities,
      qualifications: state?.qualifications,
      companySite: state?.companySite,
      docs: state?.docs,
      hourlyRate: state?.hourlyRate,
      hoursPerWeek: state?.hoursPerWeek,
      studentHourlyRate: +Number(
        getStudentHourlyRate(
          state.hourlyRate,
          shouldShowPremiumFeatures(user),
          state.promo
        )
      ),
      durationType: state?.durationType,
      duration: state?.duration,
      budgetType, // state?.budgetType,
      retainerAmount: +Number(state.retainerAmount).toFixed(2),
      studentRetainerAmount: +Number(
        getStudentRetainerAmount(
          state?.retainerAmount,
          shouldShowPremiumFeatures(user),
          state?.promo
        )
      ),
      studentPersonalityRanking: state.personalityRanking.map(
        (elem: any) => elem.title
      ),
      appliedPromoCode: state?.promo?._id,
    };

    try {
      setState({ isProjectSubmitting: true });

      let response = null;
      if (isEditProject || isPostNewProject) {
        response = await saveProjectAsDraft(payload);
      } else if (!state?.isEditMode) {
        response = await saveProjectAsDraft(payload);
      } else {
        payload.id = projectId;
        response = await updateProject(payload);
      }
      if (response?.success) {
        isSaveAsDraft.current = false;
        setState({ isProjectSubmitting: false });
        enqueueSnackbar("Project saved in Drafts", { variant: "success" });
        if (isEditProject || isPostNewProject)
          return handleBackNavigation(null);
        navigate("/projects");
      } else {
        setState({ isProjectSubmitting: false });
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error: any) {
      setState({ isProjectSubmitting: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const {
    studentCompensation,
    platformFee,
    totalCost,
    discountAmount = 0,
  } = calculateProjectCost({
    hourlyRate: state?.hourlyRate,
    hoursPerWeek: state?.hoursPerWeek,
    duration: state?.duration,
    durationType: state?.durationType,
    retainerAmount: state?.retainerAmount,
    budgetType: state?.budgetType,
    promo: state?.promo,
  });

  const handleUpdateState = async (response: any) => {
    const studentPersonality = getStudentPersonality(response?.data?.type);

    const personalityRanking = getPersonalityRanking(response?.data?.type);

    const studentPersonalityRanking: any = [];
    const step = isEditProject ? 3 : response.data?.step || 1;
    updateProgressBar(step);

    response?.data?.studentPersonalityRanking.forEach((elem: string) => {
      const personality = personalityRanking.find(
        (item: any) => item.title === elem
      );

      studentPersonalityRanking.push(personality);
    });
    // if atv business opted in, then auto apply promo code
    if (user?.business?.atv?.optedIn) {
      const promoResponse = await applyPromoCode({ promoCode: "ATV" });

      setState({
        skipGetStarted: true,
        isEditMode: true,

        type: response?.data?.type,
        currentStep: isEditProject ? Steps[2] : Steps[step - 1],

        // project details
        title: response?.data?.title,
        keySkills: Array(response?.data?.skills).join(),
        skills: response?.data?.skills,
        description: response?.data?.description,
        keyResponsibilities: response?.data?.keyResponsibilities,
        qualifications: response?.data?.qualifications,
        companySite: response?.data?.companySite,
        docs: response?.data?.docs,

        // student personality
        studentPersonality,
        personalityRanking: studentPersonalityRanking,

        // budget
        hourlyRate: response?.data?.hourlyRate,
        hoursPerWeek: response?.data?.hoursPerWeek,
        durationType: response?.data?.durationType,
        duration: response?.data?.duration,
        retainerAmount: response?.data?.retainerAmount,
        budgetType: response?.data?.budgetType,

        // promo code
        promo: promoResponse?.data,
      });
    } else {
      setState({
        skipGetStarted: true,
        isEditMode: true,

        type: response?.data?.type,
        currentStep: isEditProject ? Steps[2] : Steps[step - 1],

        // project details
        title: response?.data?.title,
        keySkills: Array(response?.data?.skills).join(),
        skills: response?.data?.skills,
        description: response?.data?.description,
        keyResponsibilities: response?.data?.keyResponsibilities,
        qualifications: response?.data?.qualifications,
        companySite: response?.data?.companySite,
        docs: response?.data?.docs,

        // student personality
        studentPersonality,
        personalityRanking: studentPersonalityRanking,

        // budget
        hourlyRate: response?.data?.hourlyRate,
        hoursPerWeek: response?.data?.hoursPerWeek,
        durationType: response?.data?.durationType,
        duration: response?.data?.duration,
        retainerAmount: response?.data?.retainerAmount,
        budgetType: response?.data?.budgetType,
      });
    }
  };

  const init = async () => {
    if (user?.business?.atv?.optedIn && !projectId) {
      const promoResponse = await applyPromoCode({ promoCode: "ATV" });
      if (promoResponse.success)
        setState({ ...state, promo: promoResponse?.data });
    }
    if (!projectId) {
      return;
    }

    if (isEditProject && projectDetails) {
      return handleUpdateState({ data: projectDetails });
    }

    try {
      setState({ isLoading: true });

      const response = await getProjectDetails(projectId);

      if (response?.success) {
        handleUpdateState(response);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const handleDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const personality = state.personalityRanking.find(
      (elem: any) => elem.title === draggableId
    );

    if (!personality) {
      return;
    }

    const updatedPersonalityRanking = [...state.personalityRanking];

    updatedPersonalityRanking.splice(source.index, 1);

    updatedPersonalityRanking.splice(destination.index, 0, personality);

    setState({ personalityRanking: updatedPersonalityRanking });
  };

  const studentType = StudentTypes.find(
    (item: any) => item.value === state.type
  );

  const updateSkillSet = (index: number, skill: string) => {
    if (state?.skillSet.length === 1 && state?.skillSet[0].skill === "") {
      return setState({ skillSet: [{ id: index, skill }] });
    }
    const filteredSkillSet = state?.skillSet.filter(
      (item: any) => item.skill !== skill
    );
    if (filteredSkillSet.length === state.skillSet.length) {
      filteredSkillSet.push({ id: index, skill, level: "Low-Level" });
    }

    // if (filteredSkillSet.length > 3) return;
    // const tempIndex = tempSkills.findIndex((op: any) => op.id === index);
    // if (tempIndex !== -1) {
    //   temp.splice(tempIndex, 1);
    // } else {
    //   temp.push({ id: index, level: 0 });
    // }
    setState({
      skillSet: filteredSkillSet,
      skills: filteredSkillSet.map((data: any) => data.skill),
    });
  };

  const handleChangeSkill = (value: string, index: number) => {
    const skillSetCopy = state.skillSet.slice();
    const findedIndex = state?.skillSet.findIndex(
      (item: any) => item.id === index
    );
    skillSetCopy[findedIndex] = { id: index, skill: value };

    setState({
      skillSet: skillSetCopy,
      skills: skillSetCopy.map((data: any) => data.skill),
    });
  };

  const handleAddNewSkill = () => {
    if (state.newSkill.length > 0) {
      const temp = state.skillSet;
      temp.push({
        id: state.newSkillId,
        skill: state.newSkill,
      });
      setState({
        skillSet: [...temp],
        skills: temp.map((data: any) => data.skill),
        newSkillId: state.newSkillId + 1,
        newSkill: "",
      });
    }
  };

  return (
    <div>
      <div>
        <header
          className={`header ${manuBarExpend ? "body_pd" : ""}`}
          id="header"
        >
          <div className="w-25 d-flex align-items-center mob_100">
            <div className="header_toggle" onClick={() => manuBarToogle()}>
              <i
                className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
                id="header_toggle"
              />
            </div>
            <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center">
              <span className="d-block d-md-none me-3">
                <img src={require("../../../assets/imgs/logo.png")} />
              </span>
              <span>Make A Hire</span>
            </div>
          </div>
          <div className="w-50 text-center mob_100">
            <p className="text-uppercase mb-0">{state.currentStep.title}</p>
            <div>
              {progressBarPer.current.map((step: any, index: number) => (
                <span
                  className="bar"
                  style={{
                    position: "relative",
                  }}
                >
                  <span
                    className="bar"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: step.activeTab.isActive ? "#0b6fff" : "",
                      width: step.activeTab.percentage,
                    }}
                  />
                </span>
              ))}
            </div>
          </div>
        </header>
        {state?.currentStep?.label === StepLabels.CONFIRM && (
          <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
            Confirm Project
          </div>
        )}

        <div className="bg_light p-3 p-md-4 w-100 overflow-hidden mt-4 mt-md-0">
          {!state?.isProjectSubmitting && (
            <div className="flex justify-between items-center mb-2">
              <div className="d-flex align-items-center">
                <i className="fa-sharp fa-regular fa-arrow-left text-rw-blue" />
                <button className="text-rw-blue ms-3" onClick={handleBack}>
                  {/* Make a Hire */}
                  {state?.currentStep?.label === StepLabels.TYPE
                    ? "Cancel Project"
                    : "Make a Hire"}
                </button>
              </div>
              <button className="text-rw-blue" onClick={handleSaveAsDraft}>
                Save Project as Draft
              </button>
            </div>
          )}
          {state?.isProjectSubmitting && !isSaveAsDraft.current ? (
            <MatchingStudentsLoader />
          ) : state?.isProjectSubmitting && isSaveAsDraft.current ? (
            <AppLoader isLoading={state?.isProjectSubmitting} />
          ) : (
            <div className="p-3 p-md-5 bg_white justify-content-center border border_gray border_r_16px">
              <div className="middle_box">
                {state?.currentStep?.label === StepLabels.TYPE && (
                  <div className="w-full">
                    <div className="font-semibold font_16 mb-1 text-center">
                      What type of talent are looking for?
                    </div>
                    {StudentTypes.filter((item, index) => index !== 1).map(
                      (studentType) => (
                        <div
                          key={studentType?.title}
                          className={`border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4 cursor-pointer ${
                            isEditProject ? "bg-rw-disable" : ""
                          }`}
                          onClick={() =>
                            !isEditProject
                              ? handleChangeStudentType(studentType?.value)
                              : null
                          }
                        >
                          <img src={studentType?.icon} className="w-10 h-8" />
                          <div className="w-full flex justify-between items-center">
                            <div className="px-4">
                              <div
                                className={`${
                                  state?.type === studentType?.value
                                    ? "text-rw-blue"
                                    : ""
                                } text-lg font-semibold`}
                              >
                                {studentType?.title}
                              </div>

                              <div className="text-xs font-medium text-rw-gray-75">
                                {studentType?.description}
                              </div>
                            </div>

                            <div>
                              <Radio
                                checked={state?.type === studentType?.value}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    {state?.errors?.type && (
                      <ErrorMessage message={state?.errors?.type} />
                    )}
                  </div>
                )}
                {state?.currentStep?.label === StepLabels.PROJECT_TEMPLATE && (
                  <div className="w-full">
                    <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
                      Project Details
                    </div>

                    <div className="mt-4 font-medium text-rw-black-dark text-center">
                      Create a project or select from our prefilled projects.
                    </div>

                    <div className="w-full mt-4">
                      <div
                        className="border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4 cursor-pointer"
                        onClick={handleContinue}
                      >
                        <img src={Images.Edit} className="w-6 h-6" />

                        <div className="w-full flex justify-between items-center">
                          <div className="px-4">
                            <div className="text-lg font-semibold">
                              Custom Project
                            </div>

                            <div className="text-xs font-medium text-rw-gray-75">
                              Create a project from scratch
                            </div>
                          </div>

                          <div className="text-rw-blue">
                            <ChevronRightOutlinedIcon
                              sx={{ width: 36, height: 36 }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <hr className="my-8" />

                    {state.type === ProjectType.BUSINESS && (
                      <div className="w-full mt-4">
                        {ProjectTemplates.map((projectTemplate: any) => (
                          <div
                            key={projectTemplate.title}
                            className="border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4 cursor-pointer"
                            onClick={() =>
                              handleSelectProjectTemplate(projectTemplate)
                            }
                          >
                            <img
                              src={
                                StudentTypes.find(
                                  (elem) => elem.value === projectTemplate.type
                                )?.icon
                              }
                              className="w-6 h-6"
                            />

                            <div className="w-full flex justify-between items-center">
                              <div className="px-4">
                                <div className="text-lg font-semibold">
                                  {projectTemplate.title}
                                </div>

                                <div className="text-xs font-medium text-rw-gray-75">
                                  Edit from project template
                                </div>
                              </div>

                              <div className="text-rw-blue">
                                <ChevronRightOutlinedIcon
                                  sx={{ width: 36, height: 36 }}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {state?.currentStep?.label === StepLabels.TITLE && (
                  <div className="mt-8">
                    <div className="font-semibold mb-1">
                      What’s the title of your project?
                    </div>
                    <TextField
                      type="text"
                      name="title"
                      placeholder="Enter your project title"
                      value={state?.title}
                      onChange={handleChange}
                      error={state?.errors?.title}
                    />

                    <div className="text-xs text-[#808080] font-medium mt-2">
                      Samples: Product Designer Intern, Software Developer
                      Opportunity, Marketing Intern, New Business Intern, etc.
                    </div>
                  </div>
                )}
                {state?.currentStep?.label === StepLabels.SKILLS && (
                  <div>
                    <div className="font-semibold mb-1">
                      What skills should they have?
                    </div>
                    <div className="text-xs text-[#808080] font-medium mt-2">
                      If you don’t know, that’s okay. Just press continue.
                    </div>
                    {/* <div className="mt-3">
                      {state.skillList.map((data: string, index: number) => (
                        <span
                          key={index}
                          onClick={() => handleChangeKeySkills(data)}
                          className={`skill_tags cursor_pointer ${
                            state.skills.includes(data) ? "active" : ""
                          }`}
                        >
                          {data}
                        </span>
                      ))}
                    </div> */}

                    {/* Skill update */}

                    <div className="mt-3">
                      {state.skillList.map((data: string, index: number) => (
                        <span
                          key={index}
                          onClick={() => updateSkillSet(index, data)}
                          className={`skill_tags cursor_pointer ${
                            state?.skillSet?.find(
                              (item: any) => item.skill === data
                            )
                              ? "active"
                              : ""
                          }`}
                        >
                          {data}
                        </span>
                      ))}
                    </div>
                    <p className="fw-bold text_black font_18 mb-3 mt-4">
                      Selected Skills
                    </p>
                    {state?.skillSet?.length > 0 && (
                      <div className="d-flex mt-1">
                        <div className="w-100">
                          {state?.skillSet.map((da: any, index: number) => (
                            <div key={index} className="input-group mb-3">
                              <div
                                className="form-control input_skills input_style px-3 py-2"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                }}
                              >
                                <input
                                  disabled
                                  value={da.skill}
                                  type="text"
                                  className="w-100 "
                                  placeholder={da.skill}
                                  onChange={(e) =>
                                    handleChangeSkill(e.target.value, da.id)
                                  }
                                />
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    updateSkillSet(index, da.skill)
                                  }
                                >
                                  <img
                                    src={require("../../../assets/imgs/cross.png")}
                                    style={{ width: 30, height: 30 }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="input-group mb-3">
                      <input
                        // disabled

                        type="text"
                        value={state.newSkill}
                        className="form-control input_skills placeholder_light_color input_style"
                        placeholder="Enter new skill"
                        onChange={(e) => {
                          setState({ newSkill: e.target.value });
                          // handleChangeSkill(e.target.value, da.id)
                        }}
                      />
                    </div>
                    <div
                      className="input-group mb-3"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleAddNewSkill()}
                    >
                      <div className="form-control input_skills align-items-center d-flex">
                        <img
                          src={require("../../../assets/imgs/add.png")}
                          style={{ width: 30, height: 30 }}
                        />
                        <span className="ps-2 text_secondary">Add Skill</span>
                      </div>
                    </div>
                    {state?.errors?.keySkills && (
                      <ErrorMessage message={state?.errors?.keySkills} />
                    )}

                    {/* Skill update */}

                    {/* <div className="mt-8">
                      <TextField
                        type="text"
                        name="keySkills"
                        // className="mt-10"
                        placeholder="Enter key skillsets for your project"
                        value={state?.keySkills}
                        onChange={handleChangeKeySkills}
                        error={state?.errors?.keySkills}
                      />
                    </div>
                    <div className="flex flex-wrap items-center gap-4 mt-2">
                      {state?.skills?.map(
                        (skill: string) =>
                          skill && (
                            <div
                              key={skill}
                              className="w-fit bg-rw-blue text-white text-sm font-medium p-2 rounded-md flex justify-center items-center"
                            >
                              {skill}
                            </div>
                          )
                      )}
                    </div>
                    <div className="mt-2">
                      <span className="text-[#5E5E5E] font-medium text-sm">
                        Seperate skills by a comma
                      </span>
                    </div> */}
                  </div>
                )}
                {state?.currentStep?.label === StepLabels.DETAILS && (
                  <div className="w-full">
                    <div className="mt-4">
                      <div className="font-semibold mb-1">
                        Project Description
                      </div>

                      <RichTextEditorField
                        value={state?.description}
                        onChange={handleChangeDescription}
                        error={state?.errors?.description}
                      />
                    </div>

                    <div className="mt-8">
                      <div className="font-semibold mb-1">
                        Key Responsibilities
                      </div>

                      <RichTextEditorField
                        value={state?.keyResponsibilities}
                        onChange={handleChangeKeyResponsibilities}
                        error={state?.errors?.keyResponsibilities}
                      />
                    </div>

                    <div className="mt-8">
                      <div className="font-semibold mb-1">Qualifications</div>
                      <RichTextEditorField
                        value={state?.qualifications}
                        onChange={handleChangeQualifications}
                        error={state?.errors?.qualifications}
                      />
                    </div>
                  </div>
                )}
                {state?.currentStep?.label === StepLabels.DOCUMENT && (
                  <div className="w-full">
                    <div className="mt-4">
                      <div className="font-semibold mb-1">
                        Company Site
                        <span className="text-rw-gray-50"> (Optional)</span>
                      </div>

                      <TextField
                        type="text"
                        name="companySite"
                        placeholder="Company Website"
                        value={state?.companySite}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="mt-8">
                      <div className="font-semibold mb-1">
                        Document Upload
                        <span className="text-rw-gray-50"> (Optional)</span>
                      </div>

                      <div
                        className="border-[1px] border-[#00000040] border-dashed rounded-md p-4 h-40 flex justify-center items-center cursor-pointer"
                        onClick={handleDocsRef}
                      >
                        <div className="text-center">
                          <CloudUploadOutlinedIcon className="text-[#C1C1C1]" />

                          <div className="font-semibold text-[#C1C1C1]">
                            Browse Files
                          </div>

                          <div className="text-sm text-[#C1C1C180] mt-1">
                            Drag and drop files here
                          </div>
                        </div>
                      </div>

                      {!!state?.docs?.length && (
                        <div>
                          {state?.docs?.map((doc: any) => (
                            <div
                              key={doc?.fileName}
                              className="flex justify-between items-center p-2 bg-[#E3E4E7] rounded-md mt-4"
                            >
                              <span className="text-sm font-medium text-rw-gray-75 word-break">
                                {doc?.fileName}
                              </span>

                              <span
                                className="text-rw-red-dark cursor-pointer"
                                onClick={() => handleRemoveDoc(doc)}
                              >
                                <DeleteOutlineOutlinedIcon />
                              </span>
                            </div>
                          ))}
                        </div>
                      )}

                      <input
                        type="file"
                        className="hidden"
                        ref={docsRef}
                        multiple
                        onChange={handleDocsChange}
                      />
                    </div>
                  </div>
                )}
                {state?.currentStep?.label ===
                  StepLabels.STUDENT_PERSONALITY && (
                  <div className="w-full">
                    {/* <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
                      Student Personality
                    </div> */}

                    <div className="mt-4 font-medium text-rw-black-dark text-center">
                      All students have completed a personality assessment
                      designed around their project category. This helps you
                      gauge their preferred work style to ensure a better match.
                    </div>

                    <hr className="my-8" />

                    {state.studentPersonality && (
                      <div className="w-full mt-4">
                        <div className="border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4">
                          <img
                            src={state.studentPersonality.icon}
                            className="w-10 h-8"
                          />

                          <div className="w-full flex justify-between items-center">
                            <div className="text-lg font-semibold px-4">
                              {state.studentPersonality.title}
                            </div>

                            <button
                              className="text-rw-blue font-semibold border-[1px] border-rw-blue bg-rw-blue-light w-6 h-6 rounded-full flex justify-center items-center"
                              onClick={handleOpenPersonalityDefinitionsDialog}
                            >
                              i
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="mt-4 font-medium text-rw-black-dark text-center">
                      Rank the following personality types you prefer for your
                      project.
                    </div>

                    <div>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <div>
                          <Droppable droppableId="personality-traits">
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {state.personalityRanking.map(
                                  (personalityRanking: any, index: number) => (
                                    <Draggable
                                      draggableId={personalityRanking.title}
                                      index={index}
                                      key={personalityRanking.title}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className="w-full flex items-center"
                                        >
                                          <span className="font-semibold mr-2">
                                            {index + 1}.
                                          </span>

                                          <div
                                            key={personalityRanking.title}
                                            className="border-[1px] border-[#D7DBE1] rounded-md pl-4 pr-3 h-14 flex items-center my-4 w-full"
                                          >
                                            <img
                                              src={personalityRanking?.icon}
                                              className="w-6 h-6"
                                            />

                                            <div className="w-full flex justify-between items-center">
                                              <div className="text-lg font-semibold px-2">
                                                {personalityRanking?.title}
                                              </div>

                                              <div className="flex items-center">
                                                <div className="border h-14 mr-2" />
                                                <MenuOutlinedIcon />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                )}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      </DragDropContext>
                    </div>
                  </div>
                )}
                {state?.currentStep?.label === StepLabels.BUDGET_TYPE && (
                  <div className="w-full">
                    <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
                      Project Budget Type
                    </div>

                    <div className="mt-4 font-medium text-rw-black-dark text-center">
                      How would you like to pay the student?
                    </div>

                    <div className="w-full mt-4">
                      {ProjectBudgetTypes.map((budgetType) => (
                        <div
                          key={budgetType?.title}
                          className={`border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4 cursor-pointer ${
                            budgetType.disable ? "bg-rw-disable" : ""
                          }`}
                          onClick={() => {
                            if (!budgetType.disable)
                              handleChangeBudgetType(budgetType?.value);
                          }}
                        >
                          <img src={budgetType?.icon} className="w-10 h-8" />
                          <div className="w-full flex justify-between items-center">
                            <div className="px-4">
                              <div
                                className={`${
                                  state?.budgetType === budgetType?.value
                                    ? "text-rw-blue"
                                    : ""
                                } text-lg font-semibold`}
                              >
                                {budgetType?.title}
                              </div>

                              <div className="text-xs font-medium text-rw-gray-75">
                                {budgetType?.description}
                              </div>
                            </div>

                            <div>
                              <Radio
                                checked={
                                  state?.budgetType === budgetType?.value
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    {state?.errors?.budgetType && (
                      <ErrorMessage message={state?.errors?.budgetType} />
                    )}
                  </div>
                )}
                {state?.currentStep?.label === StepLabels.BUDGET && (
                  <div className="w-full">
                    {state.budgetType === BudgetType.FULL_TIME && (
                      <div>
                        <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
                          Full Time Position
                        </div>

                        <div className="mt-8 font-medium text-rw-black-dark text-center">
                          Post your job as a full time postition. You can
                          negotiate the salary with your student after they have
                          been selected.
                        </div>
                      </div>
                    )}

                    {state.budgetType === BudgetType.RETAINER && (
                      <div>
                        <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
                          Retainer Budget
                        </div>

                        <div className="mt-8">
                          <div className="text-xl font-bold text-rw-black-dark mb-1">
                            Retainer Amount
                          </div>

                          <TextField
                            type="number"
                            name="retainerAmount"
                            value={state.retainerAmount}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mt-8">
                          <div className="flex justify-between">
                            <div className="text-xl font-bold text-rw-black-dark">
                              Duration
                            </div>

                            <div className="text-xl font-bold text-rw-blue">
                              {state?.duration}{" "}
                              {state?.duration === 1 ? "Week" : "Weeks"}
                            </div>
                          </div>

                          <div className="flex items-center gap-2 mt-4">
                            <span className="text-[#D9D9D9] text-xs font-semibold">
                              1
                            </span>
                            <IOSSlider
                              min={1}
                              max={12}
                              value={state?.duration}
                              onChange={handleChangeDuration}
                            />
                            <span className="text-[#D9D9D9] text-xs font-semibold">
                              12
                            </span>
                          </div>
                        </div>
                        <div className="border-[1px] border-[#D9D9D9] border-dashed my-8" />

                        <QuoteSummary
                          hourlyRate={state?.hourlyRate}
                          hoursPerWeek={state?.hoursPerWeek}
                          duration={state?.duration}
                          durationType={state?.durationType}
                          studentCompensation={studentCompensation}
                          platformFee={platformFee}
                          totalCost={totalCost}
                          totalRetainerAmount={state?.retainerAmount}
                          budgetType={state.budgetType}
                          onOpenPromoCode={handleOpenPromoCodeDialog}
                          discountAmount={discountAmount}
                          showPremiumFeatures={shouldShowPremiumFeatures(user)}
                          promo={state.promo}
                        />
                      </div>
                    )}

                    {state.budgetType === BudgetType.HOURLY_RATE && (
                      <div>
                        <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
                          What’s your budget look like?
                        </div>

                        {shouldShowPremiumFeatures(user) && (
                          <div
                            className={`flex ${
                              state?.durationType === ProjectDurationType.PERIOD
                                ? "justify-between"
                                : "justify-center"
                            }  items-center bg-rw-blue-lightest rounded-md p-4 mt-4`}
                          >
                            <div className="text-center">
                              <div className="text-2xl text-rw-black-dark font-bold">
                                <FormattedNumber
                                  value={
                                    state?.durationType ===
                                    ProjectDurationType.UNTIL_ENDED
                                      ? totalCost
                                      : totalCost / state.duration
                                  }
                                  style="currency"
                                  currency="USD"
                                />
                              </div>
                              <div className="text-xs font-medium text-rw-gray-50">
                                Weekly Invoice Estimate
                              </div>
                            </div>

                            {state?.durationType ===
                              ProjectDurationType.PERIOD && (
                              <div className="h-10 m-0">
                                <Divider orientation="vertical" />
                              </div>
                            )}

                            {state?.durationType ===
                              ProjectDurationType.PERIOD && (
                              <div className="text-center">
                                <div className="text-2xl text-rw-black-dark font-bold">
                                  <FormattedNumber
                                    value={totalCost}
                                    style="currency"
                                    currency="USD"
                                  />
                                </div>
                                <div className="text-xs font-medium text-rw-gray-50">
                                  Total Project Estimate
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="mt-8">
                          <div className="flex justify-between">
                            <div>
                              <div className="text-xl font-bold text-rw-black-dark">
                                Hourly Rate
                              </div>
                              {shouldShowPremiumFeatures(user) && (
                                <div className="text-xs font-semibold text-[#00000040]">
                                  Suggested{" "}
                                  <span>${getSuggestedRate(state?.type)}</span>
                                  /HR
                                </div>
                              )}
                            </div>

                            <div className="text-right">
                              <div className="text-xl font-bold text-rw-blue">
                                ${state?.hourlyRate}/HR
                              </div>
                              {shouldShowPremiumFeatures(user) && (
                                <div className="text-xs font-semibold bg-rw-blue-lightest rounded-full px-2 py-1">
                                  <span className="text-[#00000080]">
                                    Student Compensation:
                                  </span>{" "}
                                  <span className="text-rw-blue">
                                    $
                                    {getStudentHourlyRate(
                                      state.hourlyRate,
                                      shouldShowPremiumFeatures(user),
                                      state.promo
                                    )}
                                    /HR
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="flex items-center gap-2 mt-4">
                            <span className="text-[#D9D9D9] text-xs font-semibold">
                              $15
                            </span>
                            <IOSSlider
                              min={15}
                              max={50}
                              value={state?.hourlyRate}
                              onChange={handleChangeHourlyRate}
                            />
                            <span className="text-[#D9D9D9] text-xs font-semibold">
                              $50
                            </span>
                          </div>
                        </div>

                        {shouldShowPremiumFeatures(user) && (
                          <>
                            <div className="mt-8">
                              <div className="flex justify-between">
                                <div className="text-xl font-bold text-rw-black-dark">
                                  Hours Per Week
                                </div>

                                <div className="text-xl font-bold text-rw-blue">
                                  {state?.hoursPerWeek} Hours
                                </div>
                              </div>

                              <div className="flex items-center gap-2 mt-4">
                                <span className="text-[#D9D9D9] text-xs font-semibold">
                                  5
                                </span>
                                <IOSSlider
                                  min={5}
                                  max={40}
                                  value={state?.hoursPerWeek}
                                  onChange={handleChangeHoursPerWeek}
                                />
                                <span className="text-[#D9D9D9] text-xs font-semibold">
                                  40
                                </span>
                              </div>
                            </div>

                            <div className="mt-8">
                              <div className="flex justify-between">
                                <div className="text-xl font-bold text-rw-black-dark">
                                  Duration
                                </div>

                                <div className="text-xl font-bold text-rw-blue">
                                  {state?.durationType ===
                                  ProjectDurationType.UNTIL_ENDED
                                    ? "N/A"
                                    : `${state?.duration} ${
                                        state?.duration === 1 ? "Week" : "Weeks"
                                      }`}
                                </div>
                              </div>

                              <div className="mt-4">
                                <div className="flex justify-between items-center">
                                  <div className="font-medium text-rw-black-slight-dark">
                                    Run this project until I end it
                                  </div>

                                  <div>
                                    <Radio
                                      checked={
                                        state?.durationType ===
                                        ProjectDurationType.UNTIL_ENDED
                                      }
                                      value={ProjectDurationType.UNTIL_ENDED}
                                      onChange={handleChangeDurationType}
                                    />
                                  </div>
                                </div>

                                <div className="flex justify-between items-center mt-2">
                                  <div className="font-medium text-rw-black-slight-dark">
                                    Set Duration
                                  </div>

                                  <div>
                                    <Radio
                                      checked={
                                        state?.durationType ===
                                        ProjectDurationType.PERIOD
                                      }
                                      value={ProjectDurationType.PERIOD}
                                      onChange={handleChangeDurationType}
                                    />
                                  </div>
                                </div>
                              </div>

                              {state?.durationType ===
                                ProjectDurationType.PERIOD && (
                                <div className="flex items-center gap-2 mt-4">
                                  <span className="text-[#D9D9D9] text-xs font-semibold">
                                    1
                                  </span>
                                  <IOSSlider
                                    min={1}
                                    max={10}
                                    value={state?.duration}
                                    onChange={handleChangeDuration}
                                  />
                                  <span className="text-[#D9D9D9] text-xs font-semibold">
                                    10
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="border-[1px] border-[#D9D9D9] border-dashed my-8" />

                            <QuoteSummary
                              hourlyRate={state?.hourlyRate}
                              hoursPerWeek={state?.hoursPerWeek}
                              duration={state?.duration}
                              durationType={state?.durationType}
                              studentCompensation={studentCompensation}
                              platformFee={platformFee}
                              totalCost={totalCost}
                              totalRetainerAmount={state?.retainerAmount}
                              budgetType={state.budgetType}
                              onOpenPromoCode={handleOpenPromoCodeDialog}
                              discountAmount={discountAmount}
                              showPremiumFeatures={shouldShowPremiumFeatures(
                                user
                              )}
                              promo={state.promo}
                            />
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {state?.currentStep?.label === StepLabels.CONFIRM && (
                  <div className="w-full">
                    {/* <div className="mt-8 text-3xl font-semibold text-rw-black-dark text-center">
                      Confirm Project
                    </div> */}

                    <div className="mt-4">
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Project Type
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() => handleEditStep(StepLabels.TYPE)}
                        >
                          Edit
                        </button>
                      </div>
                      {studentType && (
                        <div
                          className="border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4 cursor-pointer mt-2 mb-8"
                          onClick={() =>
                            handleChangeStudentType(studentType?.value)
                          }
                        >
                          <img src={studentType?.icon} className="w-10 h-8" />
                          <div className="w-full flex justify-between items-center">
                            <div className="px-4">
                              <div className="text-lg font-semibold">
                                {studentType?.title}
                              </div>

                              <div className="text-xs font-medium text-rw-gray-75">
                                {studentType?.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Project Title
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() => handleEditStep(StepLabels.TITLE)}
                        >
                          Edit
                        </button>
                      </div>

                      <div className="mt-2">
                        <TextField type="text" value={state?.title} readOnly />
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Project Skillsets
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() => handleEditStep(StepLabels.SKILLS)}
                        >
                          Edit
                        </button>
                      </div>

                      <div className="flex flex-wrap items-center gap-4 mt-2">
                        {state?.skills?.map((skill: string, index: number) => (
                          <div
                            key={index}
                            className="w-fit bg-rw-blue text-white text-sm font-medium p-2 rounded-md flex justify-center items-center"
                          >
                            {skill}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Project Description
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() => handleEditStep(StepLabels.DETAILS)}
                        >
                          Edit
                        </button>
                      </div>

                      <div
                        className="border-[1px] border-[#00000040] rounded-md mt-2 p-4 text-sm font-medium h-40 overflow-y-scroll"
                        dangerouslySetInnerHTML={{
                          __html: wrapHTMLContent(state?.description),
                        }}
                      />
                    </div>

                    <div className="mt-8">
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Key Responsibilities
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() => handleEditStep(StepLabels.DETAILS)}
                        >
                          Edit
                        </button>
                      </div>

                      <div
                        className="border-[1px] border-[#00000040] rounded-md mt-2 p-4 text-sm font-medium h-40 overflow-y-scroll"
                        dangerouslySetInnerHTML={{
                          __html: wrapHTMLContent(state?.keyResponsibilities),
                        }}
                      />
                    </div>

                    <div className="mt-8">
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Qualifications
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() => handleEditStep(StepLabels.DETAILS)}
                        >
                          Edit
                        </button>
                      </div>

                      <div
                        className="border-[1px] border-[#00000040] rounded-md mt-2 p-4 text-sm font-medium h-40 overflow-y-scroll"
                        dangerouslySetInnerHTML={{
                          __html: wrapHTMLContent(state?.qualifications),
                        }}
                      />
                    </div>

                    <div className="mt-8">
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Company Site
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() => handleEditStep(StepLabels.DOCUMENT)}
                        >
                          Edit
                        </button>
                      </div>

                      <TextField
                        type="text"
                        value={state?.companySite}
                        readOnly
                      />
                    </div>

                    <div className="mt-8">
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Document Upload
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() => handleEditStep(StepLabels.DOCUMENT)}
                        >
                          Edit
                        </button>
                      </div>

                      <div
                        className={`flex justify-center items-center h-40 border-[1px] ${
                          !state?.doc
                            ? "border-[#00000040] border-dashed"
                            : "border-rw-blue"
                        } rounded-md mt-2 p-4`}
                      >
                        {!state?.doc && !state?.docBase64 ? (
                          <div className="text-center">
                            <CloudUploadOutlinedIcon className="text-[#C1C1C1]" />

                            <div className="font-semibold text-[#C1C1C1]">
                              Browse Files
                            </div>

                            <div className="text-sm text-[#C1C1C180] mt-1">
                              Drag and drop files here
                            </div>
                          </div>
                        ) : (
                          <div className="text-center">
                            <CheckCircleOutlinedIcon className="text-rw-blue" />

                            <div className="text-rw-blue font-semibold">
                              File Uploaded
                            </div>

                            <div className="text-sm text-[#C1C1C180] mt-1">
                              Select to upload again
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="mt-8">
                      <div className="flex justify-between items-center">
                        <div className="text-rw-black-dark font-semibold text-lg">
                          Personality Ranking
                        </div>
                        <button
                          className="font_16 text_secondary cursor_pointer"
                          onClick={() =>
                            handleEditStep(StepLabels.STUDENT_PERSONALITY)
                          }
                        >
                          Edit
                        </button>
                      </div>

                      {state.studentPersonality && (
                        <div className="w-full mt-4">
                          <div className="border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4 cursor-pointer">
                            <img
                              src={state.studentPersonality.icon}
                              className="w-10 h-8"
                            />

                            <div className="w-full flex justify-between items-center">
                              <div className="text-lg font-semibold px-4">
                                {state.studentPersonality.title}
                              </div>

                              <button
                                className="text-rw-blue font-semibold border-[1px] border-rw-blue bg-rw-blue-light w-6 h-6 rounded-full flex justify-center items-center"
                                onClick={handleOpenPersonalityDefinitionsDialog}
                              >
                                i
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      <div>
                        {state.personalityRanking.map(
                          (personalityRanking: any, index: number) => (
                            <div className="w-full flex items-center">
                              <span className="font-semibold mr-2">
                                {index + 1}.
                              </span>

                              <div
                                key={personalityRanking.title}
                                className="border-[1px] border-[#D7DBE1] rounded-md pl-4 pr-3 h-14 flex items-center my-4 cursor-pointer w-full"
                              >
                                <img
                                  src={personalityRanking?.icon}
                                  className="w-6 h-6"
                                />

                                <div className="w-full flex justify-between items-center">
                                  <div className="text-lg font-semibold px-2">
                                    {personalityRanking?.title}
                                  </div>

                                  <div className="flex items-center">
                                    <div className="border h-14 mr-2" />
                                    <MenuOutlinedIcon />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    <div className="mt-8">
                      {shouldShowPremiumFeatures(user) ? (
                        <QuoteSummary
                          editable
                          onEditStep={() => handleEditStep(StepLabels.BUDGET)}
                          hourlyRate={state?.hourlyRate}
                          hoursPerWeek={state?.hoursPerWeek}
                          duration={state?.duration}
                          durationType={state?.durationType}
                          studentCompensation={studentCompensation}
                          platformFee={platformFee}
                          totalCost={totalCost}
                          totalRetainerAmount={state?.retainerAmount}
                          budgetType={state.budgetType}
                          discountAmount={discountAmount}
                          showPremiumFeatures={shouldShowPremiumFeatures(user)}
                          promo={state.promo}
                        />
                      ) : (
                        <div>
                          {state.budgetType === BudgetType.HOURLY_RATE && (
                            <div className="flex flex-col gap-4">
                              <div className="flex justify-between">
                                <div>Hourly Rate Summary</div>
                                <div
                                  className="font_16 text_secondary cursor_pointer"
                                  onClick={() =>
                                    handleEditStep(StepLabels.BUDGET)
                                  }
                                >
                                  Edit
                                </div>
                              </div>
                              <div className="bg-rw-blue-lightest rounded-md p-4 mt-4 flex justify-between">
                                <div className="text-sm text-rw-black-slight-dark font-semibold">
                                  Hourly Rate
                                </div>
                                <div className="rw-black-dark font-medium">
                                  ${state.hourlyRate}/hr
                                </div>
                              </div>
                            </div>
                          )}
                          {state.budgetType === BudgetType.RETAINER && (
                            <div className="flex flex-col gap-4">
                              <div className="flex justify-between">
                                <div>Retainer Summary</div>
                                <div
                                  className="font_16 text_secondary cursor_pointer"
                                  onClick={() =>
                                    handleEditStep(StepLabels.BUDGET)
                                  }
                                >
                                  Edit
                                </div>
                              </div>
                              <div className="bg-rw-blue-lightest rounded-md p-4 mt-4 flex justify-between">
                                <div className="text-sm text-rw-black-slight-dark font-semibold">
                                  Student Retainer
                                </div>
                                <div className="rw-black-dark font-medium">
                                  ${state.retainerAmount}
                                </div>
                              </div>
                            </div>
                          )}
                          {state.budgetType === BudgetType.FULL_TIME && (
                            <div className="flex flex-col gap-4">
                              <div className="flex justify-between">
                                <div>Full Time Role</div>
                                <div
                                  className="font_16 text_secondary cursor_pointer"
                                  onClick={() =>
                                    handleEditStep(StepLabels.BUDGET)
                                  }
                                >
                                  Edit
                                </div>
                              </div>
                              <div className="bg-rw-blue-lightest rounded-md p-4 mt-4 text-sm text-rw-black-slight-dark font-medium text-center">
                                Post your job as a full time postition. You can
                                negotiate the salary with your student after
                                they have been selected.
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="w-full mt-8">
                  {state?.currentStep?.label === StepLabels.CONFIRM ? (
                    <Button
                      className="font-medium w-full mt-4"
                      onClick={() => handleSubmit("submit project")}
                    >
                      Submit Project
                    </Button>
                  ) : state?.currentStep?.label ===
                    StepLabels.PROJECT_TEMPLATE ? null : (
                    <Button
                      className={`font-medium w-full ${
                        state.isBtnDisable ? "bg-rw-disable" : ""
                      }`}
                      disabled={state.isBtnDisable}
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
                  )}

                  {state?.currentStep?.id > 1 && (
                    <Button
                      variant="secondary"
                      className="font-medium w-full mt-4"
                      onClick={handleBack}
                    >
                      Back
                    </Button>
                  )}

                  {state?.currentStep?.label ===
                    StepLabels.STUDENT_PERSONALITY && (
                    <button
                      className="text-rw-blue font-semibold underline text-center w-full mt-8"
                      onClick={handleContinue}
                    >
                      SKIP SELECTION
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {state?.shouldShowPersonalityDefinitionsDialog && (
        <PersonalityDefinitionsDialog
          open={state?.shouldShowPersonalityDefinitionsDialog}
          onClose={handleClosePersonalityDefinitionsDialog}
          type={state?.type}
        />
      )}

      {state?.shouldShowPromoCodeDialog && (
        <PromoCodeDialog
          open={state?.shouldShowPromoCodeDialog}
          onApply={handleApplyPromoCode}
          onClose={handleClosePromoCodeDialog}
        />
      )}
    </div>
  );
}
