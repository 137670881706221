import React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { googleLogout } from "@react-oauth/google";
import { wrapFullName, wrapNameTwoLetters } from "utils/common";
import { AccountType, RUNWAY_TOKEN } from "utils/constants";

type Props = {
  user: any;
};

export default function UserDetails({ user }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const is_social_login = await localStorage.getItem("is_social_login");
    if (is_social_login && is_social_login === "true") {
      googleLogout();
      localStorage.removeItem("is_social_login");
    }
    localStorage.removeItem(RUNWAY_TOKEN);
    amplitude.reset();
    window.location.href = "/";
  };

  return (
    <div className="flex flex-col lg:flex-row items-center cursor-pointer">
      <div className="mr-1" style={{ marginRight: 10 }}>
        <div
          className="text-[18px] font-semibold lg:mt-0 text-right"
          style={{ textWrap: "nowrap" }}
        >
          {wrapFullName(user?.firstName, user?.lastName)}
        </div>
        <div
          className="text-[10px] text-rw-blue font-semibold"
          style={{ textWrap: "nowrap" }}
        >
          {user?.accountType === AccountType.BUSINESS
            ? `${user?.business?.name} Services`
            : `${user?.university}`}
        </div>
      </div>

      <div
        className="bg-rw-blue rounded-full w-10 h-10 flex justify-center items-center"
        onClick={handleOpenMenu}
      >
        <span className="text-white text-[18px] font-medium">
          {user?.avatar?.url ? (
            <div className="user_img">
              <img
                className="rounded-full object-cover"
                src={user?.avatar?.url}
              />
            </div>
          ) : (
            wrapNameTwoLetters(user?.firstName, user?.lastName)
          )}
        </span>
      </div>

      {anchorEl && (
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleLogout}>
            <span className="text-rw-red-dark font-semibold">Logout</span>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
}
