import React, { useState, useEffect, useCallback } from "react";
import ProjectCard from "../ProjectCard";
import useEmblaCarousel from "embla-carousel-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckIcon from "@mui/icons-material/Check";
import { useMergeState, useWindowSize } from "utils/custom-hooks";
import { ProjectStatus } from "utils/constants";

const TitleLogo = ({
  title,
  tittleText,
}: {
  title: number;
  tittleText: string;
}) => {
  const textSize = useWindowSize().width < 460 ? 16 : 24;
  const icons: any = {
    0: { icon: "far fa-redo-alt", className: "refresh_icon me-3" },
    1: { icon: "fal fa-clock", className: "text_orange me-3" },
    2: { icon: "fal fa-clock", className: "text_secondary me-3" },
    3: { icon: "fal fa-clock", className: "text_secondary me-3" },
    4: { icon: "fal fa-clock", className: "text_secondary me-3" },
    5: { icon: "fal fa-clock", className: "text_secondary me-3" },
    6: { icon: "fa-solid fa-check", className: "text_redish me-3" },
    7: { icon: "fa-light fa-file-zipper", className: "text_redish me-3" },
  };
  return (
    <h4
      className="fs-4 fw_500 text_black mb-3"
      style={{
        fontSize: textSize,
        fontFamily: "system-ui",
      }}
    >
      <span className={icons[title].className}>
        <i className={icons[title].icon} />
      </span>
      {tittleText}{" "}
    </h4>
  );
  // return title === 0 ? (
  //   <h4
  //     className="fs-4 fw_500 text_black mb-3"
  //     style={{
  //       fontSize: textSize,
  //       fontFamily: "system-ui",
  //     }}
  //   >
  //     <span className="refresh_icon">
  //       <i className="far fa-redo-alt" aria-hidden="true" />
  //     </span>
  //     {"  "}
  //     {tittleText}{" "}
  //   </h4>
  // ) : title === 1 ? (
  //   <h4
  //     className="fs-4 fw_500 text_black mb-3"
  //     style={{
  //       fontSize: textSize,
  //       fontFamily: "system-ui",
  //     }}
  //   >
  //     <span className={`${"text_orange"} me-3`}>
  //       <i className="fal fa-clock" aria-hidden="true" />
  //     </span>
  //     {tittleText}{" "}
  //   </h4>
  // ) : (
  //   <h4
  //     className="fs-4 fw_500 text_black mb-3"
  //     style={{
  //       fontSize: textSize,
  //       fontFamily: "system-ui",
  //     }}
  //   >
  //     <span className={`${title < 6 ? "text_secondary" : "text_redish"} me-3`}>
  //       {title < 6 ? (
  //         <i className="fal fa-clock" aria-hidden="true" />
  //       ) : (
  //         <CheckIcon style={{ width: 30, height: 30 }} />
  //       )}
  //       {/* <CheckIcon style={{ width: 30, height: 30 }} /> */}
  //     </span>
  //     {tittleText}{" "}
  //   </h4>
  // );
};

const Carousel = ({
  list,
  title,
  tittleText,
  isStudent,
  link = "/projects/details?",
}: {
  list: any;
  title: number;
  tittleText: string;
  isStudent: boolean;
  link?: string;
}) => {
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);
  // const [state, setState] = useMergeState({
  //   isEmpty: false,
  // });
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    slidesToScroll: 1,
  });

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setCanScrollNext(emblaApi.canScrollNext());
      setCanScrollPrev(emblaApi.canScrollPrev());
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect);
      onSelect();
    }
  }, [emblaApi]);

  return (
    <div className="bg_white p-3 p-md-4 border_r_16px mt-4 border border_gray embla carouselMainDiv ">
      <div className="carouselTopDiv">
        <TitleLogo title={title} tittleText={tittleText} />
        <div>
          <button
            disabled={!canScrollPrev}
            className="owl-prev"
            onClick={() => emblaApi?.scrollPrev()}
          >
            <ChevronLeftIcon
              className={`carouselArrow ${
                canScrollPrev ? "text_secondary" : ""
              }`}
              sx={{ width: 40, height: 40 }}
              style={{ marginRight: -10 }}
            />
          </button>
          <button
            disabled={!canScrollNext}
            type="button"
            onClick={() => emblaApi?.scrollNext()}
          >
            <ChevronRightIcon
              className={`carouselArrow ${
                canScrollNext ? "text_secondary" : ""
              }`}
              sx={{ width: 40, height: 40 }}
            />
          </button>
        </div>
      </div>
      <div
        className="embla__viewport"
        style={{ overflow: "hidden" }}
        ref={emblaRef}
      >
        <div className="embla__container">
          {list.map((data: any, index: any) => (
            <div className="embla__slide" key={index}>
              <ProjectCard
                projectName={data.projectName}
                description={data.description}
                skillList={data.skillList}
                projectStatus={title}
                hourlyRate={title === 1 ? undefined : data.hourlyRate}
                updatedAt={data.updatedAt}
                isStudent={isStudent}
                companyLogo={data.companyLogo}
                owner={data.owner}
                business={data.business}
                link={`${link}id=${data.id}`}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Carousel;
