import React, { useState, useCallback, useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InterviewLink from "components/MyProjects/RequestInterviewProject/InterviewLink";

const UpcomingInterviews = ({
  upComingInterviews = [],
}: {
  upComingInterviews?: any[];
}) => {
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(true);
  // const [state, setState] = useMergeState({
  //   isEmpty: false,
  // });
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    slidesToScroll: 1,
  });

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setCanScrollNext(emblaApi.canScrollNext());
      setCanScrollPrev(emblaApi.canScrollPrev());
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect);
      onSelect();
    }
  }, [emblaApi]);
  return (
    <>
      <div className="carouselTopDiv">
        <p className="fs-5 fw-bold text_black mb-2">Upcoming Interviews</p>
        <div>
          <button
            disabled={!canScrollPrev}
            className="owl-prev"
            onClick={() => emblaApi?.scrollPrev()}
          >
            <ChevronLeftIcon
              className={`carouselArrow ${
                canScrollPrev ? "text_secondary" : ""
              }`}
              sx={{ width: 40, height: 40 }}
              style={{ marginRight: -10 }}
            />
          </button>
          <button
            disabled={!canScrollNext}
            type="button"
            onClick={() => emblaApi?.scrollNext()}
          >
            <ChevronRightIcon
              className={`carouselArrow ${
                canScrollNext ? "text_secondary" : ""
              }`}
              sx={{ width: 40, height: 40 }}
            />
          </button>
        </div>
      </div>
      <div
        className="embla__viewport"
        style={{ overflow: "hidden" }}
        ref={emblaRef}
      >
        <div className="embla__container">
          {upComingInterviews.map((item: any, index: any) => (
            <div className="embla__slide" key={index}>
              <InterviewLink
                key={index}
                type={item?.interviewType === "external" ? 1 : 0}
                link={item?.link}
                date={item?.date}
                time={item?.time}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default UpcomingInterviews;
