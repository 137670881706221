import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "components/Button";
import AppLoader from "components/AppLoader";
import { useMergeState } from "utils/custom-hooks";
import { wrapFullName } from "utils/common";
import { listBusiness, signInAsBusiness } from "api";
import { RUNWAY_TOKEN } from "utils/constants";

type Props = {
  setUser: (user: any) => void;
};

export default function AdminContainer({ setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    isLoading: false,
    business: [],
  });

  const handleSignInAsBusiness = async (businessId: string) => {
    try {
      const response = await signInAsBusiness({ id: businessId });

      if (response?.success) {
        localStorage.setItem(RUNWAY_TOKEN, response?.data?.token);

        setUser(response?.data?.user);

        navigate("/projects");
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await listBusiness();

      setState({
        business: response?.data,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return (
    <div className="flex justify-center items-center">
      {state?.isLoading ? (
        <AppLoader isLoading={state?.isLoading} />
      ) : (
        <div className="w-11/12 lg:w-10/12 mt-8">
          <div className="text-2xl font-bold my-4">Businesses</div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Sr. No.</TableCell>
                  <TableCell>Business Name</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>ATV Opt Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state?.business?.map((business: any, index: number) => (
                  <TableRow
                    key={business._id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {business.name}
                    </TableCell>
                    <TableCell>
                      {wrapFullName(
                        business?.owner?.firstName,
                        business?.owner?.lastName
                      )}
                    </TableCell>
                    <TableCell>{business?.owner?.email}</TableCell>
                    <TableCell>{business?.owner?.phone}</TableCell>
                    <TableCell>
                      {!business?.atv
                        ? "Not ATV Company"
                        : !business?.atv?.optedIn
                        ? "Not Opted In"
                        : "Opted In"}
                    </TableCell>
                    <TableCell>
                      <Button
                        className="font-semibold"
                        onClick={() => handleSignInAsBusiness(business._id)}
                      >
                        Sign In
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}
