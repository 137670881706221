import React from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { ThemeProvider } from "@mui/material/styles";
import { IntlProvider } from "react-intl";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";

// MUI theme
import theme from "mui";

// containers
import Routes from "routes";

const options = {
  api_host: "https://us.i.posthog.com",
};

export default function App() {
  React.useEffect(() => {
    if (process.env.REACT_APP_AMPLITUDE_API_KEY) {
      amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY);
    }
  }, []);

  return (
    <PostHogProvider
      apiKey="phc_D398hoI6FVuiVqAxX5W1Benx92EMJkXOKXEQUq4zPwM"
      options={options}
    >
      <ThemeProvider theme={theme}>
        <IntlProvider locale="en" defaultLocale="en">
          <SnackbarProvider
            maxSnack={3}
            autoHideDuration={3000}
            hideIconVariant
            preventDuplicate
          >
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </SnackbarProvider>
        </IntlProvider>
      </ThemeProvider>
    </PostHogProvider>
  );
}
