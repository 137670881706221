import React from "react";

type Props = {
  variant?: string;
  className?: string;
  children: any;
  loading?: boolean;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export default function Button({
  variant = "primary",
  className,
  children,
  loading,
  disabled = false,
  onClick,
}: Props) {
  return (
    <button
      disabled={disabled}
      className={`${
        variant === "primary"
          ? "hover:bg-blue-600 bg-rw-blue text-white"
          : "hover:bg-slate-100 bg-white text-rw-blue border-2 border-rw-blue"
      } ${
        disabled ? "hover:bg-rw-disable bg-rw-disable" : ""
      } rounded-md flex justify-center items-center p-2 min-h-[48px] lg:min-h-[48px] ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}
