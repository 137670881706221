import Images from "../../../assets/images/index";
import InfoIcon from "@mui/icons-material/Info";
import { useSnackbar } from "notistack";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { useMergeState } from "utils/custom-hooks";
import { PersonalityTests, PersonalityTestColors } from "utils/constants";
import TestBreakDownDialog from "components/TestBreakDownDialog/index";
import UserDetails from "components/UserDetails";
import { addResultsToUserState } from "./helper";

type Props = {
  user: any;
};

export default function ResultsContainer({ user }: Props) {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  let type = params.get("type");
  if (!type) {
    type = "";
  }

  let resultTypes: any = {};
  if (type === "designer") {
    resultTypes = PersonalityTests.designer.results;
  } else if (type === "developer") {
    resultTypes = PersonalityTests.developer.results;
  } else if (type === "engineer") {
    resultTypes = PersonalityTests.engineer.results;
  } else if (type === "business") {
    resultTypes = PersonalityTests.business.results;
  }

  const location = useLocation();
  const results: any = location.state?.results;
  const topChoiceType: any = location.state?.topChoiceType;
  const projectType: any = location.state?.projectType || null;
  const projectId: any = location.state?.projectId || null;

  addResultsToUserState(results, type, user);

  const [state, setState] = useMergeState({
    showPersonalityInfographicPopup: false,
  });

  const togglePopUp = () => {
    setState({
      showPersonalityInfographicPopup: !state.showPersonalityInfographicPopup,
    });
  };

  const handleContinue = () => {
    if (projectType) {
      enqueueSnackbar(
        "Your test has successfully completed. please apply to project now",
        { variant: "success" }
      );
    }
    navigate("/personality-tests", { state: { projectType, projectId } });
  };

  return (
    <div>
      <div className="h-20 flex items-center px-4">
        {/* <div className="hidden lg:flex items-center w-1/4" /> */}

        <div className="w-full">
          <div className="text-center">
            <div className="text-rw-gray-0 text-center text-2xl font-semibold">
              Personality Test Results
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="mt-7 flex justify-center">
        <div className="md:border-[1px] rounded-2xl border-solid border-rw-gray-gainsboro bg-white px-4 py-12">
          <div className="flex justify-center">
            <img src={Images.Innovator} className="w-36 h-36 mb-6" />
          </div>
          <div className="text-black text-3xl text-center font-semibold mb-5">
            {topChoiceType[0]}
          </div>
          <div className="flex justify-center">
            <div className="w-11/12 md:w-80 text-rw-black-raisin text-center mb-8">
              {resultTypes[topChoiceType[0]]?.text}
            </div>
          </div>
          <div className="flex justify-center">
            <div className="h-52 border-[1px] border-solid border-rw-gray-gainsboro shadow-md bg-white rounded-xl py-5 md:px-4 px-3">
              <div className="flex justify-between mb-6">
                <div className="text-rw-gray-0 text-base md:text-xl font-semibold">
                  Test Breakdown
                </div>

                <InfoIcon
                  className="hover:cursor-pointer"
                  onClick={togglePopUp}
                />
              </div>

              {Object.keys(results).map((key: string, index: number) => {
                return (
                  <div className="flex justify-between gap-3 mb-2">
                    <div className="text-rw-gray-0 text-xs md:text-sm font-medium w-[60%]">
                      {key}
                    </div>
                    <div className="relative w-48 h-5 bg-rw-blue-lightest rounded-md">
                      <div
                        className={`absolute text-white text-center text-xs font-bold ${PersonalityTestColors[index]} rounded-md`}
                        style={{
                          width: `${Math.round((results[key] / 100) * 194)}px`,
                        }}
                      >
                        {results[key]}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex justify-center mt-11">
            <button
              className="w-11/12 sm:w-[100%] bg-rw-baby-blue-dark text-white text-center text-lg font-medium py-4 rounded-md flex justify-center items-center"
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </div>
        {state.showPersonalityInfographicPopup && (
          <TestBreakDownDialog
            open={state.showPersonalityInfographicPopup}
            onClose={togglePopUp}
            resultDescriptions={resultTypes}
            type={type}
          />
        )}
      </div>
    </div>
  );
}
