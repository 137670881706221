import React from "react";
import CheckBox from "@mui/material/Checkbox";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Button from "components/Button";
import { ProjectType } from "utils/constants";
import Images from "assets/images";

const ProjectTypeFilters = [
  {
    icon: Images.Developer,
    title: "Development",
    value: ProjectType.DEVELOPER,
  },
  // {
  //   icon: Images.Designer,
  //   title: "Design",
  //   value: ProjectType.DESIGNER,
  // },
  {
    icon: Images.Engineer,
    title: "Engineering",
    value: ProjectType.ENGINEER,
  },
  {
    icon: Images.Business,
    title: "Business",
    value: ProjectType.BUSINESS,
  },
];

type Props = {
  types: Array<string>;
  onChange: (value: string) => void;
  showFilterMenu: boolean;
  onCloseFilter: () => void;
  onOpenFilter: () => void;
  onClearFilter: () => void;
};

export default function ExploreProjectsFilter({
  types,
  onChange,
  showFilterMenu,
  onCloseFilter,
  onOpenFilter,
  onClearFilter,
}: Props) {
  return (
    <div>
      {!showFilterMenu && (
        <button
          className="w-full lg:hidden bg-white border-[1px] border-[#E3E3E3] rounded-md flex justify-center items-center py-3 my-4"
          onClick={onOpenFilter}
        >
          <span className="text-rw-blue">
            <TuneOutlinedIcon />
            <span className="ml-2 font-medium">Filter</span>
          </span>
          <span className="text-rw-gray ml-2">(1)</span>
        </button>
      )}

      <div
        className={`${
          !showFilterMenu ? "hidden lg:block rounded-md" : ""
        } lg:mt-8`}
      >
        <div className="text-xl lg:text-base lg:hidden flex justify-between items-center lg:px-4 py-3">
          <div className="font-semibold">Filter</div>

          <button className="font-semibold text-rw-red" onClick={onClearFilter}>
            Clear all
          </button>
        </div>

        <hr className="block lg:hidden" />

        <div className="lg:px-3">
          {ProjectTypeFilters.map((filter) => (
            <div
              key={filter?.value}
              className="cursor-pointer flex justify-between items-center border-[1px] border-[#00000040] rounded-md my-4 px-4 py-2"
              onClick={() => onChange(filter?.value)}
            >
              <div className="flex gap-4 items-center">
                <img src={filter?.icon} className="w-6 h-6" />
                <span className="lg:text-sm font-medium">{filter?.title}</span>
              </div>
              <CheckBox
                value={filter?.value}
                className="w-6"
                checked={types.includes(filter?.value)}
              />
            </div>
          ))}

          <Button
            className="w-full h-14 mt-8 font-semibold lg:hidden"
            onClick={onCloseFilter}
          >
            View Projects
          </Button>

          <Button
            variant="secondary"
            className="w-full h-14 mt-4 font-semibold lg:hidden"
            onClick={onCloseFilter}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
}
