import React, { useEffect } from "react";
import { useMergeState } from "utils/custom-hooks";
import {
  updateProject,
  startProject,
  matchingStudents,
  getInterviews,
} from "api";
import ProjectStatusComp from "../ProjectStatus";
import ListedProjectOverview from "./Overview";
import CandidatesList from "./CandidatesList";
import StudentProfile from "./StudentProfile";
import ProjectStartedTips from "../OngoingProject/ProjectStartedTips";
import { StudentProjectStatus } from "utils/constants";

type Props = {
  user: any;
  studentId: any;
  projectId: string;
  project: any;
  status?: string;
  title: string;
  type: string;
  description: string;
  responsibilties: string[];
  qualifications: string[];
  skills: string[];
  studentList: [];
  interviewTimes: any;
  duration: number;
  hourlyRate?: number;
  jobDocumentUrl: string;
  companyLogo?: any;
  business?: any;
  updateSelectedUserName?: (name: string, data: any) => void;
  handleChangeProjectStatus: (status: string) => void;
  handleToggleEditButton: (isEdit: any) => void;
  handleChangeCapHour?: any;
  handleChangeHoursPerWeek?: any;
  handleChangeHourlyRate?: any;
  handleEditProject?: any;
};

const ListedProject = ({
  user,
  projectId,
  studentId,
  project,
  status,
  title,
  type,
  description,
  responsibilties,
  qualifications,
  skills,
  studentList,
  interviewTimes,
  duration = 0,
  hourlyRate = 0,
  jobDocumentUrl = "",
  companyLogo = null,
  business = null,
  handleChangeCapHour = () => {},
  handleChangeHoursPerWeek = () => {},
  handleChangeHourlyRate = () => {},
  handleEditProject = () => {},
  updateSelectedUserName = () => {},
  handleChangeProjectStatus = () => {},
  handleToggleEditButton,
}: Props) => {
  const [state, setState] = useMergeState({
    showOverView: true,
    // studentId: { email: "" },
    student: {},
    showSelection: false,
    selectedStudent: [],
    studentStatus: "",
    changeStatus: true,
    matchingData: {},
    upComingInterviews: [],
    activeTab: "Overview",
  });

  const handleOpenProfile = (
    id: string,
    studentStatus: string,
    name: string
  ) => {
    if (status === "IN_ACTIVE") return;
    updateSelectedUserName(name, id);
    setState({ studentStatus });
  };

  const handleSelect = () => {
    setState({ showSelection: true });
  };

  const handleStartProject = async () => {
    if (state.selectedStudent.length === 0) {
      alert("Please select student");
    } else {
      const response = await startProject({
        id: projectId,
        students: [...state.selectedStudent],
      });

      if (response.success) {
        setState({ changeStatus: false, showSelection: false });
      }
    }
  };

  // const updateSelectedStudent = (id: string) => {
  //   if (state.selectedStudent.includes(id)) {
  //     const index = state.selectedStudent.indexOf(id);
  //     const temp = state.selectedStudent;
  //     temp.splice(index, 1);
  //     setState({ selectedStudent: [...temp] });
  //   } else {
  //     const temp = state.selectedStudent;
  //     temp.push(id);
  //     setState({ selectedStudent: [...temp] });
  //   }
  // };

  const updateSelectedStudent = (id: string) => {
    const selectedStudentCopy = state.selectedStudent.filter(
      (item: any) => item !== id
    );
    if (selectedStudentCopy.length === state.selectedStudent.length) {
      setState({ selectedStudent: [...selectedStudentCopy, id] });
    } else setState({ selectedStudent: selectedStudentCopy });
  };

  const currentStudent = (details: any) => {
    setState({ student: details });
  };

  const init = async () => {
    const interviewRequestedStudents = project.studentProjects.filter(
      (student: any) =>
        student.status === StudentProjectStatus.REQUESTED_TO_INTERVIEW
    );
    const params = {
      projectId,
      isUpcoming: true,
      studentId: interviewRequestedStudents.map(
        (item: any) => item.student._id
      ),
    };
    const promise = await Promise.all(
      interviewRequestedStudents.length > 0
        ? [matchingStudents({ projectId }), getInterviews(params)]
        : [matchingStudents({ projectId })]
    );
    // const response = await matchingStudents({ projectId });
    const response = promise[0];
    let upComingInterviews = [];
    let matchingData = {};
    if (response.success) {
      matchingData = response.data;
    }
    if (interviewRequestedStudents.length > 0) {
      const interviewsResponse = promise[1];
      if (interviewsResponse.success) {
        upComingInterviews = interviewsResponse.data;
      }
    }
    setState({ matchingData, upComingInterviews });
  };

  useEffect(() => {
    init();
  }, []);

  return studentId?.email === "" ? (
    <div>
      <div className="p-4 bg-white w-100 border border_gray border_r_16px">
        <ProjectStatusComp
          type={0}
          buttonTitle={
            state?.changeStatus
              ? state?.showSelection
                ? "Start Project"
                : "Select Student"
              : "Continue"
          }
          buttonOnClick={() => {
            if (state?.changeStatus === true) {
              if (state?.showSelection) {
                handleStartProject();
              } else {
                handleSelect();
              }
            } else {
              window.location.reload();
            }
          }}
          projectId={projectId}
          showStatusButton
          statusChangeButton={state.changeStatus}
          checkStatus={status}
          title={title}
          titleType={type}
          showDeclineButton={state.showSelection}
          declineButtonTitle="Cancel"
          companyLogo={companyLogo}
          business={business}
          handleDecline={() => setState({ showSelection: false })}
          handleChangeProjectStatus={handleChangeProjectStatus}
        />
      </div>
      {state.changeStatus === true && (
        <div className="bg-white w-100 border border_gray border_r_16px border_sm_r_0 mt-3 mb-4">
          <ul
            className="nav nav-tabs nav_project_tabs px-2 pt-2"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                onClick={() => setState({ activeTab: "Overview" })}
                className={`nav-link ${
                  state.activeTab === "Overview" ? "active" : ""
                }`}
              >
                Overview
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => setState({ activeTab: "All Candidates" })}
                className={`nav-link ${
                  state.activeTab === "All Candidates" ? "active" : ""
                }`}
              >
                All Candidates
              </button>
            </li>
            {/* <li className="nav-item" role="presentation">
              <button
                onClick={() => setState({ activeTab: "Suggested" })}
                className={`nav-link ${
                  state.activeTab === "Suggested" ? "active" : ""
                }`}
              >
                Suggested
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                onClick={() => setState({ activeTab: "Interviewing" })}
                className={`nav-link ${
                  state.activeTab === "Interviewing" ? "active" : ""
                }`}
              >
                Interviewing
              </button>
            </li> */}
          </ul>
          <div className="tab-pane fade show active w-100 p-3 p-md-3">
            {state.activeTab === "Overview" ? (
              <ListedProjectOverview
                user={user}
                description={description}
                responsibilties={responsibilties}
                qualifications={qualifications}
                skills={skills}
                duration={duration}
                jobDocumentUrl={jobDocumentUrl}
                status={status}
                hourlyRate={hourlyRate}
                projectId={projectId}
                project={project}
                upComingInterviews={state.upComingInterviews}
                handleChangeCapHour={handleChangeCapHour}
                handleChangeHoursPerWeek={handleChangeHoursPerWeek}
                handleChangeHourlyRate={handleChangeHourlyRate}
                handleEditProject={handleEditProject}
                handleToggleEditButton={() => handleToggleEditButton(true)}
              />
            ) : (
              <CandidatesList
                status={status}
                studentList={
                  studentList
                  // state.activeTab === "All Candidates"
                  //   ? studentList.filter(
                  //       (item: any) =>
                  //         !item.suggested &&
                  //         item.status !== "REQUESTED_TO_INTERVIEW"
                  //     )
                  //   : state.activeTab === "Suggested"
                  //   ? studentList.filter((item: any) => item.suggested)
                  //   : studentList.filter(
                  //       (item: any) => item.status === "REQUESTED_TO_INTERVIEW"
                  //     )
                }
                selectedStudent={state.selectedStudent}
                handleOpenProfile={handleOpenProfile}
                handleSelect={(id: string) => updateSelectedStudent(id)}
                showSelection={state.showSelection}
                matchingData={state?.matchingData}
              />
            )}
          </div>
          {/* {state.showOverView ? (
            <div
              className={`tab-pane fade ${
                state.showOverView ? "show active" : ""
              } w-100 p-3 p-md-4`}
              id="overview"
              role="tabpanel"
              aria-labelledby="overview-tab"
            >
              <ListedProjectOverview
                user={user}
                description={description}
                responsibilties={responsibilties}
                qualifications={qualifications}
                skills={skills}
                duration={duration}
                jobDocumentUrl={jobDocumentUrl}
                status={status}
                hourlyRate={hourlyRate}
                projectId={projectId}
                project={project}
                handleChangeCapHour={handleChangeCapHour}
                handleChangeHoursPerWeek={handleChangeHoursPerWeek}
                handleChangeHourlyRate={handleChangeHourlyRate}
                handleEditProject={handleEditProject}
              />
            </div>
          ) : (
            <div
              className={`tab-pane fade ${
                state.showOverView ? "" : "show active"
              } w-100`}
              id="candidates"
              role="tabpanel"
              aria-labelledby="all_candidates"
            >
              <CandidatesList
                status={status}
                studentList={studentList}
                handleOpenProfile={handleOpenProfile}
                handleSelect={(id: string) => updateSelectedStudent(id)}
                showSelection={state.showSelection}
                matchingData={state?.matchingData}
              />
            </div>
          )} */}
        </div>
      )}
      {state.changeStatus === false && <ProjectStartedTips />}
    </div>
  ) : (
    <StudentProfile
      user={studentId}
      projectId={projectId}
      studentStatus={state?.studentStatus}
      interviewTimes={interviewTimes}
    />
  );
};
export default ListedProject;
