import React, { useRef } from "react";
import * as amplitude from "@amplitude/analytics-browser";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TextField from "components/TextField";
// import Select from "components/Select";
import Button from "components/Button";
import RichTextEditorField from "components/RichTextEditorField";
import ErrorMessage from "components/ErrorMessage";
import RaceDefinitionsDialog from "components/RaceDefinitionsDialog";
import { useMergeState } from "utils/custom-hooks";
import {
  AccountType,
  RUNWAY_TOKEN,
  StudentTypes,
  USAUniversities,
} from "utils/constants";
import { validateEmail, wrapHTMLContent } from "utils/string";
import {
  isValidUrl,
  toBase64,
  validatePhone,
  wrapNameTwoLetters,
} from "utils/common";
import { getSignedUrlOfS3, signup } from "api";
import Images from "assets/images";

const CustomTextField = styled(MuiTextField)(() => ({
  "& .MuiInputLabel-root": {
    marginTop: 8,
    fontSize: 14,
    width: "100%",
    "&.Mui-focused": {
      color: "#363333",
    },
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 6,
    "& legend": {
      display: "none",
    },
    "&.Mui-focused fieldset": {
      border: "0.8px solid rgba(112, 110, 110, 0.4)",
    },
  },
}));

const StepLabels = {
  SCHOOL: "SCHOOL",
  TYPE: "TYPE",
  LOGIN: "DETAILS",
  PROFILE: "PROFILE",
  SELF_IDENTITY: "SELF_IDENTITY",
  CONFIRM: "CONFIRM",
};

const Steps = [
  {
    id: 1,
    label: StepLabels.SCHOOL,
    title: "School Selection",
    show: true,
  },
  {
    id: 2,
    label: StepLabels.TYPE,
    title: "Account Type",
    show: true,
  },
  {
    id: 3,
    label: StepLabels.LOGIN,
    title: "Login Details",
    show: true,
  },
  {
    id: 4,
    label: StepLabels.PROFILE,
    title: "Profile Details",
    show: true,
  },
  {
    id: 5,
    label: StepLabels.SELF_IDENTITY,
    title: "Self Identity",
    show: false,
  },
  {
    id: 6,
    label: StepLabels.CONFIRM,
    title: "Confirm Account",
    show: true,
  },
];

const SMS_UPDATES_CONSENT = {
  YES: "YES",
  NO: "NO",
};

const RaceOptions = [
  "I do not wish to disclose",
  "Asian",
  "Black or African American",
  "Hispanic or Latino",
  "Native American or Alaska Native",
  "Native Hawaiian or Other Pacific Islander",
  "Two or More Races",
  "White/Caucasian",
];

const GenderOptions = ["Female", "Male", "Not Declared"];
const internationalStudentList = ["Yes", "No"];

type Props = {
  setUser: any;
};

export default function StudentSignUpContainer({ setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    currentStep: Steps[0],

    // school
    university: "",

    // type
    profileTypes: [],

    // login details
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: "",
    smsOptIn: SMS_UPDATES_CONSENT.YES,

    // self identity
    race: "",
    gender: "",

    // profile details
    avatarFile: null,
    avatarBase64: null,

    resumeFile: null,
    resumeBase64: null,

    website: "",
    bio: "",
    keySkills: "",
    skills: [],
    internationalStudent: "",
    isDisableBtn: false,

    errors: {},

    shouldShowRaceDefinitionsDialog: false,
  });

  const avatarRef = useRef<any>();

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = event?.target?.files && event?.target?.files[0];

      if (!file) {
        return;
      }

      event.target.value = "";

      const base64 = await toBase64(file);

      setState({
        avatarFile: file,
        avatarBase64: base64,
      });
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleAvatarRef = () => {
    avatarRef?.current?.click();
  };

  const resumeRef = useRef<any>();

  const handleResumeRef = () => {
    resumeRef?.current?.click();
  };

  const handleResumeChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event?.target?.files && event?.target?.files[0];

    if (!file) {
      return;
    }

    event.target.value = "";

    if (
      file?.name.includes("pdf") ||
      file?.name.includes("doc") ||
      file?.name.includes("docx")
    ) {
      const base64 = await toBase64(file);

      setState({
        resumeFile: file,
        resumeBase64: base64,
        errors: {
          ...state?.errors,
          resumeFile: "",
        },
      });
    } else {
      setState({
        resumeFile: null,
        resumeBase64: null,
        errors: {
          ...state?.errors,
          resumeFile: "File type should be .pdf or .docx",
        },
      });
    }

    // const base64 = await toBase64(file);

    // setState({
    //   resumeFile: file,
    //   resumeBase64: base64,
    // });
  };

  const handleRemoveResume = () => {
    setState({
      resumeFile: null,
      resumeBase64: null,
    });
  };

  const handleChangeProfileType = (profileType: string) => {
    const updatedProfileTypes = [...state.profileTypes];

    const index = state.profileTypes.findIndex(
      (elem: string) => elem === profileType
    );

    if (index === -1) {
      updatedProfileTypes.push(profileType);
    } else {
      updatedProfileTypes.splice(index, 1);
    }

    setState({
      profileTypes: updatedProfileTypes,
      errors: {
        ...state?.errors,
        profileTypes: "",
      },
    });
  };

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const handleChangeBio = (bio: string) => {
    setState({
      bio,
      errors: {
        ...state?.errors,
        bio: "",
      },
    });
  };

  const handleChangeUniversity = (university: string) => {
    setState({
      university,
      errors: {
        ...state?.errors,
        university: "",
      },
    });
  };

  const handleChangeSmsOptIn = (smsOptIn: string) => {
    setState({
      smsOptIn,
    });
  };

  const handleChangeKeySkills = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const keySkills = event?.target?.value;

    const keySkillsArray = keySkills?.split(",");

    const skills = keySkillsArray.map((elem: string) =>
      String(elem)?.trim()?.toUpperCase()
    );

    setState({
      keySkills,
      skills,
      errors: {
        keySkills: "",
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (state?.currentStep?.label === StepLabels.SCHOOL) {
      if (!state?.university) {
        errors = { ...errors, university: "University is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.TYPE) {
      if (!state?.profileTypes?.length) {
        errors = { ...errors, profileTypes: "Profile type is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.LOGIN) {
      if (!state?.firstName) {
        errors = { ...errors, firstName: "First Name is required." };
        isValid = false;
      }

      if (!state?.lastName) {
        errors = { ...errors, lastName: "Last Name is required." };
        isValid = false;
      }

      if (!validateEmail(state?.email)) {
        isValid = false;
        errors = { ...errors, email: "Email is invalid." };
      }

      if (!state?.password) {
        errors = { ...errors, password: "Password is required." };
        isValid = false;
      }

      if (!state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Confirm password is required.",
        };
        isValid = false;
      }

      if (state?.password !== state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Passwords do not match.",
        };
        isValid = false;
      }

      if (!validatePhone(state?.phone)) {
        errors = { ...errors, phone: "Phone is invalid." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.PROFILE) {
      if (!state?.resumeFile) {
        errors = { ...errors, resumeFile: "Resume is required." };
        isValid = false;
      }
      if (!state?.avatarFile) {
        errors = { ...errors, avatarFile: "profile pic is required." };
        isValid = false;
      }

      if (state?.website && !isValidUrl(state?.website)) {
        isValid = false;
        errors = { ...errors, website: "Website is invalid." };
      }

      if (!state?.bio) {
        errors = { ...errors, bio: "Bio is required." };
        isValid = false;
      }

      if (!state?.keySkills) {
        errors = { ...errors, keySkills: "Skills is required." };
        isValid = false;
      }
    }

    if (state?.currentStep?.label === StepLabels.SELF_IDENTITY) {
      if (!state?.race) {
        errors = { ...errors, race: "Race is required." };
        isValid = false;
      }

      if (!state?.gender) {
        errors = { ...errors, gender: "Gender is required." };
        isValid = false;
      }

      if (!state?.internationalStudent) {
        errors = {
          ...errors,
          internationalStudent: "international student field is required.",
        };
        isValid = false;
      }
    }

    setState({ errors });

    return isValid;
  };

  const getNextStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) + 1];

  const getPrevStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) - 1];

  const handleContinue = () => {
    if (!isFormValid()) {
      return;
    }

    const nextStep = getNextStep();

    setState({ currentStep: nextStep });
  };

  const handleBack = () => {
    const prevStep = getPrevStep();

    setState({ currentStep: prevStep });
  };

  const handleEditStep = (stepLabel: string) => {
    setState({ currentStep: Steps.find((step) => step?.label === stepLabel) });
  };

  const handleOpenRaceDefinitionsDialog = () => {
    setState({ shouldShowRaceDefinitionsDialog: true });
  };

  const handleCloseRaceDefinitionsDialog = () => {
    setState({ shouldShowRaceDefinitionsDialog: false });
  };

  const handleStudentSignup = async () => {
    try {
      const payload = {
        accountType: AccountType.STUDENT,
        firstName: state?.firstName,
        lastName: state?.lastName,
        email: String(state?.email).trim().toLowerCase(),
        password: state?.password,
        confirmPassword: state?.confirmPassword,
        phone: state?.phone,
        university: state?.university,
        profileTypes: state?.profileTypes,
        smsOptIn: state?.smsOptIn === SMS_UPDATES_CONSENT.YES,
        race: state?.race,
        gender: state?.gender,
        avatarBase64: state?.avatarBase64,
        resumeBase64: state?.resumeBase64,
        website: state?.website,
        bio: state?.bio,
        skills: state?.skills,
        internationalStudent:
          state?.internationalStudent === "Yes" ? true : false,
      };

      setState({ isDisableBtn: true });

      const response = await signup(payload);

      setState({ isDisableBtn: false });
      if (response?.success) {
        localStorage.setItem(RUNWAY_TOKEN, response?.data?.token);
        const userInfo = response?.data?.user;
        if (userInfo?.avatar && userInfo?.avatar?.key) {
          const signedUrlRes = await getSignedUrlOfS3(userInfo?.avatar?.key);
          if (signedUrlRes?.success) {
            userInfo.avatar = {
              ...userInfo.avatar,
              url: signedUrlRes.data.url,
            };
          }
        }
        setUser(userInfo);

        amplitude.track("Student Sign Up");
        amplitude.setUserId(response?.data?.user?._id);

        navigate("/personality-tests");
      } else {
        setState({ isDisableBtn: false });
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return (
    <div>
      <div className="min-h-fit flex flex-col lg:flex-row items-center bg-rw-blue-lightest relative h-fit">
        <div className="flex justify-center items-center left-[4.17%] lg:left-[8.38%] py-4 lg:py-0 lg:absolute">
          <a
            href={process.env.REACT_APP_HOME_BASE_URL}
            className="flex items-center"
          >
            <img src={Images.Logo} width={24} height={24} />
            <span className="text-2xl font-bold ml-2">Runway</span>
          </a>
        </div>
        {state?.currentStep?.label === StepLabels.SCHOOL ? (
          <div className="w-[100vw] h-24 flex flex-col justify-center items-center">
            <div className="text-rw-blue font-semibold">GET STARTED</div>
            <div className="text-4xl font-bold">School Selection</div>
          </div>
        ) : (
          <div className="w-[100vw] flex flex-col justify-center items-center h-24">
            <div className="text-rw-blue font-semibold text-center pb-2">
              Applying as a Student
            </div>

            <div className="flex h-24">
              {Steps.filter((step) => step.show).map((step, index: number) => (
                <div key={step?.id} className="flex flex-col items-center mx-4">
                  <div
                    className={`rounded-full w-6 h-6 border-[1px] text-sm font-medium ${
                      state?.currentStep?.id >= step?.id
                        ? "bg-rw-blue text-white"
                        : "border-[#00000040] text-[#B8B8B8]"
                    } flex justify-center items-center`}
                  >
                    {step?.id < state?.currentStep?.id ? (
                      <CheckOutlinedIcon sx={{ width: 16, height: 16 }} />
                    ) : (
                      index + 1
                    )}
                  </div>

                  <div
                    className={`text-xs ${
                      state?.currentStep?.id >= step?.id
                        ? "text-rw-blue"
                        : "text-[#B8B8B8]"
                    } font-semibold mt-2`}
                  >
                    {step?.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-center mt-10">
        <div className="flex w-96 justify-center">
          <div className="w-[90%]">
            {state?.currentStep?.label === StepLabels.SCHOOL && (
              <div>
                <div className="text-center text-rw-black-dark">
                  Which university do you attend?
                </div>

                <div className="mt-4">
                  <Autocomplete
                    value={state?.university}
                    onChange={(event: any, newValue: string | null) => {
                      handleChangeUniversity(newValue || "");
                    }}
                    options={USAUniversities}
                    renderInput={(params: any) => (
                      <CustomTextField
                        sx={[
                          {
                            "&:hover .MuiAutocomplete-clearIndicator": {
                              visibility: state?.university
                                ? "visible"
                                : "hidden",
                            },
                          },
                        ]}
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          required: true,
                          error: state?.errors?.university,
                        }}
                      />
                    )}
                  />

                  {state?.errors?.university && (
                    <ErrorMessage message={state?.errors?.university} />
                  )}
                </div>
              </div>
            )}

            {state?.currentStep?.label === StepLabels.TYPE && (
              <div>
                <div className="text-center text-rw-black-dark">
                  What type of opportunities are you interested in?
                </div>

                {StudentTypes.filter((item, index) => index !== 1).map(
                  (profileType) => (
                    <div
                      key={profileType?.title}
                      className="border-[1px] border-[#D7DBE1] rounded-md p-4 h-20 flex items-center my-4 cursor-pointer"
                      onClick={() =>
                        handleChangeProfileType(profileType?.value)
                      }
                    >
                      <img src={profileType?.icon} className="w-10 h-8" />

                      <div className="w-full flex justify-between items-center">
                        <div className="px-4">
                          <div
                            className={`${
                              state?.profileType === profileType?.value
                                ? "text-rw-blue"
                                : ""
                            } text-lg font-semibold`}
                          >
                            {profileType?.title}
                          </div>

                          <div className="text-xs font-medium text-rw-gray-75">
                            {profileType?.description}
                          </div>
                        </div>

                        <div>
                          <Checkbox
                            checked={state.profileTypes.includes(
                              profileType?.value
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )}

                {state?.errors?.profileTypes && (
                  <ErrorMessage message={state?.errors?.profileTypes} />
                )}
              </div>
            )}

            {state?.currentStep?.label === StepLabels.LOGIN && (
              <div className="flex justify-center">
                <div className="w-[90%]">
                  <div className="text-3xl font-semibold text-rw-black-dark text-center">
                    Login Details
                  </div>

                  <div className="w-full mt-8">
                    <div className="font-semibold mb-1">Name</div>

                    <div className="flex gap-2 justify-between">
                      <TextField
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={state?.firstName}
                        onChange={handleChange}
                        error={state?.errors?.firstName}
                      />

                      <TextField
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={state?.lastName}
                        onChange={handleChange}
                        error={state?.errors?.lastName}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="font-semibold mb-1">Email</div>

                    <TextField
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      value={state?.email}
                      onChange={handleChange}
                      error={state?.errors?.email}
                    />
                  </div>

                  <div className="mt-4">
                    <div className="font-semibold mb-1">Password</div>

                    <TextField
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={state?.password}
                      onChange={handleChange}
                      error={state?.errors?.password}
                    />

                    <div className="mt-1">
                      <TextField
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        value={state?.confirmPassword}
                        onChange={handleChange}
                        error={state?.errors?.confirmPassword}
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="font-semibold mb-1">Phone Number</div>

                    <TextField
                      type="number"
                      name="phone"
                      placeholder="(000) 000-0000"
                      value={state?.phone}
                      onChange={handleChange}
                      error={state?.errors?.phone}
                      onWheel={(event: any) => event.target.blur()}
                      onInput={(event: any) => {
                        if (event?.target?.value) {
                          event.target.value = Math.max(
                            0,
                            parseInt(event.target.value, 10)
                          )
                            .toString()
                            .slice(0, 10);
                        }
                      }}
                    />

                    <div className="mt-4">
                      <div
                        className="flex items-center gap-2 cursor-pointer"
                        onClick={() =>
                          handleChangeSmsOptIn(SMS_UPDATES_CONSENT.YES)
                        }
                      >
                        <div>
                          <Radio
                            checked={
                              state?.smsOptIn === SMS_UPDATES_CONSENT.YES
                            }
                            value={SMS_UPDATES_CONSENT.YES}
                          />
                        </div>

                        <div className="text-xs text-[#808080]">
                          By selecting this box, you agree to recieve SMS
                          updates on new projects.{" "}
                          <span className="font-semibold">
                            This is the only way you will be updated on
                            opportunities.
                          </span>
                        </div>
                      </div>

                      <div
                        className="flex items-center gap-2 mt-2 cursor-pointer"
                        onClick={() =>
                          handleChangeSmsOptIn(SMS_UPDATES_CONSENT.NO)
                        }
                      >
                        <div>
                          <Radio
                            checked={state?.smsOptIn === SMS_UPDATES_CONSENT.NO}
                            value={SMS_UPDATES_CONSENT.NO}
                          />
                        </div>

                        <div className="text-xs text-[#808080]">
                          Opt-out of SMS Project Reminders.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {state?.currentStep?.label === StepLabels.PROFILE && (
              <div className="w-full">
                <div className="text-3xl font-semibold text-rw-black-dark text-center">
                  Profile Details
                </div>

                <div className="mt-8">
                  {state?.avatarBase64 ? (
                    <div className="flex justify-center mb-12">
                      <div className="bg-rw-blue rounded-full w-24 h-24 flex justify-center items-center relative">
                        <span className="text-white text-xl font-semibold">
                          <img
                            className="rounded-full object-cover"
                            src={state?.avatarBase64}
                          />
                        </span>

                        <button
                          className="absolute bottom-0 right-0 bg-white rounded-full flex justify-center items-center text-rw-blue w-6 h-6 border-[1px] border-rw-blue"
                          onClick={handleAvatarRef}
                        >
                          <EditOutlinedIcon sx={{ width: 14, height: 14 }} />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="font-semibold mb-1">
                        Upload your Profile Pic
                        <span className="text-rw-gray-50" />
                      </div>
                      <div
                        className={`border-[1px] border-[#00000040] border-dashed rounded-md p-4 h-40 flex justify-center items-center cursor-pointer ${
                          state?.errors?.avatarFile ? "border-rw-red" : ""
                        }`}
                        onClick={handleAvatarRef}
                      >
                        <div className="text-center">
                          <CloudUploadOutlinedIcon className="text-[#C1C1C1]" />

                          <div className="font-semibold text-[#C1C1C1]">
                            UPLOAD IMAGE
                          </div>

                          <div className="text-sm text-[#C1C1C180] mt-1">
                            Drag and drop files here
                          </div>
                        </div>
                      </div>
                      {state?.errors?.avatarFile && (
                        <ErrorMessage message={state?.errors?.avatarFile} />
                      )}
                    </div>
                  )}

                  <input
                    type="file"
                    accept="image/*"
                    className="hidden"
                    ref={avatarRef}
                    onChange={handleAvatarChange}
                  />
                </div>

                <div className="mt-8">
                  <div className="font-semibold mb-1">Upload your Resume</div>

                  <div
                    className={`border-[1px] border-[#00000040] border-dashed rounded-md p-4 h-40 flex justify-center items-center cursor-pointer ${
                      state?.errors?.resumeFile ? "border-rw-red" : ""
                    }`}
                    onClick={handleResumeRef}
                  >
                    <div className="text-center">
                      <CloudUploadOutlinedIcon className="text-[#C1C1C1]" />

                      <div className="font-semibold text-[#C1C1C1]">
                        UPLOAD PDF FILE
                      </div>

                      <div className="text-sm text-[#C1C1C180] mt-1">
                        Drag and drop files here
                      </div>
                    </div>
                  </div>

                  {state?.resumeFile && (
                    <div className="flex justify-between items-center p-2 bg-[#E3E4E7] rounded-md mt-4">
                      <span className="text-sm font-medium text-rw-gray-75 word-break">
                        {state?.resumeFile?.name}
                      </span>

                      <span
                        className="text-rw-red-dark cursor-pointer"
                        onClick={handleRemoveResume}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </span>
                    </div>
                  )}

                  {state?.errors?.resumeFile && (
                    <ErrorMessage message={state?.errors?.resumeFile} />
                  )}

                  <input
                    type="file"
                    accept="application/pdf"
                    className="hidden"
                    ref={resumeRef}
                    onChange={handleResumeChange}
                  />
                </div>

                <div className="mt-8">
                  <div className="font-semibold mb-1">
                    Website<span className="text-rw-gray-50"> (Optional)</span>
                  </div>

                  <TextField
                    type="text"
                    name="website"
                    placeholder="www.mywebsite.com"
                    value={state?.website}
                    onChange={handleChange}
                    error={state?.errors?.website}
                  />
                </div>

                <div className="mt-8">
                  <div className="font-semibold mb-1">Write a Short Bio</div>

                  <RichTextEditorField
                    value={state?.bio}
                    placeholder="Write a short bio that briefly explains your previous experience..."
                    onChange={handleChangeBio}
                    error={state?.errors?.bio}
                  />
                </div>

                <div className="mt-8">
                  <div className="font-semibold mb-1">Key Skillsets</div>

                  <TextField
                    type="text"
                    name="keySkills"
                    placeholder="Enter your key skills"
                    value={state?.keySkills}
                    onChange={handleChangeKeySkills}
                    error={state?.errors?.keySkills}
                  />

                  <div className="flex flex-wrap items-center gap-4 my-2">
                    {state?.skills?.map(
                      (skill: string) =>
                        skill && (
                          <div
                            key={skill}
                            className="w-fit bg-rw-blue text-white text-sm font-medium p-2 rounded-md flex justify-center items-center"
                          >
                            {skill}
                          </div>
                        )
                    )}
                  </div>
                  <span className="text-[#5E5E5E] font-medium text-xs">
                    Seperate skills by a comma (eg: FIGMA, HUBSPOT, REACT.JS)
                  </span>
                </div>
              </div>
            )}

            {state?.currentStep?.label === StepLabels.SELF_IDENTITY && (
              <div className="flex justify-center">
                <div className="w-96">
                  <div className="text-3xl font-semibold text-rw-black-dark text-center">
                    Self Identity
                  </div>

                  <div className="w-full mt-8">
                    <div className="flex justify-between items-center mb-4">
                      <div className="font-semibold">
                        What is your race/ethnicity?
                      </div>

                      <button
                        className="text-rw-blue font-semibold border-[1px] border-rw-blue bg-rw-blue-light w-6 h-6 rounded-full flex justify-center items-center"
                        onClick={handleOpenRaceDefinitionsDialog}
                      >
                        i
                      </button>
                    </div>

                    <FormControl
                      variant="outlined"
                      className="input-select-field"
                    >
                      <Select
                        name="race"
                        variant="outlined"
                        value={state?.race}
                        onChange={handleChange}
                        required
                        error={state?.errors?.race}
                      >
                        {RaceOptions.map((race: any) => (
                          <MenuItem key={race} value={race}>
                            {race}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {state?.errors?.race && (
                      <ErrorMessage message={state?.errors?.race} />
                    )}
                  </div>

                  <div className="mt-8">
                    <div className="font-semibold mb-4">
                      What is your gender?
                    </div>

                    <FormControl
                      variant="outlined"
                      className="input-select-field"
                    >
                      <Select
                        name="gender"
                        variant="outlined"
                        value={state?.gender}
                        onChange={handleChange}
                        required
                        error={state?.errors?.gender}
                      >
                        {GenderOptions.map((gender: any) => (
                          <MenuItem key={gender} value={gender}>
                            {gender}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {state?.errors?.gender && (
                      <ErrorMessage message={state?.errors?.gender} />
                    )}
                  </div>

                  {/* <div className="mt-8">
                    <div className="font-semibold mb-4">
                      Are you an international student?
                    </div>

                    <FormControl
                      variant="outlined"
                      className="input-select-field"
                    >
                      <Select
                        name="gender"
                        variant="outlined"
                        value={state?.gender}
                        onChange={handleChange}
                        required
                        error={state?.errors?.gender}
                      >
                        {GenderOptions.map((gender: any) => (
                          <MenuItem key={gender} value={gender}>
                            {gender}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {state?.errors?.gender && (
                      <ErrorMessage message={state?.errors?.gender} />
                    )}
                  </div> */}
                  <div className="mt-8">
                    <div className="font-semibold mb-4">
                      Are you an international student?
                    </div>

                    <FormControl
                      variant="outlined"
                      className="input-select-field"
                    >
                      <Select
                        name="internationalStudent"
                        variant="outlined"
                        value={state?.internationalStudent}
                        onChange={handleChange}
                        required
                        error={state?.errors?.internationalStudent}
                      >
                        {internationalStudentList.map((li: any) => (
                          <MenuItem key={li} value={li}>
                            {li}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {state?.errors?.internationalStudent && (
                      <ErrorMessage
                        message={state?.errors?.internationalStudent}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            {state?.currentStep?.label === StepLabels.CONFIRM && (
              <div>
                <div className="text-3xl font-semibold text-rw-black-dark text-center">
                  Confirm Account
                </div>

                <div className="w-full mt-8">
                  <div className="flex justify-center mb-12">
                    <div className="bg-rw-blue rounded-full w-24 h-24 flex justify-center items-center relative">
                      <span className="text-white text-4xl font-semibold">
                        {state?.avatarBase64 ? (
                          <img
                            className="rounded-full object-cover"
                            src={state?.avatarBase64}
                          />
                        ) : (
                          wrapNameTwoLetters(state?.firstName, state?.lastName)
                        )}
                      </span>

                      <button
                        className="absolute bottom-0 right-0 bg-white rounded-full flex justify-center items-center text-rw-blue w-6 h-6 border-[1px] border-rw-blue"
                        onClick={handleAvatarRef}
                      >
                        <EditOutlinedIcon sx={{ width: 14, height: 14 }} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="w-full mt-8">
                  <div className="flex justify-between items-center mb-1">
                    <div className="font-semibold">Full Name</div>
                    <button
                      className="text-rw-blue text-sm font-medium underline"
                      onClick={() => handleEditStep(StepLabels.LOGIN)}
                    >
                      Edit
                    </button>
                  </div>

                  <div className="flex gap-2 justify-between">
                    <TextField type="text" value={state?.firstName} readOnly />
                    <TextField type="text" value={state?.lastName} readOnly />
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex justify-between items-center mb-1">
                    <div className="font-semibold">Email Address</div>
                    <button
                      className="text-rw-blue text-sm font-medium underline"
                      onClick={() => handleEditStep(StepLabels.LOGIN)}
                    >
                      Edit
                    </button>
                  </div>

                  <TextField type="text" value={state?.email} readOnly />
                </div>

                <div className="mt-8">
                  <div className="flex justify-between items-center mb-1">
                    <div className="font-semibold">Password</div>
                    <button
                      className="text-rw-blue text-sm font-medium underline"
                      onClick={() => handleEditStep(StepLabels.LOGIN)}
                    >
                      Edit
                    </button>
                  </div>

                  <TextField type="password" value={state?.password} readOnly />
                </div>

                <div className="mt-8">
                  <div className="flex justify-between items-center mb-1">
                    <div className="font-semibold">Phone Number</div>
                    <button
                      className="text-rw-blue text-sm font-medium underline"
                      onClick={() => handleEditStep(StepLabels.LOGIN)}
                    >
                      Edit
                    </button>
                  </div>

                  <TextField type="text" value={state?.phone} readOnly />
                </div>

                <div className="mt-8">
                  <div className="flex justify-between items-center">
                    <div className="text-rw-black-dark font-semibold text-lg">
                      Resume Upload
                    </div>
                    <button
                      className="text-rw-blue font-medium underline"
                      onClick={() => handleEditStep(StepLabels.PROFILE)}
                    >
                      Edit
                    </button>
                  </div>

                  <div className="flex justify-center items-center h-40 border-[1px] border-[#D7DBE1] rounded-md mt-2 p-4">
                    <div className="text-center">
                      <CheckCircleOutlinedIcon />

                      <div className="font-semibold">File Uploaded</div>
                    </div>
                  </div>
                </div>

                <div className="mt-8">
                  <div className="flex justify-between items-center mb-1">
                    <div className="font-semibold">Website</div>
                    <button
                      className="text-rw-blue text-sm font-medium underline"
                      onClick={() => handleEditStep(StepLabels.PROFILE)}
                    >
                      Edit
                    </button>
                  </div>

                  <TextField type="text" value={state?.website} readOnly />
                </div>

                <div className="mt-8">
                  <div className="flex justify-between items-center">
                    <div className="text-rw-black-dark font-semibold text-lg">
                      Short Bio
                    </div>
                    <button
                      className="text-rw-blue font-medium underline"
                      onClick={() => handleEditStep(StepLabels.PROFILE)}
                    >
                      Edit
                    </button>
                  </div>

                  <div
                    className="border-[1px] border-[#00000040] rounded-md mt-2 p-4 text-sm font-medium h-40 overflow-y-scroll"
                    dangerouslySetInnerHTML={{
                      __html: wrapHTMLContent(state?.bio),
                    }}
                  />
                </div>

                <div className="mt-8">
                  <div className="flex justify-between items-center">
                    <div className="text-rw-black-dark font-semibold text-lg">
                      Skillsets
                    </div>
                    <button
                      className="text-rw-blue font-medium underline"
                      onClick={() => handleEditStep(StepLabels.PROFILE)}
                    >
                      Edit
                    </button>
                  </div>

                  <div className="flex flex-wrap items-center gap-4 mt-2">
                    {state?.skills?.map((skill: string) => (
                      <div
                        key={skill}
                        className="w-fit bg-rw-blue text-white text-sm font-medium p-2 rounded-md flex justify-center items-center"
                      >
                        {skill}
                        {/* <button className="ml-1">
                        <CloseOutlinedIcon sx={{ width: 16, height: 16 }} />
                      </button> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-center">
              <div className="w-96">
                {state?.currentStep?.label === StepLabels.CONFIRM ? (
                  <Button
                    disabled={state.isDisableBtn}
                    className={`font-semibold w-full h-12 mt-8 ${
                      state.isDisableBtn ? "bg-rw-disable" : ""
                    }`}
                    onClick={handleStudentSignup}
                  >
                    Confirm
                  </Button>
                ) : (
                  <Button
                    className="font-semibold w-full h-12 mt-8"
                    onClick={handleContinue}
                  >
                    Continue
                  </Button>
                )}

                {state?.currentStep?.id > 1 && (
                  <Button
                    variant="secondary"
                    className="font-medium w-full mt-4 h-12"
                    onClick={handleBack}
                  >
                    Back
                  </Button>
                )}
              </div>
            </div>

            {state?.currentStep?.label === StepLabels.SCHOOL && (
              <div className="flex justify-center gap-2 text-sm mt-8">
                <div>Not a Student?</div>
                <Link to="/signup/business" className="text-rw-blue underline">
                  Apply as a Business
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>

      {state?.shouldShowRaceDefinitionsDialog && (
        <RaceDefinitionsDialog
          open={state?.shouldShowRaceDefinitionsDialog}
          onClose={handleCloseRaceDefinitionsDialog}
        />
      )}
    </div>
  );
}
