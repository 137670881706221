import { Helmet } from "react-helmet";
import { useSearchParams, useNavigate } from "react-router-dom";
import ProjectsListInternalContainer from "./list/internal";
import ProjectsListExternalContainer from "./list/external";
import StudentSearchProjectsContainer from "./list/studentSearchProjects";
import MyProjects from "containers/MyProjects";

type Props = {
  isLoggedIn: boolean;
  manuBarExpend: boolean;
  user: any;
  setUser: (user: any) => void;
  manuBarToogle: () => void;
};

export default function ProjectsListContainer({
  isLoggedIn,
  manuBarExpend,
  user,
  setUser,
  manuBarToogle,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const isExplore = searchParams.get("explore") || "";
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content="Runway Projects: Exclusive Internship & Project Opportunities for Top Students"
        />
        <meta
          name="description"
          content="Browse a curated list of unique projects and internship opportunities on Runway, featuring business, software development, design and engineering projects."
        />
        <meta
          name="keywords"
          content="Student intern projects, internship opportunities, software development internships, engineering internships, design internships, elite university talent, project-based hiring, part time college jobs, part-time internships, joinrunway, industry collaboration, college jobs"
        />
      </Helmet>
      {isExplore ? (
        <StudentSearchProjectsContainer
          user={user}
          manuBarExpend={manuBarExpend}
          isLoggedIn={isLoggedIn}
          setUser={setUser}
          manuBarToogle={manuBarToogle}
        />
      ) : (
        <MyProjects
          user={user}
          manuBarExpend={manuBarExpend}
          manuBarToogle={manuBarToogle}
        />
      )}
    </div>
  );
}

// {isExplore ? (
//   <StudentSearchProjectsContainer
//     user={user}
//     isLoggedIn={isLoggedIn}
//     setUser={setUser}
//     manuBarToogle={manuBarToogle}
//   />
// ) : isLoggedIn && !isExplore ? (
//   <ProjectsListInternalContainer
//     user={user}
//     manuBarToogle={manuBarToogle}
//   />
// ) : (
//   <ProjectsListExternalContainer />
// )}

// {
/* {isExplore ? (
        <StudentSearchProjectsContainer
          user={user}
          manuBarExpend={manuBarExpend}
          isLoggedIn={isLoggedIn}
          setUser={setUser}
          manuBarToogle={manuBarToogle}
        />
      ) : (
        <MyProjects
          user={user}
          manuBarExpend={manuBarExpend}
          manuBarToogle={manuBarToogle}
        />
      )} */
// }
