import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import siteLogo from "../../../assets/imgs/site_logo.svg";
import { useWindowSize } from "utils/custom-hooks";

type Props = {
  show: boolean;
  userName: string;
  AIResult: any;
  pdfLink: any;
  onClose: () => void;
};

const AiAssessmentModal = ({
  show,
  userName,
  AIResult,
  pdfLink,
  onClose,
}: Props) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      dialogClassName={
        useWindowSize().width < 800 ? "my-modal-mobile" : "my-modal"
      }
    >
      <Modal.Body
        style={{
          padding: 0,
          margin: 0,
        }}
      >
        <div>
          <div
            style={{
              margin: "auto",
            }}
          >
            <div style={{ backgroundColor: "white", borderRadius: 10 }}>
              <div className="modal-header border-0 p-4">
                <div className="logo">
                  <img src={siteLogo} />
                </div>
                <div className="d-flex align-items-center">
                  <p className="text_black fw-bold fs-6 me-4">
                    {moment().format("DD MMM YYYY")}
                  </p>
                  <button
                    type="button"
                    onClick={onClose}
                    className="text_secondary border-0 bg-transparent fs-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="far fa-times" />
                  </button>
                </div>
              </div>
              <div className="modal-body p-0">
                <div className="d-flex px-4">
                  <p className="w-100 text_primary fs-6">Name:</p>
                  <p className="w-100 text_black fs-6 fw-bold">{userName}</p>
                </div>
                {pdfLink && (
                  <div className="d-flex mt-3 px-4">
                    <p className="w-100 text_primary fs-6">
                      Transcript of questions & answers:
                    </p>
                    <a
                      href={pdfLink}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      className="w-100 fs-6 text_secondary fw_500 text-decoration-underline cursor_pointer"
                    >
                      Click here to download transcript
                    </a>
                  </div>
                )}

                <div className="bg_light p-4 border_r_8px mt-4">
                  <p className="text-uppercase pb-3 font_18 fw-bold text_primary">
                    REsults Summary
                  </p>
                  {AIResult?.feedbackData &&
                    Object.keys(AIResult.feedbackData).map(
                      (key: string, index: number) => (
                        <div key={index} className="d-flex">
                          <p className="w-100 text_black fs-6 fw-bold">{`${key}:`}</p>
                          <p className="w-100 text_orange fs-6 fw_600">
                            {AIResult.feedbackData[key].level}
                          </p>
                        </div>
                      )
                    )}
                </div>

                <div className="px-4 mt-4">
                  <p className="text-uppercase pb-4 font_18 fw-bold text_primary">
                    AI assessment
                  </p>
                  {AIResult.feedbackData &&
                    Object.keys(AIResult.feedbackData).map(
                      (key: string, index: number) => (
                        <div key={index} className="pb-4">
                          <p className="fw-bold text_black fs-5 pb-2">{key}</p>
                          <p className="fs-6 fw_500 pb-3">
                            AI Rating:{" "}
                            <span className="text_orange">
                              {AIResult.feedbackData[key].level}
                            </span>
                          </p>
                          <p>
                            <strong>AI Assessment:</strong>{" "}
                            {AIResult.feedbackData[key].feedback}
                          </p>
                        </div>
                      )
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AiAssessmentModal;
