import { Dialog, DialogContent } from "@mui/material";
import { titleCaseText } from "utils/common";
import CloseIcon from "@mui/icons-material/Close";
import Images from "assets/images";
import { Navigate, useNavigate } from "react-router-dom";

type Props = {
  projectType: string;
  projectId: string;
  open: any;
  onClose: any;
};

export default function CompletePersonalityTestsDialog({
  projectType,
  projectId,
  open,
  onClose,
}: Props) {
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate("/personality-tests", { state: { projectType, projectId } });
  };
  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogContent className="w-[100%] md:w-96 border-[1px] border-solid border-rw-gray-gainsboro p-7">
          <div className="flex justify-center mb-4">
            <img src={Images.PersonalityTest} className="h-20 w-20" />
          </div>
          <CloseIcon
            className="absolute right-7 top-7 text-rw-blue cursor-pointer mb-4"
            onClick={onClose}
          />
          <div className="font-semibold text-3xl text-center text-black flex justify-center mb-4">
            Complete Personality Tests to Apply
          </div>
          <div className="text-rw-gray-0 flex justify-center mb-4">
            12 Questions (5-10 min)
          </div>
          <div className="text-rw-black-raisin mb-7">
            <div className="flex justift-center text-center mb-4">
              This test helps identify your natural work style in a work
              environment.
            </div>
            <div className="flex justift-center text-center mb-4">
              Remember, there are no right or wrong answers, and all personality
              types have their unique strengths.
            </div>
            <div className="flex justift-center text-center mb-4">
              Always select the option that resonates most with you, not the one
              you think is &apos;correct&apos;.
            </div>
          </div>
          <div className="flex justify-center mb-4">
            <button
              className="text-white text-center text-lg font-medium w-[100%] py-4 bg-rw-blue rounded-lg"
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
