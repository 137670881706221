import SocialLinks from "../SocialLinks";
import Images from "assets/images";

export default function HowToUseLinkedIn() {
  return (
    <main className="w-full min-h-screen">
      <div className="my-8">
        <div className="flex justify-center font-semibold mt-9 mb-3 text-xs text-rw-baby-blue-dark break-words">
          For Employers
        </div>
        <div className="text-zinc-700 text-3xl md:text-4xl font-semibold flex justify-center text-center mb-5">
          <div className="w-11/12 lg:w-3/5">
            How to Use Linkedin Sales Navigator to Find Target Customers
          </div>
        </div>
        <div className="flex gap-4 justify-center mb-7 flex-wrap">
          <div className="text-zinc-600 font-semibold">By Runway Team</div>
          <div>|</div> <div className="text-zinc-600">July 20th, 2023</div>
        </div>
        <div className="flex justify-center mb-10">
          <img
            src={Images.HowToUseLinkedIn}
            className="w-11/12 lg:w-3/5"
            width={50}
            height={50}
            alt=""
          />
        </div>
        <div className="flex justify-center">
          <div className="w-11/12 lg:w-3/5">
            <div className="text-zinc-600 md:text-lg leading-7">
              LinkedIn Sales Navigator is a powerful tool for businesses looking
              to generate qualified leads. With Sales Navigator, you can easily
              search for and connect with potential leads, build relationships,
              and ultimately drive more sales. In this blog, we&apos;ll take a
              look at how you can use LinkedIn Sales Navigator to get a list of
              qualified leads for your business.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-loose">
              <br />
            </div>
            <div className="text-zinc-600 text-xl md:text-2xl font-semibold leading-loose">
              Step by Step Process
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Step 1<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Define Your Ideal Customer Before you can start using LinkedIn
              Sales Navigator to generate leads, you need to define your ideal
              customer. This includes identifying factors like job title,
              industry, company size, and location. Once you have a clear
              understanding of your ideal customer, you can start using Sales
              Navigator to search for potential leads that match these criteria.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Step 2<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Search for Leads Once you&apos;ve defined your ideal customer,
              it&apos;s time to start searching for leads. To do this, log into
              LinkedIn Sales Navigator and click on the &quot;Lead Search&quot;
              tab. From here, you can enter your search criteria, including
              keywords, location, industry, and more. You can also use the
              &quot;Save Search&quot; function to save your search criteria and
              receive updates when new leads that match your criteria become
              available.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              <br />
              Step 3<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Refine Your Search As you start to search for leads, you may find
              that your results aren&apos;t quite what you&apos;re looking for.
              In this case, you can refine your search criteria to narrow down
              your results. For example, you might try using more specific
              keywords, or adjusting the location or industry filters to better
              target your ideal customer.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Step 4<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Connect with Leads Once you&apos;ve found potential leads that
              match your criteria, it&apos;s time to start connecting with them.
              You can do this by sending a personalized connection request,
              along with a brief message introducing yourself and your business.
              Be sure to customize your message for each lead, highlighting how
              your product or service can help them solve a specific problem or
              achieve a particular goal.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Step 5<br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Connect with Leads Once you&apos;ve found potential leads that
              match your criteria, it&apos;s time to start connecting with them.
              You can do this by sending a personalized connection request,
              along with a brief message introducing yourself and your business.
              Be sure to customize your message for each lead, highlighting how
              your product or service can help them solve a specific problem or
              achieve a particular goal.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lgleading-7">
              <br />
              <br />
              In conclusion, LinkedIn Sales Navigator is a powerful tool for
              businesses looking to generate qualified leads. By following these
              steps, you can use Sales Navigator to search for and connect with
              potential leads, refine your search criteria, and ultimately build
              relationships that can help you drive more sales for your
              business. Sign up for a free trial for a month and help your
              client find qualified leads!
            </div>
            <div className="bg-zinc-600 mb-5 h-0.5 mt-20" />
            <div className="text-center text-zinc-600 text-2xl font-semibold">
              Share Article
            </div>
            <SocialLinks
              text="How to Use Linkedin Sales Navigator to Find Target Customers"
              url="https://www.joinrunway.io/blogs/how-to-use-linkedin-sales-navigator-to-find-target-customers/"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
