import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useMergeState } from "utils/custom-hooks";

type Props = {
  isShow: boolean;
  onHide: () => void;
  addProjectToArchived?: () => void;
};
const ArchiveProjectModal = ({
  isShow,
  onHide,
  addProjectToArchived = () => {},
}: Props) => {
  const [isDisableArchiveProjectBtn, setIsDisableArchiveProjectBtn] =
    useState(false);
  //   const [state, setState] = useMergeState({
  //     isShowArchiveProjectModal: false,
  //   });

  const handleArchivedBtn = async () => {
    setIsDisableArchiveProjectBtn(true);
    await addProjectToArchived();
    setIsDisableArchiveProjectBtn(false);
  };

  return (
    <Modal show={isShow} onHide={onHide} centered>
      <div className="modal-content p-3 border_r_16px">
        <div className="modal-body">
          <p className="fs-4 fw-bold text-center mb-2 text_black lh-sm">
            Are you sure you want to archive this project?
          </p>
          <p className="fs-6 text_primary text-center">
            Click below to add it to archives
          </p>
        </div>
        <div className="modal-footer border-0">
          <button
            disabled={isDisableArchiveProjectBtn}
            type="button"
            className={`btn text_white w-100 ${
              isDisableArchiveProjectBtn ? "bg-rw-disable" : "bg_fullred"
            }`}
            data-bs-dismiss="modal"
            onClick={handleArchivedBtn}
          >
            Archive Project
          </button>
          <button
            type="button"
            className="btn bg_light_secondary text_secondary w-100"
            data-bs-dismiss="modal"
            onClick={onHide}
          >
            Back to Overview
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ArchiveProjectModal;
