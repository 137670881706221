import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { useMergeState } from "utils/custom-hooks";
import { depositByAdmin } from "api";

type Props = {
  isShow: boolean;
  payableAmount: any;
  payableId: string;
  onHide: () => void;
  handleUpdatePayment: (id: string, status: string, note: string) => void;
};
const DepositModal = ({
  isShow,
  payableAmount,
  payableId,
  onHide,
  handleUpdatePayment,
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useMergeState({
    note: "",
    isDisableBtn: false,
  });

  const handleDeposit = async () => {
    setState({ isDisableBtn: true });
    const res = await depositByAdmin({ id: payableId, note: state.note });
    setState({ isDisableBtn: false });
    if (res.success && res.message === "Status updated successfully") {
      handleUpdatePayment(payableId, "DEPOSITED", state.note);
    }
  };

  return (
    <Modal show={isShow} onHide={onHide} centered>
      <div className="modal-content p-3 p-md-4 border_r_16px overflow-hidden">
        <div className="modal-body p-0 mt-0">
          <p className="fw-bold fs-4 text-start text_black lh-sm mb-1 mt-3 text-center">
            Deposit ${payableAmount} in the Runway Account?
          </p>
          <p className="fs-6 text_primary text-start mb-2 text-center">
            Click below to confirm
          </p>

          <textarea
            className="notes_textarea mb-0"
            placeholder="Enter Notes"
            value={state.note}
            onChange={(e: any) => {
              setState({ note: e.target.value });
            }}
          />
          <button
            disabled={state.isDisableBtn}
            type="button"
            className={`btn w-100 my-3 bg_secondary ${
              state.isDisableBtn ? "bg-rw-disable" : ""
            }`}
            onClick={() => handleDeposit()}
          >
            Deposit Funds
          </button>
          <button
            type="button"
            className="btn bg_light_secondary text_secondary w-100"
            onClick={onHide}
          >
            Back
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DepositModal;
