import DialogContent from "@mui/material/DialogContent";
import { Dialog } from "components/Dialog";
import Button from "components/Button";
import { Modal } from "react-bootstrap";

type Props = {
  isDisable?: boolean;
  open: boolean;
  onClose: any;
  onConfirm: () => void;
};

export default function ConfirmInActivateProjectDialog({
  isDisable = false,
  open,
  onClose,
  onConfirm,
}: Props) {
  return (
    // <Dialog
    //   onClose={(event, reason) => {
    //     if (reason !== "backdropClick") {
    //       onClose(event);
    //     }
    //   }}
    //   open={open}
    //   maxWidth="xs"
    //   fullWidth
    //   disableEscapeKeyDown
    //   sx={{
    //     "& .MuiDialog-paper": {
    //       width: 400,
    //     },
    //     "& .MuiDialogContent-root": {
    //       padding: 3,
    //     },
    //   }}
    // >
    //   <DialogContent>
    //     <div className="text-xl text-rw-blue font-semibold text-center">
    //       Are you sure you want to stop running your project?
    //     </div>

    //     <div className="font-medium text-center mt-8">
    //       Your project won&apos;t be visible to students.
    //     </div>

    //     <Button
    //       disabled={isDisable}
    //       className="text-sm font-semibold px-6 w-full mt-4"
    //       onClick={onConfirm}
    //     >
    //       Stop Project
    //     </Button>

    //     <Button
    //       variant="secondary"
    //       className="text-sm font-semibold px-6 w-full mt-4"
    //       onClick={onClose}
    //     >
    //       Cancel
    //     </Button>
    //   </DialogContent>
    // </Dialog>
    <Modal show={open} onHide={() => onClose()} centered>
      <div className="modal-content p-3 border_r_16px">
        <div className="modal-body mt-0">
          <p className="fs-4 fw-bold text-center mb-2 text_black lh-sm">
            Are you sure you want to stop running your project?
          </p>
          <p className="fs-6 text_primary text-center">
            Your project won’t be visual to students.
          </p>
        </div>
        <div className="modal-footer border-0">
          <button
            disabled={isDisable}
            type="button"
            className={`btn text_white w-100 ${
              isDisable ? "bg-rw-disable" : "bg_secondary"
            }  `}
            onClick={onConfirm}
          >
            Stop Project
          </button>
          <button
            type="button"
            className="btn bg_light_secondary text_secondary w-100"
            onClick={onClose}
          >
            Back
          </button>
        </div>
      </div>
    </Modal>
  );
}
