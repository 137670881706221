import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "containers/dashboard/Carousel";
import { useMergeState } from "utils/custom-hooks";
import { getDashboardData } from "api";
import Images from "assets/images";
import { AccountType, BudgetType, ProjectStatus } from "utils/constants";
import AppLoader from "components/AppLoader";
import logo from "../../assets/imgs/stack.svg";

type Props = {
  user: any;
  manuBarExpend: boolean;
  manuBarToogle: () => void;
};

// const list = Array(10).fill({
//   projectName: "Full Stack Engineering Intern",
//   description: "Runway Services",
//   skillList: ["ENGINEERING", "DEBUGGING", "TYPESCRIPT"],
//   projectStatus: 0,
//   hourlyRate: "23",
//   id: "135464",
// });

const getProjectFees = (project: any) => {
  if (project.budgetType === BudgetType.FULL_TIME) {
    return "Salary TBD";
  }

  if (project.budgetType === BudgetType.RETAINER) {
    return `${project.studentRetainerAmount}`;
  }

  if (project.budgetType === BudgetType.HOURLY_RATE) {
    return `${project.studentHourlyRate}`;
  }
};

const getBusinessProjectFees = (project: any) => {
  if (project.budgetType === BudgetType.FULL_TIME) {
    return "Salary TBD";
  }

  if (project.budgetType === BudgetType.RETAINER) {
    return `${project.retainerAmount}`;
  }

  if (project.budgetType === BudgetType.HOURLY_RATE) {
    return project.hourlyRate;
  }
};

const MyProjects = ({ user, manuBarExpend, manuBarToogle }: Props) => {
  const [state, setState] = useMergeState({
    activeProjectList: [],
    onGoingProjectList: [],
    requestedProjectList: [],
    appliedProjectList: [],
    inProgressProjectList: [],
    finishedProjectList: [],
    inActiveProjectList: [],
    draftedProjectList: [],
    archivedProjects: [],
    isLoading: true,
  });
  const companyLogo = useRef("");
  const companyName = useRef("");
  const navigate = useNavigate();

  const isStudentType = user.accountType === AccountType.STUDENT;

  const loadDashboardData = async () => {
    const data = await getDashboardData();
    const isCompanyLogoUrls = data.data?.companyLogoUrls;
    companyLogo.current =
      isCompanyLogoUrls && Object.keys(isCompanyLogoUrls).length > 0
        ? isCompanyLogoUrls[Object.keys(isCompanyLogoUrls)[0]]
        : "";

    type Props = {
      projectName: string;
      description: string;
      skillList: [];
      projectStatus: number;
      hourlyRate: number;
      id: string;
      updatedAt?: string;
      business?: any;
      companyLogo?: any;
    };

    const onGoingProject: Props[] = [];
    if (data?.data?.onGoingProjects.length > 0) {
      companyName.current =
        data?.data?.onGoingProjects[0]?.business?.name || "";
      data?.data?.onGoingProjects?.map((da: any) => {
        if (isStudentType) {
          if (da?.status !== ProjectStatus.IN_ACTIVE)
            onGoingProject.push({
              projectName: da.title,
              description: da?.type,
              skillList: da.skills,
              projectStatus: da?.status,
              hourlyRate: isStudentType
                ? getProjectFees(da)
                : getBusinessProjectFees(da),
              id: da._id,
              companyLogo: isCompanyLogoUrls
                ? data.data.companyLogoUrls[da._id]
                : null,
              business: da.business,
            });
        } else {
          onGoingProject.push({
            projectName: da.title,
            description: da?.type,
            skillList: da.skills,
            projectStatus: da?.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            business: da.business,
          });
        }
      });
    }
    const draftProject: Props[] = [];
    if (data?.data?.draftProjects.length > 0) {
      companyName.current = data?.data?.draftProjects[0]?.business?.name || "";
      data?.data?.draftProjects?.map((da: any) =>
        draftProject.push({
          projectName: da.title,
          description: da?.type,
          skillList: da.skills,
          projectStatus: da?.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          id: da._id,
          companyLogo: isCompanyLogoUrls
            ? data.data.companyLogoUrls[da._id]
            : null,
          business: da.business,
        })
      );
    }
    const requestedProject: Props[] = [];
    if (data?.data?.requestedProjects.length > 0) {
      companyName.current =
        data?.data?.requestedProjects[0]?.business?.name || "";
      data?.data?.requestedProjects?.map((da: any) => {
        if (isStudentType) {
          if (da?.status !== ProjectStatus.IN_ACTIVE)
            requestedProject.push({
              projectName: da.title,
              description: da?.type,
              skillList: da.skills,
              projectStatus: da?.status,
              hourlyRate: isStudentType
                ? getProjectFees(da)
                : getBusinessProjectFees(da),
              updatedAt: da.updatedAt,
              id: da._id,
              companyLogo: isCompanyLogoUrls
                ? data.data.companyLogoUrls[da._id]
                : null,
              business: da.business,
            });
        } else {
          requestedProject.push({
            projectName: da.title,
            description: da?.type,
            skillList: da.skills,
            projectStatus: da?.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            updatedAt: da.updatedAt,
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            business: da.business,
          });
        }
      });
    }
    const appliedProject: Props[] = [];
    if (data?.data?.appliedProjects.length > 0) {
      companyName.current =
        data?.data?.appliedProjects[0]?.business?.name || "";
      data?.data?.appliedProjects?.map((da: any) => {
        if (isStudentType) {
          if (da?.status !== ProjectStatus.IN_ACTIVE)
            appliedProject.push({
              projectName: da.title,
              description: da?.type,
              skillList: da.skills,
              projectStatus: da?.status,
              hourlyRate: isStudentType
                ? getProjectFees(da)
                : getBusinessProjectFees(da),
              id: da._id,
              companyLogo: isCompanyLogoUrls
                ? data.data.companyLogoUrls[da._id]
                : null,
              business: da.business,
            });
        } else {
          appliedProject.push({
            projectName: da.title,
            description: da?.type,
            skillList: da.skills,
            projectStatus: da?.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            business: da.business,
          });
        }
      });
    }
    const activeProject: Props[] = [];
    if (data?.data?.activeProjects.length > 0) {
      companyName.current = data?.data?.activeProjects[0]?.business?.name || "";
      data?.data?.activeProjects?.map((da: any) => {
        if (isStudentType) {
          if (da?.status !== ProjectStatus.IN_ACTIVE)
            activeProject.push({
              projectName: da.title,
              description: da?.type,
              skillList: da.skills,
              projectStatus: da?.status,
              hourlyRate: isStudentType
                ? getProjectFees(da)
                : getBusinessProjectFees(da),
              id: da._id,
              companyLogo: isCompanyLogoUrls
                ? data.data.companyLogoUrls[da._id]
                : null,
              business: da.business,
            });
        } else {
          activeProject.push({
            projectName: da.title,
            description: da?.type,
            skillList: da.skills,
            projectStatus: da?.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            business: da.business,
          });
        }
      });
    }
    const inProgressProject: Props[] = [];
    if (data?.data?.inProgressProjects.length > 0) {
      companyName.current =
        data?.data?.inProgressProjects[0]?.business?.name || "";
      data?.data?.inProgressProjects?.map((da: any) => {
        if (isStudentType) {
          if (da?.status !== ProjectStatus.IN_ACTIVE)
            inProgressProject.push({
              projectName: da.title,
              description: da?.type,
              skillList: da.skills,
              projectStatus: da?.status,
              hourlyRate: isStudentType
                ? getProjectFees(da)
                : getBusinessProjectFees(da),
              id: da._id,
              companyLogo: isCompanyLogoUrls
                ? data.data.companyLogoUrls[da._id]
                : null,
              business: da.business,
            });
        } else {
          inProgressProject.push({
            projectName: da.title,
            description: da?.type,
            skillList: da.skills,
            projectStatus: da?.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            business: da.business,
          });
        }
      });
    }

    const inActiveProject: Props[] = [];
    if (data?.data?.inActiveProjects.length > 0) {
      companyName.current =
        data?.data?.inActiveProjects[0]?.business?.name || "";
      data?.data?.inActiveProjects?.map((da: any) =>
        inActiveProject.push({
          projectName: da.title,
          description: da?.type,
          skillList: da.skills,
          projectStatus: da?.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          // hourlyRate: isStudentType ? da.studentHourlyRate : da.hourlyRate,,
          id: da._id,
          companyLogo: isCompanyLogoUrls
            ? data.data.companyLogoUrls[da._id]
            : null,
          business: da.business,
        })
      );
    }
    const finshedProject: Props[] = [];
    if (data?.data?.finishedProjects.length > 0) {
      companyName.current =
        data?.data?.finishedProjects[0]?.business?.name || "";
      data?.data?.finishedProjects?.map((da: any) => {
        if (isStudentType) {
          if (da?.status !== ProjectStatus.IN_ACTIVE)
            finshedProject.push({
              projectName: da.title,
              description: da?.type,
              skillList: da.skills,
              projectStatus: da?.status,
              hourlyRate: isStudentType
                ? getProjectFees(da)
                : getBusinessProjectFees(da),
              id: da._id,
              companyLogo: isCompanyLogoUrls
                ? data.data.companyLogoUrls[da._id]
                : null,
              business: da.business,
            });
        } else {
          finshedProject.push({
            projectName: da.title,
            description: da?.type,
            skillList: da.skills,
            projectStatus: da?.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            business: da.business,
          });
        }
      });
    }
    const archivedProjects: Props[] = [];
    if (data?.data?.archivedProjects.length > 0) {
      companyName.current =
        data?.data?.archivedProjects[0]?.business?.name || "";
      data?.data?.archivedProjects?.map((da: any) => {
        if (isStudentType) {
          if (da?.status !== ProjectStatus.IN_ACTIVE)
            archivedProjects.push({
              projectName: da.title,
              description: da.type,
              skillList: da.skills,
              projectStatus: da.status,
              hourlyRate: isStudentType
                ? getProjectFees(da)
                : getBusinessProjectFees(da),
              id: da._id,
              companyLogo: isCompanyLogoUrls
                ? data.data.companyLogoUrls[da._id]
                : null,
              business: da.business,
            });
        } else {
          archivedProjects.push({
            projectName: da.title,
            description: da.type,
            skillList: da.skills,
            projectStatus: da.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            business: da.business,
          });
        }
      });
    }

    setState({
      onGoingProjectList: [...onGoingProject, ...inProgressProject],
      requestedProjectList: [...requestedProject],
      appliedProjectList: [...appliedProject],
      activeProjectList: [...activeProject],
      inProgressProjectList: [...inProgressProject],
      finishedProjectList: [...finshedProject],
      inActiveProjectList: [...inActiveProject],
      draftedProjectList: [...draftProject],
      archivedProjects,
      isLoading: false,
    });

    // setState({
    //   onGoingProjectList: [],
    //   requestedProjectList: [],
    //   appliedProjectList: [],
    //   activeProjectList: [],
    //   inProgressProjectList: [],
    //   finishedProjectList: [],
    //   inActiveProjectList: [],
    //   draftedProjectList: [],
    //   isLoading: false,
    // });
  };

  const init = async () => {
    try {
      setState({ isLoading: true });
      loadDashboardData();
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const IsEmpty =
    state?.onGoingProjectList.length === 0 &&
    state?.requestedProjectList.length === 0 &&
    state?.appliedProjectList.length === 0 &&
    state?.activeProjectList.length === 0 &&
    state?.inActiveProjectList.length === 0 &&
    state?.draftedProjectList.length === 0 &&
    state?.finishedProjectList.length === 0
      ? true
      : false;

  return (
    <div style={{ paddingBottom: 100 }}>
      <div className={`header ${manuBarExpend ? "body_pd" : ""}`} id="header">
        <div className="header_toggle" onClick={() => manuBarToogle()}>
          {" "}
          <i
            className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
            id="header_toggle"
          />{" "}
        </div>
        <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center w-100">
          <span className="d-block d-md-none me-3">
            <img src={Images.Logo} />
          </span>
          {!isStudentType ? (
            <div className="d-flex align-items-center">
              <div className="sm_logo border_r_8px overflow-hidden lg_stack_logo">
                <img
                  src={companyLogo.current !== "" ? companyLogo.current : logo}
                />
              </div>
              <div>
                <p className="text_black fw-bold fs-6 ms-3 lh-base">Projects</p>
                <p className="font_12 text_primary ms-3">
                  {companyName.current}
                </p>
              </div>
            </div>
          ) : (
            <span>My Projects</span>
          )}
        </div>
      </div>
      {!isStudentType && (
        <div className="text-end pt-3 mx-2 mx-md-4">
          <button
            type="button"
            className="btn bg_secondary btn_small mob_100"
            onClick={() => navigate("/projects/create")}
          >
            <span className="me-2">
              <i className="fa-sharp fa-solid fa-plus" />
            </span>
            Create New Project
          </button>
        </div>
      )}
      <div className="p-0 px-md-3 w-100 mb-5 mb-md-0 pb-4 pb-md-4 mt-0 mt-md-3">
        {state?.onGoingProjectList.length > 0 && (
          <Carousel
            title={0}
            tittleText="Ongoing Projects"
            list={state?.onGoingProjectList}
            isStudent={isStudentType}
            link="/projects/details?"
          />
        )}
        {state?.requestedProjectList.length > 0 && (
          <Carousel
            title={1}
            tittleText="Requested to Interview"
            list={state?.requestedProjectList}
            isStudent={isStudentType}
            link="/projects/details?"
          />
        )}

        {state?.appliedProjectList.length > 0 && (
          <Carousel
            title={2}
            tittleText="Applied Projects"
            list={state?.appliedProjectList}
            isStudent={isStudentType}
            link="/projects/details?"
          />
        )}
        {state?.activeProjectList.length > 0 && (
          <Carousel
            title={3}
            tittleText="Listed Projects"
            list={state?.activeProjectList}
            isStudent={isStudentType}
            link="/projects/details?"
          />
        )}
        {state?.inActiveProjectList.length > 0 && (
          <Carousel
            title={4}
            tittleText="Inactive Projects"
            list={state?.inActiveProjectList}
            isStudent={isStudentType}
            link="/projects/details?"
          />
        )}
        {state?.draftedProjectList.length > 0 && (
          <Carousel
            title={5}
            tittleText="Drafted Projects"
            list={state?.draftedProjectList}
            isStudent={isStudentType}
            link="/projects/create?"
          />
        )}
        {state?.finishedProjectList.length > 0 && (
          <Carousel
            title={6}
            tittleText="Completed"
            list={state?.finishedProjectList}
            isStudent={isStudentType}
            link="/projects/details?"
          />
        )}
        {state?.archivedProjects.length > 0 && (
          <Carousel
            title={7}
            tittleText="Archived Projects"
            list={state?.archivedProjects}
            isStudent={isStudentType}
            link="/projects/details?"
          />
        )}
      </div>
      {IsEmpty && state.isLoading === false ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            minHeight: 600,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span style={{ fontWeight: "bold", fontSize: 22 }}>
            No Projects Available
          </span>
        </div>
      ) : null}
      {state?.isLoading ? <AppLoader isLoading={state?.isLoading} /> : null}
    </div>
  );
};

export default MyProjects;
