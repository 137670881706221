import SocialLinks from "../SocialLinks";
import Images from "assets/images";

export default function WhatYouNeed() {
  return (
    <main className="w-full min-h-screen">
      <meta
        name="What You Need To Know Before Starting Your Talent Acquisition Journey"
        content="Unlock exceptional talent for your startup! Connect with top-
performing college students from prestigious universities through Runway's vetting 
process. Gain fresh perspectives and state-of-the-art knowledge in software 
development, engineering, graphic design, and more. Our fair pricing ensures budget-
friendly solutions during series A/B/C raises. Let us make your journey easier and more 
effective. Engage with the brightest minds and build something extraordinary. Take 
charge today with Runway!"
      />

      <div className="my-8">
        <div className="flex justify-center font-semibold mt-9 mb-3 text-xs text-rw-baby-blue-dark break-words">
          For Employers
        </div>
        <div className="text-zinc-700 text-3xl md:text-4xl font-semibold flex justify-center text-center mb-5">
          <div className="w-11/12 lg:w-3/5">
            What You Need To Know Before Starting Your Talent Acquisition
            Journey
          </div>
        </div>
        <div className="flex gap-4 justify-center mb-7 flex-wrap">
          <div className="text-zinc-600 font-semibold">By Runway Team</div>
          <div>|</div> <div className="text-zinc-600">July 31st, 2023</div>
        </div>
        <div className="flex justify-center mb-10">
          <img
            src={Images.WhatYouNeed}
            className="w-11/12 lg:w-3/5"
            width={50}
            height={50}
            alt=""
          />
        </div>
        <div className="flex justify-center">
          <div className="w-11/12 lg:w-3/5">
            <div className=" text-zinc-600 md:text-lg leading-7">
              In the highly competitive world of startups, one thing is crystal
              clear – those who swiftly acquire exceptional talent at an
              affordable rate are the ones most likely to dominate their
              industries. At Runway, we firmly believe in this principle and
              have built a platform centered around connecting startups and
              medium-sized businesses like yours with top-performing college
              students from only the most prestigious universities.
              <br />
              <br />
              Finding talented individuals who possess not only raw,
              state-of-the art skills needed for your individual project but
              also drive and ambition can be quite a challenge. That&apos;s why
              our vetting process focuses on sourcing students exclusively from
              universities with acceptance rates of 20% or less. By doing so, we
              ensure that your startup gains access to the most concentrated
              pool of the highest-quality candidates who are equipped to make an
              impact right away.
              <br />
              <br />
              Imagine having interns from renowned institutions such as Stanford
              or MIT working alongside you, bringing fresh perspectives and
              state-of-the-art knowledge about software development, engineering
              disciplines, graphic design principles — even business strategies!
              All it takes is one connection through us here at Runway to unlock
              these incredible opportunities without wasting valuable time
              trawling through job boards or getting lost amidst bureaucratic
              career centers.
              <br />
              <br />
              Let’s talk engagement models - our approach revolves around
              flexibility while ensuring mutual benefits for both parties
              involved. As independent contractors ourselves (and proud veterans
              utilizing top tier intern talent), we understand that every penny
              counts when building your empire. That&apos;s why our pricing
              structure operates on a fair 20% placement fee basis—designed to
              keep small businesses&apos; budgets intact despite capital
              constraints faced during series A/B/C raises!
              <br />
              <br />
              At Runway, success comes hand-in-hand with convenience—all aimed
              towards making life easier, more effective and more streamlined
              for ambitious visionaries like yourself. We know firsthand how
              overwhelming running a company can feel – overworked doesn&apos;t
              even begin to cover it! So let us take some weight off your
              shoulders by introducing you directly into the only talent pool
              teeming with the brightest and more brilliant young minds eager to
              contribute their expertise within reach locally.
              <br />
              <br />
              Our goal? To create a seamless platform giving startups to
              medium-sized businesses the opportunity to engage with vetted, top
              performing students and new grads from top schools, year round -
              all at an affordable rate. Join us on this exciting journey;
              together we&apos;ll build something extraordinary! Are you ready
              to take off with Runway? Take charge today - invest wisely & watch
              as your dreams take flight!
            </div>

            <div className="bg-zinc-600 mb-5 h-0.5 mt-20" />
            <div className="text-center text-zinc-600 text-2xl font-semibold">
              Share Article
            </div>
            <SocialLinks
              text="What You Need To Know Before Starting Your Talent Acquisition
            Journey"
              url="https://www.joinrunway.io/blogs/what-you-need-to-know-before-starting-your-talent-acquisition-journey/"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
