import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import AppLoader from "components/AppLoader";
import { verfyPasswordResetLinkExpiry } from "api";

export default function MerchantResetPasswordVerifyLinkExpiryContainer() {
  const { enqueueSnackbar } = useSnackbar();

  const { token } = useParams();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const init = async () => {
    try {
      if (!token) {
        enqueueSnackbar("Oops! Something went wrong!", {
          variant: "error",
        });
        return;
      }

      setIsLoading(true);

      const response = await verfyPasswordResetLinkExpiry({ token });

      if (response) {
        navigate(`/reset-password?email=${response?.data?.email}`);
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return isLoading ? <AppLoader isLoading={isLoading} /> : null;
}
