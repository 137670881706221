import React, { useEffect } from "react";
import moment from "moment";
import { useSnackbar } from "notistack";
import ProjectStatusComp from "../ProjectStatus";
import Overview from "./Overview";
import SubmitHours from "./SubmitHours";
import { useMergeState } from "utils/custom-hooks";
import axios from "axios";
import {
  createTimesheets,
  getTimesheets,
  getProjectDetails,
  listInvoices,
  payInvoice,
  matchingStudents,
  changeInvoiceStatus,
  getInterviews,
  startProject,
  addProjectToArchive,
  getEditPayoutLink,
  createConnectedAccount,
} from "api";
import PayoutTable from "./PayoutTable";
// import ProjectStartedTips from "./ProjectStartedTips";
// import { HourlyRate, ProjectStatus } from "../../../utils/constants";
import TimeSheetDetails from "./TimeSheetDetails";
import { getInvoices, getProjectTimesheets } from "utils/common";
import { formatDate } from "utils/date";
import {
  InvoiceStatus,
  ProjectStatus,
  StudentProjectStatus,
} from "utils/constants";
import CandidatesList from "../ListedProject/CandidatesList";
import OfflineLiveSwitch from "./OfflineLiveSwitch";
import StudentProfile from "../ListedProject/StudentProfile";
import ListedProjectOverview from "../ListedProject/Overview";

let tabs = [
  { name: "Overview", value: "Overview" },
  { name: "Submit Hours", value: "Submit Hours" },
  { name: "Payouts", value: "Invoices" },
];

type Props = {
  user: any;
  firstName: string;
  lastName: string;
  companyLogo?: any;
  projectId: string;
  hourlyRate: number;
  hoursPerWeek: number;
  title: string;
  type: string;
  duration: number;
  jobDocumentUrl: string;
  project?: any;
  business?: any;
  studentInfo?: any;
  studentAvatars?: any;
  interviewTimes?: any;
  handleUpdateProject: (project: any) => any;
  handleToggleEditButton: (isEdit: any) => void;
  handleChangeProjectStatus: (status: boolean) => void;
  updateSelectedUserName?: (name: string, data: any) => void;
  handleChangeCapHour?: any;
  handleChangeHoursPerWeek?: any;
  handleChangeHourlyRate?: any;
  handleEditProject?: any;
};

const OngoingProject = ({
  firstName,
  lastName,
  companyLogo,
  user,
  projectId,
  hourlyRate,
  hoursPerWeek,
  title,
  type,
  duration = 0,
  jobDocumentUrl = "",
  business = null,
  project = {},
  studentInfo,
  studentAvatars = {},
  interviewTimes = {},
  handleUpdateProject,
  handleToggleEditButton,
  handleChangeProjectStatus,
  updateSelectedUserName = () => {},
  handleChangeCapHour = () => {},
  handleChangeHoursPerWeek = () => {},
  handleChangeHourlyRate = () => {},
  handleEditProject = () => {},
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useMergeState({
    selectedTab: "Overview",
    timesheets: [],
    invoices: {},
    currentInvoice: {},
    invoice: [],
    invoicesList: [],
    currentTimesheet: {},
    isStudent: user.accountType === "STUDENT" ? true : false,
    totalAmount: 0,
    totalHours: 0,
    inProgressStudents: [],
    matchingData: {},
    showSelection: false,
    selectedStudent: [],
    upComingInterviews: [],
    // studentInfo: {},
    // isShowStudentProfile: false,
  });

  const isOngoingProject = project.status === ProjectStatus.IN_PROGRESS;
  if (
    state?.isStudent === false &&
    project.status === ProjectStatus.IN_PROGRESS
  ) {
    tabs = [
      { name: "Overview", value: "Overview" },
      { name: "Candidates", value: "Candidates" },
      { name: "View Timesheets", value: "View Timesheets" },
      { name: "Invoices", value: "Invoices" },
    ];
  } else if (!state?.isStudent && project.status === ProjectStatus.ARCHIVED) {
    tabs = [
      { name: "Overview", value: "Overview" },
      { name: "View Timesheets", value: "View Timesheets" },
      { name: "Invoices", value: "Invoices" },
    ];
  }

  let inProgressStudentsName = "";
  const inProgressStudentsBusinessSide = project.studentProjects.filter(
    (item: any) => {
      if (item.status === "IN_PROGRESS") {
        inProgressStudentsName += `${item.student.firstName} ${item.student.lastName}, `;
      }
      return item.status === "IN_PROGRESS";
    }
  );

  const handleCalculateWeeklyHoursAndAmount = (
    timesheets: any,
    property: string
  ) =>
    timesheets.reduce(
      (total: any, item: any) => ({
        totalAmount: item[property] + (total?.totalAmount || 0),
        totalHours: item.totalHours + (total?.totalHours || 0),
      }),
      { totalAmount: 0, totalHours: 0 }
    );

  const handleStudentTimesheets = async (projectInfo: any) => {
    const { timesheets, currentTimesheet } = getProjectTimesheets({
      project: projectInfo,
      studentId: user?._id,
    });
    const timesheetIds = timesheets.map((item: any) => item._id);

    let invoicesResponse = await listInvoices({ id: projectId });
    if (invoicesResponse?.success) {
      // invoicesResponse = invoicesResponse.data.filter((invoice: any) =>
      //   invoice.timesheets.some((timesheet: any) =>
      //     timesheetIds.includes(timesheet?._id)
      //   )
      // );
      invoicesResponse = invoicesResponse.data.reduce(
        (items: any, invoice: any) => {
          if (
            invoice.timesheets.some((timesheet: any) =>
              timesheetIds.includes(timesheet?._id)
            )
          )
            items.push({
              ...invoice,
              status:
                invoice?.studentPayment &&
                invoice.studentPayment.length > 0 &&
                !invoice.studentPayment[0]?.status
                  ? "ONHOLD"
                  : invoice.status,
            });
          return items;
        },
        []
      );
    } else {
      invoicesResponse = [];
    }

    const { totalAmount, totalHours } = handleCalculateWeeklyHoursAndAmount(
      timesheets,
      "studentTotalAmount"
    );

    // const { totalAmount, totalHours } = timesheets.reduce(
    //   (total: any, item: any) => ({
    //     totalAmount: item.studentTotalAmount + (total?.totalAmount || 0),
    //     totalHours: item.totalHours + (total?.totalHours || 0),
    //   }),
    //   { totalAmount: 0, totalHours: 0 }
    // );
    const inProgressStudents = projectInfo.studentProjects.filter(
      (item: any) =>
        !item.suggested &&
        item.status === "IN_PROGRESS" &&
        item?.student?._id === user?._id
    );
    setState({
      timesheets,
      currentTimesheet,
      totalAmount,
      totalHours,
      inProgressStudents,
      invoicesList: invoicesResponse,
    });
  };

  const handleUpdateInvoicesList = (data: any) => {
    let invoices = data.reduce((netObj: any, item: any) => {
      const key = moment(item.weekStart).format("YYYY-MM-DD");
      return {
        ...netObj,
        [key]: {
          weekStart: item.weekStart,
          timesheets: [
            ...(netObj[key]?.timesheets || []),
            ...item.timesheets.map((timesheet: any) => ({
              ...timesheet,
              invoiceId: item._id,
              status: item.status,
            })),
          ],
        },
      };
    }, {});
    invoices = Object.keys(invoices)
      .sort((a: string, b: string) => {
        const currentDate1: any = new Date(a);
        const currentDate2: any = new Date(b);
        return currentDate1 - currentDate2;
      })
      .reduce((netObj: any, key: any) => {
        return { ...netObj, [key]: invoices[key] };
      }, {});
    let lastIndex = Object.keys(invoices).length;
    lastIndex = lastIndex ? lastIndex - 1 : 0;
    const weekStart = moment(Object.keys(invoices)[lastIndex]).startOf(
      "isoWeek"
    );
    const currentInvoice = invoices[formatDate(weekStart, "YYYY-MM-DD")];
    return {
      invoices,
      currentInvoice,
    };
  };

  const handleGetTimesheets = async () => {
    const timesheetsResponse = await getTimesheets({
      id: projectId,
    });
    return timesheetsResponse;
  };
  const handleMatchingStudents = async () => {
    const matchingStudentsResponse = await matchingStudents({ projectId });
    return matchingStudentsResponse;
  };
  const handleListInvoices = async () => {
    const listInvoicesResponse = await listInvoices({ id: projectId });
    return listInvoicesResponse;
  };

  const init = async () => {
    if (state.isStudent) {
      handleStudentTimesheets(project);
    } else {
      const interviewRequestedStudents = project.studentProjects.filter(
        (student: any) =>
          student.status === StudentProjectStatus.REQUESTED_TO_INTERVIEW
      );
      const params = {
        projectId,
        isUpcoming: true,
        studentId: interviewRequestedStudents.map(
          (item: any) => item.student._id
        ),
      };
      const promise = await Promise.all(
        interviewRequestedStudents.length > 0
          ? [
              handleGetTimesheets(),
              handleMatchingStudents(),
              handleListInvoices(),
              getInterviews(params),
            ]
          : [
              handleGetTimesheets(),
              handleMatchingStudents(),
              handleListInvoices(),
            ]
      );
      const timesheetsResponse = promise[0];
      let timesheets = [];
      if (timesheetsResponse.success) {
        timesheets = timesheetsResponse.data;
      }
      const currentWeekTimesheets = isOngoingProject
        ? timesheets.filter(
            (item: any) =>
              moment(item.weekStart).format("YYYY-MM-DD") ===
              moment().startOf("isoWeek").format("YYYY-MM-DD")
          )
        : timesheets;

      const { totalAmount, totalHours } = handleCalculateWeeklyHoursAndAmount(
        currentWeekTimesheets,
        "totalAmount"
      );
      const matchingDataResponse = promise[1];
      const invoicesResponse = promise[2];
      let currentInvoiceData = {};
      let invoicesData: any = {};
      let upComingInterviews = [];
      if (invoicesResponse.success) {
        const { invoices, currentInvoice } = handleUpdateInvoicesList(
          invoicesResponse.data
        );
        currentInvoiceData = currentInvoice;
        invoicesData = invoices;
      }

      if (interviewRequestedStudents.length > 0) {
        const interviewsResponse = promise[3];
        if (interviewsResponse.success) {
          upComingInterviews = interviewsResponse.data;
        }
      }
      setState({
        invoice: invoicesResponse.data,
        timesheets,
        invoices: invoicesData,
        currentInvoice: currentInvoiceData,
        invoicesList: invoicesResponse.data,
        totalAmount,
        totalHours,
        upComingInterviews,
        matchingData: matchingDataResponse.data,
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  const invoicesLength = Object.keys(state?.invoices)?.length;

  const getIndexOfCurrentInvoice = () => {
    const keys = Object.keys(state?.invoices);

    // Find the index of a specific key
    const keyToFind = formatDate(
      state?.currentInvoice?.weekStart,
      "YYYY-MM-DD"
    );
    const index = keys.indexOf(keyToFind);
    return index;
  };

  const hasPrev = getIndexOfCurrentInvoice() > 0 ? true : false;
  const hasNext =
    invoicesLength > 0 && invoicesLength - 1 !== getIndexOfCurrentInvoice()
      ? true
      : false;

  const handlePreviousWeek = () => {
    if (state.isStudent) {
      const weekStart = moment(state?.currentTimesheet?.weekStart)
        .subtract(1, "week")
        .startOf("isoWeek");

      const { timesheets, currentTimesheet } = getProjectTimesheets({
        project,
        studentId: user?._id,
        weekStart,
      });

      setState({
        timesheets,
        currentTimesheet,
      });
    } else {
      const invoicesKey = Object.keys(state?.invoices);
      const weekStart = moment(invoicesKey[getIndexOfCurrentInvoice() - 1]);

      // const { invoices, currentInvoice } = getInvoices({
      //   timesheets: state?.timesheets,
      //   weekStart,
      // });

      setState({
        // invoices,
        currentInvoice: state?.invoices[weekStart.format("YYYY-MM-DD")],
      });
    }
  };

  const handleNextWeek = () => {
    if (state.isStudent) {
      const weekStart = moment(state?.currentTimesheet?.weekStart)
        .add(1, "week")
        .startOf("isoWeek");

      const { timesheets, currentTimesheet } = getProjectTimesheets({
        project,
        studentId: user?._id,
        weekStart,
      });

      setState({
        timesheets,
        currentTimesheet,
      });
    } else {
      const invoicesKey = Object.keys(state?.invoices);
      const weekStart = moment(invoicesKey[getIndexOfCurrentInvoice() + 1]);

      // const { invoices, currentInvoice } = getInvoices({
      //   timesheets: state?.timesheets,
      //   weekStart,
      // });

      setState({
        // invoices,
        currentInvoice: state?.invoices[weekStart.format("YYYY-MM-DD")],
      });
    }
  };

  const getTimeSheetOfInvoice = (id: any) => {
    const findedTimeSheet = state.timesheets.find(
      (timesheet: any) => timesheet._id === id
    );
    return findedTimeSheet;
  };

  const onCalculatePerHourRate = (timesheet: any) => {
    const totalAmount = Number(
      state.isStudent ? timesheet?.studentTotalAmount : timesheet?.totalAmount
    );
    const totalHours = Number(timesheet?.totalHours);
    return totalAmount ? totalAmount / totalHours : 0;
  };

  const getHourlyRateOfTimeSheet = (item: any) => {
    const invoiceTimeSheet = getTimeSheetOfInvoice(item?._id);
    if (!invoiceTimeSheet) return 0;
    return onCalculatePerHourRate(invoiceTimeSheet);
  };

  const getTimeSheetHours = (item: any) => {
    const invoiceTimeSheet = getTimeSheetOfInvoice(item?._id);
    if (!invoiceTimeSheet) return 0;
    return Number(invoiceTimeSheet?.totalHours);
  };

  const onCalculateTotalAmountOfInvoice = (invoice: any) => {
    const amount = invoice.timesheets.reduce(
      (total: number, item: any) =>
        total + getTimeSheetHours(item) * getHourlyRateOfTimeSheet(item),
      0
    );
    return amount;
  };

  const invoiceStatus = () => {
    const invoice = state.invoicesList?.find(
      (elem: any) =>
        formatDate(elem.weekStart, "YYYY-MM-DD") ===
        formatDate(
          state.isStudent
            ? state.currentTimesheet?.weekStart
            : state.currentInvoice?.weekStart,
          "YYYY-MM-DD"
        )
    );
    return invoice?.status;
  };

  const getInvoiceId = () => {
    const invoice = state.invoicesList?.find(
      (elem: any) =>
        formatDate(elem.weekStart, "YYYY-MM-DD") ===
        formatDate(
          state.isStudent
            ? state.currentTimesheet?.weekStart
            : state.currentInvoice?.weekStart,
          "YYYY-MM-DD"
        )
    );
    return invoice;
  };

  const handleChangeHours = (index: number, hours: string) => {
    const updatedTimesheet = [...state.currentTimesheet.timesheet];

    updatedTimesheet[index].hours = hours;

    setState({
      currentTimesheet: {
        ...state?.currentTimesheet,
        timesheet: updatedTimesheet,
      },
    });
  };

  const handleClearTimesheet = () => {
    let updatedTimesheet = [...state.currentTimesheet.timesheet];

    updatedTimesheet = updatedTimesheet.map((timesheet: any) => ({
      day: timesheet.day,
      hours: "",
    }));

    setState({
      currentTimesheet: {
        ...state?.currentTimesheet,
        timesheet: updatedTimesheet,
      },
    });
  };

  const handleSubmitTimesheet = async (
    weeklySummary: string,
    invoiceId = ""
  ) => {
    try {
      if (!isOngoingProject) return;
      setState({ isLoading: true });

      const payload = {
        id: projectId,
        weekStart: state?.currentTimesheet?.weekStart,
        weeklySummary,
        timesheet: state?.currentTimesheet?.timesheet?.map((elem: any) => ({
          day: elem.day,
          hours: elem.hours ? Number(elem.hours) : 0,
        })),
      };

      const response = await createTimesheets(payload);

      if (response?.success) {
        if (invoiceId.length > 0) {
          const changeStatusResponse = await changeInvoiceStatus({
            id: invoiceId,
            status: InvoiceStatus.REQUESTED_FOR_APPROVAL,
          });
        }
        const projectResponse = await getProjectDetails(projectId);
        if (projectResponse?.success) {
          const projectInfo = projectResponse.data;
          await handleStudentTimesheets(projectInfo);
          handleUpdateProject(projectInfo);
        }
        enqueueSnackbar(
          invoiceId.length > 0 ? "Time sheet updated." : response?.message,
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const updateInvoiceState = async (
    invoiceId: string,
    status: string,
    reason?: ""
  ) => {
    try {
      const response = await changeInvoiceStatus({
        id: invoiceId,
        status,
        reason,
      });
      if (response.success) {
        const findedIndex = state.invoicesList.findIndex(
          (invoice: any) => invoice._id === response.data._id
        );
        const invoiceListCopy = state.invoicesList.slice();
        invoiceListCopy[findedIndex] = {
          ...response.data,
          timesheets: invoiceListCopy[findedIndex].timesheets.map(
            (item: any) => ({ ...item, status: "APPROVED" })
          ),
        };
        const { invoices, currentInvoice } =
          handleUpdateInvoicesList(invoiceListCopy);
        setState({ invoicesList: invoiceListCopy, invoices, currentInvoice });
        enqueueSnackbar("Invoice updated.", { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (err: any) {
      enqueueSnackbar("Oops something went wrong.", { variant: "error" });
    }
  };

  const handlePayInvoice = async (invoice: any) => {
    try {
      // const invoice = state.invoicesList.find(
      //   (elem: any) =>
      //     formatDate(elem.weekStart, "YYYY-MM-DD") ===
      //     formatDate(state.currentInvoice.weekStart, "YYYY-MM-DD")
      // );

      // if (!invoice || invoice?.status !== InvoiceStatus.PENDING || ) {
      //   return;
      // }

      if (!invoice || invoice?.status !== InvoiceStatus.APPROVED) {
        return;
      }

      const studentFees: number = +Number(
        invoice?.studentTotalAmount || 0
      ).toFixed(2);

      const totalAmount: number = +Number(invoice?.totalAmount || 0).toFixed(2);

      const platformFees = +Number(totalAmount * 0.2).toFixed(2);
      const processingFees = +Number((totalAmount || 0) * 0.03).toFixed(2);

      const payload = {
        id: invoice._id,
        studentFees,
        platformFees,
        processingFees,
        totalAmount,
      };

      const response = await payInvoice(payload);
      if (response.success && response?.url) {
        window.open(response?.url, "_blank");
      } else if (
        response.success &&
        response.message === "This invoice already paid."
      ) {
        window.location.reload();
      }

      // if (response?.data?.nextAction) {
      //   window.location.href = response.data.nextAction.url;
      //   return;
      // }

      // enqueueSnackbar("Payout initiated successfully!", {
      //   variant: "success",
      // });

      // // window.location.href = `/projects/details?id=${projectId}&view=OVERVIEW`;
      // setTimeout(() => {
      //   window.location.href = `/projects/details?id=${projectId}`;
      // }, 2000);
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleChangeTab = (value: string) => {
    setState({ selectedTab: value });
  };

  const handleViewInvoice = (weekStart: string) => {
    // const { invoices, currentInvoice } = getInvoices({
    //   timesheets: state?.timesheets,
    //   weekStart,
    // });

    // setState({
    //   invoices,
    //   currentInvoice,
    // });

    weekStart = moment(weekStart).format("YYYY-MM-DD");
    setState({
      currentInvoice: state?.invoices[weekStart],
    });
  };

  const handleEditPayoutAccount = async () => {
    try {
      const response = await getEditPayoutLink();

      if (response?.success) {
        window.open(response?.data?.url, "_blank");
        // window.location.href = response?.data?.url;
        // "_blank"
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleCreateConnectAccount = async () => {
    try {
      const response = await createConnectedAccount();

      if (response?.success) {
        window.location.href = response?.data?.url;
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const goToInvoiceView = async (pdfLink: string, status: any = "") => {
    if (state.isStudent) {
      if (user?.payoutsEnabled) await handleEditPayoutAccount();
      else await handleCreateConnectAccount();
      // window.open("https://dashboard.stripe.com/login", "_blank");
    } else if (!state.isStudent) {
      // handleViewInvoice(weekStart);
      if (status === "PAID") window.open(pdfLink, "_blank");
      else setState({ selectedTab: "View Timesheets" });
    }
  };

  const updateSelectedStudent = (id: string) => {
    const selectedStudentCopy = state.selectedStudent.filter(
      (item: any) => item !== id
    );
    if (selectedStudentCopy.length === state.selectedStudent.length) {
      setState({ selectedStudent: [...selectedStudentCopy, id] });
    } else setState({ selectedStudent: selectedStudentCopy });
  };

  const handleStartProject = async (students?: any) => {
    if (students.length === 0) {
      alert("Please select student");
    } else {
      const response = await startProject({
        id: projectId,
        students: [...students],
      });

      if (response.success) {
        handleUpdateProject(response.data);
        setState({ showSelection: false });
      }
    }
  };

  const handleAddProjectToArchived = async () => {
    const response = await addProjectToArchive({
      id: projectId,
    });

    if (response.success) {
      handleUpdateProject(response.data);
    }
  };

  const handleOpenProfile = (
    id: string,
    studentStatus: string,
    name: string
  ) => {
    updateSelectedUserName(name, id);
    setState({
      // studentInfo: id,
      studentStatus,
      // isShowStudentProfile: true,
    });
  };

  const currentTimesheetWeekIsAfterTheProjectCreatedAt = () => {
    const firstDayOfProjectCreatedAt = moment(project.createdAt)
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    const firstDayOfCurrentTimesheet = moment(
      state?.currentTimesheet?.weekStart
    )
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    return moment(firstDayOfCurrentTimesheet).isAfter(
      firstDayOfProjectCreatedAt
    );
  };

  const currentTimesheetWeekIsBeforeTheNextWeek = () => {
    const firstDayOfCurrentWeek = moment()
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    const firstDayOfCurrentTimesheet = moment(
      state?.currentTimesheet?.weekStart
    )
      .startOf("isoWeek")
      .format("YYYY-MM-DD");
    return moment(firstDayOfCurrentTimesheet).isBefore(firstDayOfCurrentWeek);
  };

  // const isOngoingStatus = project?.status === ProjectStatus.IN_PROGRESS;
  const isCapRate = project?.isCapRate ? true : false;

  return studentInfo?.email === "" ? (
    <div>
      <div className="p-4 bg-white w-100 border border_gray border_r_16px">
        <ProjectStatusComp
          type={isOngoingProject ? 2 : 3}
          buttonTitle={
            !state.isStudent
              ? `${
                  inProgressStudentsBusinessSide.length > 1
                    ? "Students"
                    : "Student"
                }: ${inProgressStudentsName.slice(0, -2)}`
              : `Manager: ${firstName} ${lastName}`
          }
          hourlyRate={hourlyRate}
          title={title}
          titleType={type}
          jobDocumentUrl={jobDocumentUrl}
          duration={duration}
          companyLogo={companyLogo}
          business={business}
          // buttonOnClick={() => console.log("click")}
        />
      </div>

      <div className="bg-white w-100 border border_gray border_r_16px mt-4 mb-4 mb-md-0">
        <ul
          className="nav nav-tabs nav_project_tabs px-2 pt-2"
          id="myTab"
          role="tablist"
        >
          {tabs.map((tab, index) => (
            <li
              key={index}
              className="nav-item"
              role="presentation"
              onClick={() => handleChangeTab(tab.value)}
            >
              <button
                className={`nav-link ${
                  tab.value === state?.selectedTab ? "active" : ""
                }`}
                id="overview-tab"
                type="button"
                role="tab"
              >
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className={`tab-pane fade show active w-100 ${
              state?.selectedTab === "Overview" ? "p-3 p-md-4" : ""
            }`}
          >
            {state?.selectedTab === "Overview" ? (
              <Overview
                firstName={firstName}
                lastName={lastName}
                user={user}
                studentProjects={
                  state.isStudent
                    ? state.inProgressStudents
                    : inProgressStudentsBusinessSide
                }
                totalHours={state.totalHours}
                totalEarning={state.totalAmount}
                hourlyRate={hourlyRate}
                hoursPerWeek={hoursPerWeek}
                studentAvatars={studentAvatars}
                project={project}
                upComingInterviews={state.upComingInterviews}
                handleChangeTab={handleChangeTab}
                goToInvoiceView={goToInvoiceView}
                handleChangeProjectStatus={handleChangeProjectStatus}
                handleChangeCapHour={handleChangeCapHour}
                handleChangeHoursPerWeek={handleChangeHoursPerWeek}
                handleChangeHourlyRate={handleChangeHourlyRate}
                handleEditProject={handleEditProject}
                handleStartProject={(student: string) =>
                  handleStartProject([student])
                }
                handleUpdateProject={handleUpdateProject}
                handleToggleEditButton={handleToggleEditButton}
                handleAddProjectToArchived={handleAddProjectToArchived}
              />
            ) : state?.selectedTab === "Candidates" ? (
              <>
                <div className="d-flex justify-content-between align-items-center my-3 flex-wrap px-4 border-bottom pb-3">
                  <OfflineLiveSwitch
                    isOnline={project?.isSearchable || false}
                    handleChangeProjectStatus={handleChangeProjectStatus}
                  />
                  {!state.showSelection ? (
                    <div className="d-flex align-items-center flex-wrap mt-2 mt-md-0 w_sm_100">
                      <button
                        type="button"
                        className="btn btn_small text_secondary w_sm_100 me-0 me-md-2 border border_secondary"
                        onClick={() => handleToggleEditButton(true)}
                      >
                        Edit Project Listing
                      </button>
                      <button
                        type="button"
                        className="btn bg_secondary btn_small text_white w_sm_100 mt-2 mt-md-0 mb-0"
                        onClick={() => setState({ showSelection: true })}
                      >
                        Add Student to Project
                      </button>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center flex-wrap flex-md-nowrap mb-0 mb-md-2 mob_100 column_reverse">
                      <button
                        type="button"
                        className="btn bg_warning btn_small text_redish mob_100 mb-2 mb-md-0 me-0 me-md-1"
                        data-bs-toggle="modal"
                        data-bs-target="#declineModal"
                        onClick={() => setState({ showSelection: false })}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn bg_secondary btn_small text_secondary mob_100 cursor_pointer"
                        onClick={() =>
                          handleStartProject(state.selectedStudent)
                        }
                      >
                        Start Project
                      </button>
                    </div>
                  )}
                </div>
                <CandidatesList
                  status={project.status}
                  studentList={project.studentProjects.filter(
                    (item: any) =>
                      item.status !== StudentProjectStatus.IN_PROGRESS
                  )}
                  handleOpenProfile={handleOpenProfile}
                  handleSelect={(id: string) => updateSelectedStudent(id)}
                  selectedStudent={state.selectedStudent}
                  showSelection={state.showSelection}
                  matchingData={state.matchingData}
                />
              </>
            ) : state?.selectedTab === "Submit Hours" ? (
              <SubmitHours
                isPayoutsEnabled={user?.payoutsEnabled}
                projectId={projectId}
                isOngoingProject={isOngoingProject}
                hasPrev={currentTimesheetWeekIsAfterTheProjectCreatedAt()}
                hasNext={currentTimesheetWeekIsBeforeTheNextWeek()}
                isCapRate={isCapRate}
                hoursPerWeek={project.hoursPerWeek}
                currentTimesheet={state.currentTimesheet}
                handlePreviousWeek={handlePreviousWeek}
                handleNextWeek={handleNextWeek}
                invoiceStatus={invoiceStatus}
                getInvoiceId={getInvoiceId}
                handleChangeHours={handleChangeHours}
                handleClearTimesheet={handleClearTimesheet}
                handleSubmitTimesheet={handleSubmitTimesheet}
              />
            ) : state?.selectedTab === "View Timesheets" ? (
              <TimeSheetDetails
                invoice={state?.invoice}
                hasPrev={hasPrev}
                hasNext={hasNext}
                currentInvoice={state.currentInvoice}
                studentAvatars={studentAvatars}
                handlePreviousWeek={handlePreviousWeek}
                handleNextWeek={handleNextWeek}
                onCalculatePerHourRate={onCalculatePerHourRate}
                invoiceStatus={invoiceStatus}
                handlePayInvoice={handlePayInvoice}
                updateInvoiceState={updateInvoiceState}
                getTimeSheetOfInvoice={getTimeSheetOfInvoice}
              />
            ) : state?.selectedTab === "Invoices" ? (
              <PayoutTable
                user={user}
                invoice={state?.invoice}
                totalEarning={state.totalAmount}
                hourlyRate={hourlyRate}
                hoursPerWeek={hoursPerWeek}
                isStudent={state?.isStudent}
                invoicesList={state.invoicesList}
                goToInvoiceView={goToInvoiceView}
                getTimeSheetHours={getTimeSheetHours}
                getTimeSheetOfInvoice={getTimeSheetOfInvoice}
                getHourlyRateOfTimeSheet={getHourlyRateOfTimeSheet}
                onCalculateTotalAmountOfInvoice={
                  onCalculateTotalAmountOfInvoice
                }
              />
            ) : null}
          </div>
        </div>
      </div>
      {!state.isStudent && state?.selectedTab === "Overview" && (
        <div className="bg-white w-100 border border_gray border_r_16px p-4 mt-4 mb-4 mb-md-0">
          <div className="d-flex align-items-center flex-wrap justify-content-between mb-4 mt-0">
            <div className="d-flex align-items-center">
              <p className="fw-bold text_primary me-2">Project Listing:</p>
              {project.isSearchable ? (
                <p className="ps-2 font_14 text_secondary">
                  You are still accepting candidates
                </p>
              ) : (
                <p className="ps-2 font_14 text_secondary">
                  You are not accepting candidates
                </p>
              )}
            </div>
            <OfflineLiveSwitch
              isOnline={project?.isSearchable || false}
              isShowAcceptingText={false}
              handleChangeProjectStatus={handleChangeProjectStatus}
            />
          </div>

          <ListedProjectOverview
            user={user}
            description={project.description}
            responsibilties={[project.responsibilties]}
            qualifications={[project.qualifications]}
            skills={project.skills}
            duration={project.duration}
            jobDocumentUrl={project.jobDocumentUrl}
            status={project.status}
            hourlyRate={hourlyRate}
            projectId={project._id}
            project={project}
            handleChangeCapHour={handleChangeCapHour}
            handleChangeHoursPerWeek={handleChangeHoursPerWeek}
            handleChangeHourlyRate={handleChangeHourlyRate}
            handleEditProject={handleEditProject}
            handleToggleEditButton={() => handleToggleEditButton(true)}
          />
        </div>
      )}
    </div>
  ) : (
    <StudentProfile
      user={studentInfo}
      projectId={projectId}
      studentStatus={state?.studentStatus}
      interviewTimes={interviewTimes}
    />
  );
};

export default OngoingProject;
