import DialogContent from "@mui/material/DialogContent";
import Button from "components/Button";
import { Dialog } from "components/Dialog";
import TextField from "components/TextField";
import { useMergeState } from "utils/custom-hooks";

type Props = {
  open: boolean;
  onApply: any;
  onClose: any;
};

export default function PromoCodeDialog({ open, onApply, onClose }: Props) {
  const [state, setState] = useMergeState({
    promoCode: "",

    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (!state?.promoCode) {
      errors = { ...errors, promoCode: "Promo Code is required." };
      isValid = false;
    }

    setState({ errors });

    return isValid;
  };

  const handleApply = () => {
    if (!isFormValid()) {
      return;
    }

    onApply(state.promoCode);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
      open={open}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-paper": {
          width: 400,
        },
        "& .MuiDialogContent-root": {
          padding: 2,
        },
      }}
    >
      <DialogContent>
        <div className="relative">
          <div className="text-xl text-rw-blue font-bold text-center">
            Have a Promo Code?
          </div>

          <div className="font-semibold text-center mt-4">
            Enter below to apply promo.
          </div>

          <div className="mt-8">
            <TextField
              type="text"
              name="promoCode"
              placeholder="Promo Code"
              value={state?.promoCode}
              onChange={handleChange}
              error={state?.errors?.promoCode}
            />
          </div>

          <Button
            variant="primary"
            className="w-full h-12 font-semibold mt-8"
            onClick={handleApply}
          >
            Apply
          </Button>

          <Button
            variant="secondary"
            className="w-full h-12 font-semibold mt-4"
            onClick={onClose}
          >
            Back
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
