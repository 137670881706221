import Images from "assets/images";

export default function SocialLinks({
  text,
  url,
}: {
  text: string;
  url: string;
}) {
  return (
    <div className="flex gap-5 justify-center mt-7">
      <a href={`https://twitter.com/intent/tweet?text=${text}%20${url}`}>
        <img src={Images.TwitterLogo} width={46} height={46} alt="Twitter" />
      </a>
      <a href={`https://www.linkedin.com/shareArticle?url=${url}`}>
        <img src={Images.LinkedInLogo} width={46} height={46} alt="LinkedIn" />
      </a>
    </div>
  );
}
