import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { offsetDates, wrapFullName } from "utils/common";
import { InvoiceStatus } from "utils/constants";
import { useMergeState } from "utils/custom-hooks";
import { formatDate } from "utils/date";

const paymentTable = [
  {
    paymentStatus: 0,
    date: "06-28-2023",
    due: "06-28-2023",
    hours: 10,
    amount: 360,
    action: () => {},
  },
  {
    paymentStatus: 1,
    date: "06-28-2023",
    due: "06-28-2023",
    hours: 10,
    amount: 360,
    action: () => {},
  },
  {
    paymentStatus: 2,
    date: "06-28-2023",
    due: "06-28-2023",
    hours: 10,
    amount: 360,
    action: () => {},
  },
  {
    paymentStatus: 2,
    date: "06-28-2023",
    due: "06-28-2023",
    hours: 10,
    amount: 360,
    action: () => {},
  },
];
const statusClass = [
  "btn btn_small bg_warning text-uppercase text_redish px-3 py-2 lh-sm",
  "btn btn_small bg_lightorange text-uppercase text_orange px-3 py-2 lh-sm",
  "btn btn_small bg_light_green text-uppercase text_green px-3 py-2 lh-sm",
];

const buttonClass = [
  "btn btn_small bg_secondary px-3 py-2 lh-sm text-uppercase",
  "btn btn_small bg_light_secondary text_secondary text-uppercase px-3 py-2 lh-sm",
];
type Props = {
  user?: any;
  invoice: any;
  totalEarning: number;
  hourlyRate: number;
  hoursPerWeek: number;
  isStudent: boolean;
  invoicesList: any;
  goToInvoiceView: (weekstart: string, status?: any) => any;
  getTimeSheetHours: any;
  getTimeSheetOfInvoice: (id: string) => any;
  getHourlyRateOfTimeSheet: any;
  onCalculateTotalAmountOfInvoice: any;
};

const PayoutTable = ({
  user,
  invoice,
  totalEarning,
  hourlyRate,
  hoursPerWeek,
  isStudent,
  invoicesList,
  goToInvoiceView,
  getTimeSheetHours,
  getTimeSheetOfInvoice,
  getHourlyRateOfTimeSheet,
  onCalculateTotalAmountOfInvoice,
}: Props) => {
  const [clickableInvoice, setClickableInvoice] = useState("false");
  const getInvoiceProcessingFees = (amount: number) =>
    +Number(amount * 0.03).toFixed(2);

  return (
    <div>
      <div className="table-responsive payout_table">
        <table className="table table-striped">
          <thead>
            <tr>
              <th scope="col" className="ps-4">
                Status
              </th>
              {!isStudent && <th scope="col">Student</th>}
              <th scope="col">Date Issued</th>
              <th scope="col">Date Due</th>
              <th scope="col">Hours</th>
              <th scope="col">Amount Due</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {invoicesList.length > 0 ? (
              invoicesList.map((invoice: any, index: string) => (
                <tr key={index}>
                  <td className="ps-4 align-middle">
                    <button
                      type="button"
                      className={
                        invoice.status === InvoiceStatus.PENDING ||
                        invoice.status === InvoiceStatus.REQUESTED_FOR_APPROVAL
                          ? statusClass[1]
                          : invoice.status === InvoiceStatus.PAID
                          ? statusClass[2]
                          : statusClass[0]
                      }
                    >
                      {invoice.status === InvoiceStatus.PENDING ||
                      invoice.status === InvoiceStatus.REQUESTED_FOR_APPROVAL
                        ? "PENDING"
                        : invoice.status === InvoiceStatus.PAID
                        ? "PAID"
                        : "UNPAID"}
                    </button>
                  </td>
                  {!isStudent && (
                    <td className="align-middle">
                      {invoice.timesheets.map((item: any, index: any) =>
                        getTimeSheetOfInvoice(item._id)
                          ? `${wrapFullName(
                              item?.student?.firstName,
                              item?.student?.lastName
                            )}  `
                          : null
                      )}
                    </td>
                  )}
                  <td className="align-middle">
                    {" "}
                    {formatDate(invoice?.weekStart, "MM-DD-YYYY")}
                  </td>
                  <td className="align-middle">
                    {formatDate(offsetDates(invoice?.due, 0), "MM-DD-YYYY")}
                  </td>
                  <td className="text_secondary align-middle">
                    {invoice.timesheets.map((item: any, index: any) =>
                      getTimeSheetOfInvoice(item._id) ? (
                        <React.Fragment key={index}>
                          ${getHourlyRateOfTimeSheet(item).toFixed(2)} x{" "}
                          {getTimeSheetHours(item)}
                          hrs
                          {index + 1 !== invoice.timesheets.length &&
                            !isStudent && (
                              <div className="w-6/12 text-rw-blue text-center font-semibold">
                                +
                              </div>
                            )}
                        </React.Fragment>
                      ) : null
                    )}
                  </td>
                  <td
                    className={`align-middle ${
                      invoice.status === InvoiceStatus.PENDING ||
                      invoice.status === InvoiceStatus.REQUESTED_FOR_APPROVAL
                        ? "text_orange"
                        : invoice.status === InvoiceStatus.PAID
                        ? "text_green"
                        : "text_redish"
                    } `}
                  >
                    $
                    {(
                      Number(onCalculateTotalAmountOfInvoice(invoice)) +
                      (isStudent
                        ? 0
                        : getInvoiceProcessingFees(
                            onCalculateTotalAmountOfInvoice(invoice)
                          ))
                    ).toFixed(2)}{" "}
                    {invoice.status === InvoiceStatus.PENDING ||
                    invoice.status === InvoiceStatus.REQUESTED_FOR_APPROVAL
                      ? "Pending"
                      : invoice.status === InvoiceStatus.PAID
                      ? "Paid"
                      : "Due"}
                  </td>
                  <td className="align-middle">
                    <button
                      disabled={invoice._id === clickableInvoice}
                      type="button"
                      className={
                        invoice._id === clickableInvoice
                          ? "btn btn_small bg-rw-disable px-3 py-2 lh-sm text-uppercase"
                          : invoice.status === InvoiceStatus.PENDING ||
                            invoice.status ===
                              InvoiceStatus.REQUESTED_FOR_APPROVAL ||
                            invoice.status === InvoiceStatus.APPROVED
                          ? buttonClass[0]
                          : buttonClass[1]
                      }
                      onClick={async () => {
                        if (isStudent) {
                          setClickableInvoice(invoice._id);
                          await goToInvoiceView(invoice.weekStart);
                          setClickableInvoice("false");
                        } else {
                          goToInvoiceView(
                            invoice?.stripeInvoice?.invoice_pdf,
                            invoice.status
                          ); // invoice.weekStart
                        }
                      }}
                    >
                      {!isStudent
                        ? invoice.status === InvoiceStatus.PENDING ||
                          invoice.status ===
                            InvoiceStatus.REQUESTED_FOR_APPROVAL ||
                          invoice.status === InvoiceStatus.APPROVED
                          ? "Pay Invoice"
                          : "view invoice"
                        : user?.payoutsEnabled
                        ? "Open Stripe"
                        : "Connect Stripe"}
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={7} className="text-center text_rgba_50 pt-3">
                No Invoices have been paid.
              </td>
            )}
            {isStudent && invoicesList.length > 0 && (
              <tr className="total_hours">
                <td colSpan={6} className="text-center fw-bold">
                  Total: ${totalEarning.toFixed(2)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PayoutTable;
