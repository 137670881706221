import React from "react";
import logo from "../../../../assets/imgs/interview.svg";
import calendarLogo from "../../../../assets/imgs/calendar.svg";
import moment from "moment";

type Props = {
  type?: number;
  link?: string;
  date?: string;
  time: string;
};

const InterviewLink = ({ type = 0, link = "", date, time }: Props) => {
  return (
    <div className="interview_schedule mr-2">
      <div className="d-flex align-items-center">
        <div className="lg_stack_logo">
          <img src={type === 0 ? logo : calendarLogo} />
        </div>
        <div>
          <p className="text_black fw-bold fs-5 ms-3 lh-base">
            {type === 0
              ? `${moment(date).format("dddd, MMM DD")}th`
              : "Calendar Link"}{" "}
          </p>
          <p className="font_14 text_primary ms-3 text-start">
            {type === 0
              ? `Interview Scheduled at ${time}`
              : "Click to book an interview"}{" "}
          </p>
        </div>
      </div>
      <a href={link} className="anchor_team">
        <span style={{ width: 100, height: 20, overflow: "hidden" }}>
          {link?.substring(0, 40)}...
        </span>
      </a>
    </div>
  );
};

export default InterviewLink;
