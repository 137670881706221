import React from "react";

const ProgressBar = ({
  percentage,
  type,
}: {
  percentage: Number;
  type: number;
}) => {
  const barTypes = ["bg_yellow", "bg_skyblue", "bg_lightgreen", "bg_pink"];
  return (
    <div className="progress w-75 w_sm_100">
      <div
        className={`progress-bar ${barTypes[type]}`}
        role="progressbar"
        style={{ width: `${percentage}%` }}
        //   style="width: 65%;" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100"
      >
        {percentage.toString()}%
      </div>
    </div>
  );
};

export default ProgressBar;
