import React from "react";
import Slider from "./Slider";

type Props = {
  interviewWindow: any[];
  updateNewWindow: (slot: []) => void;
};

const ChangeInterviewWindow = ({ interviewWindow, updateNewWindow }: Props) => {
  return (
    <div className="px-0 px-md-3 w-100 mb-5 mb-md-0 pb-5 pb-md-4 mt-3">
      <div className="bg-white w-100 border border_gray border_r_16px border_sm_r_0 mb-4 mb-md-0 p-4">
        <p className="fs-4 fw-bold text_primary mb-4">Interview Windows</p>
        <div className="row">
          <Slider
            dayName="Monday"
            times={interviewWindow[0].monday.times}
            enabled={interviewWindow[0].monday.enabled}
            setEnable={() => {
              const temp: any = interviewWindow;
              temp[0].monday.enabled = !temp[0].monday.enabled;
              updateNewWindow(temp);
            }}
            setTime={(time: number[]) => {
              const temp: any = interviewWindow;
              temp[0].monday.times = [...time];
              updateNewWindow(temp);
            }}
          />

          <Slider
            dayName="Tuesday"
            times={interviewWindow[0].tuesday.times}
            enabled={interviewWindow[0].tuesday.enabled}
            setEnable={() => {
              const temp: any = interviewWindow;
              temp[0].tuesday.enabled = !temp[0].tuesday.enabled;
              updateNewWindow(temp);
            }}
            setTime={(time: number[]) => {
              const temp: any = interviewWindow;
              temp[0].tuesday.times = [...time];
              updateNewWindow(temp);
            }}
          />

          <Slider
            dayName="Wednesday"
            times={interviewWindow[0].wednesday.times}
            enabled={interviewWindow[0].wednesday.enabled}
            setEnable={() => {
              const temp: any = interviewWindow;
              temp[0].wednesday.enabled = !temp[0].wednesday.enabled;
              updateNewWindow(temp);
            }}
            setTime={(time: number[]) => {
              const temp: any = interviewWindow;
              temp[0].wednesday.times = [...time];
              updateNewWindow(temp);
            }}
          />

          <Slider
            dayName="Thursday"
            times={interviewWindow[0].thursday.times}
            enabled={interviewWindow[0].thursday.enabled}
            setEnable={() => {
              const temp: any = interviewWindow;
              temp[0].thursday.enabled = !temp[0].thursday.enabled;
              updateNewWindow(temp);
            }}
            setTime={(time: number[]) => {
              const temp: any = interviewWindow;
              temp[0].thursday.times = [...time];
              updateNewWindow(temp);
            }}
          />

          <Slider
            dayName="Friday"
            times={interviewWindow[0].friday.times}
            enabled={interviewWindow[0].friday.enabled}
            setEnable={() => {
              const temp: any = interviewWindow;
              temp[0].friday.enabled = !temp[0].friday.enabled;
              updateNewWindow(temp);
            }}
            setTime={(time: number[]) => {
              const temp: any = interviewWindow;
              temp[0].friday.times = [...time];
              updateNewWindow(temp);
            }}
          />

          <Slider
            dayName="Saturday"
            times={interviewWindow[0].saturday.times}
            enabled={interviewWindow[0].saturday.enabled}
            setEnable={() => {
              const temp: any = interviewWindow;
              temp[0].saturday.enabled = !temp[0].saturday.enabled;
              updateNewWindow(temp);
            }}
            setTime={(time: number[]) => {
              const temp: any = interviewWindow;
              temp[0].saturday.times = [...time];
              updateNewWindow(temp);
            }}
          />

          <Slider
            dayName="Sunday"
            times={interviewWindow[0].sunday.times}
            enabled={interviewWindow[0].sunday.enabled}
            setEnable={() => {
              const temp: any = interviewWindow;
              temp[0].sunday.enabled = !temp[0].sunday.enabled;
              updateNewWindow(temp);
            }}
            setTime={(time: number[]) => {
              const temp: any = interviewWindow;
              temp[0].sunday.times = [...time];
              updateNewWindow(temp);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangeInterviewWindow;
