import { useState, useEffect } from "react";

export const useMergeState = (initialState: any) => {
  const [state, setState] = useState(initialState);

  const setMergedState = (newState: any) =>
    setState((prevState: any) => ({ ...prevState, ...newState }));

  return [state, setMergedState];
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowSize;
};
