import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";
import moment from "moment";
// import { FormattedNumber } from "react-intl";
// import Slider from "@mui/material/Slider";
// import Radio from "@mui/material/Radio";
// import { styled } from "@mui/material/styles";
import { useMergeState } from "utils/custom-hooks";
import { removeStudentFromProject } from "api";
import manager from "../../../../assets/imgs/manager.svg";
import hours from "../../../../assets/imgs/hours.svg";
import earning from "../../../../assets/imgs/earning.svg";
import ListedProjectOverview from "components/MyProjects/ListedProject/Overview";
import OfflineLiveSwitch from "../OfflineLiveSwitch";
import {
  calculateProjectCost,
  // getStudentHourlyRate,
  // shouldShowPremiumFeatures,
  wrapFullName,
  wrapNameTwoLetters,
} from "utils/common";
import StudentCard from "./StudentCard";
import {
  // BudgetType,
  // HourlyRate,
  // ProjectDurationType,
  ProjectStatus,
} from "utils/constants";
import InterviewLink from "components/MyProjects/RequestInterviewProject/InterviewLink";
import EditRateModal from "./EditRateModal";
import ArchiveProjectModal from "./ArchiveProjectModal";
// import QuoteSummary from "components/Projects/QuoteSummary";

// const getSuggestedRate = (studentType: string) => HourlyRate[studentType];

// const iOSBoxShadow =
//   "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

// const IOSSlider = styled(Slider)(({ theme }) => ({
//   color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
//   height: 2,
//   padding: "15px 0",
//   "& .MuiSlider-thumb": {
//     height: 36,
//     width: 36,
//     backgroundColor: "#fff",
//     boxShadow: iOSBoxShadow,
//     "&:focus, &:hover, &.Mui-active": {
//       boxShadow:
//         "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
//       // Reset on touch devices, it doesn't add specificity
//       "@media (hover: none)": {
//         boxShadow: iOSBoxShadow,
//       },
//     },
//   },
//   "& .MuiSlider-valueLabel": {
//     fontSize: 12,
//     fontWeight: "normal",
//     top: -6,
//     backgroundColor: "unset",
//     color: theme.palette.text.primary,
//     "&:before": {
//       display: "none",
//     },
//     "& *": {
//       background: "transparent",
//       color: theme.palette.mode === "dark" ? "#fff" : "#000",
//     },
//   },
//   "& .MuiSlider-track": {
//     border: "none",
//   },
//   "& .MuiSlider-rail": {
//     opacity: 0.5,
//     backgroundColor: "#bfbfbf",
//   },
//   "& .MuiSlider-mark": {
//     backgroundColor: "#bfbfbf",
//     height: 8,
//     width: 1,
//     "&.MuiSlider-markActive": {
//       opacity: 1,
//       backgroundColor: "currentColor",
//     },
//   },
// }));

// const getQuoteTitle = (budgetType: string, editable: boolean) => {
//   if (!editable) {
//     return "Quote Summary";
//   }

//   if (budgetType === BudgetType.FULL_TIME) {
//     return "Full Time Role";
//   }

//   if (budgetType === BudgetType.RETAINER) {
//     return "Retainer Summary";
//   }

//   if (budgetType === BudgetType.HOURLY_RATE) {
//     return "Hourly Rate Summary";
//   }
// };

type Props = {
  user: any;
  firstName: string;
  lastName: string;
  hoursPerWeek: number;
  totalHours: number;
  totalEarning: number;
  studentProjects: any;
  hourlyRate: number;
  studentAvatars?: any;
  project: any;
  upComingInterviews?: any;
  handleChangeTab: (index: string) => any;
  goToInvoiceView: (date: string) => any;
  handleChangeProjectStatus: (status: boolean) => void;
  handleChangeCapHour?: any;
  handleChangeHoursPerWeek?: any;
  handleChangeHourlyRate?: any;
  handleEditProject?: any;
  handleStartProject?: (id: string) => void;
  handleUpdateProject?: (data: any) => void;
  handleAddProjectToArchived?: () => void;
  handleToggleEditButton?: (isEdit: any) => void;
};
const Overview = ({
  firstName,
  lastName,
  user,
  totalHours,
  totalEarning,
  studentProjects,
  hoursPerWeek,
  hourlyRate,
  studentAvatars = {},
  project,
  upComingInterviews,
  handleChangeTab,
  goToInvoiceView,
  handleChangeProjectStatus,
  handleChangeCapHour = () => {},
  handleChangeHoursPerWeek = () => {},
  handleChangeHourlyRate = () => {},
  handleEditProject = () => {},
  handleStartProject = () => {},
  handleUpdateProject = () => {},
  handleAddProjectToArchived = () => {},
  handleToggleEditButton = () => {},
}: Props) => {
  const isStudent = user.accountType === "STUDENT";
  const isOngoingProject = project.status === ProjectStatus.IN_PROGRESS;
  const [state, setState] = useMergeState({
    isShowRemoveStudentModal: false,
    isShowPayRemainingModal: false,
    isShowArchiveProjectModal: false,
    isShowEditRateModal: false,
    isDisableRemoveProjectBtn: false,
    isDisableArchiveProjectBtn: false,
    isDisableContinueBtn: false,
    isCollapse: false,
  });
  const selectStudent = useRef({
    _id: "",
    name: "",
    firstName: "",
    lastName: "",
    avatar: "",
    studentId: "",
    projectId: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleRemoveStudent = async (projectId: string, studentId: string) => {
    setState({
      isDisableRemoveProjectBtn: true,
    });
    const removeStudentResponse = await removeStudentFromProject({
      projectId,
      studentId,
    });
    if (removeStudentResponse.success) {
      handleUpdateProject(removeStudentResponse.data);
      setState({
        isDisableRemoveProjectBtn: false,
        isShowRemoveStudentModal: false,
      });
    } else if (
      !removeStudentResponse.success &&
      removeStudentResponse.message ===
        "You must first settle all approved invoices before removing this user."
    ) {
      enqueueSnackbar(removeStudentResponse?.message, { variant: "error" });
      setState({
        isDisableRemoveProjectBtn: false,
        isShowRemoveStudentModal: false,
        isShowPayRemainingModal: true,
      });
    } else {
      enqueueSnackbar(removeStudentResponse?.message, { variant: "error" });
      setState({
        isDisableRemoveProjectBtn: false,
        isShowRemoveStudentModal: false,
      });
    }
  };

  const handleToggleRemoveProjectPopup = (item: any) => {
    const obj = {
      name: wrapFullName(
        item?.student?.firstName || "",
        item?.student?.lastName || ""
      ),
      _id: item.student._id,
      firstName: item?.student?.firstName,
      lastName: item?.student?.lastName,
      avatar: item.student.avatar,
      studentId: item.student._id,
      projectId: item.project,
    };
    if (item) {
      selectStudent.current = obj;
    }
    setState({
      isShowRemoveStudentModal: !state.isShowRemoveStudentModal,
    });
  };

  const addProjectToArchived = async () => {
    // setState({ isDisableArchiveProjectBtn: true });
    await handleAddProjectToArchived();
    setState({
      isShowArchiveProjectModal: false,
      // isDisableArchiveProjectBtn: false,
    });
  };

  const onCalculateHours = (student: any) => {
    if (isStudent)
      return {
        totalAmountOfStudent: totalEarning,
        totalHoursOfStudent: totalHours,
      };
    const { totalAmountOfStudent, totalHoursOfStudent } =
      student.timesheets.reduce(
        (total: any, item: any) => ({
          totalAmountOfStudent:
            item.totalAmount + (total?.totalAmountOfStudent || 0),
          totalHoursOfStudent:
            item.totalHours + (total?.totalHoursOfStudent || 0),
        }),
        { totalAmountOfStudent: 0, totalHoursOfStudent: 0 }
      );
    return {
      totalAmountOfStudent:
        totalAmountOfStudent + Number(totalAmountOfStudent * 0.03),
      totalHoursOfStudent,
    };
  };
  const lastWeekHoursAndAmount = (student: any) => {
    const findedTimesheet = student.timesheets.find(
      (timesheet: any) =>
        moment(timesheet.weekStart).format("YYYY-MM-DD") ===
        moment().subtract(1, "week").startOf("isoWeek").format("YYYY-MM-DD")
    );
    return findedTimesheet
      ? {
          totalHours: findedTimesheet.totalHours,
          totalAmount: findedTimesheet.totalAmount,
        }
      : { totalHours: 0, totalAmount: 0 };
  };
  const totalHoursAndAmountOfRemovedStudent = (student: any) =>
    student.timesheets.reduce(
      (netObj: any, timesheet: any) => ({
        totalAmount: timesheet.totalAmount + (netObj?.totalAmount || 0),
        totalHours: timesheet.totalHours + (netObj?.totalHours || 0),
      }),
      { totalHours: 0, totalAmount: 0 }
    );
  const initials = (firstName: string, lastName: string) => (
    <div className="profile_image" style={{ width: 50, height: 50 }}>
      <p className="profileText fs-5">
        {wrapNameTwoLetters(firstName, lastName).toUpperCase()}
      </p>
    </div>
  );
  const {
    studentCompensation,
    platformFee,
    totalCost,
    discountAmount = 0,
  } = calculateProjectCost({
    hourlyRate: project?.hourlyRate,
    hoursPerWeek: project?.hoursPerWeek,
    duration: project?.duration,
    durationType: project?.durationType,
    retainerAmount: project?.retainerAmount,
    budgetType: project?.budgetType,
    promo: project?.promo,
  });
  const isCapRate = project?.isCapRate ? true : false;
  return (
    <div>
      {isStudent &&
        studentProjects.map((item: any) => (
          <div className="row mb-3" key={item._id}>
            <div className="col-md-4">
              <div className="bg_lightgray px-4 px-sm-3 py-4 py-md-5 text-center mb-3 mb-md-0">
                <div
                  className="project_icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={manager} />
                </div>
                <p className="fs-6">{isStudent ? "Manager" : "Student"}</p>
                {isStudent ? (
                  <p className="text_black fw-bold fs-5">{`${firstName} ${lastName}`}</p>
                ) : (
                  <p className="text_black fw-bold fs-5">{`${item.student.firstName} ${item.student.lastName}`}</p>
                )}
                <div className="w-50 text-center mx-auto mt-3 mob_100 invisible">
                  <button
                    type="button"
                    className="btn btn_small bg_black text-white w-100 px-2 px-sm-2"
                  >
                    Contact
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg_lightblue p-4 p-sm-3 py-4 py-md-5 text-center mb-3 mb-md-0">
                <div
                  className="project_icon "
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={hours} />
                </div>
                <p className="fs-6">Total Hours</p>
                <p className="text_black fw-bold fs-5">
                  {onCalculateHours(item)?.totalHoursOfStudent} Hours
                </p>
                <div className="w-50 text-center mx-auto mt-3 mob_100">
                  <button
                    type="button"
                    className="btn btn_small bg_secondary text-white w-100 px-2 px-sm-2"
                    onClick={() =>
                      isStudent
                        ? handleChangeTab("Submit Hours")
                        : goToInvoiceView(item.timesheets[0]?.weekStart)
                    }
                  >
                    View Timesheets
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="bg_lightorange p-4 p-sm-3 py-4 py-md-5 text-center mb-0">
                <div
                  className="project_icon"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={earning} />
                </div>
                <p className="fs-6">
                  {isStudent ? "Total Earnings" : "Amount Due"}
                </p>
                <p className="text_black fw-bold fs-5">
                  ${onCalculateHours(item)?.totalAmountOfStudent?.toFixed(2)}
                </p>
                <div className="w-50 text-center mx-auto mt-3 mob_100">
                  <button
                    type="button"
                    className="btn btn_small bg_orange text-white w-100 px-2 px-sm-2"
                    onClick={() =>
                      goToInvoiceView(item.timesheets[0]?.weekStart)
                    }
                  >
                    {isStudent ? "View Stripe" : "Pay Student"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      {!isStudent && (
        <>
          <div className="bg_light p-4 border_r_16px mb-3">
            <div className="d-flex align-items-center justify-content-between flex-wrap flex-lg-nowrap">
              <div className="mb-3 mb-lg-0 mob_100">
                <p className="fw-bold fs-6">
                  {isOngoingProject
                    ? "This week’s total charges"
                    : "Total Paid"}
                </p>
                <p className="fw-bold fs-4">${totalEarning.toFixed(2)}</p>
                {isOngoingProject ? (
                  <p className="d-flex align-items-center font_14">
                    Based on your student’s timesheet{" "}
                    <span className="ms-1 text_secondary font_14">
                      <i className="fa-solid fa-arrow-right" />
                    </span>
                  </p>
                ) : null}
              </div>
              <div className="mb-3 mb-lg-0 mob_100">
                <p className="fw-bold fs-6">Hourly Rate</p>
                <p className="fw-bold fs-4">${hourlyRate}/hr</p>
                <span
                  className="d-flex align-items-center font_14 text_secondary cursor_pointer"
                  onClick={() => setState({ isShowEditRateModal: true })}
                >
                  Edit rate{" "}
                  <span className="ms-1 font_12">
                    <i className="fa-solid fa-pencil" />
                  </span>
                </span>
              </div>
              <div className="mb-3 mb-lg-0 mob_100">
                <p className="fw-bold fs-6">
                  {isOngoingProject
                    ? "This week’s tracked"
                    : "Total Hours Worked"}
                </p>
                <p className="fw-bold fs-4">{totalHours} Hours</p>
                <span
                  className="d-flex align-items-center font_14 cursor_pointer"
                  onClick={() => setState({ isShowEditRateModal: true })}
                >
                  of {hoursPerWeek ? hoursPerWeek * studentProjects.length : 0}{" "}
                  hour limit
                  <span className="ms-1 font_12 text_secondary">
                    <i className="fa-solid fa-pencil" />
                  </span>
                </span>
              </div>
              <div className="mb-3 mb-lg-0 mob_100">
                <button
                  type="button"
                  className="btn bg_secondary btn_small mob_100"
                  onClick={() => handleChangeTab("View Timesheets")}
                >
                  View Timesheets
                </button>
              </div>
            </div>
          </div>
          {upComingInterviews?.length > 0 && (
            <>
              <p className="fs-5 fw-bold text_black mb-2">
                Upcoming Interviews
              </p>
              <div className="row">
                {upComingInterviews.map((item: any, index: any) => (
                  <div className="col-md-4 mb-3">
                    <InterviewLink
                      key={index}
                      type={item?.interviewType === "external" ? 1 : 0}
                      link={item?.link}
                      date={item?.date}
                      time={item?.time}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {isOngoingProject && studentProjects.length === 0 ? (
            <>
              <p className="fs-5 fw-bold text_black mb-2">Your Hires</p>
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <div className="px-4 py-5 bg-white dotted border border_gray border_r_8px mb-3">
                    <p className="text_black fw-bold fs-5 ms-3 lh-sm text-center">
                      No Hires on Project
                    </p>
                    <p className="font_14 text_primary ms-3 text-start text-center">
                      Add a student
                    </p>
                    <button
                      type="button"
                      className="btn btn_small bg_secondary w-100 mt-3"
                      onClick={() => handleChangeTab("Candidates")}
                    >
                      Hire Student
                    </button>
                  </div>
                </div>
                <div className="col-md-6 col-xl-4">
                  <div className="px-4 py-5 bg-white dotted border border_gray border_r_8px mb-3">
                    <p className="text_black fw-bold fs-5 ms-3 lh-sm text-center">
                      Done with project?
                    </p>
                    <p className="font_14 text_primary ms-3 text-start text-center">
                      Add it to archives
                    </p>
                    <button
                      type="button"
                      className="btn btn_small bg_fullred text-white w-100 mt-3"
                      data-bs-toggle="modal"
                      data-bs-target="#archieveModal"
                      onClick={() =>
                        setState({ isShowArchiveProjectModal: true })
                      }
                    >
                      Archive Project
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : !isOngoingProject ? (
            <>
              <p className="fs-5 fw-bold text_black mb-2">Create New Project</p>
              <div className="row">
                <div className="col-md-6 col-xl-4">
                  <div className="px-4 py-5 bg-white dotted border border_gray border_r_8px mb-3">
                    <p className="text_black fw-bold fs-5 ms-3 lh-sm text-center">
                      Post a new project
                    </p>
                    <p className="font_14 text_primary ms-3 text-start text-center">
                      Use this project’s details
                    </p>
                    <button
                      type="button"
                      className="btn btn_small bg_secondary w-100 mt-3"
                      onClick={() => handleToggleEditButton(false)}
                    >
                      Create New Project
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {studentProjects.length > 0 && (
            <>
              <div className="border-bottom border_gray my-3" />
              <p className="fs-5 fw-bold text_black mb-2">Your Hires</p>
              <div className="row">
                {studentProjects.map((item: any) => (
                  <StudentCard
                    key={item._id}
                    item={item}
                    user={user}
                    status="Ongoing"
                    date={moment(item.createdAt).format("MMM DD")}
                    totalHours={lastWeekHoursAndAmount(item)?.totalHours}
                    totalAmount={lastWeekHoursAndAmount(item)?.totalAmount}
                    studentAvatars={studentAvatars}
                    handleRemoveStudent={() =>
                      handleToggleRemoveProjectPopup(item)
                    }
                    handleRehireProject={() =>
                      handleToggleRemoveProjectPopup(item)
                    }
                    handleViewTimesheet={() =>
                      handleChangeTab("View Timesheets")
                    }
                  />
                ))}
              </div>
            </>
          )}
          {project.studentProjectsRemoved.length > 0 && (
            <>
              <div className="border-bottom border_gray my-3" />
              <div className="d-flex align-items-center justify-content-between my-2">
                <p className="fs-5 fw-bold text_black mb-2">
                  Removed from Project
                </p>
                <span
                  className="text_secondary d-flex justify-content-end fs-6 fw-bold pe-2 cursor_pointer"
                  onClick={() => setState({ isCollapse: !state.isCollapse })}
                >
                  {state.isCollapse ? "EXPAND" : "COLLAPSE"}
                </span>
              </div>
              <div
                className="row"
                style={{ display: state.isCollapse ? "none" : "flex" }}
              >
                {project.studentProjectsRemoved.map((item: any) => (
                  <StudentCard
                    key={item?._id}
                    item={item}
                    user={user}
                    status="Removed"
                    date={moment(item?.startedAt || item?.createdAt).format(
                      "MMM DD"
                    )}
                    totalHours={
                      totalHoursAndAmountOfRemovedStudent(item).totalHours
                    }
                    totalAmount={
                      totalHoursAndAmountOfRemovedStudent(item).totalAmount
                    }
                    studentAvatars={studentAvatars}
                    handleRemoveStudent={() =>
                      handleToggleRemoveProjectPopup(item)
                    }
                    handleRehireProject={() =>
                      handleStartProject(item.student._id)
                    }
                    handleViewTimesheet={() =>
                      handleChangeTab("View Timesheets")
                    }
                  />
                ))}
              </div>
            </>
          )}
          {/* <div className="border-bottom border_gray my-4" /> */}
          {/* <div className="d-flex align-items-center flex-wrap justify-content-between my-3">
            <div className="d-flex align-items-center">
              <p className="fw-bold text_primary me-2">Project Listing:</p>
              {project.isSearchable && (
                <p className="ps-2 font_14 text_secondary">
                  You are still accepting candidates
                </p>
              )}
            </div>
            <OfflineLiveSwitch
              isOnline={project?.isSearchable || false}
              isShowAcceptingText={false}
              handleChangeProjectStatus={handleChangeProjectStatus}
            />
          </div>

          <ListedProjectOverview
            user={user}
            description={project.description}
            responsibilties={[project.responsibilties]}
            qualifications={[project.qualifications]}
            skills={project.skills}
            duration={project.duration}
            jobDocumentUrl={project.jobDocumentUrl}
            status={project.status}
            hourlyRate={hourlyRate}
            projectId={project._id}
            project={project}
            handleChangeCapHour={handleChangeCapHour}
            handleChangeHoursPerWeek={handleChangeHoursPerWeek}
            handleChangeHourlyRate={handleChangeHourlyRate}
            handleEditProject={handleEditProject}
            handleToggleEditButton={() => handleToggleEditButton(true)}
          /> */}
          <Modal
            show={state.isShowRemoveStudentModal}
            onHide={() =>
              setState({
                isShowRemoveStudentModal: !state.isShowRemoveStudentModal,
              })
            }
            centered
          >
            <div className="modal-content p-3 border_r_16px">
              <div className="modal-body">
                <div className="sm_logo text-center mb-4 d-flex justify-center">
                  {studentAvatars[selectStudent.current?._id] ? (
                    <img
                      src={studentAvatars[selectStudent.current?._id]}
                      className="image_card"
                    />
                  ) : (
                    initials(
                      selectStudent.current?.firstName,
                      selectStudent.current?.lastName
                    )
                  )}
                </div>
                <p className="fs-4 fw-bold text-center mb-2 text_black lh-sm">
                  {`Remove ${selectStudent.current?.name} from the project.`}
                </p>
                <p className="fs-6 text_primary text-center">
                  Click below to remove them from project
                </p>
              </div>
              <div className="modal-footer border-0">
                <button
                  disabled={state.isDisableRemoveProjectBtn}
                  type="button"
                  className={`btn text_white w-100 ${
                    state.isDisableRemoveProjectBtn
                      ? "bg-rw-disable"
                      : "bg_secondary"
                  }`}
                  data-bs-dismiss="modal"
                  onClick={() =>
                    handleRemoveStudent(
                      selectStudent.current.projectId,
                      selectStudent.current.studentId
                    )
                  }
                >
                  Remove from Project
                </button>
                <button
                  type="button"
                  className="btn bg_light_secondary text_secondary w-100"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    setState({
                      isShowRemoveStudentModal: !state.isShowRemoveStudentModal,
                    })
                  }
                >
                  Back to Overview
                </button>
              </div>
              <p className="text-center">
                Looking to end the entire project?{" "}
                <a href="/" className="text_secondary">
                  End Project
                </a>
              </p>
            </div>
          </Modal>

          <Modal
            show={state.isShowPayRemainingModal}
            onHide={() =>
              setState({
                isShowPayRemainingModal: !state.isShowPayRemainingModal,
              })
            }
            centered
          >
            <div className="modal-content p-3 border_r_16px">
              <div className="modal-body">
                <div className="sm_logo text-center mb-4 d-flex justify-center">
                  {studentAvatars[selectStudent.current?._id] ? (
                    <img
                      src={studentAvatars[selectStudent.current?._id]}
                      className="image_card"
                    />
                  ) : (
                    initials(
                      selectStudent.current?.firstName,
                      selectStudent.current?.lastName
                    )
                  )}
                </div>
                <p className="fs-4 fw-bold text-center mb-2 text_black lh-sm">
                  {`Pay remaining balance to remove ${selectStudent.current.name} from project.`}
                </p>
                <p className="fs-6 text_primary text-center">
                  Click below to pay remaining balance
                </p>
              </div>
              <div className="modal-footer border-0">
                <button
                  type="button"
                  className="btn bg_secondary text_white w-100"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    setState({
                      isShowPayRemainingModal: false,
                    });
                    handleChangeTab("View Timesheets");
                  }}
                >
                  Go to Timesheet
                </button>
                <button
                  type="button"
                  className="btn bg_light_secondary text_secondary w-100"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    setState({
                      isShowPayRemainingModal: false,
                    })
                  }
                >
                  Back to Overview
                </button>
              </div>
            </div>
          </Modal>

          {/* <Modal
            show={state.isShowArchiveProjectModal}
            onHide={() => setState({ isShowArchiveProjectModal: false })}
            centered
          >
            <div className="modal-content p-3 border_r_16px">
              <div className="modal-body">
                <p className="fs-4 fw-bold text-center mb-2 text_black lh-sm">
                  Are you sure you want to archive this project?
                </p>
                <p className="fs-6 text_primary text-center">
                  Click below to add it to archives
                </p>
              </div>
              <div className="modal-footer border-0">
                <button
                  disabled={state.isDisableArchiveProjectBtn}
                  type="button"
                  className={`btn text_white w-100 ${
                    state.isDisableArchiveProjectBtn
                      ? "bg-rw-disable"
                      : "bg_fullred"
                  }`}
                  data-bs-dismiss="modal"
                  onClick={addProjectToArchived}
                >
                  Archive Project
                </button>
                <button
                  type="button"
                  className="btn bg_light_secondary text_secondary w-100"
                  data-bs-dismiss="modal"
                  onClick={() => setState({ isShowArchiveProjectModal: false })}
                >
                  Back to Overview
                </button>
              </div>
            </div>
          </Modal> */}

          <ArchiveProjectModal
            isShow={state.isShowArchiveProjectModal}
            addProjectToArchived={addProjectToArchived}
            onHide={() => setState({ isShowArchiveProjectModal: false })}
          />

          <EditRateModal
            isShow={state.isShowEditRateModal}
            user={user}
            hourlyRate={hourlyRate}
            project={project}
            onHide={() => setState({ isShowEditRateModal: false })}
            handleChangeCapHour={handleChangeCapHour}
            handleChangeHoursPerWeek={handleChangeHoursPerWeek}
            handleChangeHourlyRate={handleChangeHourlyRate}
            handleEditProject={handleEditProject}
          />

          {/* <Modal
            show={state.isShowEditRateModal}
            onHide={() => setState({ isShowEditRateModal: false })}
            centered
          >
            <div className="modal-content p-3 border_r_16px">
              <div className="modal-body mt-0">
                <div className="text-3xl text-center text_black fw-bold">
                  Edit Budget
                </div>

                <div className="mt-8">
                  <div className="flex justify-between">
                    <div>
                      <div className="text-xl text-rw-black-dark fw-bold lh-sm">
                        Hourly Rate
                      </div>
                      {shouldShowPremiumFeatures(user) && (
                        <div className="text-xs font-semibold text-[#00000040]">
                          Suggested{" "}
                          <span>${getSuggestedRate(project?.type)}</span>
                          /HR
                        </div>
                      )}
                    </div>

                    <div className="text-right">
                      <div className="text-xl font-bold text-rw-blue">
                        ${project?.hourlyRate}/HR
                      </div>
                      {shouldShowPremiumFeatures(user) && (
                        <div className="text-xs font-semibold bg-rw-blue-lightest rounded-full px-2 py-1">
                          <span className="text-[#00000080]">
                            Student Compensation:
                          </span>{" "}
                          <span className="text-rw-blue">
                            $
                            {getStudentHourlyRate(
                              project?.hourlyRate,
                              shouldShowPremiumFeatures(user),
                              project?.promo
                            )}
                            /HR
                          </span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex items-center gap-2 mt-4">
                    <span className="text-[#D9D9D9] text-xs font-semibold">
                      $15
                    </span>
                    <IOSSlider
                      min={15}
                      max={50}
                      value={project?.hourlyRate}
                      onChange={handleChangeHourlyRate}
                    />
                    <span className="text-[#D9D9D9] text-xs font-semibold">
                      $50
                    </span>
                  </div>
                </div>

                {shouldShowPremiumFeatures(user) && (
                  <>
                    <div className="mt-8">
                      <div className="flex justify-between">
                        <div className="text-xl font-bold text-rw-black-dark">
                          Hours Per Week
                        </div>

                        <div className="text-xl font-bold text-rw-blue">
                          {project?.hoursPerWeek} Hours
                        </div>
                      </div>

                      <div className="flex items-center gap-2 mt-4">
                        <span className="text-[#D9D9D9] text-xs font-semibold">
                          5
                        </span>
                        <IOSSlider
                          min={5}
                          max={40}
                          value={project?.hoursPerWeek}
                          onChange={handleChangeHoursPerWeek}
                        />
                        <span className="text-[#D9D9D9] text-xs font-semibold">
                          40
                        </span>
                      </div>
                    </div>

                    <div
                      className="border_r_8px border_gray border p-2 flex items-center my-4 mt-4 cursor-pointer"
                      onClick={() => handleChangeCapHour(!isCapRate)}
                    >
                      <div className="w-full flex justify-between items-center">
                        <div className="px-3">
                          <div className="rw-gray-0 text-lg font-semibold">
                            Cap Timesheet Hours
                          </div>

                          <div className="text-xs font-medium text-rw-gray-75">
                            Prevent student from entering more than selected
                            budget
                          </div>
                        </div>
                        <input
                          className="form-check-input mt-0 me-2"
                          type="checkbox"
                          checked={isCapRate}
                        />
                      </div>
                    </div>

                    <div className="border border_gray my-4 dashed" />
                    <div className="text-rw-black-dark font-bold text-xl">
                      {getQuoteTitle(project.budgetType, false)}
                    </div>
                    <div>
                      <div className="bg-rw-blue-lightest rounded-md border_r_8px p-4 mt-4">
                        <div>
                          <div className="flex justify-between items-center">
                            <div className="text-rw-black-slight-dark font-semibold ">
                              Student Compensation
                            </div>

                            <div className="font-semibold text-rw-black-dark">
                              <FormattedNumber
                                value={studentCompensation}
                                style="currency"
                                currency="USD"
                              />
                            </div>
                          </div>

                          <div className="mt-2 text-sm text-rw-black-slight-dark">
                            {getStudentHourlyRate(
                              hourlyRate,
                              false!,
                              project.promo
                            )}
                            /hr x {project?.hoursPerWeek} hrs{" "}
                            {project?.durationType ===
                              ProjectDurationType.PERIOD &&
                              `x ${project.duration} Weeks`}
                          </div>
                        </div>

                        <div className="mt-4">
                          <div className="flex justify-between items-center">
                            <div className="text-rw-black-slight-dark font-semibold ">
                              Platform Fee
                            </div>

                            <div className="font-semibold text-rw-black-dark">
                              <FormattedNumber
                                value={platformFee}
                                style="currency"
                                currency="USD"
                              />
                            </div>
                          </div>

                          <div className="mt-2 text-sm text-rw-black-slight-dark">
                            20% is taken from the student&apos;s rate to support
                            the platform
                          </div>
                        </div>

                        <hr className="my-3" />

                        <div>
                          <div className="flex justify-between items-center">
                            <div className="text-rw-black-slight-dark font-semibold text-lg">
                              Estimated Total
                            </div>

                            <div className="font-semibold text-rw-black-dark text-lg">
                              <FormattedNumber
                                value={totalCost}
                                style="currency"
                                currency="USD"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="modal-footer border-0">
                <button
                  disabled={state.isDisableContinueBtn}
                  type="button"
                  className={`btn text_white w-100 ${
                    state.isDisableContinueBtn
                      ? "bg-rw-disable"
                      : "bg_secondary"
                  }`}
                  onClick={async () => {
                    setState({ isDisableContinueBtn: true });
                    await handleEditProject();
                    setState({
                      isDisableContinueBtn: false,
                      isShowEditRateModal: false,
                    });
                  }}
                >
                  Continue
                </button>
                <button
                  type="button"
                  className="btn bg_light_secondary text_secondary w-100"
                  onClick={() => setState({ isShowEditRateModal: false })}
                >
                  Back
                </button>
              </div>
            </div>
          </Modal> */}
        </>
      )}
    </div>
  );
};

export default Overview;
