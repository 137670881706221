import React, { useState } from "react";
import { wrapNameTwoLetters } from "utils/common";

type Props = {
  user: any;
  item: any;
  status: string;
  date: string;
  totalHours?: number;
  totalAmount?: number;
  studentAvatars?: any;
  handleRemoveStudent: () => void;
  handleRehireProject: () => void;
  handleViewTimesheet: () => void;
};
const StudentCard = ({
  user,
  item,
  status,
  date,
  totalHours,
  totalAmount,
  studentAvatars,
  handleRemoveStudent,
  handleRehireProject,
  handleViewTimesheet,
}: Props) => {
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const isOngoingProject = status === "Ongoing";
  const initials = (firstName: string, lastName: string) => (
    <div className="profile_image" style={{ width: 50, height: 50 }}>
      <p className="profileText fs-5">
        {wrapNameTwoLetters(firstName, lastName).toUpperCase()}
      </p>
    </div>
  );
  const handleClickBtn = async () => {
    if (isOngoingProject) {
      handleRemoveStudent();
    } else {
      setIsDisableBtn(true);
      await handleRehireProject();
      setIsDisableBtn(false);
    }
  };
  return (
    <div key={item._id} className="col-md-6 col-xl-4">
      <div className="p-4 bg-white border border_gray border_r_8px box_shadow">
        <div className="d-flex align-items-center">
          <div className="sm_logo">
            {studentAvatars[item?.student?._id] ? (
              <img
                src={studentAvatars[item?.student?._id]}
                className="image_card"
              />
            ) : (
              initials(item?.student?.firstName, item?.student?.lastName)
            )}
          </div>
          <div>
            <p className="text_black fw-bold fs-5 ms-3 lh-sm">
              {item?.student?.firstName || ""} {item?.student?.lastName || ""}
            </p>
            <p className="font_14 text_primary ms-3 text-start">
              {item?.student?.university || ""}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center mt-2 flex-wrap">
          <div className="d-flex align-items-center flex-wrap my-2 w-100">
            <div
              className={` ${
                isOngoingProject
                  ? "text_green bg_light_green"
                  : "text_redish bg_light_redish"
              } mb-2 mb-md-0 p-2 fw-bold border_r_8px font_14`}
            >
              {isOngoingProject && (
                <span className="me-2">
                  <i className="fa-sharp fa-solid fa-arrow-rotate-left" />
                </span>
              )}
              {!isOngoingProject ? "Removed from Project" : "Ongoing Project"}
            </div>
            <p className="font_12 ms-2">
              {isOngoingProject && "Started"} {date}
            </p>
          </div>
          <p>
            {isOngoingProject ? "Time logged last week" : "Total Hours Worked"}:{" "}
            {totalHours} hours (${totalAmount})
          </p>
          <div className="d-flex align-items-center flex-wrap flex-lg-nowrap mt-4 w-100 justify-content-center">
            <button
              disabled={isDisableBtn}
              type="button"
              className={`btn btn_small w-100 border me-0 me-lg-1 px-2 mb-2 mb-lg-0 ${
                isDisableBtn ? "" : "text_secondary border_secondary"
              }`}
              onClick={handleClickBtn}
            >
              {isOngoingProject ? "Remove" : "Rehire"}
            </button>
            <button
              type="button"
              className="btn bg_secondary btn_small text_white w-100 mt-2 mt-md-0 mb-0 ms-0 ms-lg-1 px-2"
              onClick={handleViewTimesheet}
            >
              View Timesheet
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentCard;
