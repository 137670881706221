import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import TextField from "components/TextField";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import { validateEmail } from "utils/common";
import { resetPassword, sendPasswordResetInstructions } from "api";

export default function ResetPasswordContainer() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const resetEmail = searchParams.get("email") || "";

  const [state, setState] = useMergeState({
    email: "",
    password: "",
    confirmPassword: "",
    isDisableBtn: false,
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const returnToLogin = () => {
    navigate("/signin");
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (!resetEmail && !validateEmail(state?.email)) {
      isValid = false;
      errors = { ...errors, email: "Email is invalid." };
    }

    if (resetEmail) {
      if (!state?.password) {
        errors = { ...errors, password: "Password is required." };
        isValid = false;
      }

      if (!state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Confirm password is required.",
        };
        isValid = false;
      }

      if (state?.password !== state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Passwords do not match.",
        };
        isValid = false;
      }
    }

    setState({ errors });

    return isValid;
  };

  const handleSubmit = async () => {
    try {
      if (!isFormValid()) {
        return;
      }
      setState({ isDisableBtn: true });

      if (resetEmail) {
        const response = await resetPassword({
          email: resetEmail,
          newPassword: state?.password,
          confirmNewPassword: state?.confirmPassword,
        });

        if (response?.success) {
          enqueueSnackbar(
            "Password reset successful, please sign in to continue.",
            {
              variant: "success",
            }
          );
          navigate("/signin");
        }

        return;
      }

      const response = await sendPasswordResetInstructions({
        email: state?.email,
      });
      setState({ isDisableBtn: false });
      if (response?.success) {
        enqueueSnackbar("Reset password instructions sent.", {
          variant: "success",
        });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return (
    <div>
      <div className="h-24 flex justify-center items-center">
        <div className="text-4xl font-bold">Reset Password</div>
      </div>

      <div className="flex justify-center mt-10">
        <div className="w-11/12 lg:w-1/3">
          <div className="text-center font-medium">
            {resetEmail
              ? "Enter your New Password"
              : "Please enter your email address and we'll send you a link to reset your password."}
          </div>

          {resetEmail ? (
            <div>
              <div className="mt-8">
                <TextField
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={state?.password}
                  onChange={handleChange}
                  error={state?.errors?.password}
                />
              </div>

              <div className="mt-8">
                <TextField
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={state?.confirmPassword}
                  onChange={handleChange}
                  error={state?.errors?.confirmPassword}
                />
              </div>
            </div>
          ) : (
            <div className="mt-8">
              <TextField
                type="text"
                name="email"
                placeholder="Email address"
                value={state?.email}
                onChange={handleChange}
                error={state?.errors?.email}
              />
            </div>
          )}

          <Button
            disabled={state.isDisableBtn}
            className={`font-semibold w-full h-12 mt-8 ${
              state.isDisableBtn ? "bg-rw-disable" : ""
            }`}
            onClick={handleSubmit}
          >
            {resetEmail ? "Reset Password" : "Continue"}
          </Button>

          {!resetEmail && (
            <Button
              variant="secondary"
              className="w-full h-12 font-semibold mt-4"
              onClick={returnToLogin}
            >
              Return to Login
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
