import Images from "assets/images";

export default function MatchingStudentsLoader() {
  return (
    <div className="bg-rw-blue-lightest flex flex-col justify-center items-center w-full min-h-screen h-full">
      <div className="bg-white rounded-md shadow-lg w-11/12 lg:w-96 h-80 text-center">
        <div className="flex flex-col justify-center items-center h-full">
          <div className="text-rw-blue font-semibold">PROJECT SUBMITTED</div>

          <div className="text-rw-black-dark font-semibold text-3xl mt-4">
            Matching you with students...
          </div>

          <img src={Images.AppLoaderGif} className="w-20 h-20 mt-4" />
        </div>
      </div>

      <div className="text-rw-black-slight-dark w-11/12 lg:w-96 text-center mt-12">
        <div className="font-semibold">RUNWAY TIP:</div>
        <div className="font-medium mt-2">
          View Students Ratings and Reviews on their Profile.
        </div>
      </div>
    </div>
  );
}
