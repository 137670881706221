import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import TextField from "components/TextField";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import { RUNWAY_TOKEN } from "utils/constants";
import { validatePhone } from "utils/common";
import { signupMember } from "api";

const StepLabels = {
  TYPE: "TYPE",
  DETAILS: "DETAILS",
  CONFIRM: "CONFIRM",
};

const Steps = [
  {
    id: 1,
    label: StepLabels.TYPE,
    path: "/signup",
  },
  {
    id: 2,
    label: StepLabels.DETAILS,
    title: "Login Details",
  },
  {
    id: 3,
    label: StepLabels.CONFIRM,
    title: "Confirm Account",
  },
];

type Props = {
  setUser: any;
};

export default function BusinessMemberSignUpContainer({ setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const companyName = searchParams.get("companyName") || "";

  const email = searchParams.get("email") || "";

  const business = searchParams.get("business") || "";

  const [state, setState] = useMergeState({
    currentStep: Steps[1],

    // login details
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",

    phone: "",

    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (state?.currentStep?.label === StepLabels.DETAILS) {
      if (!state?.firstName) {
        errors = { ...errors, firstName: "First Name is required." };
        isValid = false;
      }

      if (!state?.lastName) {
        errors = { ...errors, lastName: "Last Name is required." };
        isValid = false;
      }

      if (!state?.password) {
        errors = { ...errors, password: "Password is required." };
        isValid = false;
      }

      if (!state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Confirm password is required.",
        };
        isValid = false;
      }

      if (state?.password !== state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Passwords do not match.",
        };
        isValid = false;
      }

      if (!validatePhone(state?.phone)) {
        errors = { ...errors, phone: "Phone is invalid." };
        isValid = false;
      }
    }

    setState({ errors });

    return isValid;
  };

  const getNextStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) + 1];

  const getPrevStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) - 1];

  const handleContinue = () => {
    if (!isFormValid()) {
      return;
    }

    const nextStep = getNextStep();

    setState({ currentStep: nextStep });
  };

  const handleBack = () => {
    const prevStep = getPrevStep();

    if (prevStep?.path) {
      navigate(prevStep.path);
      return;
    }

    setState({ currentStep: prevStep });
  };

  const handleEditStep = (stepLabel: string) => {
    setState({ currentStep: Steps.find((step) => step?.label === stepLabel) });
  };

  const handleBusinessMemberSignup = async () => {
    try {
      if (!companyName || !email || !business) {
        return;
      }

      const payload = {
        firstName: state?.firstName,
        lastName: state?.lastName,
        email: String(email).trim().toLowerCase(),
        password: state?.password,
        confirmPassword: state?.confirmPassword,
        phone: state?.phone,
        business,
      };

      const response = await signupMember(payload);

      if (response?.success) {
        localStorage.setItem(RUNWAY_TOKEN, response?.data?.token);

        setUser(response?.data?.user);

        navigate("/projects");
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return (
    <div>
      <div className="w-full h-24 bg-rw-blue-lightest flex flex-col justify-center items-center">
        <div>
          <div className="text-rw-blue font-semibold text-center py-2">
            Applying as a Business
          </div>

          <div className="flex">
            {Steps.map((step) => (
              <div
                key={step?.id}
                className="flex flex-col items-center mx-4 lg:mx-8"
              >
                <div
                  className={`rounded-full w-6 h-6 border-[1px] text-sm font-medium ${
                    state?.currentStep?.id >= step?.id
                      ? "bg-rw-blue text-white"
                      : "border-[#00000040] text-[#B8B8B8]"
                  } flex justify-center items-center`}
                >
                  {step?.id < state?.currentStep?.id ? (
                    <CheckOutlinedIcon sx={{ width: 16, height: 16 }} />
                  ) : (
                    step?.id
                  )}
                </div>

                <div
                  className={`text-xs ${
                    state?.currentStep?.id >= step?.id
                      ? "text-rw-blue"
                      : "text-[#B8B8B8]"
                  } font-semibold mt-2`}
                >
                  {step?.label}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <div className="w-11/12 lg:w-1/3">
          {state?.currentStep?.label === StepLabels.DETAILS && (
            <div>
              <div className="text-3xl font-semibold text-rw-black-dark text-center">
                Login Details
              </div>

              <div className="w-full mt-8">
                <div className="font-semibold mb-1">Name</div>

                <div className="flex gap-2 justify-between">
                  <TextField
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={state?.firstName}
                    onChange={handleChange}
                    error={state?.errors?.firstName}
                  />

                  <TextField
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={state?.lastName}
                    onChange={handleChange}
                    error={state?.errors?.lastName}
                  />
                </div>
              </div>

              <div className="mt-4">
                <div className="font-semibold mb-1">Company Name</div>

                <TextField
                  type="text"
                  name="companyName"
                  placeholder="Name of your company"
                  value={companyName}
                  readOnly
                  disabled
                  className="bg-rw-blue-lightest"
                />
              </div>

              <div className="mt-8">
                <div className="font-semibold mb-1">Email</div>

                <TextField
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  readOnly
                  disabled
                  className="bg-rw-blue-lightest"
                />
              </div>

              <div className="mt-4">
                <div className="font-semibold mb-1">Password</div>

                <TextField
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={state?.password}
                  onChange={handleChange}
                  error={state?.errors?.password}
                />

                <div className="mt-4">
                  <TextField
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={state?.confirmPassword}
                    onChange={handleChange}
                    error={state?.errors?.confirmPassword}
                  />
                </div>
              </div>

              <div className="mt-4">
                <div className="font-semibold mb-1">Phone Number</div>

                <TextField
                  type="number"
                  name="phone"
                  placeholder="(000) 000-0000"
                  value={state?.phone}
                  onChange={handleChange}
                  error={state?.errors?.phone}
                  onWheel={(event: any) => event.target.blur()}
                  onInput={(event: any) => {
                    if (event?.target?.value) {
                      event.target.value = Math.max(
                        0,
                        parseInt(event.target.value, 10)
                      )
                        .toString()
                        .slice(0, 10);
                    }
                  }}
                />
              </div>
            </div>
          )}

          {state?.currentStep?.label === StepLabels.CONFIRM && (
            <div>
              <div className="text-3xl font-semibold text-rw-black-dark text-center">
                Confirm Account
              </div>

              <div className="w-full mt-8">
                <div className="flex justify-between items-center mb-1">
                  <div className="font-semibold">Full Name</div>
                  <button
                    className="text-rw-blue text-sm font-medium underline"
                    onClick={() => handleEditStep(StepLabels.DETAILS)}
                  >
                    Edit
                  </button>
                </div>

                <TextField
                  type="text"
                  value={`${state?.firstName || ""} ${state?.lastName || ""}`}
                  readOnly
                />
              </div>

              <div className="mt-8">
                <div className="font-semibold mb-1">Company Name</div>

                <TextField
                  type="text"
                  value={companyName}
                  readOnly
                  className="bg-rw-blue-lightest"
                />
              </div>

              <div className="mt-8">
                <div className="font-semibold mb-1">Email</div>

                <TextField
                  type="text"
                  value={email}
                  readOnly
                  className="bg-rw-blue-lightest"
                />
              </div>

              <div className="mt-8">
                <div className="flex justify-between items-center mb-1">
                  <div className="font-semibold">Password</div>
                  <button
                    className="text-rw-blue text-sm font-medium underline"
                    onClick={() => handleEditStep(StepLabels.DETAILS)}
                  >
                    Edit
                  </button>
                </div>

                <TextField type="password" value={state?.password} readOnly />
              </div>

              <div className="mt-8">
                <div className="flex justify-between items-center mb-1">
                  <div className="font-semibold">Phone Number</div>
                  <button
                    className="text-rw-blue text-sm font-medium underline"
                    onClick={() => handleEditStep(StepLabels.DETAILS)}
                  >
                    Edit
                  </button>
                </div>

                <TextField type="text" value={state?.phone} readOnly />
              </div>
            </div>
          )}

          {state?.currentStep?.label === StepLabels.CONFIRM ? (
            <Button
              className="font-semibold w-full h-12 mt-8"
              onClick={handleBusinessMemberSignup}
            >
              Confirm
            </Button>
          ) : (
            <Button
              className="font-semibold w-full h-12 mt-8"
              onClick={handleContinue}
            >
              Continue
            </Button>
          )}

          {state?.currentStep?.id > 1 && (
            <Button
              variant="secondary"
              className="font-medium w-full mt-4 h-12"
              onClick={handleBack}
            >
              Back
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
