import React from "react";
import { useMergeState } from "utils/custom-hooks";

const slots = [
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
];
const generateIndex = (value: number) => {
  const slots = [
    "9:00 AM",
    "9:30 AM",
    "10:00 AM",
    "10:30 AM",
    "11:00 AM",
    "11:30 AM",
    "12:00 PM",
    "12:30 PM",
    "1:00 PM",
    "1:30 PM",
    "2:00 PM",
    "2:30 PM",
    "3:00 PM",
    "3:30 PM",
    "4:00 PM",
    "4:30 PM",
    "5:00 PM",
  ];
  const newVal = (value / 60 - 9) * 2;
  return newVal;
};

const minutes = [
  540, 570, 600, 630, 660, 690, 720, 750, 780, 810, 840, 870, 900, 930, 960,
  990, 1020,
];

type Props = {
  dayName: string;
  times: any[];
  enabled: boolean;
  setEnable?: () => void;
  setTime?: (time: number[]) => void;
};
const Slider = ({
  dayName,
  times,
  enabled,
  setEnable = () => {},
  setTime = () => {},
}: Props) => {
  const [state, setState] = useMergeState({
    timeSlots: [...slots],
    lowerBoundSlot: generateIndex(times[0]),
    upperBoundSlot: generateIndex(times[1]),
    enable: enabled,
  });
  return (
    <div className="col-12 col-md-6 col-lg-4 mb-3">
      <div
        className={`bg_white border border_gray border_r_16px overflow-hidden ${
          state?.enable ? "" : "inactive_window"
        }`}
      >
        <div className="d-flex justify-content-between p-3 bg_primary bg_light">
          <p className="fs-6 fw-bold text_primary">{dayName}</p>
          <label className="m5 d-flex align-items-center">
            <input
              type="checkbox"
              className="switch_input d-none"
              value={state.enable}
              checked={state?.enable}
              onChange={(e) => {
                setState({ enable: e.currentTarget.checked });
                setEnable();
              }}
            />{" "}
            <small className="switch_small" />
          </label>
        </div>
        <div className="bg_white pt-3">
          <p className="fs-5 fw-bold text_primary text-center">
            {slots[state?.lowerBoundSlot]} - {slots[state?.upperBoundSlot]}
          </p>
          <p className="text-center text_secondary fw-bold">
            {(state.upperBoundSlot - state.lowerBoundSlot) / 2} hours{" "}
          </p>
          <div className="dualslider_wrapper">
            <div className="values d-none">
              <span id="range1">0</span>
              <span> &dash; </span>
              <span id="range2">100</span>
            </div>
            <div
              className="slider-track bg_secondary"
              style={{
                background: `linear-gradient(to right, #dadae5 ${
                  (state.lowerBoundSlot / 16) * 100
                }% , #3264fe ${(state.lowerBoundSlot / 16) * 100}% , #3264fe ${
                  (state.upperBoundSlot / 16) * 100
                }%, #dadae5 ${(state.upperBoundSlot / 16) * 100}%)`,
              }}
            />
            <input
              type="range"
              min="0"
              max={slots.length - 1}
              value={state?.lowerBoundSlot}
              id="slider-1"
              onInput={(e) => {
                const value = parseInt(e.currentTarget.value, 10);
                if (value < state.upperBoundSlot) {
                  setState({ lowerBoundSlot: e.currentTarget.value });
                  setTime([
                    minutes[parseInt(e.currentTarget.value, 10)],
                    minutes[state.upperBoundSlot],
                  ]);
                }
              }}
            />
            <input
              type="range"
              min="0"
              max={slots.length - 1}
              value={state?.upperBoundSlot}
              id="slider-2"
              onInput={(e) => {
                const value = parseInt(e.currentTarget.value, 10);
                if (value > state.lowerBoundSlot) {
                  setState({ upperBoundSlot: e.currentTarget.value });
                  setTime([
                    minutes[state.lowerBoundSlot],
                    minutes[parseInt(e.currentTarget.value, 10)],
                  ]);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
