import { FormattedNumber } from "react-intl";
import { getStudentHourlyRate, getStudentRetainerAmount } from "utils/common";
import { BudgetType, ProjectDurationType } from "utils/constants";

const getQuoteTitle = (budgetType: string, editable: boolean) => {
  if (!editable) {
    return "Quote Summary";
  }

  if (budgetType === BudgetType.FULL_TIME) {
    return "Full Time Role";
  }

  if (budgetType === BudgetType.RETAINER) {
    return "Retainer Summary";
  }

  if (budgetType === BudgetType.HOURLY_RATE) {
    return "Hourly Rate Summary";
  }
};

export default function QuoteSummary({
  editable = false,
  onEditStep,
  hourlyRate,
  hoursPerWeek,
  duration,
  durationType,
  studentCompensation,
  platformFee,
  discountAmount = 0,
  totalCost,
  budgetType,
  onOpenPromoCode,
  showPremiumFeatures,
  promo,
  totalRetainerAmount,
}: {
  editable?: boolean;
  onEditStep?: () => void;
  hourlyRate: number;
  hoursPerWeek: number;
  duration: number;
  durationType: string;
  studentCompensation: number;
  platformFee: number;
  discountAmount: number;
  totalCost: number;
  budgetType: string;
  onOpenPromoCode?: any;
  showPremiumFeatures?: boolean;
  promo: any;
  totalRetainerAmount: number;
}) {
  const totalOrWeekly =
    durationType === ProjectDurationType.PERIOD ? "" : "Weekly";
  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-rw-black-dark font-bold text-xl">
          {getQuoteTitle(budgetType, editable)}
        </div>

        {showPremiumFeatures && editable ? (
          <button
            className="text-rw-blue font-medium underline"
            onClick={onEditStep}
          >
            Edit
          </button>
        ) : showPremiumFeatures ? (
          <button
            className="text-rw-blue font-semibold text-sm underline"
            onClick={onOpenPromoCode}
          >
            Add Promo
          </button>
        ) : (
          <div />
        )}
      </div>

      {budgetType === BudgetType.FULL_TIME && (
        <div className="bg-rw-blue-lightest rounded-md p-4 mt-4 text-sm text-rw-black-slight-dark font-medium text-center">
          Post your job as a full time postition. You can negotiate the salary
          with your student after they have been selected.
        </div>
      )}

      {budgetType === BudgetType.RETAINER && (
        <div>
          <div className="bg-rw-blue-lightest rounded-md p-4 mt-4">
            <div className="flex justify-between items-center">
              <div className="text-rw-black-slight-dark font-semibold ">
                Student Compensation
              </div>

              <div className="font-semibold text-rw-black-dark">
                <FormattedNumber
                  value={
                    showPremiumFeatures
                      ? getStudentRetainerAmount(
                          totalRetainerAmount,
                          showPremiumFeatures,
                          promo
                        )
                      : totalCost
                  }
                  style="currency"
                  currency="USD"
                />
              </div>
            </div>

            {showPremiumFeatures && (
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <div className="text-rw-black-slight-dark font-semibold ">
                    Platform Fee
                  </div>

                  <div className="font-semibold text-rw-black-dark">
                    <FormattedNumber
                      value={platformFee}
                      style="currency"
                      currency="USD"
                    />
                  </div>
                </div>

                <div className="mt-2 text-sm text-rw-black-slight-dark">
                  20% is taken from the retainer to support the platform
                </div>
              </div>
            )}

            {discountAmount > 0 && (
              <div className="flex justify-between items-center mt-4">
                <div className="text-rw-black-slight-dark font-semibold ">
                  Promo Code
                </div>

                <div className="font-semibold text-rw-red">
                  -
                  <FormattedNumber
                    value={discountAmount}
                    style="currency"
                    currency="USD"
                  />
                </div>
              </div>
            )}

            <hr className="my-4" />

            <div>
              <div className="flex justify-between items-center">
                <div className="text-rw-black-slight-dark font-semibold text-lg">
                  Total Budget
                </div>

                <div className="font-semibold text-rw-black-dark text-lg">
                  <FormattedNumber
                    value={totalRetainerAmount!}
                    style="currency"
                    currency="USD"
                  />
                </div>
              </div>
            </div>
          </div>

          {!editable && (
            <div className="text-xs text-rw-black-slight-dark font-medium mt-4">
              {showPremiumFeatures
                ? "Note: You will pay the retainer once the project is completed."
                : "Note: Payment will be handled off platform"}
            </div>
          )}
        </div>
      )}

      {budgetType === BudgetType.HOURLY_RATE && (
        <div>
          <div className="bg-rw-blue-lightest rounded-md p-4 mt-4">
            <div>
              <div className="flex justify-between items-center">
                <div className="text-rw-black-slight-dark font-semibold ">
                  {totalOrWeekly} Student Compensation
                </div>

                <div className="font-semibold text-rw-black-dark">
                  <FormattedNumber
                    value={studentCompensation}
                    style="currency"
                    currency="USD"
                  />
                </div>
              </div>

              <div className="mt-2 text-sm text-rw-black-slight-dark">
                {getStudentHourlyRate(hourlyRate, showPremiumFeatures!, promo)}
                /hr x {hoursPerWeek} hrs{" "}
                {durationType === ProjectDurationType.PERIOD &&
                  `x ${duration} Weeks`}
              </div>
            </div>

            <div className="mt-4">
              <div className="flex justify-between items-center">
                <div className="text-rw-black-slight-dark font-semibold ">
                  {totalOrWeekly} Platform Fee
                </div>

                <div className="font-semibold text-rw-black-dark">
                  <FormattedNumber
                    value={platformFee}
                    style="currency"
                    currency="USD"
                  />
                </div>
              </div>

              <div className="mt-2 text-sm text-rw-black-slight-dark">
                20% is taken from the student&apos;s rate to support the
                platform
              </div>
            </div>

            {discountAmount > 0 && (
              <div className="flex justify-between items-center mt-4">
                <div className="text-rw-black-slight-dark font-semibold ">
                  Promo Code
                </div>

                <div className="font-semibold text-rw-red">
                  -
                  <FormattedNumber
                    value={discountAmount}
                    style="currency"
                    currency="USD"
                  />
                </div>
              </div>
            )}

            <hr className="my-4" />

            <div>
              <div className="flex justify-between items-center">
                <div className="text-rw-black-slight-dark font-semibold text-lg">
                  Estimated {totalOrWeekly} Total
                </div>

                <div className="font-semibold text-rw-black-dark text-lg">
                  <FormattedNumber
                    value={totalCost}
                    style="currency"
                    currency="USD"
                  />
                </div>
              </div>
            </div>
          </div>

          {!editable && (
            <div className="text-xs text-rw-black-slight-dark font-medium mt-4">
              Note: Once project begins, payment will be requested weekly via
              email through a 3rd-party payment service.
            </div>
          )}
        </div>
      )}
    </div>
  );
}
