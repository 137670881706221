import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AppLoader from "components/AppLoader";
import { getPaymentIntent } from "api";

export default function ConfirmPaymentContainer() {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const paymentIntentId = searchParams.get("payment_intent") || "";

  const [isLoading, setIsLoading] = React.useState(false);

  const init = async () => {
    try {
      setIsLoading(true);

      const response = await getPaymentIntent({ id: paymentIntentId });

      if (response?.success) {
        enqueueSnackbar(response?.message, { variant: "success" });
      } else {
        enqueueSnackbar(response?.message, { variant: "error" });
      }

      navigate(`/projects/details?id=${response?.data?.id}`);
    } catch (error: any) {
      setIsLoading(false);
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  React.useEffect(() => {
    init();
  }, []);

  return <div>{isLoading && <AppLoader isLoading={isLoading} />}</div>;
}
