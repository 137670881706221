import Slider from "@mui/material/Slider";

const marks = [
  { value: 0, label: "0%" },
  { value: 25, label: "25%" },
  { value: 50, label: "50%" },
  { value: 75, label: "75%" },
];

const StaticSlider = ({ value }: any) => {
  // Prevent any change to the slider value
  const handleChange = () => {};

  return (
    <div className="">
      <Slider
        value={value}
        onChange={handleChange}
        marks={marks}
        disabled
        aria-labelledby="static-slider"
        valueLabelDisplay="auto"
        sx={{
          "& .MuiSlider-markLabel": {
            fontSize: "8px",
            fontWeight: 500,
            color: "#2A75F1",
          },
        }}
        style={{ color: "#2A75F1" }}
        className="text-[11px]"
      />
    </div>
  );
};

export default StaticSlider;
