import React from "react";
import { Modal } from "react-bootstrap";
import { wrapFullName, wrapNameTwoLetters } from "utils/common";
import { formatDate, getDayByIndex } from "utils/date";
import { InvoiceStatus } from "utils/constants";
import { useMergeState } from "utils/custom-hooks";
import moment from "moment";

type Props = {
  invoice: any;
  hasPrev: boolean;
  hasNext: boolean;
  currentInvoice: any;
  studentAvatars?: any;
  handlePreviousWeek: () => void;
  handleNextWeek: () => void;
  onCalculatePerHourRate: (timesheet: any) => any;
  invoiceStatus: () => any;
  handlePayInvoice: (data: any) => void;
  getTimeSheetOfInvoice: (id: string) => any;
  updateInvoiceState: (id: string, status: string, reason: any) => any;
};

const TimeSheetDetails = ({
  invoice,
  hasPrev,
  hasNext,
  currentInvoice,
  studentAvatars,
  handlePreviousWeek,
  handleNextWeek,
  onCalculatePerHourRate,
  invoiceStatus,
  handlePayInvoice,
  updateInvoiceState,
  getTimeSheetOfInvoice,
}: Props) => {
  const processingFees = (amount: number) =>
    +Number((amount || 0) * 0.03).toFixed(2);
  const [state, setState] = useMergeState({
    selectedTimesheetIndex: 0,
    isBtnDisable: false,
    isShowRequestChangeModal: false,
    isShowApproveHours: false,
    requestChangeReason: "",
  });

  const selectedTimesheet =
    currentInvoice?.timesheets && currentInvoice.timesheets.length > 0
      ? currentInvoice.timesheets[state.selectedTimesheetIndex]
      : null;

  const handleApproveHours = async (
    status: string,
    index: number,
    data: any
  ) => {
    if (status === InvoiceStatus.REJECTED) return;
    if (
      status === InvoiceStatus.PENDING ||
      status === InvoiceStatus.REQUESTED_FOR_APPROVAL
    ) {
      setState({ isShowApproveHours: true, selectedTimesheetIndex: index });
    } else {
      await handlePayInvoice(data);
    }
  };

  const initials = (firstName: string, lastName: string) => (
    <div className="profile_image" style={{ width: 50, height: 50 }}>
      <p className="profileText fs-5">
        {wrapNameTwoLetters(firstName, lastName).toUpperCase()}
      </p>
    </div>
  );

  return (
    <>
      <div className="d-flex justify-content-center pt-4">
        <span
          className={`fs-5 ${
            hasPrev ? "text_secondary cursor_pointer" : "text_light_primary"
          }`}
          onClick={() => (hasPrev ? handlePreviousWeek() : null)}
        >
          <i className="fa-regular fa-chevron-left" />
        </span>
        <div className="text-center px-5">
          <p className="font_12">WEEK START</p>
          <p className="font_14 text_secondary fw-bold">
            {formatDate(currentInvoice?.weekStart, "ddd MMM Do, YYYY")}
          </p>
        </div>
        <span
          className={`fs-5 ${
            hasNext ? "text_secondary cursor_pointer" : "text_light_primary"
          }`}
          onClick={() => (hasNext ? handleNextWeek() : null)}
        >
          <i className="fa-regular fa-chevron-right" />
        </span>
      </div>
      <div className="border-bottom box_shadow w-100 my-4" />
      {currentInvoice && currentInvoice?.timesheets?.length > 0 ? (
        currentInvoice?.timesheets?.map((timesheet: any, index: number) =>
          getTimeSheetOfInvoice(timesheet._id) ? (
            <div
              key={timesheet._id}
              className="bg_light p-4 m-4 border_r_16px border border_gray"
            >
              <div className="row align-items-center">
                <div className="col-md-6 col-lg-3 mb-3">
                  <div className="d-flex align-items-center">
                    <div className="sm_logo">
                      {studentAvatars[timesheet.student._id] ? (
                        <img
                          src={studentAvatars[timesheet.student._id]}
                          className="image_card"
                        />
                      ) : (
                        initials(
                          timesheet?.student?.firstName,
                          timesheet?.student?.lastName
                        )
                      )}
                    </div>
                    <div>
                      <p className="text_black fw-bold fs-5 ms-3 lh-sm">
                        {wrapFullName(
                          timesheet?.student?.firstName,
                          timesheet?.student?.lastName
                        )}
                      </p>
                      <p className="font_14 text_primary ms-3 text-start">
                        {timesheet?.student?.university || ""}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-2 text-start mb-3">
                  <p className="fw-bold fs-6">Hourly Rate</p>
                  <p className="fw-bold fs-4">{`$${onCalculatePerHourRate(
                    timesheet
                  )}/hr`}</p>
                </div>
                <div className="col-md-6 col-lg-2 text-start mb-3">
                  <p className="fw-bold fs-6">This week’s tracked</p>
                  <p className="fw-bold fs-4">{`${timesheet.totalHours} Hours`}</p>
                </div>
                <div className="col-md-6 col-lg-3 text-start mb-3">
                  <p className="fw-bold fs-6">This week’s total charges</p>
                  <p className="fw-bold fs-4">{`$${timesheet?.totalAmount}`}</p>
                </div>
                <div className="col-md-12 col-lg-2 text-start">
                  <button
                    disabled={state.isBtnDisable}
                    type="button"
                    className={`btn btn_small mob_100 ${
                      state.isBtnDisable
                        ? "bg-rw-disable"
                        : timesheet.status === InvoiceStatus.PENDING ||
                          timesheet.status ===
                            InvoiceStatus.REQUESTED_FOR_APPROVAL ||
                          timesheet.status === InvoiceStatus.APPROVED
                        ? "bg_secondary"
                        : timesheet.status === InvoiceStatus.REJECTED
                        ? "bg-rw-disable"
                        : "bg_light_green text_green"
                    }`}
                    onClick={async () => {
                      setState({ isBtnDisable: true });
                      await handleApproveHours(timesheet.status, index, {
                        _id: timesheet.invoiceId,
                        status: timesheet.status,
                        totalAmount: timesheet?.totalAmount,
                        studentTotalAmount: timesheet.studentTotalAmount,
                      });
                      setState({ isBtnDisable: false });
                    }}
                  >
                    {timesheet.status === InvoiceStatus.PENDING ||
                    timesheet.status === InvoiceStatus.REQUESTED_FOR_APPROVAL ||
                    timesheet.status === InvoiceStatus.REJECTED
                      ? "Approve Hours"
                      : timesheet.status === InvoiceStatus.APPROVED
                      ? "Pay Student"
                      : timesheet.status === InvoiceStatus.PAID
                      ? "Student Paid"
                      : ""}
                    {timesheet.status === InvoiceStatus.APPROVED && (
                      <span className="ms-2">
                        <i className="fa-solid fa-arrow-right" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-6">
                  <div className="total_hours bg-white border border_gray mb-0 p-3">
                    <table className="table table-borderless mb-0">
                      <thead>
                        <tr>
                          <th scope="col" className="font_12">
                            Submitted{" "}
                            {moment(timesheet.createdAt).format("MMM Do h:mma")}
                          </th>
                          {timesheet.status === InvoiceStatus.PENDING ||
                          timesheet.status ===
                            InvoiceStatus.REQUESTED_FOR_APPROVAL ? (
                            <th
                              scope="col"
                              className="font_12 text-end text_secondary text-decoration-underline cursor_pointer"
                              data-bs-toggle="modal"
                              data-bs-target="#requestchange"
                              onClick={() =>
                                setState({
                                  isShowRequestChangeModal: true,
                                  selectedTimesheetIndex: index,
                                })
                              }
                            >
                              Request Change
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {timesheet?.timesheet.map(
                          (elmt: any, index: number) => (
                            <tr key={index}>
                              <td className="font_14">
                                {getDayByIndex(index)}
                              </td>
                              <td className="text-end font_14">
                                {elmt.hours} Hours
                              </td>
                            </tr>
                          )
                        )}
                        <tr>
                          <td className="font_14 pt-3">
                            <div className="border-top border_dark_gray pt-2">
                              Total Hours{" "}
                            </div>
                          </td>
                          <td className="text-end font_14 pt-3">
                            <div className="border-top border_dark_gray pt-2">
                              {`${timesheet.totalHours} Hours`}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="total_hours bg-white border border_gray mb-0 p-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_14">
                        ${onCalculatePerHourRate(timesheet)}/hr x{" "}
                        {timesheet.totalHours} hrs
                      </p>
                      <p className="font_14 text-end">
                        $
                        {onCalculatePerHourRate(timesheet) *
                          timesheet.totalHours}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <p className="font_14">3% Processing Fee</p>
                      <p className="font_14 text-end">
                        ${processingFees(timesheet.totalAmount)}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-3 mb-0">
                      <p className="fw-bold fs-6 text_primary">
                        Total Week Invoice
                      </p>
                      <p className="text-end fw-bold fs-6 text_primary">
                        $
                        {onCalculatePerHourRate(timesheet) *
                          timesheet.totalHours +
                          processingFees(timesheet.totalAmount)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null
        )
      ) : (
        <div className="text-center text_rgba_50 pb-4">
          No timesheets have been submitted.
        </div>
      )}

      <Modal
        show={state.isShowApproveHours}
        onHide={() => setState({ isShowApproveHours: false })}
        centered
      >
        <div className="modal-content p-3 p-md-4 border_r_16px overflow-hidden">
          <div className="modal-header text-end p-0 border-0">
            <div className="d-flex align-items-center w-75">
              <div className="sm_logo">
                {studentAvatars[selectedTimesheet?.student?._id] ? (
                  <img
                    src={studentAvatars[selectedTimesheet?.student?._id]}
                    className="image_card"
                  />
                ) : (
                  initials(
                    selectedTimesheet?.student?.firstName,
                    selectedTimesheet?.student?.lastName
                  )
                )}
              </div>
              <div>
                <p className="text_black fw-bold fs-5 ms-3 lh-sm text-start">
                  {wrapFullName(
                    selectedTimesheet?.student?.firstName,
                    selectedTimesheet?.student?.lastName
                  )}
                </p>
                <p className="font_14 text_primary ms-3 text-start">
                  {selectedTimesheet?.student.university}
                </p>
              </div>
            </div>
            <button
              type="button"
              className="d-flex justify-content-end border-0 bg-transparent w-25 fs-4 text_secondary"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setState({ isShowApproveHours: false })}
            >
              <i className="fa-regular fa-xmark" />
            </button>
          </div>
          <div className="modal-body p-0">
            <p className="fs-4 text-start text_black lh-sm mb-1 mt-3">
              Confirm Approval
            </p>
            <p className="fs-6 text_primary text-start">
              You will be expected to pay your student for the following hours.
            </p>
            <div className="total_hours table-responsive mt-4 mb-3">
              <table className="table table-borderless mb-0">
                <thead>
                  <tr>
                    <th scope="col">Total Hours</th>
                    <th scope="col" className="text-end text_secondary">
                      {selectedTimesheet?.totalHours || 0} Hours
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedTimesheet?.timesheet.map(
                    (elmt: any, index: number) => (
                      <tr key={index}>
                        <td>{getDayByIndex(index)}</td>
                        <td className="text-end">{elmt.hours} Hours</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <button
              disabled={state.isBtnDisable}
              type="button"
              className={`btn w-100 mb-3 ${
                state.isBtnDisable ? "bg-rw-disable" : "bg_secondary"
              }`}
              data-bs-toggle="modal"
              data-bs-target="#weekly_summary"
              onClick={async () => {
                setState({ isBtnDisable: true });
                await updateInvoiceState(
                  selectedTimesheet?.invoiceId,
                  InvoiceStatus.APPROVED,
                  ""
                );
                setState({
                  isShowApproveHours: false,
                  isBtnDisable: false,
                });
              }}
            >
              Confirm
            </button>
            <button
              type="button"
              className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
              onClick={() => setState({ isShowApproveHours: false })}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        show={state.isShowRequestChangeModal}
        onHide={() => setState({ isShowRequestChangeModal: false })}
        centered
      >
        <div className="modal-content p-3 p-md-4 border_r_16px overflow-hidden">
          <div className="modal-header text-end p-0 border-0">
            <div className="d-flex align-items-center w-75">
              <div className="sm_logo">
                {studentAvatars[selectedTimesheet?.student?._id] ? (
                  <img
                    src={studentAvatars[selectedTimesheet?.student?._id]}
                    className="image_card"
                  />
                ) : (
                  initials(
                    selectedTimesheet?.student?.firstName,
                    selectedTimesheet?.student?.lastName
                  )
                )}
              </div>
              <div>
                <p className="text_black fw-bold fs-5 ms-3 lh-sm text-start">
                  {wrapFullName(
                    selectedTimesheet?.student?.firstName,
                    selectedTimesheet?.student?.lastName
                  )}
                </p>
                <p className="font_14 text_primary ms-3 text-start">
                  {selectedTimesheet?.student.university}
                </p>
              </div>
            </div>
            <button
              type="button"
              className="d-flex justify-content-end border-0 bg-transparent w-25 fs-4 text_secondary"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setState({ isShowRequestChangeModal: false })}
            >
              <i className="fa-regular fa-xmark" />
            </button>
          </div>
          <div className="modal-body p-0">
            <p className="fs-4 text-start text_black lh-sm mb-1 mt-3">
              Request a Change
            </p>
            <p className="fs-6 text_primary text-start">
              Let student know what they should update.
            </p>
            <textarea
              className="summary_textarea"
              placeholder="Enter Summary"
              value={state.requestChangeReason}
              onChange={(e: any) => {
                setState({ requestChangeReason: e.target.value });
              }}
            />
            <button
              disabled={state.isBtnDisable}
              type="button"
              className={`btn w-100 mb-3 ${
                state.isBtnDisable ? "bg-rw-disable" : "bg_secondary"
              }`}
              onClick={async () => {
                setState({ isBtnDisable: true });
                await updateInvoiceState(
                  selectedTimesheet?.invoiceId,
                  InvoiceStatus.REJECTED,
                  state.requestChangeReason
                );
                setState({
                  isShowRequestChangeModal: false,
                  isBtnDisable: false,
                });
              }}
            >
              Send Request
            </button>
            <button
              type="button"
              className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
              onClick={() => setState({ isShowRequestChangeModal: false })}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TimeSheetDetails;
