import React, { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useMergeState } from "utils/custom-hooks";
// import { createTimesheets, getTimesheets } from "api";
import { Modal } from "react-bootstrap";
import { formatDate, getDayByIndex } from "utils/date";
import { InvoiceStatus } from "utils/constants";
import ConnectPayoutModal from "components/Profile/ConnectPayoutModal";
import StripeAccountModal from "components/Profile/StripeAccountModal";
import { createConnectedAccount } from "api";

// function getNext7DaysLoop(startDate: any) {
//   const currentDate = new Date(startDate);
//   const next7Days = [];
//   for (let i = 1; i <= 7; i++) {
//     const nextDate = new Date(currentDate);
//     nextDate.setDate(currentDate.getDate() + i);
//     next7Days.push(nextDate.toISOString().slice(0, 10));
//     // next7Days.push({ day: nextDate.toISOString().slice(0, 10), hours: 0 });
//   }
//   return next7Days;
// }

// const dayNames = [
//   "Sunday",
//   "Monday",
//   "Tuesday",
//   "Wednesday",
//   "Thursday",
//   "Friday",
//   "Saturday",
// ];

type Props = {
  isPayoutsEnabled?: boolean;
  projectId?: string;
  isOngoingProject?: boolean;
  hasPrev: boolean;
  hasNext: boolean;
  isCapRate: boolean;
  hoursPerWeek: number;
  currentTimesheet: any;
  handlePreviousWeek: any;
  handleNextWeek: any;
  invoiceStatus: () => any;
  getInvoiceId: () => any;
  handleChangeHours: any;
  handleClearTimesheet: any;
  handleSubmitTimesheet: (summary: string, invoiceId: string) => any;
};

const SubmitHours = ({
  isPayoutsEnabled,
  projectId,
  isOngoingProject,
  hasPrev,
  hasNext,
  isCapRate,
  hoursPerWeek,
  currentTimesheet,
  handlePreviousWeek,
  handleNextWeek,
  handleChangeHours,
  invoiceStatus,
  getInvoiceId,
  handleClearTimesheet,
  handleSubmitTimesheet,
}: Props) => {
  const [state, setState] = useMergeState({
    showSummaryModal: false,
    weeklySummary: "",
    isWeeklySummayAdded: false,
    isBtnDisable: false,
    isShowConnectPayoutModal: false,
    isShowStripeAccountModal: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleCreateConnectAccount = async () => {
    try {
      const response = await createConnectedAccount();
      if (response?.success) {
        localStorage.setItem("returnUrl", `/projects/details?id=${projectId}`);
        window.location.href = response?.data?.url;
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const handleConnectPayout = async () => {
    await handleCreateConnectAccount();
    setState({ isShowConnectPayoutModal: false });
  };

  const handleSubmit = async (inVoiceId = "") => {
    // if (!isPayoutsEnabled) {
    //   return setState({ isShowConnectPayoutModal: true });
    //   // return enqueueSnackbar(
    //   //   "Please connect payout in profile settings before submitting timesheet.",
    //   //   { variant: "error" }
    //   // );
    // }
    try {
      setState({ isBtnDisable: true });
      await handleSubmitTimesheet(state.weeklySummary, inVoiceId);
      setState({
        isWeeklySummayAdded: false,
        weeklySummay: "",
      });
    } finally {
      setState({ isBtnDisable: false });
    }
  };

  const onCalculatePerHourRate = () => {
    const studentTotalAmount = Number(currentTimesheet?.studentTotalAmount);
    const totalHours = Number(currentTimesheet?.totalHours);
    return studentTotalAmount / totalHours;
  };

  const onCalculateTotalHours = () => {
    const total = currentTimesheet?.timesheet?.reduce(
      (total: any, item: any) => total + Number(item.hours),
      0
    );
    return total;
  };

  const isDisableSubmitBtn = () => {
    if (isCapRate) {
      if (onCalculateTotalHours() > hoursPerWeek) {
        return true;
      }
    }
  };

  const isPositiveNumber = (value: string) => {
    const num = Number(value);
    return !Number.isNaN(num) && num >= 0 && num <= 24;
  };

  const handleHours = (index: number, value: string) => {
    if (isPositiveNumber(value)) handleChangeHours(index, value.toString());
  };
  return (
    <div>
      <div className="d-flex justify-content-center pt-4">
        <span
          className={`fs-5 ${
            !state.isWeeklySummayAdded && hasPrev
              ? "text_secondary cursor_pointer"
              : "text_light_primary"
          }`}
          onClick={
            !state.isWeeklySummayAdded && hasPrev ? handlePreviousWeek : null
          }
        >
          <i className="fa-regular fa-chevron-left" />
        </span>
        <div className="text-center px-5">
          <p className="font_12">WEEK START</p>
          <p className="font_14 text_secondary fw-bold">
            {formatDate(currentTimesheet.weekStart, "ddd MMM Do, YYYY")}
          </p>
        </div>
        <span
          className={`fs-5 ${
            !state.isWeeklySummayAdded && hasNext
              ? "text_secondary cursor_pointer"
              : "text_light_primary"
          }`}
          onClick={
            !state.isWeeklySummayAdded && hasNext ? handleNextWeek : null
          }
        >
          <i className="fa-regular fa-chevron-right" />
        </span>
      </div>
      <div className="border-bottom box_shadow w-100 my-4" />
      {/* Hour input */}
      {!currentTimesheet?.hasTimesheet && !isOngoingProject ? (
        <div className="text-center text_rgba_50 pb-4">
          No timesheets have been submitted.
        </div>
      ) : !currentTimesheet?.hasTimesheet && !state.isWeeklySummayAdded ? (
        <div className="middle_box pb-5">
          {currentTimesheet?.timesheet?.map((elmt: any, index: number) => (
            <div className="mb-3" key={elmt?.day}>
              <label className="font_14 w-100 mb-2 fw-bold">
                {getDayByIndex(index)}
              </label>
              <input
                type="text"
                min="0"
                name={`${index}`}
                value={elmt.hours || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  // handleChangeHours(index, event?.target?.value)
                  handleHours(index, event?.target?.value)
                }
                placeholder="Hours Worked"
                className="form-control input_style"
              />
            </div>
          ))}

          <div className="mb-3 d-flex align-items-center justify-content-between">
            <p className="font_14 fw-bold">Total</p>
            <p className="text_secondary fw_500">
              {onCalculateTotalHours()} Hours
            </p>
          </div>

          <button
            type="button"
            className="btn bg_secondary w-100 mb-3"
            data-bs-toggle="modal"
            data-bs-target="#weekly_summary"
            onClick={() => {
              if (onCalculateTotalHours() > 0) {
                setState({ showSummaryModal: true });
              }
            }}
          >
            Next
          </button>
          <button
            type="button"
            className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
            onClick={handleClearTimesheet}
          >
            Clear
          </button>
        </div>
      ) : !currentTimesheet?.hasTimesheet && state.isWeeklySummayAdded ? (
        <div className="smaller_box pb-5">
          <div className="total_hours table-responsive">
            <table className="table table-borderless">
              <thead>
                <tr>
                  <th scope="col">Total Hours</th>
                  <th scope="col" className="text-end text_secondary">
                    {onCalculateTotalHours()} Hours
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTimesheet?.timesheet.map((elmt: any, index: number) => (
                  <tr key={index}>
                    <td>{getDayByIndex(index)}</td>
                    <td className="text-end">{Number(elmt.hours)} Hours</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <textarea
            className="summary_textarea mt-0"
            value={state.weeklySummary}
            readOnly
            placeholder={
              state.weeklySummary === ""
                ? "No summary provided"
                : "Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary Enter Summary "
            }
          />
          {isDisableSubmitBtn() && (
            <div className="flex justify-between mb-2">
              <div className="font-semibold text-sm">Total</div>
              <div>
                <div className="font-semibold text-sm text-rw-maroon">{`${onCalculateTotalHours()} Hours`}</div>
                <div className="font-semibold text-sm rw-black-slight-dark">{`${hoursPerWeek} Hours Max`}</div>
              </div>
            </div>
          )}
          <button
            disabled={state.isBtnDisable || isDisableSubmitBtn()}
            type="button"
            className={`btn w-100 mb-3 ${
              state.isBtnDisable || isDisableSubmitBtn()
                ? "bg-rw-disable"
                : "bg_secondary"
            }`}
            data-bs-toggle="modal"
            data-bs-target="#weekly_summary"
            onClick={() => handleSubmit()}
          >
            Submit
          </button>
          <button
            type="button"
            onClick={() =>
              setState({ isWeeklySummayAdded: false, weeklySummary: "" })
            }
            className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
          >
            Back
          </button>
        </div>
      ) : (
        <div className="smaller_box pb-5">
          {InvoiceStatus.REJECTED === invoiceStatus() ? (
            <>
              <div
                style={{
                  backgroundColor: "#F6F9FC",
                  borderRadius: 16,
                  padding: 12,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <span style={{ fontSize: 14 }}>Change Requested</span>
                <span
                  style={{ fontSize: 14, color: "#21383E99", marginTop: 5 }}
                >
                  {getInvoiceId()?.rejectionReason}
                </span>
              </div>
              {currentTimesheet?.timesheet.map((elmt: any, index: number) => (
                <div className="mb-3" key={elmt?.day}>
                  <label className="font_14 w-100 mb-2 fw-bold">
                    {getDayByIndex(index)}
                  </label>
                  <input
                    type="text"
                    min="0"
                    name={`${index}`}
                    value={elmt.hours || ""}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      // handleChangeHours(index, event?.target?.value)
                      handleHours(index, event?.target?.value)
                    }
                    placeholder="Hours Worked"
                    className="form-control input_style"
                  />
                </div>
              ))}

              <div
                className={`d-flex align-items-center justify-content-between ${
                  isDisableSubmitBtn() ? "mb-1" : "mb-3"
                }`}
              >
                <p className="font_14 fw-bold">Total</p>
                <p
                  className={`${
                    isDisableSubmitBtn() ? "text-rw-maroon" : "text_secondary"
                  } fw_500`}
                >
                  {onCalculateTotalHours()} Hours
                </p>
              </div>
              {isDisableSubmitBtn() && (
                <div className="flex justify-end mb-3 font-semibold text-sm rw-black-slight-dark">{`${hoursPerWeek} Hours Max`}</div>
              )}

              <button
                disabled={state.isBtnDisable || isDisableSubmitBtn()}
                type="button"
                className={`btn bg_secondary w-100 mb-3 ${
                  state.isBtnDisable || isDisableSubmitBtn()
                    ? "bg-rw-disable"
                    : "bg_secondary"
                }`}
                data-bs-toggle="modal"
                data-bs-target="#weekly_summary"
                onClick={() => handleSubmit(getInvoiceId()?._id)}
              >
                Update
              </button>
              <button
                type="button"
                className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
                onClick={handleClearTimesheet}
              >
                Clear
              </button>
            </>
          ) : (
            <>
              {" "}
              <div className="total_hours">
                <table className="table table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Total Hours</th>
                      <th scope="col" className="text-end text_secondary">
                        {currentTimesheet.totalHours} Hours
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentTimesheet?.timesheet.map(
                      (elmt: any, index: number) => (
                        <tr key={index}>
                          <td>{getDayByIndex(index)}</td>
                          <td className="text-end">
                            {Number(elmt.hours)} Hours
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
              <div className="p-4 border_r_16px border mb-4">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <p className="font_14">
                    ${onCalculatePerHourRate().toFixed(2)}/hr x
                    {currentTimesheet.totalHours}hrs
                  </p>
                  <p className="font_14 text-end">
                    $
                    {(
                      onCalculatePerHourRate() * currentTimesheet.totalHours
                    ).toFixed(2)}
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="fw-bold fs-6">Total Week Invoice</p>
                  <p className="text-end fw-bold fs-6">
                    ${currentTimesheet.studentTotalAmount}
                  </p>
                </div>
                {(invoiceStatus() === InvoiceStatus.REQUESTED_FOR_APPROVAL ||
                  invoiceStatus() === InvoiceStatus.PENDING) && (
                  <button
                    type="button"
                    className="btn bg_light_secondary w-100 text_secondary"
                    style={{ color: "#E38516", backgroundColor: "#E385161A" }}
                    data-bs-toggle="modal"
                    data-bs-target="#weekly_summary"
                  >
                    Hours Sent for Approval
                  </button>
                )}
                {invoiceStatus() === InvoiceStatus.PAID && (
                  <button
                    type="button"
                    className="btn bg_light_green text_green w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#weekly_summary"
                  >
                    Student Paid
                  </button>
                )}
                {InvoiceStatus.APPROVED === invoiceStatus() && (
                  <div
                    className="btn w-100 bg_light_secondary text_secondary text-center"
                    data-bs-toggle="modal"
                    data-bs-target="#weekly_summary"
                  >
                    <span style={{ fontSize: 10 }}>HOURS APPROVED </span>
                    <span style={{ fontSize: 18 }}>Payment Pending</span>
                  </div>
                )}
                {InvoiceStatus.ONHOLD === invoiceStatus() && (
                  <div
                    className="btn w-100 bg_light_secondary text_secondary text-center"
                    data-bs-toggle="modal"
                    data-bs-target="#weekly_summary"
                  >
                    <span style={{ fontSize: 18 }}>In Escrow</span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      )}

      <ConnectPayoutModal
        isShow={state.isShowConnectPayoutModal}
        onHide={() =>
          setState({
            isShowConnectPayoutModal: false,
          })
        }
        onConnect={handleConnectPayout}
        // onConnect={() =>
        //   setState({
        //     isShowConnectPayoutModal: false,
        //     isShowStripeAccountModal: true,
        //   })
        // }
      />
      <StripeAccountModal
        isShow={state.isShowStripeAccountModal}
        onHide={() =>
          setState({
            isShowStripeAccountModal: false,
          })
        }
      />

      {/* Modal */}
      <Modal
        show={state.showSummaryModal}
        onHide={() => setState({ showSummaryModal: false })}
        centered
      >
        <div className="p-3 p-md-4 border_r_16px overflow-hidden">
          <div className="modal-header text-end p-0 border-0">
            <button
              type="button"
              className="d-flex justify-content-end border-0 bg-transparent w-100 fs-4 text_secondary"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => setState({ showSummaryModal: false })}
            >
              <i className="fa-regular fa-xmark" />
            </button>
          </div>
          <div className="modal-body p-0">
            <p className="fs-4 text-start text_black lh-sm mb-1">
              Weekly Summary
            </p>
            <p className="fs-6 text_primary text-start">
              Summarize what you completed this week.
            </p>
            <textarea
              className="summary_textarea"
              placeholder="Enter a summary of your work for this week."
              onChange={(e: any) => {
                setState({ weeklySummary: e.target.value });
              }}
            />
            <button
              type="button"
              className="btn bg_secondary w-100 mb-3"
              onClick={() => {
                setState({
                  isWeeklySummayAdded: true,
                  showSummaryModal: false,
                });
              }}
            >
              Next
            </button>
            <button
              type="button"
              className="btn bg-transparent text_secondary w-100 border-2 border_secondary"
              onClick={() =>
                setState({
                  isWeeklySummayAdded: true,
                  showSummaryModal: false,
                  weeklySummary: "",
                })
              }
            >
              Skip
            </button>
          </div>
        </div>
      </Modal>
      {/* modal end */}
    </div>
  );
};

export default SubmitHours;
