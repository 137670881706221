import React, { useRef, useEffect } from "react";
import { wrapNameTwoLetters } from "utils/common";

type Props = {
  name: string;
  email: string;
};

export default function UserInfoComp({ name, email }: Props) {
  return (
    <div className="d-flex align-items-center bg_main p-3 mb-4 border_r_8px">
      <div className="bg-rw-blue rounded-full w-10 h-10 flex justify-center items-center">
        <span className="text-white text-[18px] font-medium">
          {wrapNameTwoLetters(name)}
        </span>
      </div>
      <div className="ms-3">
        <p className="fs-5 fw-bold mb-0">{name}</p>
        <p className="font_14 text_primary mb-0">{email}</p>
      </div>
    </div>
  );
}
