import SocialLinks from "../SocialLinks";
import Images from "assets/images";

export default function TheBestFreelanceJobs() {
  return (
    <main className="w-full min-h-screen">
      <div className="my-8">
        <div className="flex justify-center font-semibold mt-9 mb-3 text-xs text-rw-baby-blue-dark break-words">
          For Students
        </div>
        <div className="text-zinc-700 text-3xl md:text-4xl font-semibold flex justify-center text-center mb-5">
          <div className="w-11/12 lg:w-3/5">
            The Best Freelance Jobs for College Students
          </div>
        </div>
        <div className="flex gap-4 justify-center mb-7 flex-wrap">
          <div className="text-zinc-600 font-semibold">By Runway Team</div>
          <div>|</div> <div className="text-zinc-600">January 20th, 2023</div>
        </div>
        <div className="flex justify-center mb-10">
          <img
            src={Images.TheBestFreelanceJobs}
            className="w-11/12 lg:w-3/5"
            width={50}
            height={50}
            alt=""
          />
        </div>
        <div className="flex justify-center">
          <div className="w-11/12 lg:w-3/5">
            <div className="text-zinc-600 md:text-lg">
              College life is often hectic, with students juggling classes,
              internships, work, health, and social activities. In this
              fast-paced environment, having a flexible job is crucial.
              That&apos;s why micro-internships are an ideal option for college
              students
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg ">
              <br />
            </div>
            <div className="text-zinc-600 text-2xl md:text-3xl font-semibold">
              Why are Micro-Internships a Great Choice for College Students?
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold">
              Flexible Scheduling:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              <br />
              One of the biggest perks of micro-internships are the ability to
              set your own schedule and adjust projects to fit your current
              availability. Additionally, the majority of your work can be done
              from the comfort of your own home, eliminating the need for a
              daily commute. This means you can work from your dorm, apartment,
              library, or favorite coffee shop.
              <br />
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold">
              Additional Income:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              <br />
              Whether you have a job or not, micro-internships offer an
              opportunity to earn extra money as a college student and work
              during the school semester. Depending on your skills and schedule,
              you can accept as many or as few projects as needed.
              <br />
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold">
              Career Exploration:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              <br />
              College is a time to explore your interests and potential career
              paths. Micro-internships allow you to gain hands-on experience in
              a variety of fields, helping you determine what type of work you
              might enjoy doing in the future. If you have interests in multiple
              areas, take on micro-internship projects in each of them to gain a
              better understanding of your preferences.
              <br />
              <br />
            </div>
            <div className="text-zinc-600 text-xl md:text-2xl font-semibold">
              The Best Micro-Internships for College Students
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              <br />
            </div>
            <div className="text-zinc-600 text-lg md:text-xl font-bold">
              Business Development:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-semibold">
              Market Research:
            </div>
            <div className="text-zinc-600 md:text-lg">
              {" "}
              Conduct market research and provide insights to help businesses
              make informed decisions. As a market researcher, you will help
              businesses make informed decisions by providing them with accurate
              and relevant information.
              <br />
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-semibold">
              Sales Lead Generation:
            </div>
            <div className="text-zinc-600 md:text-lg">
              {" "}
              Identify potential sales leads and assist businesses in building
              relationships with them. As a sales lead generator, you will
              assist businesses in finding and reaching out to new customers to
              help increase their sales.
              <br />
              <br />
            </div>
            <div className="text-zinc-600 text-lg md:text-xl font-bold">
              Software Development:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-semibold">
              Website Development:
            </div>
            <div className="text-zinc-600 md:text-lg">
              {" "}
              Build and maintain websites for clients. As a website developer,
              you will work with clients to build user-friendly and effective
              websites that meet their needs and goals.
              <br />
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-semibold">
              Software Testing:
            </div>
            <div className="text-zinc-600 md:text-lg">
              {" "}
              Test software for clients to ensure it meets quality standards and
              works as intended. As a software tester, you will test software
              for clients, identify any issues, and provide feedback to improve
              the overall quality of the software.
              <br />
              <br />
            </div>
            <div className="text-zinc-600 text-lg md:text-xl font-semibold">
              Design:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-semibold">
              Graphic Design:
            </div>
            <div className="text-zinc-600 md:text-lg">
              {" "}
              Create graphics for businesses and organizations. As a graphic
              designer, you will work with clients to create logos that
              accurately reflect their brand and help them stand out from their
              competition.
              <br />
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-semibold">
              UI/UX Design:
            </div>
            <div className="text-zinc-600 md:text-lg">
              {" "}
              Design the user interface and user experience for websites and
              mobile apps. As a UI/UX designer, you will work with clients to
              create user-friendly and intuitive designs that meet their
              specific needs and goals
              <br />
              <br />
            </div>
            <div className="text-zinc-600 text-xl md:text-2xl font-semibold">
              Explore Micro-Internships
              <br />
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg">
              Micro-internships offer numerous benefits for college students,
              including the flexibility to set their own schedules, the
              opportunity to explore their career interests, and the potential
              for additional income. There is a wide range of micro-internships
              available, including graphic design, writing, social media, and
              web development.
              <br />
              <br />
              Catch the perfect break and jumpstart your career with{" "}
            </div>
            <span className="text-zinc-600 md:text-lg underline">Runway</span>
            <span className="text-zinc-600 md:text-lg">
              . Find paying projects, sharpen your skills, and paddle towards
              your dream career with every micro-internship you take on.
            </span>
            <div className="bg-zinc-600 mb-5 h-0.5 mt-20" />
            <div className="text-center text-zinc-600 text-2xl font-semibold">
              Share Article
            </div>
            <SocialLinks
              text="The Best Freelance Jobs for College Students"
              url="https://www.joinrunway.io/blogs/the-best-freelance-jobs-for-college-students/"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
