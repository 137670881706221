import React from "react";

type Props = {
  interviewTimes: any;
  setWindowName: any;
};
const CreateInterviewWindow = ({ interviewTimes, setWindowName }: Props) => {
  const data = interviewTimes[0];

  const generateIndex = (value: number) => {
    const slots = [
      "9:00 AM",
      "9:30 AM",
      "10:00 AM",
      "10:30 AM",
      "11:00 AM",
      "11:30 AM",
      "12:00 PM",
      "12:30 PM",
      "1:00 PM",
      "1:30 PM",
      "2:00 PM",
      "2:30 PM",
      "3:00 PM",
      "3:30 PM",
      "4:00 PM",
      "4:30 PM",
      "5:00 PM",
    ];
    const newVal = (value / 60 - 9) * 2;
    return slots[newVal];
  };

  return (
    <div className="px-0 px-md-3 w-100 mb-5 mb-md-0 pb-5 pb-md-4 mt-3">
      <div className="bg-white w-100 border border_gray border_r_16px border_sm_r_0 mb-4 mb-md-0 p-4">
        <p className="fs-4 fw-bold text_primary mb-1">
          Create New Interview Times
        </p>
        <p className="mb-4 text_primary">
          Enter a name for the interview times below to save them for the next
          candidates. Click submit to send interview windows to your candidate.
        </p>
        <div className="row">
          <div className="col-md-4">
            <div className="bg_light border border_gray border_r_16px overflow-hidden">
              <div className="bg_white p-3">
                <input
                  type="text"
                  name=""
                  onChange={(e) => setWindowName(e)}
                  placeholder="Times Name"
                  className="form-control input_style"
                />
              </div>

              <div className="p-3 border_r_8px bg_light">
                {data.monday.enabled && (
                  <div className="d-flex justify-content-between mb-1 flex-wrap">
                    <p className="text_primary fw-bold font_14">Monday</p>
                    <p className="text_rgba_50 font_14 fw-bold">
                      {generateIndex(data.monday.times[0])} -{" "}
                      {generateIndex(data.monday.times[1])}
                    </p>
                  </div>
                )}
                {data.tuesday.enabled && (
                  <div className="d-flex justify-content-between mb-1 flex-wrap">
                    <p className="text_primary fw-bold font_14">Tuesday</p>
                    <p className="text_rgba_50 font_14 fw-bold">
                      {generateIndex(data.tuesday.times[0])} -{" "}
                      {generateIndex(data.tuesday.times[1])}
                    </p>
                  </div>
                )}
                {data.wednesday.enabled && (
                  <div className="d-flex justify-content-between mb-1 flex-wrap">
                    <p className="text_primary fw-bold font_14">Wednesday</p>
                    <p className="text_rgba_50 font_14 fw-bold">
                      {generateIndex(data.wednesday.times[0])} -{" "}
                      {generateIndex(data.wednesday.times[1])}
                    </p>
                  </div>
                )}
                {data.thursday.enabled && (
                  <div className="d-flex justify-content-between mb-1 flex-wrap">
                    <p className="text_primary fw-bold font_14">Thursday</p>
                    <p className="text_rgba_50 font_14 fw-bold">
                      {generateIndex(data.thursday.times[0])} -{" "}
                      {generateIndex(data.thursday.times[1])}
                    </p>
                  </div>
                )}
                {data.friday.enabled && (
                  <div className="d-flex justify-content-between mb-1 flex-wrap">
                    <p className="text_primary fw-bold font_14">Friday</p>
                    <p className="text_rgba_50 font_14 fw-bold">
                      {generateIndex(data.friday.times[0])} -{" "}
                      {generateIndex(data.friday.times[1])}
                    </p>
                  </div>
                )}
                {data.saturday.enabled && (
                  <div className="d-flex justify-content-between mb-1 flex-wrap">
                    <p className="text_primary fw-bold font_14">Saturday</p>
                    <p className="text_rgba_50 font_14 fw-bold">
                      {generateIndex(data.saturday.times[0])} -{" "}
                      {generateIndex(data.saturday.times[1])}
                    </p>
                  </div>
                )}
                {data.sunday.enabled && (
                  <div className="d-flex justify-content-between mb-1 flex-wrap">
                    <p className="text_primary fw-bold font_14">Sunday</p>
                    <p className="text_rgba_50 font_14 fw-bold">
                      {generateIndex(data.sunday.times[0])} -{" "}
                      {generateIndex(data.sunday.times[1])}
                    </p>
                  </div>
                )}
                {/* <div className="d-flex justify-content-between mb-1 flex-wrap">
                  <p className="text_primary fw-bold font_14">Monday</p>
                  <p className="text_rgba_50 font_14 fw-bold">
                    9:00 AM - 5:00 PM
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-1 flex-wrap">
                  <p className="text_primary fw-bold font_14">Wednesday</p>
                  <p className="text_rgba_50 font_14 fw-bold">
                    9:00 AM - 5:00 PM
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-1 flex-wrap">
                  <p className="text_primary fw-bold font_14">Thursday</p>
                  <p className="text_rgba_50 font_14 fw-bold">
                    9:00 AM - 5:00 PM
                  </p>
                </div>
                <div className="d-flex justify-content-between mb-1 flex-wrap">
                  <p className="text_primary fw-bold font_14">Friday</p>
                  <p className="text_rgba_50 font_14 fw-bold">
                    9:00 AM - 5:00 PM
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateInterviewWindow;
