type Props = {
  message: string;
};

export default function ErrorMessage({ message }: Props) {
  return (
    <span className="mt-1 mb-2 text-xs text-rw-red-dark font-medium">
      {message}
    </span>
  );
}
