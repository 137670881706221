import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Images from "assets/images";

const ConnectPayoutModal = ({
  isShow,
  onHide,
  onConnect,
}: {
  isShow: boolean;
  onHide: () => void;
  onConnect: () => void;
}) => {
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const handleConnectPayout = async () => {
    setIsDisableBtn(true);
    await onConnect();
    setIsDisableBtn(false);
  };
  return (
    <Modal show={isShow} onHide={onHide} centered>
      <div className="modal-content p-3 p-md-4 border_r_16px overflow-hidden">
        <div className="modal-body p-0 mt-0">
          <button
            type="button"
            className="d-flex justify-content-end border-0 bg-transparent w-25 fs-4 text_black position-absolute end-0 me-0 mt-2 z_index_10"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i className="fa-regular fa-xmark" />
          </button>
          <img src={Images.StripeSvg} className="runway_stripe_logo" />
          <p className="fw-bold fs-4 text-start text_black lh-sm mb-1 mt-3">
            Connect Payout
          </p>
          <p className="fs-6 text_primary text-start mb-3">
            Your account is not yet set-up to accept payments.
          </p>
          <p className="fs-6 text_primary text-start">
            We use Stripe to process payments. Connect or set up a Stripe
            account to start accepting payments. It usually takes less than 5
            minutes.
          </p>

          <button
            disabled={isDisableBtn}
            type="button"
            className={`btn ${
              isDisableBtn ? "bg-rw-disable" : "bg_secondary"
            } w-100 my-3`}
            onClick={handleConnectPayout}
          >
            Connect Stripe
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConnectPayoutModal;
