import React from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

type Props = {
  search: string;
  onChange: React.ChangeEventHandler;
};

export default function SearchProjects({ search, onChange }: Props) {
  return (
    <div className="border-[1px] border-[#E3E3E3] p-1 flex justify-center items-center gap-2 rounded-full pl-2">
      <SearchOutlinedIcon />
      <input
        type="text"
        value={search}
        onChange={onChange}
        placeholder="Search Job Title or Key Word"
        className="w-full placeholder:font-medium placeholder:text-[#C7C7C7] placeholder:text-sm h-8"
      />
    </div>
  );
}
