import React, { useState } from "react";
import Images from "assets/images";
import { useMergeState } from "utils/custom-hooks";
import ConnectPayoutModal from "./ConnectPayoutModal";
import StripeAccountModal from "./StripeAccountModal";

const ProfileSettings = ({
  user,
  stateData,
  isBusinessView,
  handleCreateConnectAccount,
  handleEditPayoutAccount,
  handleNotificationSettingsChange,
}: {
  user: any;
  isBusinessView: any;
  stateData: any;
  handleCreateConnectAccount: any;
  handleEditPayoutAccount: any;
  handleNotificationSettingsChange: any;
}) => {
  const [state, setState] = useMergeState({
    isShowConnectPayoutModal: false,
    isShowStripeAccountModal: false,
    isDisableBtn: false,
  });
  const handleConnectPayout = async () => {
    await handleCreateConnectAccount();
    setState({ isShowConnectPayoutModal: false });
  };
  const handleEditPayout = async () => {
    setState({ isDisableBtn: true });
    await handleEditPayoutAccount();
    setState({ isDisableBtn: false });
  };
  const paymentData = stateData?.payment?.details
    ? stateData?.payment?.details?.external_accounts?.data
    : null;
  return (
    <div className="px-0 px-md-3 w-100 mb-5 mb-md-0 pb-4 pb-md-4 overflow-hidden">
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active w-100"
          id="setting"
          role="tabpanel"
          aria-labelledby="setting-tab"
        >
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 mb-3 mb-lg-0">
              {/* <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                <div className="d-flex justify-content-between pb-4">
                  <p className="fw-bold text_black fs-5">Payouts</p>
                  {!user?.payoutsEnabled ? (
                    <button
                      disabled={isDisableBtn}
                      type="button"
                      className={`btn btn_small text_white ${
                        isDisableBtn ? "bg-rw-disable" : "bg_secondary"
                      }`}
                      onClick={handleConnectPayout}
                    >
                      
                    Connect Payout
                    </button>
                  ) : (
                    <button
                      disabled={isDisableBtn}
                      type="button"
                      className={`btn btn_small text_white ${
                        isDisableBtn ? "bg-rw-disable" : "bg_secondary"
                      }`}
                      onClick={handleEditPayout}
                    >
                      Edit Payouts
                    </button>
                  )}
                </div>
                {user?.payoutsEnabled &&
                paymentData &&
                paymentData.length === 0 ? (
                  <>
                    <div className="flex justify-between items-center text-grey my-2">
                      <span className="font-medium">Country</span>
                      <span>{stateData?.payment?.country}</span>
                    </div>

                    <div className="flex justify-between items-center my-2">
                      <span className="font-medium">Default Currency</span>
                      <span>
                        {String(stateData?.payment?.defaultCurrency)?.toUpperCase()}
                      </span>
                    </div>
                  </>
                ) : user?.payoutsEnabled && paymentData ? (
                  <>
                    <p>Account Number</p>
                    <div className="border border_gray my-3" />
                    <div className="d-flex">
                      <p className="fw-bold">{`**** **** **** ${
                        paymentData[0]?.last4 || "****"
                      }`}</p>
                      <p className="text_secondary ps-4 fw_500">
                        Set as default
                      </p>
                    </div>
                  </>
                ) : null}
              </div> */}
              {!user?.payoutsEnabled ? (
                <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                  <img src={Images.StripeSvg} />

                  <p className="fw-bold fs-5 mt-3">
                    Set-up Payouts with Stripe
                  </p>
                  <p>
                    Set-up payouts by creating a Stripe account. It usually
                    takes less than 5 minutes to set up
                  </p>
                  <button
                    type="button"
                    className="btn btn_small bg_secondary w_sm_100 my-2 mt-3"
                    data-bs-toggle="modal"
                    data-bs-target="#getstarted"
                    onClick={() =>
                      setState({
                        isShowConnectPayoutModal: true,
                      })
                    }
                  >
                    Get started{" "}
                    <span className="ms-2 fs-6">
                      <i className="fal fa-long-arrow-right" />
                    </span>
                  </button>
                  <p className="font_12 pt-2">
                    **Stripe is a secure payment processor with low fees that
                    handles all of Runway’s payment.
                  </p>
                </div>
              ) : (
                <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                  <div className="d-flex align-items-center">
                    <div className="stripe_logo">
                      <img src={Images.Stripe_Logo} />
                    </div>
                    <div className="ms-3">
                      <p className="fs-5 fw-bold mb-0">Stripe Account</p>
                      <p className="text_green">
                        <span className="stripe_active" />
                        Active
                      </p>
                    </div>
                  </div>

                  <p className="text_black mt-3">
                    Your Stripe account is active and accepting payments for
                    your projects.
                  </p>
                  <button
                    disabled={state.isDisableBtn}
                    type="button"
                    className={`btn btn_small ${
                      state.isDisableBtn ? "bg-rw-disable" : "bg_secondary"
                    } w_sm_100 my-2 mt-3`}
                    onClick={handleEditPayout}
                  >
                    Open Stripe
                    <span className="ms-2 fs-6">
                      <i className="fal fa-long-arrow-right" />
                    </span>
                  </button>
                  <p className="font_12 pt-2">
                    **Stripe is a secure payment processor with low fees that
                    handles all of Runway’s payment.
                  </p>
                </div>
              )}
            </div>
            <div className="col-12 col-md-12 col-lg-6">
              <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0">
                <p className="fs-5 fw-bold pb-4">SMS Notifcations</p>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>New Project Posted</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="newProjectPosted"
                      checked={
                        stateData?.notificationSettings?.sms?.newProjectPosted
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "sms")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>Requested to Interview</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="requestedToInterview"
                      checked={
                        stateData?.notificationSettings?.sms
                          ?.requestedToInterview
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "sms")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>New Project Started</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="newProjectStarted"
                      checked={
                        stateData?.notificationSettings?.sms?.newProjectStarted
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "sms")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>Timesheet Reminders</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="timesheetReminders"
                      checked={
                        stateData?.notificationSettings?.sms?.timesheetReminders
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "sms")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="bg_white p-4 border_r_16px border border_gray border_sm_r_0 mt-3">
                <p className="fs-5 fw-bold pb-4">Email Notifcations</p>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>New Project Posted</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="newProjectPosted"
                      checked={
                        stateData?.notificationSettings?.email?.newProjectPosted
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "email")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>Requested to Interview</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="requestedToInterview"
                      checked={
                        stateData?.notificationSettings?.email
                          ?.requestedToInterview
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "email")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>New Project Started</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="newProjectStarted"
                      checked={
                        stateData?.notificationSettings?.email
                          ?.newProjectStarted
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "email")
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <p>Timesheet Reminders</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      name="timesheetReminders"
                      checked={
                        stateData?.notificationSettings?.email
                          ?.timesheetReminders
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleNotificationSettingsChange(event, "email")
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ConnectPayoutModal
            isShow={state.isShowConnectPayoutModal}
            onHide={() =>
              setState({
                isShowConnectPayoutModal: false,
              })
            }
            onConnect={handleConnectPayout}
            // onConnect={() =>
            //   setState({
            //     isShowConnectPayoutModal: false,
            //     isShowStripeAccountModal: true,
            //   })
            // }
          />
          <StripeAccountModal
            isShow={state.isShowStripeAccountModal}
            onHide={() =>
              setState({
                isShowStripeAccountModal: false,
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
