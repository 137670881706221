import React from "react";
import { useSnackbar } from "notistack";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import StaticSlider from "components/ProgressSlider/index";
import UserDetails from "components/UserDetails";
import { PersonalityTests } from "utils/constants";
import { scrollToTop, titleCaseText } from "utils/common";
import { useMergeState, useWindowSize } from "utils/custom-hooks";
import { addPersonalityTest } from "api";
import {
  generateResultTypes,
  getChoiceType,
  sortedTallyMap,
  topChoice,
  validateUserPersonalityTest,
  verifyPage,
} from "./helper";

type Props = { user: any };

export default function PersonalityTest({ user }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isProjectPersonalityTest =
    location.state && location.state?.projectType;
  const projectType = isProjectPersonalityTest
    ? location.state?.projectType
    : null;
  const projectId = isProjectPersonalityTest ? location.state?.projectId : null;

  let type: any = searchParams.get("type");
  if (!type) type = "";

  const personalityTest: any =
    type === "designer"
      ? PersonalityTests.designer
      : type === "developer"
      ? PersonalityTests.developer
      : type === "engineer"
      ? PersonalityTests.engineer
      : type === "business"
      ? PersonalityTests.business
      : undefined;

  const [state, setState] = useMergeState({
    selectedMap: {},
    page: 0,
    choiceTally: generateResultTypes(personalityTest),
  });

  const incPage = async () => {
    if (state.page + 1 < 4) {
      if (Object.keys(state.selectedMap).length >= (state.page + 1) * 3) {
        scrollToTop();
        setState({ page: state.page + 1 });
      } else {
        enqueueSnackbar("Please verify you filled out all previous questions", {
          variant: "error",
        });
      }
    } else if (Object.keys(state.selectedMap).length === 12) {
      const sortedResults = sortedTallyMap(state.choiceTally);
      try {
        const response = await addPersonalityTest({
          type,
          results: sortedResults,
        });
        if (response?.success) {
          navigate(`/personality-tests/results?type=${type}`, {
            state: {
              projectType,
              projectId,
              results: sortedResults,
              topChoiceType: topChoice(sortedResults),
            },
          });
        } else {
          enqueueSnackbar(response.message, { variant: "error" });
        }
      } catch (error: any) {
        enqueueSnackbar(error?.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar("Please verify you filled out all previous questions", {
        variant: "error",
      });
    }
  };

  const decPage = () => {
    if (state.page - 1 >= 0) {
      scrollToTop();
      setState({ page: state.page - 1 });
    } else {
      navigate("/personality-tests", { state: { projectType } });
    }
  };

  const handleCancel = () => {
    navigate("/projects");
  };

  const handleBoxClick = (
    choice: string,
    question: string,
    resultType: string
  ) => {
    if (state.selectedMap[question] === undefined) {
      const updateTally: any = { ...state.choiceTally };
      updateTally[resultType] += 1;

      const updatedSelectedMap = { ...state.selectedMap };
      updatedSelectedMap[question] = choice;

      setState({
        selectedMap: updatedSelectedMap,
        choiceTally: updateTally,
      });
    } else if (
      state.selectedMap[question] !== undefined &&
      state.selectedMap[question] === choice
    ) {
      const updateTally: any = { ...state.choiceTally };
      updateTally[
        getChoiceType(personalityTest, state.page, question, choice)
      ] -= 1;
      const updatedSelectedMap = { ...state.selectedMap };
      delete updatedSelectedMap[question];

      setState({
        selectedMap: updatedSelectedMap,
        choiceTally: updateTally,
      });
    } else if (state.selectedMap[question] !== undefined) {
      const updateTally: any = { ...state.choiceTally };
      updateTally[
        getChoiceType(
          personalityTest,
          state.page,
          question,
          state.selectedMap[question]
        )
      ] -= 1;
      updateTally[resultType] += 1;
      const updatedSelectedMap = { ...state.selectedMap };
      updatedSelectedMap[question] = choice;

      setState({
        selectedMap: updatedSelectedMap,
        choiceTally: updateTally,
      });
    }
  };

  React.useEffect(() => {
    console.log("personalityTest ---", personalityTest);
    console.log("user in PersonalityTest ---", user);
    // used to make sure students who arent in the other roles cant take other tests

    const init = () => {
      // if (!verifyPage(user?.profileTypes, type)) {
      //   return navigate(-1);
      // }
      if (!isProjectPersonalityTest && validateUserPersonalityTest(user)) {
        navigate("/projects");
      } else if (isProjectPersonalityTest) {
        const isPersonalityTestPerformed =
          user.personality[projectType.toLowerCase()] !== undefined;
        if (isPersonalityTestPerformed) {
          navigate("/projects");
        }
      }
      // if (validateUserPersonalityTest(user)) {
      //   navigate("/projects");
      // }
    };
    init();
  }, [type]);
  const widthValue =
    (useWindowSize().width - (useWindowSize().width / 100) * 81) * -1;

  return (
    <div>
      <div className="h-20 flex items-center px-4">
        <button
          className="hidden lg:flex items-center w-1/4"
          style={{ zIndex: 100 }}
          onClick={handleCancel}
        >
          <span className="text-rw-red mr-2">
            <CancelIcon sx={{ width: 30, height: 30 }} />
          </span>
          <div className="text-xl font-semibold">Cancel</div>
        </button>

        <div className="w-full">
          <div className="h-20 py-1 lg:py-2">
            <div
              className="text-rw-baby-blue-dark text-center text-sm font-semibold flex justify-center"
              style={{
                marginLeft: useWindowSize().width < 1020 ? 0 : widthValue,
              }}
            >
              {titleCaseText(type)} Personality Test
            </div>
            <div
              className="flex justify-center "
              style={{
                marginLeft: useWindowSize().width < 1020 ? 0 : widthValue,
              }}
            >
              <div className="w-3/4 sm:w-80 md:w-96">
                <StaticSlider value={state.page * 25} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="flex flex-col items-center mt-8">
        <div className="text-rw-black-raisin text-sm w-[90%] sm:w-80 md:w-96 bg-rw-blue-lightest rounded-[8px] py-4 px-3">
          Select the option that resonates most with you, not the one you think
          is &apos;correct&apos;.
        </div>
        <div className="w-[90%] sm:w-80 md:w-96 py-5 rounded-lg border-none">
          {" "}
          <div className="mb-10">
            {personalityTest?.questions[state.page].map((questionMap: any) => {
              return (
                <div>
                  <div className="font-medium text-black my-6">
                    {questionMap.question}
                  </div>
                  {Object.keys(questionMap.choices).map((choice: string) => {
                    return (
                      <div
                        className={`cursor-pointer rounded-lg flex py-3 px-4 justify-between border-solid ${
                          state.selectedMap[questionMap.question] !==
                            undefined &&
                          state.selectedMap[questionMap.question] === choice
                            ? "border-rw-baby-blue-dark"
                            : "border-rw-gray-gainsboro"
                        } border-[1px] bg-white hover:shadow-lg mb-3`}
                        onClick={() => {
                          handleBoxClick(
                            choice,
                            questionMap.question,
                            questionMap.choices[choice]
                          );
                        }}
                      >
                        <div className="md:text-base text-sm">{choice}</div>
                        <div className="flex items-center">
                          <div
                            className={`w-6 h-6 min-w-[24px] min-h-[24px] rounded-full border-[1px] border-solid border-rw-gray-gainsboro ml-4 ${
                              state.selectedMap[questionMap.question] !==
                                undefined &&
                              state.selectedMap[questionMap.question] === choice
                                ? "bg-rw-baby-blue-dark"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="block mb-4">
            <button
              className="w-[100%] bg-rw-blue text-white py-4 rounded-lg font-medium text-lg text-center"
              onClick={incPage}
            >
              {state.page === 3 ? "Complete" : "Continue"}
            </button>
          </div>
          <div className="block mb-16">
            <button
              className="w-[100%] bg-white text-rw-blue py-4 rounded-lg font-medium text-lg text-center border-[2px] border-solid border-rw-blue"
              onClick={decPage}
            >
              Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
