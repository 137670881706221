import DialogContent from "@mui/material/DialogContent";
import { Dialog } from "components/Dialog";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  open: boolean;
  onClose: any;
};

export default function RaceDefinitionsDialog({ open, onClose }: Props) {
  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
      open={open}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-paper": {
          width: 400,
        },
        "& .MuiDialogContent-root": {
          padding: 2,
        },
      }}
    >
      <DialogContent>
        <div className="relative">
          <div className="text-lg font-semibold">
            Race/Ethnicity Definitions
          </div>

          <button
            className="absolute top-0 right-0 text-rw-blue"
            onClick={onClose}
          >
            <CloseIcon sx={{ width: 28, height: 28 }} />
          </button>

          <div className="text-sm font-medium">
            <p className="my-4">
              Hispanic or Latino - A person of Cuban, Mexican, Puerto Rican,
              South or Central American, or other Spanish culture or origin
              regardless of race.
            </p>

            <p className="my-4">
              White/Caucasian - A person having origins in any of the original
              peoples of Europe, the Middle East, or North Africa.
            </p>

            <p className="my-4">
              Black or African American - A person having origins in any of the
              black racial groups of Africa.
            </p>

            <p className="my-4">
              Native Hawaiian or Pacific Islander - A person having origins in
              any of the peoples of Hawaii, Guam, Samoa, or other Pacific
              Islands.
            </p>

            <p className="my-4">
              Asian - A person having origins in any of the original peoples of
              the Far East, Southeast Asia, or the Indian Subcontinent,
              including, for example, Cambodia, China, India, Japan, Korea,
              Malaysia, Pakistan, the Philippine Islands, Thailand, and Vietnam.
            </p>

            <p className="my-4">
              Native American or Alaska Native - A person having origins in any
              of the original peoples of North and South America (including
              Central America), and who maintain tribal affiliation or community
              attachment.
            </p>

            <p className="my-4">
              Two or More Races - All persons who identify with more than one of
              the above five races.
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
