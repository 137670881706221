import React, { useEffect, useState } from "react";
import { useMergeState } from "utils/custom-hooks";
import { useNavigate, useLocation } from "react-router-dom";
import Rating from "@mui/material/Rating";
import ProgressBar from "components/ProgressBar";
import TestBreakDownDialog from "components/TestBreakDownDialog";
import RunwayPremiumDialog from "components/RunwayPremiumDialog";
import designerLogo from "../../assets/imgs/designer.svg";
import scienceLogo from "../../assets/imgs/science.svg";
import { wrapHTMLContent } from "utils/string";
import Images from "assets/images";
import {
  PersonalityTests,
  StudentTypes,
  PersonalityTestColors,
} from "utils/constants";
import {
  isValidUrl,
  openInNewTab,
  shouldShowPremiumFeatures,
  titleCaseText,
  generateRattingArray,
  toBase64,
  wrapFullName,
  wrapNameTwoLetters,
} from "utils/common";
import ViewDetailModal from "./ViewDetailModal";
import AiAssessmentModal from "./AiAssessmentModal";
import TextField from "components/TextField";
import { Checkbox } from "@mui/material";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import RichTextEditorField from "components/RichTextEditorField";
import ErrorMessage from "components/ErrorMessage";
import { getSignedUrlOfResume, getAIAssessmentPdf } from "api";

const ProfileDetails = ({
  state = {},
  user,
  isEditable,
  resumeRef,
  personalityTestState,
  showPremiumFeatures,
  isBusinessView,
  togglePremiumFeatures,
  handleStart,
  handleRedirectWebsite,
  togglePersonalityInfographicPopup,
  closePersonalityInfographicPopup,
  handleChange,
  handleResumeRef,
  handleRemoveResume,
  handleResumeChange,
  handleChangeBio,
  handleChangeKeySkills,
  handleRemoveSkills,
  handleChangeProfileType,
  showAiAssessmentButton = true,
}: {
  user: any;
  isBusinessView: any;
  state: any;
  isEditable: boolean;
  resumeRef: any;
  personalityTestState: any;
  showPremiumFeatures: any;
  togglePremiumFeatures: any;
  handleStart: any;
  handleRedirectWebsite: any;
  togglePersonalityInfographicPopup: any;
  closePersonalityInfographicPopup: any;
  handleChange: any;
  handleResumeRef: any;
  handleRemoveResume: any;
  handleResumeChange: any;
  handleChangeBio: any;
  handleChangeKeySkills: any;
  handleRemoveSkills: any;
  handleChangeProfileType: any;
  showAiAssessmentButton?: boolean;
}) => {
  const [isShowViewDetailModal, setIsShowViewDetailModal] = useState(false);
  const [pdfLink, setPdfLink] = useState(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isSettingsPage = pathname.includes("settings");

  const handleDownloadPdf = async () => {
    const AIResult = user?.skillAssessment;
    if (AIResult?.report && AIResult?.report?.key) {
      const pdfResponse = await getSignedUrlOfResume(
        AIResult?.report?.key,
        AIResult?.report?.bucket
      );
      if (pdfResponse?.success && pdfResponse?.data) {
        setPdfLink(pdfResponse?.data?.url);
      }
    } else {
      const pdfResponse = await getAIAssessmentPdf();
      if (pdfResponse?.success && pdfResponse?.url) {
        setPdfLink(pdfResponse?.url);
      }
    }
  };

  useEffect(() => {
    handleDownloadPdf();
  }, []);

  const toggleViewDetailModal = () => {
    setIsShowViewDetailModal(!isShowViewDetailModal);
  };

  return (
    <div
      className={`px-0 w-100 mb-5 mb-md-0 pb-4 pb-md-4 ${
        isSettingsPage ? "px-md-3" : ""
      } overflow-hidden`}
    >
      <div className="tab-content" id="myTabContent">
        <div
          className="tab-pane fade show active w-100"
          id="profile"
          role="tabpanel"
          aria-labelledby="profile-tab"
        >
          <div className="custom_column studentprofile_edit pt-2">
            <div className="custom_box border_sm_r_0">
              {!isEditable ? (
                <div>
                  <p className="fw-bold text_black fs-5 mb-4">
                    Student Details
                  </p>
                  {state?.resume?.url && (
                    <a
                      href={state?.resume?.url}
                      target="_blank"
                      rel="noopener noreferrer nofollow"
                      className="box_style mb-4 shadow-none cursor_pointer"
                    >
                      <div className="d-flex align-items-center">
                        <span className="text_secondary fs-3">
                          <i className="fal fa-file-alt" />
                        </span>
                        <div className="ms-3">
                          <p className="fs-6 fw-bold mb-0">Resume</p>
                          <p className="font_14 text_primary mb-0">
                            Download Document
                          </p>
                        </div>
                      </div>
                      <span className="text_secondary fs-3">
                        <i className="far fa-cloud-download" />
                      </span>
                    </a>
                  )}
                  {state?.website && (
                    <div
                      className="box_style mb-4 shadow-none cursor_pointer"
                      onClick={handleRedirectWebsite}
                    >
                      <div className="d-flex align-items-center">
                        <span className="text_secondary fs-3">
                          <i className="fa-sharp fa-solid fa-link" />
                        </span>
                        <div className="ms-3">
                          <p className="fs-6 fw-bold mb-0">Website</p>
                          <p className="font_14 text_primary mb-0">
                            Visit Website
                          </p>
                        </div>
                      </div>
                      <span className="text_secondary fs-3">
                        <i className="fal fa-long-arrow-right" />
                      </span>
                    </div>
                  )}
                  <div
                    className="box_style shadow-none text-break"
                    dangerouslySetInnerHTML={{
                      __html: wrapHTMLContent(state?.bio),
                    }}
                  />
                  {/* <p className="text-start">
                    I am a junior at Georgia Tech and have experience in new
                    business development. I plan to graduate in 2024 and am
                    ready to take on any business job
                  </p> */}
                  <div className="mt-4">
                    {state?.skills?.map((skill: string, index: string) => (
                      <span key={index} className="tag">
                        {skill}
                      </span>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <p className="fw-bold text_black fs-5">Personal Details</p>
                  </div>

                  <p className="font_18 text_primary mb-4">Profile Type</p>
                  {StudentTypes.filter((item, index) => index !== 1).map(
                    (profileType) => (
                      <div
                        key={profileType?.title}
                        className="d-flex justify-content-between align-items-center p-3 border_r_8px border border_gray mb-3 cursor-pointer"
                        onClick={() =>
                          handleChangeProfileType(profileType?.value)
                        }
                      >
                        <div className="d-flex align-items-center">
                          <img src={profileType?.icon} className="w-10 h-8" />
                          <div className="ms-4">
                            <p className="font_18 fw-bold text_primary">
                              {profileType?.title}
                            </p>
                            <p className="text_primary font_12">
                              {profileType?.description}
                            </p>
                          </div>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={state.profileTypes.includes(
                              profileType?.value
                            )}
                            id="flexCheckDefault"
                          />
                          <label className="form-check-label" />
                        </div>

                        {/* <div className="w-full flex justify-between items-center">
                              <div className="px-4">
                                <div
                                  className={`${
                                    state?.profileTypes.includes(
                                      profileType?.value
                                    )
                                      ? "text-rw-blue"
                                      : ""
                                  } text-lg font-semibold`}
                                >
                                  {profileType?.title}
                                </div>

                                <div className="text-xs font-medium text-rw-gray-75">
                                  {profileType?.description}
                                </div>
                              </div>

                              <div>
                                <Checkbox
                                  checked={state.profileTypes.includes(
                                    profileType?.value
                                  )}
                                />
                              </div>
                            </div> */}
                      </div>
                    )
                  )}

                  {state?.errors?.profileTypes && (
                    <ErrorMessage message={state?.errors?.profileTypes} />
                  )}

                  <p className="font_18 text_black mb-2">Name</p>
                  <div className="d-flex align-items-center justify-content-between">
                    <TextField
                      type="text"
                      name="firstName"
                      // className="input_style mb-3 m3-2"
                      className="form-control input_style mb-3 m3-2"
                      value={state?.firstName}
                      onChange={handleChange}
                      error={state?.errors?.firstName}
                      disabled={!isEditable}
                      isMultipleCol
                    />

                    <TextField
                      type="text"
                      name="lastName"
                      // className="input_style mb-3 ms-2"
                      className="form-control input_style mb-3 ms-2"
                      value={state?.lastName}
                      onChange={handleChange}
                      error={state?.errors?.lastName}
                      disabled={!isEditable}
                      isMultipleCol
                    />
                  </div>

                  <p className="font_18 text_black mb-2">Email</p>
                  <TextField
                    type="text"
                    name="email"
                    value={state?.email}
                    onChange={handleChange}
                    error={state?.errors?.email}
                    // disabled={!isEditable}
                    disabled
                  />

                  <p className="font_18 text_black mb-2">Phone Number</p>
                  <TextField
                    type="text"
                    name="phone"
                    value={state?.phone}
                    onChange={handleChange}
                    error={state?.errors?.phone}
                    // disabled={!isEditable}
                    disabled
                    onWheel={(event: any) => event.target.blur()}
                    onInput={(event: any) => {
                      if (event?.target?.value) {
                        event.target.value = Math.max(
                          0,
                          parseInt(event.target.value, 10)
                        )
                          .toString()
                          .slice(0, 10);
                      }
                    }}
                  />
                  <p className="font_18 text_black mb-2">Resume</p>
                  <div
                    className="border border_dark_gray dashed p-5 text-center border_r_8px mb-3 cursor_pointer"
                    onClick={handleResumeRef}
                  >
                    <label className="cursor_pointer">
                      <p className="fs-4 text_light_primary">
                        <i className="fa-light fa-cloud-arrow-up" />
                      </p>
                      <p className="text_light_primary fw-bold fs-6">
                        {" "}
                        Browse Files
                      </p>
                      <p className="text_light_primary font_10">
                        Drag and drop files here
                      </p>
                    </label>
                  </div>
                  <input
                    type="file"
                    accept="application/pdf"
                    className="hidden"
                    ref={resumeRef}
                    onChange={handleResumeChange}
                  />
                  {(state?.resume || state?.resumeBase64) && (
                    <div className="flex justify-between items-center p-2 bg-[#E3E4E7] rounded-md mt-4 mb-4">
                      <span className="text-sm font-medium text-rw-gray-75 word-break">
                        {state?.resumeFile?.name || "RESUME"}
                      </span>

                      <span
                        className="text-rw-red-dark cursor-pointer"
                        onClick={handleRemoveResume}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </span>
                    </div>
                  )}

                  <p className="font_18 text_black mb-2">Website</p>
                  <TextField
                    type="text"
                    name="website"
                    className="input_style mb-3"
                    value={state?.website}
                    onChange={handleChange}
                    error={state?.errors?.website}
                    disabled={!state?.isEditable}
                  />

                  <div className="my-4">
                    <RichTextEditorField
                      value={state?.bio}
                      placeholder="Write a short bio that briefly explains your previous experience..."
                      onChange={handleChangeBio}
                      error={state?.errors?.bio}
                    />
                  </div>

                  <p className="font_18 text_black mb-2 mt-4">Key Skillsets</p>
                  <div className="my-4">
                    <TextField
                      type="text"
                      name="keySkills"
                      className="input_style mb-3"
                      placeholder="Enter key skillsets for your project"
                      value={state?.keySkills}
                      onChange={handleChangeKeySkills}
                      error={state?.errors?.keySkills}
                    />

                    <div className="doted_tags active">
                      {state?.skills?.map(
                        (skill: string, index: string) =>
                          skill && (
                            <span key={index}>
                              {skill}
                              <span className="ms-3">
                                <i
                                  className="fa-light fa-xmark"
                                  onClick={() => handleRemoveSkills(skill)}
                                />
                              </span>
                            </span>
                          )
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* {!user?.skillAssessment && !showAiAssessmentButton ? null : (
              <div className="custom_box border_sm_r_0">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="fw-bold text_black fs-5">AI Assessment</p>
                  {user?.skillAssessment?.feedbackData && (
                    <span
                      onClick={toggleViewDetailModal}
                      className="text_secondary fw_500 text-decoration-underline cursor_pointer"
                    >
                      View Details
                    </span>
                  )}
                </div>
                {!isBusinessView && !user?.skillAssessment ? (
                  <>
                    <hr className="my-4" />
                    <div className="text-sm font-medium text-black text-opacity-75">
                      Increase your chances of getting an interview by 4x by
                      completing a 15-minute skills assessment.
                    </div>
                    <div className="w-full mt-4">
                      <button
                        onClick={() => navigate("/skills-assessment")}
                        className="bg-rw-blue text-white rounded-lg py-4 font-medium text-lg w-full"
                      >
                        Complete AI Assessment
                      </button>
                    </div>
                  </>
                ) : user?.skillAssessment &&
                  user?.skillAssessment?.feedbackData ? (
                  <>
                    {Object.keys(user.skillAssessment.feedbackData).map(
                      (key: string, index: number) => (
                        <div className="border border_gray border_r_16px p-4 mb-4">
                          <p className="fw-bold text_black fs-5 pb-2">{key}</p>
                          <p className="fs-6 fw_500 pb-3">
                            AI Rating:{" "}
                            <span className="text_orange">
                              {user.skillAssessment.feedbackData[key].level}
                            </span>
                          </p>
                          <p>
                            <strong>AI Assessment:</strong>{" "}
                            {user.skillAssessment.feedbackData[key].feedback}
                          </p>
                        </div>
                      )
                    )}
                  </>
                ) : null}
              </div>
            )} */}

            {state?.reviews.length > 0 && (
              <div className="custom_box border_sm_r_0">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p className="fw-bold text_black fs-5">Student Details</p>
                  <div className="bg_light p-2 border_r_8px">
                    {state?.stats?.reviews?.overallRating || ""}{" "}
                    <span className="ms-3 text_secondary">
                      <i className="fas fa-star" />
                    </span>
                  </div>
                </div>
                {state?.reviews?.map((review: any) => (
                  <div
                    key={review?._id}
                    className="border border_gray border_r_16px p-4 mb-4"
                  >
                    <p className="fw-bold text_black fs-5 pb-2">
                      {review?.project?.title || ""}
                    </p>
                    <div className="mb-3">
                      {generateRattingArray(review.rating).map(
                        (ratting: number, index: number) => (
                          <span key={index} className="text_secondary">
                            <i
                              className={
                                ratting === 1
                                  ? "fas fa-star"
                                  : ratting === 2
                                  ? "fas fa-star-half-alt"
                                  : "fa-regular fa-star"
                              }
                            />
                          </span>
                        )
                      )}
                    </div>
                    <p>
                      <strong>AI Assessment:</strong> {review?.review}
                    </p>
                  </div>
                ))}
              </div>
            )}

            {Object.keys(state?.personalityTests).length > 0 && (
              <div className="custom_box border_sm_r_0 pb-5 pb-md-0">
                {Object.keys(state?.personalityTests).map(
                  (type: string, index: number) => {
                    type = type.toLowerCase();
                    if (!shouldShowPremiumFeatures(user)) {
                      return;
                    }
                    if (
                      state?.personalityTests[type.toLowerCase()] !== undefined
                    ) {
                      const isLast =
                        Object.keys(state?.personalityTests).length ===
                        index + 1;
                      return (
                        <div
                          key={index.toString()}
                          className={!isLast ? "mb-4" : ""}
                        >
                          <div className="d-flex align-items-center mb-4">
                            <img
                              src={
                                type === "designer"
                                  ? Images.Designer
                                  : type === "developer"
                                  ? Images.Developer
                                  : type === "engineer"
                                  ? Images.Engineer
                                  : type === "business"
                                  ? Images.Business
                                  : ""
                              }
                              className="mr-4 w-8 h-8"
                            />
                            <p className="fw-bold text_black fs-5 ms-4">
                              {titleCaseText(type)}
                            </p>
                          </div>
                          <div className=" border-bottom border_gray my-4" />
                          <div className="d-flex icon_size_lg">
                            <img
                              src={
                                PersonalityTests[type].results[
                                  Object.keys(state.personalityTests[type])[0]
                                ]?.icon
                              }
                            />
                            {/* <img src={scienceLogo} /> */}
                            <div className="ms-4">
                              <p className="fs-4 fw-bold text_black">
                                {Object.keys(state.personalityTests[type])[0]}
                              </p>
                              <p>
                                {
                                  PersonalityTests[type].results[
                                    Object.keys(state.personalityTests[type])[0]
                                  ].text
                                }
                              </p>
                            </div>
                          </div>
                          <div className=" border-bottom border_gray mt-4 mb-5" />
                          {Object.keys(state.personalityTests[type]).map(
                            (scoreKeys: any, index: number) => {
                              return (
                                <div
                                  key={index}
                                  className="d-flex align-items-center mb-3 flex-wrap"
                                >
                                  <p className="fs-6 text_black w-25 w_sm_100">
                                    {scoreKeys}
                                  </p>
                                  <ProgressBar
                                    percentage={
                                      state.personalityTests[type][scoreKeys]
                                    }
                                    //   Math.round(
                                    //   (state.personalityTests[type][scoreKeys] /
                                    //     100) *
                                    //     194
                                    // )}
                                    type={index}
                                  />
                                </div>
                              );
                            }
                          )}
                        </div>
                      );
                    }

                    if (!isBusinessView) {
                      return (
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md bg-white border-[1px] border-rw-gray-gainsboro mt-4 px-8 py-6">
                            <div className="flex justify-start align-center gap-2 mb-7">
                              <img
                                src={
                                  type === "designer"
                                    ? Images.Designer
                                    : type === "developer"
                                    ? Images.Developer
                                    : type === "engineer"
                                    ? Images.Engineer
                                    : type === "business"
                                    ? Images.Business
                                    : ""
                                }
                                className="mr-4 w-8 h-8"
                              />
                              <div className="text-xl font-semibold text-[#414141]">
                                {titleCaseText(type)}
                              </div>
                            </div>
                            <div className="flex justify-center">
                              <button
                                className="w-[100%] bg-rw-blue rounded-md py-4 text-white text-lg font-medium text-center"
                                onClick={() => {
                                  handleStart(type);
                                }}
                              >
                                Start Personality Test
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </div>
            )}
          </div>
        </div>
        {/* {isShowViewDetailModal ? (
          <ViewDetailModal
            userName={`${user.firstName} ${user.lastName}`}
            AIResult={user?.skillAssessment?.feedbackData}
            toggleViewDetailModal={toggleViewDetailModal}
          />
        ) : null} */}
        {/* {personalityTestState.showPersonalityInfographicPopup && (
          <TestBreakDownDialog
            open={personalityTestState.showPersonalityInfographicPopup}
            onClose={closePersonalityInfographicPopup}
            resultDescriptions={
              personalityTestState.personalityResultDescriptions[
                personalityTestState.personalityInfographicType
              ]
            }
            type={personalityTestState.personalityInfographicType}
          />
        )} */}
        {/* {showPremiumFeatures && (
          <RunwayPremiumDialog
            open={showPremiumFeatures}
            onClose={togglePremiumFeatures}
            user={user}
          />
        )} */}
      </div>
      {isShowViewDetailModal && (
        <AiAssessmentModal
          show={isShowViewDetailModal}
          userName={`${user.firstName} ${user.lastName}`}
          AIResult={user?.skillAssessment}
          pdfLink={pdfLink}
          onClose={toggleViewDetailModal}
        />
      )}
    </div>
  );
};

export default ProfileDetails;
