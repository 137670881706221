import ReactDOM from "react-dom/client";
import "index.scss";
import App from "app";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import "bootstrap/dist/css/bootstrap.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_WEB_CLIENT_ID}>
    <App />
  </GoogleOAuthProvider>
);
