import Checkbox from "@mui/material/Checkbox";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Button from "components/Button";

import {
  AccountType,
  ProjectStatus,
  StudentProjectStatus,
} from "utils/constants";

const BusinessFilters = [
  {
    label: "ALL PROJECTS",
    value: "",
  },
  {
    label: "LISTED PROJECTS",
    value: ProjectStatus.ACTIVE,
  },
  {
    label: "UNLISTED PROJECTS",
    value: ProjectStatus.IN_ACTIVE,
  },
  {
    label: "ONGOING PROJECTS",
    value: ProjectStatus.IN_PROGRESS,
  },
  {
    label: "FINISHED PROJECTS",
    value: ProjectStatus.FINISHED,
  },
  {
    label: "DRAFT PROJECTS",
    value: ProjectStatus.DRAFT,
  },
];

const StudentFilters = [
  {
    label: "ALL PROJECTS",
    value: "",
  },
  {
    label: "ONGOING PROJECTS",
    value: StudentProjectStatus.IN_PROGRESS,
  },
  {
    label: "REQUESTED TO INTERVIEW",
    value: StudentProjectStatus.REQUESTED_TO_INTERVIEW,
  },
  {
    label: "APPLIED PROJECTS",
    value: StudentProjectStatus.APPLIED,
  },
  {
    label: "FINISHED PROJECTS",
    value: StudentProjectStatus.FINISHED,
  },
];

type Props = {
  user?: any;
  status: Array<string>;
  onChange: (value: string, filters: any[]) => void;
  showFilterMenu: boolean;
  onCloseFilter: () => void;
  onOpenFilter: () => void;
};

const isSelected = (status: Array<string>, value: string) =>
  status.includes(value);

export default function ActiveProjectsFilter({
  user,
  status,
  onChange,
  showFilterMenu,
  onCloseFilter,
  onOpenFilter,
}: Props) {
  return (
    <div>
      {!showFilterMenu && (
        <button
          className="w-full lg:hidden border-[1px] border-[#E3E3E3] rounded-md flex justify-center items-center py-3 my-4 bg-white"
          onClick={onOpenFilter}
        >
          <span className="text-rw-blue">
            <TuneOutlinedIcon />
            <span className="ml-2 font-medium">Filter</span>
          </span>
          <span className="text-rw-gray ml-2">(1)</span>
        </button>
      )}

      {showFilterMenu && (
        <div className="w-full mt-4 block lg:hidden">
          <div className="text-xl flex justify-between items-center px-4">
            <span className="font-semibold">Filter</span>

            <button
              className="text-rw-red font-semibold"
              onClick={onCloseFilter}
            >
              Clear all
            </button>
          </div>

          <hr className="my-4" />

          <div className="flex justify-center">
            {user?.accountType === AccountType.BUSINESS && (
              <div className="w-11/12">
                {BusinessFilters.map((businessFilter) => (
                  <div
                    key={businessFilter?.value}
                    className="border-[1px] border-[#0B6FFF] rounded-md flex justify-between items-center px-4 py-2 my-4 cursor-pointer"
                    onClick={() =>
                      onChange(businessFilter?.value, BusinessFilters)
                    }
                  >
                    {!businessFilter?.value && (
                      <div className="text-rw-blue text-sm font-semibold">
                        {businessFilter?.label}
                      </div>
                    )}

                    {[ProjectStatus.ACTIVE, ProjectStatus.IN_ACTIVE].includes(
                      businessFilter?.value
                    ) && (
                      <div className="text-rw-blue text-sm font-semibold bg-rw-blue-light rounded-md px-2 py-1">
                        {businessFilter?.label}
                      </div>
                    )}

                    {businessFilter?.value === ProjectStatus.IN_PROGRESS && (
                      <div className="text-rw-green text-sm font-semibold bg-rw-green-light rounded-md px-2 py-1">
                        {businessFilter?.label}
                      </div>
                    )}

                    {businessFilter?.value === ProjectStatus.FINISHED && (
                      <div className="text-rw-red text-sm font-semibold bg-rw-red-light rounded-md px-2 py-1">
                        {businessFilter?.label}
                      </div>
                    )}

                    <div>
                      <Checkbox
                        value={businessFilter?.value}
                        className="w-6"
                        checked={isSelected(status, businessFilter?.value)}
                      />
                    </div>
                  </div>
                ))}

                <Button
                  className="w-full h-14 mt-8 font-semibold"
                  onClick={onCloseFilter}
                >
                  View Projects
                </Button>

                <Button
                  variant="secondary"
                  className="w-full h-14 mt-4 font-semibold"
                  onClick={onCloseFilter}
                >
                  Close
                </Button>
              </div>
            )}

            {user?.accountType === AccountType.STUDENT && (
              <div className="w-11/12">
                {StudentFilters.map((studentFilter) => (
                  <div
                    key={studentFilter?.value}
                    className="border-[1px] border-[#0B6FFF] rounded-md flex justify-between items-center px-4 py-2 my-4 cursor-pointer"
                    onClick={() =>
                      onChange(studentFilter?.value, StudentFilters)
                    }
                  >
                    {!studentFilter?.value && (
                      <div className="text-rw-blue text-sm font-semibold">
                        {studentFilter?.label}
                      </div>
                    )}

                    {studentFilter?.value === StudentProjectStatus.APPLIED && (
                      <div className="text-rw-blue text-sm font-semibold bg-rw-blue-light rounded-md px-2 py-1">
                        {studentFilter?.label}
                      </div>
                    )}

                    {studentFilter?.value ===
                      StudentProjectStatus.REQUESTED_TO_INTERVIEW && (
                      <div className="text-rw-yellow-dark text-sm font-semibold bg-rw-yellow-lightest rounded-md px-2 py-1">
                        {studentFilter?.label}
                      </div>
                    )}

                    {studentFilter?.value ===
                      StudentProjectStatus.IN_PROGRESS && (
                      <div className="text-rw-green text-sm font-semibold bg-rw-green-light rounded-md px-2 py-1">
                        {studentFilter?.label}
                      </div>
                    )}

                    {studentFilter?.value === StudentProjectStatus.FINISHED && (
                      <div className="text-rw-red text-sm font-semibold bg-rw-red-light rounded-md px-2 py-1">
                        {studentFilter?.label}
                      </div>
                    )}

                    <div>
                      <Checkbox
                        value={studentFilter?.value}
                        className="w-6"
                        checked={isSelected(status, studentFilter?.value)}
                      />
                    </div>
                  </div>
                ))}

                <Button
                  className="w-full h-14 mt-8 font-semibold"
                  onClick={onCloseFilter}
                >
                  View Projects
                </Button>

                <Button
                  variant="secondary"
                  className="w-full h-14 mt-4 font-semibold"
                  onClick={onCloseFilter}
                >
                  Close
                </Button>
              </div>
            )}
          </div>
        </div>
      )}

      {user?.accountType === AccountType.BUSINESS && (
        <div className="p-4 hidden lg:block">
          {BusinessFilters.map((businessFilter, index: number) => (
            <div
              key={businessFilter?.value}
              className={`border-[1px] border-[#0B6FFF] rounded-md flex justify-between items-center px-4 py-1 ${
                index === 0 ? "" : "my-4"
              } cursor-pointer`}
              onClick={() => onChange(businessFilter?.value, BusinessFilters)}
            >
              {!businessFilter?.value && (
                <div className="text-rw-blue text-sm font-semibold">
                  {businessFilter?.label}
                </div>
              )}

              {[ProjectStatus.ACTIVE, ProjectStatus.IN_ACTIVE].includes(
                businessFilter?.value
              ) && (
                <div className="text-rw-blue text-sm font-semibold bg-rw-blue-light rounded-md px-2 py-1">
                  {businessFilter?.label}
                </div>
              )}

              {businessFilter?.value === ProjectStatus.IN_PROGRESS && (
                <div className="text-rw-green text-sm font-semibold bg-rw-green-light rounded-md px-2 py-1">
                  {businessFilter?.label}
                </div>
              )}

              {businessFilter?.value === ProjectStatus.FINISHED && (
                <div className="text-rw-red text-sm font-semibold bg-rw-red-light rounded-md px-2 py-1">
                  {businessFilter?.label}
                </div>
              )}

              {businessFilter?.value === ProjectStatus.DRAFT && (
                <div className="text-rw-yellow-dark text-sm font-semibold bg-rw-yellow-lightest rounded-md px-2 py-1">
                  {businessFilter?.label}
                </div>
              )}

              <div>
                <Checkbox
                  value={businessFilter?.value}
                  className="w-6"
                  checked={isSelected(status, businessFilter?.value)}
                />
              </div>
            </div>
          ))}
        </div>
      )}

      {user?.accountType === AccountType.STUDENT && (
        <div className="p-4 hidden lg:block">
          {StudentFilters.map((studentFilter) => (
            <div
              key={studentFilter?.value}
              className="border-[1px] border-[#0B6FFF] rounded-md flex justify-between items-center px-4 py-2 my-4 cursor-pointer"
              onClick={() => onChange(studentFilter?.value, StudentFilters)}
            >
              {!studentFilter?.value && (
                <div className="text-rw-blue text-sm font-semibold">
                  {studentFilter?.label}
                </div>
              )}

              {studentFilter?.value === StudentProjectStatus.APPLIED && (
                <div className="text-rw-blue text-sm font-semibold bg-rw-blue-light rounded-md px-2 py-1">
                  {studentFilter?.label}
                </div>
              )}

              {studentFilter?.value ===
                StudentProjectStatus.REQUESTED_TO_INTERVIEW && (
                <div className="text-rw-yellow-dark text-sm font-semibold bg-rw-yellow-lightest rounded-md px-2 py-1">
                  {studentFilter?.label}
                </div>
              )}

              {studentFilter?.value === StudentProjectStatus.IN_PROGRESS && (
                <div className="text-rw-green text-sm font-semibold bg-rw-green-light rounded-md px-2 py-1">
                  {studentFilter?.label}
                </div>
              )}

              {studentFilter?.value === StudentProjectStatus.FINISHED && (
                <div className="text-rw-red text-sm font-semibold bg-rw-red-light rounded-md px-2 py-1">
                  {studentFilter?.label}
                </div>
              )}

              <div>
                <Checkbox
                  value={studentFilter?.value}
                  className="w-6"
                  checked={isSelected(status, studentFilter?.value)}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
