import React from "react";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { wrapFullName } from "utils/common";

type Props = {
  isShow: boolean;
  selectedPayment: any;
  onHide: () => void;
};
const NotesModal = ({ isShow, selectedPayment, onHide }: Props) => {
  return (
    <Modal show={isShow} onHide={onHide} centered>
      <div className="modal-content p-3 p-md-4 border_r_16px overflow-hidden">
        <div className="modal-body p-0 mt-0">
          <p className="fw-bold fs-4 text-start text_black lh-sm mb-1 text-center">
            Notes
          </p>
          <p className="fs-6 text_primary text-start mb-1 text-center">
            {selectedPayment?.business?.name}
          </p>
          <p className="fs-6 text_primary text-start mb-1 text-center">
            {wrapFullName(
              selectedPayment?.student?.firstName,
              selectedPayment?.student?.lastName
            )}
          </p>
          <p className="fs-6 text_primary text-start mb-1 text-center">
            {moment(selectedPayment?.paidAt).format("MM/DD")}
          </p>
          <p className="fs-6 text_primary text-start my-2 text-center">
            {selectedPayment?.note || ""}
            {/* We paid the student on{" "}
            {moment(selectedPayment?.paidAt).format("M/DD/YYYY")} because the
            business was late on their payment. */}
          </p>

          <button
            type="button"
            className="btn bg_light_secondary text_secondary w-100 mt-2"
            onClick={onHide}
          >
            Back
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default NotesModal;
