import React from "react";
import { Link } from "react-router-dom";
import humanize from "humanize-string";
import Checkbox from "@mui/material/Checkbox";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Button from "components/Button";
import UserDetails from "components/UserDetails";
import Images from "assets/images";
import { AccountType } from "utils/constants";

type Props = {
  user: any;
};

const BlogType = {
  EMPLOYERS: "EMPLOYERS",
  STUDENTS: "STUDENTS",
};

const Blogs: any = {
  "How to Hire Georgia Tech Students": {
    text: "Internships serve as a vital steppingstone for students, bridging the gap between academic learning and real-world experience...",
    image: Images.HowToHireGeorgiaTechStudents,
    tips: "Business Tips",
    link: "/community/blogs/how-to-hire-georgia-tech-students",
    type: BlogType.EMPLOYERS,
    featured: true,
  },
  "How to Hire Emory Students": {
    text: "Internships are critical in bridging the gap between academic theory and practical, real-world experience...",
    image: Images.HowToHireEmoryStudents,
    tips: "Business Tips",
    link: "/community/blogs/how-to-hire-emory-students",
    type: BlogType.EMPLOYERS,
    featured: true,
  },
  "The Best Freelance Jobs for College Students": {
    text: "College life is often hectic, with students juggling classes, internships, work, health, and social activities. In this fast-paced...",
    image: Images.TheBestFreelanceJobs,
    tips: "Freelancer Tips",
    link: "/community/blogs/the-best-freelance-jobs-for-college-students",
    type: BlogType.STUDENTS,
  },
  "Why Would I Hire a College Student as a Freelancer?": {
    text: "Startups and small businesses have a lot to gain by hiring college students as freelancers. These young, ambitious individuals are...",
    image: Images.WhyWouldIHire,
    tips: "Business Tips",
    link: "/community/blogs/why-would-i-hire-a-college-student-as-a-freelancer",
    type: BlogType.EMPLOYERS,
  },
  "How To Use HubSpot to Get Qualified Leads": {
    text: "HubSpot Sales Professional is a powerful sales automation tool that can help you streamline your sales process and improve your lead...",
    image: Images.HowToUseHubspot,
    tips: "Freelancer Tips",
    link: "/community/blogs/how-to-use-hubspot-to-get-qualified-leads",
    type: BlogType.STUDENTS,
  },
  "How to Use Linkedin Sales Navigator to Find Target Customers": {
    text: "LinkedIn Sales Navigator is a powerful tool for businesses looking to generate qualified leads. With Sales Navigator, you can easily...",
    image: Images.HowToUseLinkedIn,
    tips: "Business Tips",
    link: "/community/blogs/how-to-use-linkedin-sales-navigator-to-find-target-customers",
    type: BlogType.EMPLOYERS,
  },
  "Changing the Narrative: 4 Ways Interns Will Transform Your Startup": {
    text: "As an ambitious entrepreneur, you're constantly juggling multiple responsibilities to grow your startup. We understand that you might...",
    image: Images.ChangingTheNarrative,
    tips: "Business Tips",
    link: "/community/blogs/changing-the-narrative-4-ways-interns-will-transform-your-startup",
    type: BlogType.EMPLOYERS,
  },
  "The 3 Biggest Talent Acquisition Mistakes Startups Are Making in 2023": {
    text: "If you're a visionary, startup founder who is tirelessly building your business and balancing on the thin tightrope between success and...",
    image: Images.TheThreeBiggest,
    tips: "Business Tips",
    link: "/community/blogs/the-3-biggest-talent-acquisition-mistakes-startups-are-marking-in-2023",
    type: BlogType.EMPLOYERS,
  },
  "The Hidden Risks of DIY Intern Recruiting and How it Can Negatively Impact Your Business":
    {
      text: "As an ambitious entrepreneur or small business owner, you are constantly on the lookout for ways to grow your startup without breaking a...",
      image: Images.TheHiddenRisks,
      tips: "Business Tips",
      link: "/community/blogs/the-hidden-risks-of-diy-intern-recruting-and-how-it-can-negatively-impact-your-business",
      type: BlogType.EMPLOYERS,
    },
  "What You Need To Know Before Starting Your Talent Acquisition Journey": {
    text: "In the highly competitive world of startups, one thing is crystal clear – those who swiftly acquire exceptional talent at an affordable rate...",
    image: Images.WhatYouNeed,
    tips: "Business Tips",
    link: "/community/blogs/what-you-need-to-know-before-starting-your-talent-acquisition-journey",
    type: BlogType.EMPLOYERS,
  },
};

const featuredBlogs = () => {
  const featured: any = {};

  Object.keys(Blogs).forEach((key) => {
    if (Blogs[key].featured) {
      featured[key] = Blogs[key];
    }
  });

  return featured;
};

const Tabs = ["All Articles", "For Students", "For Employers"];

export default function CommunityContainer({ user }: Props) {
  const [selectedTab, setSelectedTab] = React.useState(
    user.accountType === AccountType.STUDENT
      ? Tabs[1]
      : user.accountType === AccountType.BUSINESS
      ? Tabs[2]
      : Tabs[0]
  );

  const [blogs, setBlogs] = React.useState(Blogs);

  const [showFilterMenu, setShowFilterMenu] = React.useState(false);

  const [search, setSearch] = React.useState("");

  const handleOpenFilter = () => {
    setShowFilterMenu(true);
  };

  const handleCloseFilter = () => {
    setShowFilterMenu(false);
  };

  const handleTabChange = (tab: string) => {
    let filteredBlogs: any = {};

    if (tab === Tabs[1]) {
      Object.keys(Blogs).forEach((key) => {
        if (Blogs[key].type === BlogType.STUDENTS) {
          filteredBlogs[key] = Blogs[key];
        }
      });
    } else if (tab === Tabs[2]) {
      Object.keys(Blogs).forEach((key) => {
        if (Blogs[key].type === BlogType.EMPLOYERS) {
          filteredBlogs[key] = Blogs[key];
        }
      });
    } else {
      filteredBlogs = Blogs;
    }

    setSelectedTab(tab);
    setBlogs(filteredBlogs);
  };

  const handleSearch = (event: any) => {
    setSearch(event.target.value);

    const filteredBlogs: any = {};

    Object.keys(Blogs).forEach((key) => {
      if (
        String(Blogs[key].text)
          .toLowerCase()
          .includes(String(event.target.value).toLowerCase()) ||
        String(Blogs[key])
          .toLowerCase()
          .includes(String(event.target.value).toLowerCase())
      ) {
        filteredBlogs[key] = Blogs[key];
      }
    });

    setBlogs(filteredBlogs);
  };

  return (
    <div className="flex">
      <div className="w-full max-w-[300px] min-h-screen hidden lg:flex flex-col justify-between items-center border-l-[1px] border-r-[1px] border-[#0000001A]">
        <div className="w-full">
          <div className="h-16">
            <div className="text-2xl font-semibold p-4">Runway Community</div>
          </div>

          <hr />

          <div>
            {!showFilterMenu && (
              <button
                className="w-full lg:hidden border-[1px] border-[#E3E3E3] rounded-md flex justify-center items-center py-3 my-8 bg-white"
                onClick={handleOpenFilter}
              >
                <span className="text-rw-blue">
                  <TuneOutlinedIcon />
                  <span className="ml-2 font-medium">Filter</span>
                </span>
                <span className="text-rw-gray ml-2">(1)</span>
              </button>
            )}

            {showFilterMenu && (
              <div className="w-full mt-4 block lg:hidden">
                <div className="text-xl px-4">
                  <span className="font-semibold">Filter</span>
                </div>

                <hr className="my-4" />

                <div className="flex justify-center">
                  <div className="w-11/12">
                    {Tabs.map((tab) => (
                      <div
                        key={tab}
                        className="border-[1px] border-[#0B6FFF] rounded-md flex justify-between items-center px-4 py-2 my-4 cursor-pointer"
                        onClick={() => handleTabChange(tab)}
                      >
                        <div className="text-rw-blue text-sm font-semibold">
                          {tab}
                        </div>

                        <div>
                          <Checkbox
                            value={tab}
                            className="w-6"
                            checked={selectedTab === tab}
                          />
                        </div>
                      </div>
                    ))}

                    <Button
                      variant="secondary"
                      className="w-full h-14 mt-4 font-semibold"
                      onClick={handleCloseFilter}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </div>
            )}

            <div className="px-4 hidden lg:block">
              {Tabs.map((tab) => (
                <div
                  key={tab}
                  className="border-[1px] border-[#0B6FFF] rounded-md flex justify-between items-center px-4 py-1 my-4 cursor-pointer"
                  onClick={() => handleTabChange(tab)}
                >
                  <div className="text-rw-blue text-sm font-semibold">
                    {tab}
                  </div>

                  <div>
                    <Checkbox
                      value={tab}
                      className="w-6"
                      checked={selectedTab === tab}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full mb-8">
        <div className="hidden lg:flex justify-between items-center px-4 h-16">
          <div className="w-1/3">
            <div className="border-[1px] border-[#E3E3E3] p-1 flex justify-center items-center gap-2 rounded-full pl-2">
              <SearchOutlinedIcon />
              <input
                type="text"
                value={search}
                onChange={handleSearch}
                placeholder="Search Articles"
                className="w-full placeholder:font-medium placeholder:text-[#C7C7C7] placeholder:text-sm h-8"
              />
            </div>
          </div>

          {user?._id && <UserDetails user={user} />}
        </div>

        <hr />

        <div className="flex justify-center min-h-screen">
          <div className="px-4">
            <div className="h-16 py-4">
              <div className="text-2xl font-semibold">Runway Community</div>
            </div>

            {/* mobile filters */}
            <div className="block lg:hidden">
              <div>
                {!showFilterMenu && (
                  <button
                    className="w-full lg:hidden border-[1px] border-[#E3E3E3] rounded-md flex justify-center items-center py-3 my-4 bg-white"
                    onClick={handleOpenFilter}
                  >
                    <span className="text-rw-blue">
                      <TuneOutlinedIcon />
                      <span className="ml-2 font-medium">Filter</span>
                    </span>
                    <span className="text-rw-gray ml-2">(1)</span>
                  </button>
                )}

                {showFilterMenu && (
                  <div className="w-full mt-4 block lg:hidden">
                    <div className="text-xl px-4">
                      <span className="font-semibold">Filter</span>
                    </div>

                    <hr className="my-4" />

                    <div className="flex justify-center">
                      <div className="w-11/12">
                        {Tabs.map((tab) => (
                          <div
                            key={tab}
                            className="border-[1px] border-[#0B6FFF] rounded-md flex justify-between items-center px-4 py-2 my-4 cursor-pointer"
                            onClick={() => handleTabChange(tab)}
                          >
                            <div className="text-rw-blue text-sm font-semibold">
                              {tab}
                            </div>

                            <div>
                              <Checkbox
                                value={tab}
                                className="w-6"
                                checked={selectedTab === tab}
                              />
                            </div>
                          </div>
                        ))}

                        <Button
                          variant="secondary"
                          className="w-full h-14 mt-4 font-semibold"
                          onClick={handleCloseFilter}
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="p-4 hidden lg:block">
                  {Tabs.map((tab) => (
                    <div
                      key={tab}
                      className="border-[1px] border-[#0B6FFF] rounded-md flex justify-between items-center px-4 py-1 my-4 cursor-pointer"
                      onClick={() => handleTabChange(tab)}
                    >
                      <div className="text-rw-blue text-sm font-semibold">
                        {tab}
                      </div>

                      <div>
                        <Checkbox
                          value={tab}
                          className="w-6"
                          checked={selectedTab === tab}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="flex flex-col py-4">
              {selectedTab === Tabs[0] && !search && (
                <div>
                  <div className="text-xl font-semibold text-rw-dark-grey">
                    FEATURED
                  </div>

                  <div className="flex flex-col lg:flex-row gap-4 mt-4">
                    {Object.keys(featuredBlogs()).map((blogKey: string) => (
                      <div
                        className="flex flex-col lg:flex-row lg:w-1/2 border rounded-md"
                        key={blogKey}
                      >
                        <a href={Blogs[blogKey].link}>
                          <img
                            src={Blogs[blogKey]?.image}
                            className="w-full lg:w-0 lg:min-w-[250px] h-full"
                            width={100}
                            height={100}
                            alt=""
                          />
                        </a>

                        <div className="flex flex-col justify-between p-4">
                          <Link
                            to={Blogs[blogKey].link}
                            className="font-semibold text-xl break-words text-[#21383E]"
                          >
                            {blogKey}
                          </Link>

                          <div className="mt-5 font-semibold text-xs mb-1 text-rw-baby-blue-dark bg-rw-blue-lightest rounded-md w-fit px-2 py-1">
                            For {humanize(Blogs[blogKey].type)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <hr className="my-5" />
                </div>
              )}

              <div className="text-xl font-semibold text-rw-dark-grey">
                {String(selectedTab).toUpperCase()}
              </div>

              <hr className="my-5" />

              <div className="flex flex-wrap gap-10">
                {Object.keys(blogs).map((blogKey: string) => (
                  <div className="lg:w-80" key={blogKey}>
                    <a href={Blogs[blogKey].link}>
                      <img
                        src={Blogs[blogKey]?.image}
                        className="w-[100%]"
                        width={50}
                        height={50}
                        alt=""
                      />
                    </a>

                    <div className="mt-5 font-semibold text-xs mb-1 text-rw-baby-blue-dark">
                      For {humanize(Blogs[blogKey].type)}
                    </div>

                    <Link
                      to={Blogs[blogKey].link}
                      className="font-semibold text-xl break-words text-[#21383E]"
                    >
                      {blogKey}
                    </Link>

                    <div className="text-sm break-words text-[#7B7D84] mt-2">
                      {Blogs[blogKey].text}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
