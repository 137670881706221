import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { FormattedNumber } from "react-intl";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import { useMergeState } from "utils/custom-hooks";
import {
  calculateProjectCost,
  getStudentHourlyRate,
  shouldShowPremiumFeatures,
} from "utils/common";
import { BudgetType, HourlyRate, ProjectDurationType } from "utils/constants";

const getSuggestedRate = (studentType: string) => HourlyRate[studentType];

const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 2,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 36,
    width: 36,
    backgroundColor: "#fff",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active": {
      boxShadow:
        "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

const getQuoteTitle = (budgetType: string, editable: boolean) => {
  if (!editable) {
    return "Quote Summary";
  }

  if (budgetType === BudgetType.FULL_TIME) {
    return "Full Time Role";
  }

  if (budgetType === BudgetType.RETAINER) {
    return "Retainer Summary";
  }

  if (budgetType === BudgetType.HOURLY_RATE) {
    return "Hourly Rate Summary";
  }
};

type Props = {
  user: any;
  isShow: boolean;
  hourlyRate: number;
  project: any;
  handleChangeCapHour?: any;
  handleChangeHoursPerWeek?: any;
  handleChangeHourlyRate?: any;
  handleEditProject?: any;
  onHide: () => void;
};
const EditRateModal = ({
  user,
  isShow,
  hourlyRate,
  project,
  handleChangeCapHour = () => {},
  handleChangeHoursPerWeek = () => {},
  handleChangeHourlyRate = () => {},
  handleEditProject = () => {},
  onHide,
}: Props) => {
  const [isDisableContinueBtn, setIsDisableContinueBtn] = useState(false);
  const {
    studentCompensation,
    platformFee,
    totalCost,
    discountAmount = 0,
  } = calculateProjectCost({
    hourlyRate: project?.hourlyRate,
    hoursPerWeek: project?.hoursPerWeek,
    duration: project?.duration,
    durationType: project?.durationType,
    retainerAmount: project?.retainerAmount,
    budgetType: project?.budgetType,
    promo: project?.promo,
  });
  const isCapRate = project?.isCapRate ? true : false;
  return (
    <Modal show={isShow} onHide={onHide} centered>
      <div className="modal-content p-3 border_r_16px">
        <div className="modal-body mt-0">
          <div className="text-3xl text-center text_black fw-bold">
            Edit Budget
          </div>

          <div className="mt-8">
            <div className="flex justify-between">
              <div>
                <div className="text-xl text-rw-black-dark fw-bold lh-sm">
                  Hourly Rate
                </div>
                {shouldShowPremiumFeatures(user) && (
                  <div className="text-xs font-semibold text-[#00000040]">
                    Suggested <span>${getSuggestedRate(project?.type)}</span>
                    /HR
                  </div>
                )}
              </div>

              <div className="text-right">
                <div className="text-xl font-bold text-rw-blue">
                  ${project?.hourlyRate}/HR
                </div>
                {shouldShowPremiumFeatures(user) && (
                  <div className="text-xs font-semibold bg-rw-blue-lightest rounded-full px-2 py-1">
                    <span className="text-[#00000080]">
                      Student Compensation:
                    </span>{" "}
                    <span className="text-rw-blue">
                      $
                      {getStudentHourlyRate(
                        project?.hourlyRate,
                        shouldShowPremiumFeatures(user),
                        project?.promo
                      )}
                      /HR
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex items-center gap-2 mt-4">
              <span className="text-[#D9D9D9] text-xs font-semibold">$15</span>
              <IOSSlider
                min={15}
                max={50}
                value={project?.hourlyRate}
                onChange={handleChangeHourlyRate}
              />
              <span className="text-[#D9D9D9] text-xs font-semibold">$50</span>
            </div>
          </div>

          {shouldShowPremiumFeatures(user) && (
            <>
              <div className="mt-8">
                <div className="flex justify-between">
                  <div className="text-xl font-bold text-rw-black-dark">
                    Hours Per Week
                  </div>

                  <div className="text-xl font-bold text-rw-blue">
                    {project?.hoursPerWeek} Hours
                  </div>
                </div>

                <div className="flex items-center gap-2 mt-4">
                  <span className="text-[#D9D9D9] text-xs font-semibold">
                    5
                  </span>
                  <IOSSlider
                    min={5}
                    max={40}
                    value={project?.hoursPerWeek}
                    onChange={handleChangeHoursPerWeek}
                  />
                  <span className="text-[#D9D9D9] text-xs font-semibold">
                    40
                  </span>
                </div>
              </div>

              <div
                className="border_r_8px border_gray border p-2 flex items-center my-4 mt-4 cursor-pointer"
                onClick={() => handleChangeCapHour(!isCapRate)}
              >
                <div className="w-full flex justify-between items-center">
                  <div className="px-3">
                    <div className="rw-gray-0 text-lg font-semibold">
                      Cap Timesheet Hours
                    </div>

                    <div className="text-xs font-medium text-rw-gray-75">
                      Prevent student from entering more than selected budget
                    </div>
                  </div>
                  <input
                    className="form-check-input mt-0 me-2"
                    type="checkbox"
                    checked={isCapRate}
                  />

                  {/* <div>
                          <Radio
                            className="border-[#D7DBE1]"
                            checked={isCapRate}
                          />
                        </div> */}
                </div>
              </div>

              <div className="border border_gray my-4 dashed" />
              <div className="text-rw-black-dark font-bold text-xl">
                {getQuoteTitle(project.budgetType, false)}
              </div>
              <div>
                <div className="bg-rw-blue-lightest rounded-md border_r_8px p-4 mt-4">
                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-rw-black-slight-dark font-semibold ">
                        Student Compensation
                      </div>

                      <div className="font-semibold text-rw-black-dark">
                        <FormattedNumber
                          value={studentCompensation}
                          style="currency"
                          currency="USD"
                        />
                      </div>
                    </div>

                    <div className="mt-2 text-sm text-rw-black-slight-dark">
                      {getStudentHourlyRate(hourlyRate, false!, project.promo)}
                      /hr x {project?.hoursPerWeek} hrs{" "}
                      {project?.durationType === ProjectDurationType.PERIOD &&
                        `x ${project.duration} Weeks`}
                    </div>
                  </div>

                  <div className="mt-4">
                    <div className="flex justify-between items-center">
                      <div className="text-rw-black-slight-dark font-semibold ">
                        Platform Fee
                      </div>

                      <div className="font-semibold text-rw-black-dark">
                        <FormattedNumber
                          value={platformFee}
                          style="currency"
                          currency="USD"
                        />
                      </div>
                    </div>

                    <div className="mt-2 text-sm text-rw-black-slight-dark">
                      20% is taken from the student&apos;s rate to support the
                      platform
                    </div>
                  </div>

                  <hr className="my-3" />

                  <div>
                    <div className="flex justify-between items-center">
                      <div className="text-rw-black-slight-dark font-semibold text-lg">
                        Estimated Total
                      </div>

                      <div className="font-semibold text-rw-black-dark text-lg">
                        <FormattedNumber
                          value={totalCost}
                          style="currency"
                          currency="USD"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="modal-footer border-0">
          <button
            disabled={isDisableContinueBtn}
            type="button"
            className={`btn text_white w-100 ${
              isDisableContinueBtn ? "bg-rw-disable" : "bg_secondary"
            }`}
            onClick={async () => {
              setIsDisableContinueBtn(true);
              await handleEditProject();
              setIsDisableContinueBtn(false);
              onHide();
            }}
          >
            Continue
          </button>
          <button
            type="button"
            className="btn bg_light_secondary text_secondary w-100"
            onClick={onHide}
          >
            Back
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditRateModal;
