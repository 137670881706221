import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import DialogContent from "@mui/material/DialogContent";
import { Dialog } from "components/Dialog";
import TextField from "components/TextField";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import { RUNWAY_TOKEN } from "utils/constants";
import { validateEmail } from "utils/string";
import { signin } from "api";
import * as amplitude from "@amplitude/analytics-browser";

type Props = {
  open: boolean;
  onClose: any;
  setUser: (user: any) => void;
};

export default function LoginDialog({ open, onClose, setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    email: "",
    password: "",

    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const handleGetStarted = () => {
    amplitude.logEvent("Student Projects Login");
    navigate("/signup");
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (!validateEmail(state?.email)) {
      isValid = false;
      errors = { ...errors, email: "Email is invalid." };
    }

    if (!state?.password) {
      errors = { ...errors, password: "Password is required." };
      isValid = false;
    }

    setState({ errors });

    return isValid;
  };

  const handleSignin = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      const response = await signin({
        email: state?.email,
        password: state?.password,
      });

      if (response?.success) {
        localStorage.setItem(RUNWAY_TOKEN, response?.data?.token);

        setUser(response?.data?.user);

        onClose();
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: 400,
        },
        "& .MuiDialogContent-root": {
          padding: 3,
        },
      }}
    >
      <DialogContent>
        <div>
          <div className="flex justify-center items-center">
            <div className="text-3xl font-bold">Login to Apply</div>
          </div>

          <div className="flex justify-center mt-10">
            <div className="w-full lg:w-11/12">
              <div className="mt-4">
                <TextField
                  type="text"
                  name="email"
                  placeholder="Email address"
                  value={state?.email}
                  onChange={handleChange}
                  error={state?.errors?.email}
                />
              </div>

              <div className="mt-4">
                <TextField
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={state?.password}
                  onChange={handleChange}
                  error={state?.errors?.password}
                />
              </div>

              <Button
                className="font-semibold w-full h-12 mt-8"
                onClick={handleSignin}
              >
                Continue
              </Button>

              <div className="flex justify-center gap-4 text-sm mt-8">
                <div>Forgot Password?</div>
                <Link to="/reset-password" className="text-rw-blue underline">
                  Reset Password
                </Link>
              </div>

              <hr className="my-8" />

              <div className="text-2xl font-bold text-center">
                New To Runway?
              </div>

              <Button
                variant="secondary"
                className="w-full h-12 font-semibold mt-8"
                onClick={handleGetStarted}
              >
                Get Started
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
