import SocialLinks from "../SocialLinks";
import Images from "assets/images";

export default function WhyWouldIHire() {
  return (
    <main className="w-full min-h-screen">
      <div className="my-8">
        <div className="flex justify-center font-semibold mt-9 mb-3 text-xs text-rw-baby-blue-dark break-words">
          For Employers
        </div>
        <div className="text-zinc-700 text-3xl md:text-4xl font-semibold flex justify-center text-center mb-5">
          <div className="w-11/12 lg:w-3/5">
            Why Would I Hire a College Student as A Freelancer?
          </div>
        </div>
        <div className="flex gap-4 justify-center mb-7 flex-wrap">
          <div className="text-zinc-600 font-semibold">By Runway Team</div>
          <div>|</div> <div className="text-zinc-600">April 19th, 2023</div>
        </div>
        <div className="flex justify-center mb-10">
          <img
            src={Images.WhyWouldIHire}
            className="w-11/12 lg:w-3/5"
            width={50}
            height={50}
            alt=""
          />
        </div>
        <div className="flex justify-center">
          <div className="w-11/12 lg:w-3/5">
            <div className="text-zinc-600 md:text-lg leading-7">
              Startups and small businesses have a lot to gain by hiring college
              students as freelancers. These young, ambitious individuals are
              often eager to build their professional portfolios and gain
              practical experience while still pursuing their education.
            </div>
            <div className="text-zinc-600 md:text-lg leading-loose">
              <br />
            </div>
            <div className="text-zinc-600 text-xl md:text-2xl font-semibold leading-loose">
              How College Students Can Benefit Your Business
              <br />
            </div>
            <div className="text-zinc-600 md:text-lgleading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Cost-effectiveness:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Startups and small businesses are often operating on tight
              budgets, which can make it challenging to hire full-time employees
              with the necessary skills and experience. Hiring college students
              as freelancers can be a cost-effective solution, as they are
              typically willing to work for lower rates in exchange for the
              opportunity to gain experience and build their resumes.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Fresh perspectives:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              College students are often brimming with fresh ideas and
              perspectives that can benefit your business. They bring a unique
              perspective to the table, having grown up in the digital age and
              being adept at using technology and social media. Their innovative
              ideas and perspectives can help your business stay relevant and
              competitive in an ever-changing market.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Personal Touch:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Working with a college student provides a personal touch and
              allows for a more collaborative experience. Unlike platforms such
              as Fiverr and Upwork, working with a college student allows for a
              more customized and tailored approach to the project at hand
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Flexibility:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              College students are typically more flexible in terms of their
              availability, making them a great option for startups and small
              businesses that need help on a project basis. They are often
              willing to work around their class schedules and can be available
              for evening and weekend work when needed.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Specialized Skill:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Many college students are pursuing degrees in specialized fields
              such as marketing, graphic design, and computer science. Hiring
              these students as freelancers can give your business access to
              specialized skills and expertise that may be too expensive or
              impractical to hire full-time.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg font-bold leading-7">
              Future Employees:
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              Hiring college students as freelancers can also be a great way to
              identify future employees for your business. If a student proves
              to be reliable, skilled, and a good fit for your company culture,
              you may be able to offer them a full-time job after they graduate.
              <br />
            </div>
            <div className="text-zinc-600 md:text-lg leading-7">
              <br />
              When hiring college students as freelancers, it&apos;s essential
              to set clear expectations and communicate effectively to ensure
              that both parties are on the same page. Providing clear
              instructions, setting deadlines, and providing feedback can help
              ensure that the project is completed successfully.
              <br />
              <br />
              In conclusion, startups and small businesses can benefit greatly
              from hiring college students as freelancers. They bring fresh
              perspectives, specialized skills, and cost-effectiveness to the
              table, making them an excellent option for businesses looking to
              grow and succeed. By providing college students with real-world
              experience and practical skills, businesses can help build the
              next generation of talented professionals.
            </div>
            <div className="bg-zinc-600 mb-5 h-0.5 mt-20" />
            <div className="text-center text-zinc-600 text-2xl font-semibold">
              Share Article
            </div>
            <SocialLinks
              text="Why Would I Hire a College Student as A Freelancer?"
              url="https://www.joinrunway.io/blogs/why-would-i-hire-a-college-student-as-a-freelancer/"
            />
          </div>
        </div>
      </div>
    </main>
  );
}
