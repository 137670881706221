import { Helmet } from "react-helmet";
import ProjectsListCareerFairInternalContainer from "./internal";
import ProjectsListCareerFairExternalContainer from "./external";

type Props = {
  isLoggedIn: boolean;
  user: any;
};

export default function ProjectsListCareerFairContainer({
  isLoggedIn,
  user,
}: Props) {
  return (
    <div>
      <Helmet>
        <meta
          name="title"
          content="Runway Projects: Exclusive Internship & Project Opportunities for Top Students"
        />
        <meta
          name="description"
          content="Browse a curated list of unique projects and internship opportunities on Runway, featuring business, software development, design and engineering projects."
        />
        <meta
          name="keywords"
          content="Student intern projects, internship opportunities, software development internships, engineering internships, design internships, elite university talent, project-based hiring, part time college jobs, part-time internships, joinrunway, industry collaboration, college jobs"
        />
      </Helmet>

      {isLoggedIn ? (
        <ProjectsListCareerFairInternalContainer user={user} />
      ) : (
        <ProjectsListCareerFairExternalContainer />
      )}
    </div>
  );
}
