import * as amplitude from "@amplitude/analytics-browser";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import TextField from "components/TextField";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import {
  ATVExamples,
  ATVOptInBullets,
  AccountType,
  RUNWAY_TOKEN,
} from "utils/constants";
import { validateEmail } from "utils/string";
import { validatePhone } from "utils/common";
import { signup } from "api";
import Images from "assets/images";
import { Radio } from "@mui/material";
import { useState } from "react";

const StepLabels = {
  ATV: "TYPE",
  LOGIN: "LOGIN",
  CONFIRM: "CONFIRM",
};

const Steps = [
  // {
  //   id: 1,
  //   label: StepLabels.ATV,
  //   title: "Type",
  // },
  {
    id: 1,
    label: StepLabels.LOGIN,
    title: "Profile Details",
  },
  {
    id: 2,
    label: StepLabels.CONFIRM,
    title: "Confirm Account",
  },
];

type Props = {
  setUser: any;
};

export default function BusinessATVSignUpContainer({ setUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    currentStep: Steps[0],

    studentType: "",

    // login details
    email: "",
    password: "",
    confirmPassword: "",

    // profile details
    firstName: "",
    lastName: "",
    companyName: "",
    phone: "",

    atv: {
      isATV: false,
      optedIn: null,
    },
    isDisableBtn: false,
    errors: {},
  });
  const [toggleATVOptIn, setToggleATVOptIn] = useState<boolean>();
  const handleChange = (event: any) => {
    setState({
      [event?.target?.name]: event?.target?.value,
      errors: {
        ...state?.errors,
        [event?.target?.name]: "",
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let errors = {};

    if (state?.currentStep?.label === StepLabels.ATV && !toggleATVOptIn) {
      if (state?.atv?.isATV === null) {
        isValid = false;
        errors = { ...errors, email: "Select a radio." };
      }
    }

    if (state?.currentStep?.label === StepLabels.ATV && toggleATVOptIn) {
      if (state?.atv?.optedIn === null) {
        isValid = false;
        errors = { ...errors, email: "Select a radio." };
      }
    }

    if (state?.currentStep?.label === StepLabels.LOGIN) {
      if (!validateEmail(state?.email)) {
        isValid = false;
        errors = { ...errors, email: "Email is invalid." };
      }

      if (!state?.password) {
        errors = { ...errors, password: "Password is required." };
        isValid = false;
      }

      if (!state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Confirm password is required.",
        };
        isValid = false;
      }

      if (state?.password !== state?.confirmPassword) {
        errors = {
          ...errors,
          confirmPassword: "Passwords do not match.",
        };
        isValid = false;
      }
      if (!state?.firstName) {
        errors = { ...errors, firstName: "First Name is required." };
        isValid = false;
      }

      if (!state?.lastName) {
        errors = { ...errors, lastName: "Last Name is required." };
        isValid = false;
      }

      if (!state?.companyName) {
        errors = { ...errors, companyName: "Company Name is required." };
        isValid = false;
      }

      if (!validatePhone(state?.phone)) {
        errors = { ...errors, phone: "Phone is invalid." };
        isValid = false;
      }
    }

    setState({ errors });

    return isValid;
  };

  const getNextStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) + 1];

  const getPrevStep = () =>
    Steps[Steps.findIndex((elem) => elem?.id === state.currentStep.id) - 1];

  const handleContinue = () => {
    if (!isFormValid()) {
      return;
    }
    if (state?.currentStep?.id === 1 && state?.atv?.isATV && !toggleATVOptIn) {
      setToggleATVOptIn(true);
      return;
    }
    const nextStep = getNextStep();
    if (state?.currentStep?.id === 1 && !state?.atv?.isATV) {
      setState({
        ...state,
        currentStep: nextStep,
        atv: { ...state.atv, optedIn: null },
      });
    } else {
      setState({ ...state, currentStep: nextStep });
    }
  };

  const handleBack = () => {
    if (state?.currentStep?.id === 1 && !toggleATVOptIn) {
      navigate(-1);
    } else if (state?.currentStep?.id === 1 && toggleATVOptIn) {
      setToggleATVOptIn(false);
    } else {
      const prevStep = getPrevStep();

      setState({ currentStep: prevStep });
    }
  };

  const handleEditStep = (stepLabel: string) => {
    setState({ currentStep: Steps.find((step) => step?.label === stepLabel) });
  };

  const handleBusinessSignup = async () => {
    try {
      const payload = {
        accountType: AccountType.BUSINESS,
        firstName: state?.firstName,
        lastName: state?.lastName,
        email: String(state?.email).trim().toLowerCase(),
        password: state?.password,
        confirmPassword: state?.confirmPassword,
        phone: state?.phone,
        companyName: state?.companyName,
        atv: state.atv,
      };
      setState({ isDisableBtn: true });
      const response = await signup(payload);

      setState({ isDisableBtn: false });
      if (response?.success) {
        localStorage.setItem(RUNWAY_TOKEN, response?.data?.token);
        setUser(response?.data?.user);

        amplitude.track("Business Sign Up");
        amplitude.setUserId(response?.data?.user?._id);

        navigate("/projects");
      } else {
        setState({ isDisableBtn: false });
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (error: any) {
      setState({ isDisableBtn: false });
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  // const toggleATVFlow = (isATV: boolean) => {
  //   console.log("isATV ----", isATV);
  //   setState({ ...state, atv: { ...state.atv, isATV } });
  // };

  // const toggleOptIn = (optedIn: boolean) => {
  //   setState({ ...state, atv: { ...state.atv, optedIn } });
  // };

  return (
    <div>
      <div className="h-fit flex flex-col lg:flex-row items-center bg-rw-blue-lightest relative">
        <div className="flex justify-center items-center left-[4.17%] lg:left-[8.38%] py-4 lg:py-0 lg:absolute">
          <a
            href={process.env.REACT_APP_HOME_BASE_URL}
            className="flex items-center"
          >
            <img src={Images.Logo} width={24} height={24} />
            <span className="text-2xl font-bold ml-2">Runway</span>
          </a>
        </div>
        <div className="w-[100vw] h-24 flex flex-col justify-center items-center">
          <div className="text-rw-blue font-semibold text-center py-2">
            Applying as a Business
          </div>

          {state.currentStep.id > 0 && (
            <div className="flex">
              {Steps.map((step) => (
                <div
                  key={step?.id}
                  className="flex flex-col items-center mx-4 lg:mx-8"
                >
                  <div
                    className={`rounded-full w-6 h-6 border-[1px] text-sm font-medium ${
                      state?.currentStep?.id >= step?.id
                        ? "bg-rw-blue text-white"
                        : "border-[#00000040] text-[#B8B8B8]"
                    } flex justify-center items-center`}
                  >
                    {step?.id < state?.currentStep?.id ? (
                      <CheckOutlinedIcon sx={{ width: 16, height: 16 }} />
                    ) : (
                      step?.id
                    )}
                  </div>

                  <div
                    className={`text-xs ${
                      state?.currentStep?.id >= step?.id
                        ? "text-rw-blue"
                        : "text-[#B8B8B8]"
                    } font-semibold mt-2`}
                  >
                    {step?.label}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-center mt-10">
        <div className="w-[90%] md:w-96">
          {/* {state?.currentStep?.label === StepLabels.ATV && !toggleATVOptIn && (
            <div className="">
              <div className="text-rw-black-dark text-lg text-center">
                Are you participating in the ATDC Career Fair?
              </div>
              <div
                className={`mt-8 flex justify-between p-5 rounded-lg cursor-pointer ${
                  state.atv.isATV !== null && state?.atv?.isATV
                    ? " border-2 border-rw-blue"
                    : " border border-rw-gray-gainsboro"
                }`}
                onClick={() => toggleATVFlow(true)}
              >
                <div className="flex gap-2 w-[80%]">
                  <img src={Images.ATDCLogo} />
                  <div className="">
                    <div className="text-lg font-semibold text-rw-gray-0">
                      Yes
                    </div>
                    <div className="text-xs text-rw-gray-0 text-opacity-75">
                      I am a part of the ATDC Career Fair
                    </div>
                  </div>
                </div>
                <div>
                  <Radio
                    checked={state.atv.isATV !== null && state?.atv?.isATV}
                  />
                </div>
              </div>
              <div
                className={`mt-4 flex justify-between p-5 rounded-lg cursor-pointer ${
                  state.atv.isATV !== null && !state?.atv?.isATV
                    ? " border-2 border-rw-blue"
                    : " border border-rw-gray-gainsboro"
                }`}
                onClick={() => toggleATVFlow(false)}
              >
                <div className="flex gap-2 w-[80%]">
                  <img src={Images.BriefCase} className="w-10 h-8" />
                  <div className="">
                    <div className="text-lg font-semibold text-rw-gray-0">
                      No
                    </div>
                    <div className="text-xs text-rw-gray-0 text-opacity-75">
                      I am a business signing up for Runway
                    </div>
                  </div>
                </div>
                <div>
                  <Radio
                    checked={state.atv.isATV !== null && !state?.atv?.isATV}
                  />
                </div>
              </div>
            </div>
          )}
          {state?.currentStep?.label === StepLabels.ATV && toggleATVOptIn && (
            <div className="">
              <div className="text-rw-black-dark text-lg text-center">
                Do you want to opt-in to using Runway’s Services?
              </div>
              <div className="mt-4 rounded-lg bg-rw-blue-lightest py-7">
                <div className="flex gap-2 items-center px-7">
                  <img src={Images.DiscountTicket} className="w-7 h-7" />
                  <div className="">
                    <div className="font-semibold text-xl text-rw-black-dark">
                      10% Platform Fee
                    </div>
                    <div>
                      <span className="text-base text-rw-black-dark line-through">
                        20% Platform Fee
                      </span>{" "}
                      (ATDC Discount)
                    </div>
                  </div>
                </div>
                <hr className="w-full h-0.5 bg-rw-gray-gainsboro my-4" />
                <div className="px-7 text-sm">
                  To utilize the following features, Runway takes a 10% platform
                  fee from the students paycheck.
                </div>
              </div>
              <div className="mt-4 rounded-lg bg-rw-blue-lightest p-7">
                {ATVOptInBullets.map((infoPoint, index) => {
                  return (
                    <div className="text-center flex flex-col items-center justify-center gap-2">
                      <img src={infoPoint.icon} />
                      <div className="font-semibold text-2xl text-rw-black-dark">
                        {infoPoint.label}
                      </div>
                      <div className="text-base text-rw-black-dark">
                        {infoPoint.desc}
                      </div>
                      {index !== ATVOptInBullets.length - 1 && (
                        <hr className="my-6 bg-rw-gray-gainsboro h-0.5 w-full" />
                      )}
                    </div>
                  );
                })}
              </div>
              {ATVExamples.map((example) => {
                return (
                  <div className="mt-4 rounded-lg bg-rw-blue-lightest p-7 flex flex-col items-center justify-center gap-2">
                    <div className="font-semibold text-2xl text-rw-black-dark">
                      {example.header}
                    </div>
                    <hr className="my-6 bg-rw-gray-gainsboro h-0.5 w-full" />
                    <div className="flex justify-between w-full mb-8">
                      <div>
                        <div className="text-lg text-rw-black-dark font-semibold">
                          {example.projectName}
                        </div>
                        <div className="text-sm text-rw-black-dark">
                          {example.projectNameSubtitle}
                        </div>
                      </div>
                      <div className="text-lg text-rw-black-dark font-semibold">
                        {example.projectTotalCost}
                      </div>
                    </div>
                    {example.dividedCost.map((dividedCost, index) => {
                      return (
                        <>
                          <div className="flex justify-between w-full">
                            <div>
                              <div className="text-sm text-rw-black-dark font-semibold">
                                {dividedCost.title}
                              </div>
                              <div className="text-sm text-rw-black-dark">
                                {dividedCost.subtitle}
                              </div>
                            </div>
                            <div className="text-sm text-rw-black-dark font-semibold">
                              {dividedCost.cost}
                            </div>
                          </div>
                          {index < example.dividedCost.length - 1 && (
                            <hr className="my-2 bg-rw-gray-gainsboro h-0.5 w-full" />
                          )}
                        </>
                      );
                    })}
                  </div>
                );
              })}

              <div
                className={`mt-6 flex justify-between p-4 rounded-lg cursor-pointer ${
                  state?.atv?.optedIn !== null && state?.atv?.optedIn
                    ? " border-2 border-rw-blue"
                    : " border border-rw-gray-gainsboro"
                }`}
                onClick={() => toggleOptIn(true)}
              >
                <div className="flex gap-2 w-full items-center">
                  <img src={Images.Logo} className="w-7 h-7" />
                  <div className="">
                    <div className="text-lg font-semibold text-rw-gray-0">
                      Yes, Opt-in
                    </div>
                    <div className="text-xs text-rw-gray-0 text-opacity-75">
                      I want to be a Runway customer and have access to all
                      their features.
                    </div>
                  </div>
                </div>
                <div>
                  <Radio
                    checked={
                      state?.atv?.optedIn !== null && state?.atv?.optedIn
                    }
                  />
                </div>
              </div>
              <div
                className={`mt-4 flex justify-between p-4 rounded-lg cursor-pointer ${
                  state?.atv?.optedIn !== null && !state?.atv?.optedIn
                    ? " border-2 border-rw-blue"
                    : " border border-rw-gray-gainsboro"
                }`}
                onClick={() => toggleOptIn(false)}
              >
                <div className="flex gap-2 w-full items-center">
                  <img src={Images.BriefCase} className="w-7 h-7" />
                  <div className="">
                    <div className="text-lg font-semibold text-rw-gray-0">
                      No, Opt-out
                    </div>
                    <div className="text-xs text-rw-gray-0 text-opacity-75">
                      I only want access to the career fair resumes and will
                      handle everything for my intern off the platform.
                    </div>
                  </div>
                </div>
                <div>
                  <Radio
                    checked={
                      state?.atv?.optedIn !== null && !state?.atv?.optedIn
                    }
                  />
                </div>
              </div>
            </div>
          )} */}

          {state?.currentStep?.label === StepLabels.LOGIN && (
            <div className="">
              <div className="text-3xl font-semibold text-rw-black-dark text-center">
                Login Details
              </div>
              <div className="w-full mt-8">
                <div className="font-semibold mb-1">Name</div>

                <div className="flex gap-2 justify-between">
                  <TextField
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={state?.firstName}
                    onChange={handleChange}
                    error={state?.errors?.firstName}
                  />

                  <TextField
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={state?.lastName}
                    onChange={handleChange}
                    error={state?.errors?.lastName}
                  />
                </div>
              </div>

              <div className="mt-4">
                <div className="font-semibold mb-1">Company Name</div>

                <TextField
                  type="text"
                  name="companyName"
                  placeholder="Name of your company"
                  value={state?.companyName}
                  onChange={handleChange}
                  error={state?.errors?.companyName}
                />
              </div>

              <div className="mt-4">
                <div className="font-semibold mb-1">Email</div>

                <TextField
                  type="text"
                  name="email"
                  placeholder="Email Address"
                  value={state?.email}
                  onChange={handleChange}
                  error={state?.errors?.email}
                />
              </div>

              <div className="mt-4">
                <div className="font-semibold mb-1">Password</div>

                <TextField
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={state?.password}
                  onChange={handleChange}
                  error={state?.errors?.password}
                />

                <div className="">
                  <TextField
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    value={state?.confirmPassword}
                    onChange={handleChange}
                    error={state?.errors?.confirmPassword}
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className="font-semibold mb-1">Phone Number</div>

                <TextField
                  type="number"
                  name="phone"
                  placeholder="(000) 000-0000"
                  value={state?.phone}
                  onChange={handleChange}
                  error={state?.errors?.phone}
                  onWheel={(event: any) => event.target.blur()}
                  onInput={(event: any) => {
                    if (event?.target?.value) {
                      event.target.value = Math.max(
                        0,
                        parseInt(event.target.value, 10)
                      )
                        .toString()
                        .slice(0, 10);
                    }
                  }}
                />
              </div>
            </div>
          )}

          {state?.currentStep?.label === StepLabels.CONFIRM && (
            <div>
              <div className="text-3xl font-semibold text-rw-black-dark text-center">
                Confirm Account
              </div>

              <div className="mt-8">
                <div className="flex justify-between items-center mb-1">
                  <div className="font-semibold">Email</div>
                  <button
                    className="text-rw-blue text-sm font-medium underline"
                    onClick={() => handleEditStep(StepLabels.LOGIN)}
                  >
                    Edit
                  </button>
                </div>

                <TextField type="text" value={state?.email} readOnly />
              </div>

              <div className="mt-8">
                <div className="flex justify-between items-center mb-1">
                  <div className="font-semibold">Password</div>
                  <button
                    className="text-rw-blue text-sm font-medium underline"
                    onClick={() => handleEditStep(StepLabels.LOGIN)}
                  >
                    Edit
                  </button>
                </div>

                <TextField type="password" value={state?.password} readOnly />
              </div>

              <div className="w-full mt-8">
                <div className="flex justify-between items-center mb-1">
                  <div className="font-semibold">Full Name</div>
                  <button
                    className="text-rw-blue text-sm font-medium underline"
                    onClick={() => handleEditStep(StepLabels.LOGIN)}
                  >
                    Edit
                  </button>
                </div>

                <TextField
                  type="text"
                  value={`${state?.firstName || ""} ${state?.lastName || ""}`}
                  readOnly
                />
              </div>

              <div className="mt-8">
                <div className="flex justify-between items-center mb-1">
                  <div className="font-semibold">Company Name</div>
                  <button
                    className="text-rw-blue text-sm font-medium underline"
                    onClick={() => handleEditStep(StepLabels.LOGIN)}
                  >
                    Edit
                  </button>
                </div>

                <TextField type="text" value={state?.companyName} readOnly />
              </div>

              <div className="mt-8">
                <div className="flex justify-between items-center mb-1">
                  <div className="font-semibold">Phone Number</div>
                  <button
                    className="text-rw-blue text-sm font-medium underline"
                    onClick={() => handleEditStep(StepLabels.LOGIN)}
                  >
                    Edit
                  </button>
                </div>

                <TextField type="text" value={state?.phone} readOnly />
              </div>
            </div>
          )}

          {state?.currentStep?.label === StepLabels.CONFIRM ? (
            <Button
              disabled={state.isDisableBtn}
              className={`font-semibold w-full h-12 mt-8 ${
                state.isDisableBtn ? "bg-rw-disable" : ""
              }`}
              onClick={handleBusinessSignup}
            >
              Confirm
            </Button>
          ) : (
            <Button
              className="font-semibold w-full h-12 mt-8"
              onClick={handleContinue}
            >
              Continue
            </Button>
          )}

          <Button
            variant="secondary"
            className="font-medium w-full mt-4 h-12"
            onClick={handleBack}
          >
            Back
          </Button>

          {state?.currentStep?.label === StepLabels.LOGIN && (
            <div className="flex justify-center gap-2 text-sm mt-8">
              <div>Not a Business?</div>
              <Link to="/signup/student" className="text-rw-blue underline">
                Apply as a Student
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
