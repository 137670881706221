import React, { useEffect, useRef } from "react";
import moment from "moment";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import { getStudentPayment } from "api";
import { wrapFullName } from "utils/common";
import DepositModal from "./depositModal";
import PayStudentModal from "./payStudentModal";
import NotesModal from "./notesModal";

type Props = {
  manuBarExpend: boolean;
  manuBarToogle: () => void;
};
const ManagePayment = ({ manuBarExpend, manuBarToogle }: Props) => {
  const [state, setState] = useMergeState({
    activeTab: "Payment Approval",
    isShowPayStudentModal: false,
    isShowDepositFundModal: false,
    isShowNotesModal: false,
    paymentApproval: [],
    paymentComplete: [],
  });

  const payableAmount = useRef(null);
  const payableStudentName = useRef("");
  const payableId = useRef("");
  const selectedPayment = useRef({});

  const studentAmount = (amount: number) => amount - 0.2 * amount;
  const runwayFee = (amount: number) => 0.2 * amount;

  const handleTotalHoursAndAmount = (payments: any) =>
    payments.reduce(
      (total: any, item: any) => ({
        totalAmount: item.totalAmount + (total?.totalAmount || 0),
        totalHours: item.hours + (total?.totalHours || 0),
        totalRunwayFee:
          runwayFee(item.totalAmount) + (total?.totalRunwayFee || 0),
        totalStudentAmount:
          studentAmount(item.totalAmount) + (total?.totalStudentAmount || 0),
      }),
      {
        totalAmount: 0,
        totalHours: 0,
        totalRunwayFee: 0,
        totalStudentAmount: 0,
      }
    );

  const handleUpdatePayment = (id: string, status: string, note: string) => {
    const findedPaymentApproval = state.paymentApproval.find(
      (item: any) => item?._id === id
    );
    if (findedPaymentApproval) {
      const tempPaymentComplete = [
        { ...findedPaymentApproval, status, note },
        ...state.paymentComplete,
      ];
      const { totalAmount, totalHours, totalRunwayFee, totalStudentAmount } =
        handleTotalHoursAndAmount(tempPaymentComplete);
      setState({
        paymentApproval: state.paymentApproval.filter(
          (item: any) => item?._id !== id
        ),
        paymentComplete: tempPaymentComplete,
        totalAmount,
        totalHours,
        totalRunwayFee,
        totalStudentAmount,
        isShowDepositFundModal: false,
        isShowPayStudentModal: false,
      });
    } else {
      setState({
        isShowDepositFundModal: false,
        isShowPayStudentModal: false,
      });
    }
  };

  const init = async () => {
    const res = await Promise.all([
      getStudentPayment({ status: null }),
      getStudentPayment({ status: ["STUDENT", "DEPOSITED"] }),
    ]);
    const paymentApprovalRes = res[0];
    const paymentCompleteRes = res[1];
    let paymentApproval = [];
    let paymentComplete = [];
    if (paymentApprovalRes.message === "Student Payments get successfully!")
      paymentApproval = paymentApprovalRes.studentPayments;
    if (paymentCompleteRes.message === "Student Payments get successfully!")
      paymentComplete = paymentCompleteRes.studentPayments;
    const { totalAmount, totalHours, totalRunwayFee, totalStudentAmount } =
      handleTotalHoursAndAmount(paymentComplete);
    setState({
      paymentApproval,
      paymentComplete,
      totalAmount,
      totalHours,
      totalRunwayFee,
      totalStudentAmount,
    });
  };

  useEffect(() => {
    init();
  }, []);

  const timeLeft = (paidAt: string) => {
    const milliSeconds = 172800000; // 48 hours in milliseconds
    const minutes =
      (milliSeconds - (moment().valueOf() - moment(paidAt).valueOf())) /
      1000 /
      60;
    const hours: any = (minutes / 60).toFixed(2);
    let decimalPart = 0;
    let hoursLeft = Math.round(hours);
    let minutesLeft: any = 0;
    if (hours.includes(".")) {
      [hoursLeft, decimalPart] = hours.split(".");
      minutesLeft = Math.round(Number(`.${decimalPart}`) * 60);
    }
    return `${hoursLeft}h ${minutesLeft}m`;
  };

  return (
    <div>
      <header
        className={`header ${manuBarExpend ? "body_pd" : ""}`}
        id="header"
      >
        <div className="header_toggle" onClick={() => manuBarToogle()}>
          {" "}
          <i
            className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
            id="header_toggle"
          />{" "}
        </div>
        <div className="fw-bold fs-5 ms-3 mb-0 d-flex align-items-center w-100">
          <span className="d-block d-md-none me-3">
            <img src={Images.Logo} />
          </span>
          <span>Runway Payment</span>
        </div>
      </header>
      <div className="p-0 px-md-3 w-100 mb-5 mb-md-0 pb-5 pb-md-4">
        <div className="p-4 bg-white w-100 border border_gray border_r_16px border_sm_r_0 mt-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div className="stack_logo">
                <img src={Images.Stack} />
              </div>
              <p className="text_black fw-bold fs-5 ms-3 lh-base font_mob_16">
                Runway Payment
              </p>
            </div>
            {/* <button
              type="button"
              className="btn bg_secondary btn_small mob_100"
            >
              View stripe
            </button> */}
          </div>
        </div>
        <div className="bg-white w-100 border border_gray border_r_16px border_sm_r_0 mt-3 mb-4 mb-md-0">
          <ul
            className="nav nav-tabs nav_project_tabs px-2 pt-2"
            id="myTab"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  state.activeTab === "Payment Approval" ? "active" : ""
                }`}
                onClick={() => setState({ activeTab: "Payment Approval" })}
              >
                Payment Approval
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${
                  state.activeTab === "Payment Complete" ? "active" : ""
                }`}
                onClick={() => setState({ activeTab: "Payment Complete" })}
              >
                Payment Complete
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active w-100 ps-3"
              id="payment-approval"
              role="tabpanel"
              aria-labelledby="payment-approval-tab"
            >
              {state.activeTab === "Payment Approval" ? (
                <>
                  <div className="table-responsive payment_approval">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th scope="col" className="ps-3 text_primary font_14">
                            Company
                          </th>
                          <th scope="col" className="text_primary font_14">
                            Student
                          </th>
                          <th scope="col" className="text_primary font_14">
                            Date Paid
                          </th>
                          <th scope="col" className="text_primary font_14">
                            Hours
                          </th>
                          <th scope="col" className="text_primary font_14">
                            Total Amount
                          </th>
                          <th scope="col" className="text_primary font_14">
                            Runway Fee
                          </th>
                          <th scope="col" className="text_primary font_14">
                            Student Total
                          </th>
                          <th scope="col" className="text_primary font_14">
                            Time Left
                          </th>
                          <th
                            scope="col"
                            className="text_primary font_14 text-center"
                          >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.paymentApproval.length > 0 ? (
                          state.paymentApproval.map((item: any) => (
                            <tr key={item?._id}>
                              <td className="ps-3">{item?.business.name}</td>
                              <td>
                                {wrapFullName(
                                  item?.student?.firstName,
                                  item?.student?.lastName
                                )}
                              </td>
                              <td>
                                {moment(item?.paidAt).format("MM/DD H:mma")}
                              </td>
                              <td>{item?.hours}hrs</td>
                              <td>${item?.totalAmount.toFixed(2)}</td>
                              <td>
                                ${runwayFee(item?.totalAmount).toFixed(2)}
                              </td>
                              <td className="text_secondary">
                                ${studentAmount(item?.totalAmount).toFixed(2)}
                              </td>
                              <td>{timeLeft(item?.paidAt)}</td>
                              <td className="text-center">
                                <button
                                  type="button"
                                  className="btn btn_small border border_secondary text_secondary px-3 py-2 lh-sm me-2"
                                  onClick={() => {
                                    payableAmount.current = item?.totalAmount;
                                    payableId.current = item?._id;
                                    setState({ isShowDepositFundModal: true });
                                  }}
                                >
                                  DEPOSIT
                                </button>
                                <button
                                  type="button"
                                  className="btn btn_small bg_secondary px-3 py-2 lh-sm"
                                  onClick={() => {
                                    payableStudentName.current = wrapFullName(
                                      item?.student?.firstName,
                                      item?.student?.lastName
                                    );
                                    payableAmount.current = item?.totalAmount;
                                    payableId.current = item?._id;
                                    setState({ isShowPayStudentModal: true });
                                  }}
                                >
                                  Pay Student
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <td
                            colSpan={9}
                            className="text-center text_rgba_50 pt-3"
                          >
                            No payment approvals have been found.
                          </td>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="text-center mt-4 d-block d-md-none mx-3 mx-md-0">
                    <button
                      type="button"
                      className="btn bg_secondary mob_100 mx-auto"
                    >
                      View Stripe
                    </button>
                  </div>
                </>
              ) : (
                <div className="table-responsive payment_approval">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col" className="ps-3 text_primary font_14">
                          Status
                        </th>
                        <th scope="col" className="ps-3 text_primary font_14">
                          Company
                        </th>
                        <th scope="col" className="text_primary font_14">
                          Student
                        </th>
                        <th scope="col" className="text_primary font_14">
                          Date Paid
                        </th>
                        <th scope="col" className="text_primary font_14">
                          Hours
                        </th>
                        <th scope="col" className="text_primary font_14">
                          Runway Fee
                        </th>
                        <th scope="col" className="text_primary font_14">
                          Student Total
                        </th>
                        <th scope="col" className="text_primary font_14">
                          Total Amount
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.paymentComplete.map((item: any) => (
                        <tr key={item?._id}>
                          <td
                            className={`ps-3 text-uppercase ${
                              item?.status === "DEPOSITED"
                                ? "text_green"
                                : "text_secondary"
                            }`}
                          >
                            {item?.status}
                            {item?.note && item?.note !== "" ? (
                              <span
                                className="cursor_pointer ms-2"
                                onClick={() => {
                                  selectedPayment.current = item;
                                  setState({ isShowNotesModal: true });
                                }}
                              >
                                <i className="fal fa-edit" />
                              </span>
                            ) : null}
                          </td>
                          <td>{item?.business.name}</td>
                          <td>
                            {" "}
                            {wrapFullName(
                              item?.student?.firstName,
                              item?.student?.lastName
                            )}
                          </td>
                          <td>{moment(item?.paidAt).format("MM/DD HH:mma")}</td>
                          <td>{item?.hours}hrs</td>
                          <td>${runwayFee(item?.totalAmount).toFixed(2)}</td>
                          <td>
                            ${studentAmount(item?.totalAmount).toFixed(2)}
                          </td>
                          <td>${item?.totalAmount.toFixed(2)}</td>
                        </tr>
                      ))}

                      <tr className="total">
                        <td className="ps-3 fw-bold text_black" colSpan={4}>
                          Totals
                        </td>
                        <td className="fw-bold text_black">
                          {state.totalHours}hrs
                        </td>
                        <td className="fw-bold text_black">
                          ${state.totalRunwayFee?.toFixed(2)}
                        </td>
                        <td className="fw-bold text_black">
                          ${state.totalStudentAmount?.toFixed(2)}
                        </td>
                        <td className="fw-bold text_black">
                          ${state.totalAmount?.toFixed(2)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <DepositModal
              isShow={state.isShowDepositFundModal}
              payableAmount={payableAmount.current}
              payableId={payableId.current}
              onHide={() => setState({ isShowDepositFundModal: false })}
              handleUpdatePayment={handleUpdatePayment}
            />
            <PayStudentModal
              isShow={state.isShowPayStudentModal}
              payableStudentName={payableStudentName.current}
              payableAmount={payableAmount.current}
              payableId={payableId.current}
              onHide={() => setState({ isShowPayStudentModal: false })}
              handleUpdatePayment={handleUpdatePayment}
            />
            <NotesModal
              isShow={state.isShowNotesModal}
              selectedPayment={selectedPayment.current}
              onHide={() => setState({ isShowNotesModal: false })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagePayment;
