import Logo from "./logo.svg";
import CreateProject from "./create_project.svg";
import StudentMatching from "./student_matching.svg";
import Interviewing from "./interviewing.svg";
import SelectStudent from "./select_student.svg";
import ProjectEngagement from "./project_engagement.svg";
import FinishProject from "./finish_project.svg";
import Developer from "./developer.svg";
import Designer from "./designer.svg";
import Engineer2 from "./engineer.svg";
import Engineer from "./engineer_2.svg";
import Business from "./business.svg";
import Student from "./student.svg";
import StudentLogo from "../imgs/student_logo.svg";
import Bag from "../imgs/bag.svg";
import BriefCase from "./briefcase.svg";
import AppLoaderGif from "./app_loader.gif";
import PersonalityTest from "./personality_test.svg";
import Innovator from "./innovator.svg";
import Collaborator from "./collaborator.svg";
import Perfectionist from "./perfectionist.svg";
import Achiever from "./achiever.svg";
import AnalyticThinker from "./analytic_thinker.svg";
import RelationshipBuilder from "./relationship_builder.svg";
import ExploreProjects from "./explore_projects.svg";
import MonitorActiveProjects from "./monitor_active_projects.svg";
import RequestedToInterview from "./requested_to_interview.svg";
import StartProject from "./start_project.svg";
import SubmitTimesheets from "./submit_timesheets.svg";
import GetPaid from "./get_paid.svg";
import Clock from "./clock.svg";
import Calendar from "./calendar.svg";
import Handshake from "./handshake.svg";
import ConfirmCalender from "./confirm_calender.svg";
import StripeLogo from "./stripe.svg";
import Edit from "./edit.svg";
import FullTime from "./full_time.svg";
import Retainer from "./retainer.svg";
import HourlyRate from "./hourly_rate.svg";
import LinkedInLogo from "./LinkedInLogo.svg";
import TwitterLogo from "./TwitterLogo.svg";
import ATVLogo from "./atv_logo.svg";
import ATDCLogo from "./atdc_logo.svg";
import PayrollIcon from "./payroll_icon.svg";
import GPTIcon from "./gpt_icon.svg";
import InterviewIcon from "./interviewing_icon.svg";
import TopThreeCandidatesIcon from "./top_candidates.svg";
import CareerFairGray from "./career_fair_gray.svg";
import CareerFairBlue from "./career_fair_blue.svg";

// blogs
import ChangingTheNarrative from "./changing-the-narrative-4-ways-interns-will-transform-your-startup-blog.svg";
import HowToUseHubspot from "./how-to-use-hubspot-to-get-qualified-leads-blog.svg";
import HowToUseLinkedIn from "./how-to-use-linkedin-sales-navigator-to-find-target-customers-blog.svg";
import TheThreeBiggest from "./the-3-biggest-talent-acquisition-mistakes-startups-are-marking-in-2023.svg";
import TheBestFreelanceJobs from "./the-best-freelance-jobs-for-college-students-blog.svg";
import TheHiddenRisks from "./the-hidden-risks-of-diy-intern-recruting-and-how-it-can-negatively-impact-your-business-blog.svg";
import WhatYouNeed from "./what-you-need-to-know-before-starting-your-talent-acquisition-journey-blog.svg";
import WhyWouldIHire from "./why-would-i-hire-a-college-student-as-a-freelancer-blog.svg";
import HowToHireGeorgiaTechStudents from "./how-to-hire-georgia-tech-students.svg";
import HowToHireEmoryStudents from "./how-to-hire-emory-students.svg";
import DiscountTicket from "./discount_ticket.svg";

import GoogleIcon from "../imgs/google.png";
import Stack from "../imgs/stack.svg";
import Runaway_logo from "../imgs/runaway_logo.svg";
import Submit_icon from "../imgs/submit_icon.svg";
import Logo_png from "../imgs/logo.png";
import Logo_white from "../imgs/logo_white.svg";
import Logo_single_white from "../imgs/logo_single_white.svg";
import Brain from "../imgs/brain.png";
import Interview from "../imgs/interview.png";
import StripeSvg from "../imgs/stripe.svg";
import Stripe_Logo from "../imgs/stripe_logo.png";

const Images = {
  Logo,
  StripeLogo,
  LinkedInLogo,
  TwitterLogo,
  CreateProject,
  StudentMatching,
  Interviewing,
  SelectStudent,
  ProjectEngagement,
  FinishProject,
  Developer,
  Designer,
  Engineer,
  Business,
  Student,
  StudentLogo,
  Bag,
  BriefCase,
  AppLoaderGif,
  PersonalityTest,
  Innovator,
  Collaborator,
  Perfectionist,
  Achiever,
  AnalyticThinker,
  RelationshipBuilder,
  ExploreProjects,
  MonitorActiveProjects,
  RequestedToInterview,
  StartProject,
  SubmitTimesheets,
  GetPaid,
  Clock,
  Calendar,
  Handshake,
  ConfirmCalender,
  Edit,
  FullTime,
  Retainer,
  HourlyRate,
  ATVLogo,
  ATDCLogo,
  PayrollIcon,
  GPTIcon,
  InterviewIcon,
  TopThreeCandidatesIcon,
  CareerFairGray,
  CareerFairBlue,
  DiscountTicket,
  GoogleIcon,
  Stack,
  Runaway_logo,
  Submit_icon,
  Logo_png,
  Logo_white,
  Logo_single_white,
  Brain,
  Interview,
  StripeSvg,
  Stripe_Logo,

  // blogs
  ChangingTheNarrative,
  HowToUseHubspot,
  HowToUseLinkedIn,
  TheThreeBiggest,
  TheBestFreelanceJobs,
  TheHiddenRisks,
  WhatYouNeed,
  WhyWouldIHire,
  HowToHireGeorgiaTechStudents,
  HowToHireEmoryStudents,
};

export default Images;
