import DialogContent from "@mui/material/DialogContent";
import { Dialog } from "components/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import humanize from "humanize-string";
import { getPersonalityRanking } from "utils/common";

type Props = {
  open: boolean;
  onClose: any;
  type: string;
};

export default function PersonalityDefinitionsDialog({
  open,
  onClose,
  type,
}: Props) {
  const personalityRanking = getPersonalityRanking(type);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
      open={open}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
      sx={{
        "& .MuiDialog-paper": {
          width: 400,
        },
        "& .MuiDialogContent-root": {
          padding: 2,
        },
      }}
    >
      <DialogContent>
        <div className="relative">
          <div className="text-lg font-semibold text-center">
            {humanize(type)} Personalities
          </div>

          <button
            className="absolute top-0 right-0 text-rw-blue"
            onClick={onClose}
          >
            <CloseIcon sx={{ width: 28, height: 28 }} />
          </button>

          <div className="mt-8">
            {personalityRanking.map((personalityRanking: any) => (
              <div
                key={personalityRanking.title}
                className="w-full flex items-center"
              >
                <div key={personalityRanking.title} className="flex my-4">
                  <img
                    src={personalityRanking?.icon}
                    className="w-8 h-8 mt-2"
                  />

                  <div>
                    <div className="text-lg font-semibold px-2">
                      {personalityRanking?.title}
                    </div>

                    <div className="text-sm font-medium px-2">
                      {personalityRanking?.description}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
