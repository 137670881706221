import React, { useEffect } from "react";
import { useMergeState } from "utils/custom-hooks";
import ConfirmInActivateProjectDialog from "components/ConfirmInActivateProjectDialog";
import { ProjectStatus } from "utils/constants";

type Props = {
  isOnline?: boolean;
  isShowAcceptingText?: boolean;
  handleChangeProjectStatus: (status: boolean) => void;
};

const OfflineLiveSwitch = ({
  isOnline = false,
  isShowAcceptingText = true,
  handleChangeProjectStatus,
}: Props) => {
  const [state, setState] = useMergeState({
    isLive: true,
    checkValue: "Yearly",
    shouldShowConfirmInActivateProjectDialog: false,
    isDisableBtn: false,
  });

  const handleToggleProjectDialog = () => {
    setState({
      shouldShowConfirmInActivateProjectDialog:
        !state.shouldShowConfirmInActivateProjectDialog,
    });
  };

  const handleProjectStatus = async (status: boolean) => {
    setState({
      isDisableBtn: true,
    });
    await handleChangeProjectStatus(status);
    setState({
      isLive: !state.isLive,
      isDisableBtn: false,
      shouldShowConfirmInActivateProjectDialog: false,
    });
  };

  useEffect(() => {
    setState({
      isLive: isOnline,
    });
  }, [isOnline]);

  return (
    <>
      <div className="d-flex align-items-center flex-wrap">
        <p className="fw-bold text_primary me-4">Project Status:</p>
        <div className="switches_container mt-2 mt-md-0">
          <input
            type="radio"
            id="switchMonthly"
            name="switchPlan"
            checked={!state.isLive}
            onChange={(e) => handleToggleProjectDialog()}
          />
          <input
            type="radio"
            id="switchYearly"
            name="switchPlan"
            checked={state.isLive}
            onChange={(e) => {
              setState({
                isLive: !state.isLive,
              });
              handleProjectStatus(true);
            }}
          />
          <label htmlFor="switchMonthly">OFFLINE</label>
          <label htmlFor="switchYearly">LIVE</label>
          <div className="switch-wrapper">
            <div className="switch">
              <div>OFFLINE</div>
              <div>LIVE</div>
            </div>
          </div>
        </div>
        {isShowAcceptingText && state.isLive ? (
          <p className="ps-2 font_14">You are still accepting candidates</p>
        ) : isShowAcceptingText && !state.isLive ? (
          <p className="ps-2 font_14">You are not accepting candidates</p>
        ) : null}
      </div>
      {state?.shouldShowConfirmInActivateProjectDialog && (
        <ConfirmInActivateProjectDialog
          isDisable={state.isDisableBtn}
          open={state?.shouldShowConfirmInActivateProjectDialog}
          onClose={handleToggleProjectDialog}
          onConfirm={() => handleProjectStatus(false)}
        />
      )}
    </>
  );
};

export default OfflineLiveSwitch;
