import React from "react";
import { Link, useNavigate } from "react-router-dom";

type Props = {
  handleAssessmentState: () => void;
};

const AssessmentOnboarding = ({ handleAssessmentState }: Props) => {
  const navigate = useNavigate();
  return (
    <div className="bg_light p-3 p-md-4 w-100 overflow-hidden">
      <div className="p-3 p-md-5 bg_white justify-content-center border border_gray border_r_16px min-vh-100">
        <p className="fs-3 fw-bold text-center mb-5">
          Complete these items to stand out!
        </p>

        <div className="d-flex flex-wrap flex-md-nowrap">
          {/* <div
            onClick={handleAssessmentState}
            className="p-5 border border_gray border_r_16px w-100 box_shadow mx-2 mb-4 mb-md-0 cursor_pointer"
          >
            <div className="d-flex justify-content-center icon_size_sm text-center">
              <img src={require("../../../assets/imgs/skill.png")} />
            </div>
            <p className="fs-4 text_secondary fw-bold text-center mt-4">
              Skill Assessment
            </p>
            <p className="font_18 text_primary text-center">
              Choose your top skills to identify your skill level.
            </p>
          </div> */}
          <Link
            to="/personality-tests"
            className="p-5 border border_gray border_r_16px w-100 box_shadow mx-2"
          >
            <div className="d-flex justify-content-center icon_size_sm text-center">
              <img src={require("../../../assets/imgs/brain.png")} />
            </div>
            <p className="fs-4 text_secondary fw-bold text-center mt-4">
              Personality Assessment
            </p>
            <p className="font_18 text_primary text-center">
              12 question test to understand your work style
            </p>
          </Link>
        </div>

        <div className="text-center my-4">
          <button
            type="button"
            className="btn bg_light_secondary text_secondary"
            onClick={() => navigate("/settings")}
          >
            Complete Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default AssessmentOnboarding;
