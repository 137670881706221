export const ScorePersonalityTest = (selections: any) => {
  Object.keys(selections).map((resultType: any) => {
    selections[resultType] /= 12;
    selections[resultType] = selections[resultType].toFixed(2);
    selections[resultType] *= 100;
  });
};

export const generateResultTypes = (questionMap: any) => {
  const map: any = {};

  Object.keys(questionMap.questions[0][0].choices).forEach((choice: any) => {
    map[questionMap.questions[0][0].choices[choice]] = 0;
  });
  return map;
};

export const getChoiceType = (
  questionMap: any,
  page: number,
  question: string,
  prevChoice: string
) => {
  return questionMap.questions[page].filter((e: any) => {
    return e.question === question;
  })[0].choices[prevChoice];
};

export const sortedTallyMap = (results: any) => {
  ScorePersonalityTest(results);
  const map: any = {};
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  topChoice(results).forEach((resultType) => {
    map[resultType] = Math.round(results[resultType]);
  });
  return map;
};

export const topChoice = (results: any) => {
  return Object.keys(results).sort((a: any, b: any) => results[b] - results[a]);
};

export const verifyPage = (profileTypes: any, currentPageType: any) => {
  return profileTypes.includes(currentPageType.toUpperCase());
};

export const validateUserPersonalityTest = (user: any) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const x of user.profileTypes) {
    if (!user.personality[x.toLowerCase()]) {
      return false;
    }
  }
  return true;
};
