import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Images from "assets/images";

const StripeAccountModal = ({
  isShow,
  onHide,
}: {
  isShow: boolean;
  onHide: () => void;
}) => {
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  //   const handleConnectPayout = async () => {
  //     setIsDisableBtn(true);
  //     await handleCreateConnectAccount();
  //     setIsDisableBtn(false);
  //   };
  //   const handleEditPayout = async () => {
  //     setIsDisableBtn(true);
  //     await handleEditPayoutAccount();
  //     setIsDisableBtn(false);
  //   };
  return (
    <Modal show={isShow} onHide={onHide} centered>
      <div className="modal-content p-3 p-md-4 border_r_16px overflow-hidden">
        <div className="modal-body p-0 mt-0">
          <button
            type="button"
            className="d-flex justify-content-end border-0 bg-transparent w-25 fs-4 text_black position-absolute end-0 me-0 mt-2 z_index_10"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={onHide}
          >
            <i className="fa-regular fa-xmark" />
          </button>
          <img src={Images.StripeSvg} className="runway_stripe_logo" />
          <p className="fw-bold fs-4 text-start text_black lh-sm mb-1 mt-3">
            Connect Payout
          </p>
          <p className="font_14">in 5 minutes</p>
          <p className="fs-6 text_primary text-start mb-3">
            Once you connect to Stripe you will receive automated payouts linked
            to your bank account.
          </p>

          <button type="button" className="btn bg_secondary w-100 my-3">
            Create Stripe Account
          </button>
          <button
            type="button"
            className="btn bg_light_secondary text_secondary w-100"
          >
            Link Existing Stripe Account
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StripeAccountModal;
