import { useEffect, useRef, useLayoutEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
// import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Divider } from "@mui/material";
import Button from "components/Button";
import InviteMemberDialog from "components/InviteMemberDialog";
import HowItWorksDialog from "components/HowItWorksDialog";
import AppLoader from "components/AppLoader";
import {
  AccountType,
  BudgetType,
  ProjectStatus,
  RUNWAY_CONTACT_EMAIL,
  RUNWAY_TOKEN,
} from "utils/constants";
import { useMergeState, useWindowSize } from "utils/custom-hooks";
import {
  getStats,
  getMembers,
  createConnectedAccount,
  getDashboardData,
  sendVerificationEmail,
} from "api";
import { wrapFullName, wrapNameTwoLetters } from "utils/common";
import AssessmentTask from "./AssessmentTask";
import ProjectCard from "./ProjectCard";
import Carousel from "./Carousel";
import AssessmentOnboarding from "./AssessmentOnboarding";
import useEmblaCarousel from "embla-carousel-react";
import { Modal } from "react-bootstrap";
import menuBack from "../../assets/imgs/menu-back.svg";
import "../../assets/css/embla.css";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";

// import "./styles.scss";
// import "./styles.css";
import { HourlyRate } from "../../utils/constants";
import GetStarted from "./GetStarted";
import { validateUserPersonalityTest } from "containers/start-personality-test/personality-tests/helper";

const getProjectFees = (project: any) => {
  if (project.budgetType === BudgetType.FULL_TIME) {
    return "Salary TBD";
  }

  if (project.budgetType === BudgetType.RETAINER) {
    return `${project.studentRetainerAmount}`;
  }

  if (project.budgetType === BudgetType.HOURLY_RATE) {
    return `${project.studentHourlyRate}`;
  }
};

const getBusinessProjectFees = (project: any) => {
  if (project.budgetType === BudgetType.FULL_TIME) {
    return "Salary TBD";
  }

  if (project.budgetType === BudgetType.RETAINER) {
    return `${project.retainerAmount}`;
  }

  if (project.budgetType === BudgetType.HOURLY_RATE) {
    return project.hourlyRate;
  }
};

type Props = {
  user: any;
  manuBarExpend: boolean;
  manuBarToogle: () => void;
  onLogout: () => void;
};

export default function DashboardContainer({
  user,
  manuBarExpend,
  manuBarToogle,
  onLogout,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const swiperRef: any = useRef();
  const swiperRef1: any = useRef();
  const swiperRef2: any = useRef();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "start",
    slidesToScroll: 1,
  });

  const [state, setState] = useMergeState({
    isLoading: false,

    activeProjects: 0,
    finishedProjects: 0,
    notifications: 0,
    showVerifyModal: false,

    members: [],

    shouldShowInviteMemberDialog: false,
    shouldShowHowItWorksDialog: false,
    showAssessmentTasks: true,
    isStudent: user?.accountType === "BUSINESS" ? false : true,
    onGoingProjectList: [],
    requestedProjectList: [],
    activeProjectList: [],
    appliedProjectList: [],
    inProgressProjectList: [],
    finishedProjectList: [],
    archivedProjects: [],
    studentSide: [
      {
        title: "Complete Personality Assessment",
        taskDescription: "RECOMMENDED",
        imageLink: require("../../assets/imgs/brain.png"),
        link: "/personality-tests",
      },
      {
        title: "Verify your Email",
        taskDescription: "RECOMMENDED",
        imageLink: require("../../assets/imgs/envelop.png"),
        link: "#",
        onClick: () => setState({ showVerifyModal: true }),
      },
    ],
    businessSide: [
      {
        title: "Verify your Email",
        taskDescription: "RECOMMENDED",
        imageLink: require("../../assets/imgs/envelop.png"),
        link: "#",
        onClick: () => setState({ showVerifyModal: true }),
      },
      {
        title: "Add Company Picture",
        taskDescription: "RECOMMENDED",
        imageLink: require("../../assets/imgs/camera.png"),
        link: "/settings",
      },
      {
        title: "Set-up Payment",
        taskDescription: "RECOMMENDED",
        imageLink: require("../../assets/imgs/money.png"),
        link: "/settings?goto=settings",
      },
    ],
    showTasks: true,
  });

  const handleOpenInviteMemberDialog = () => {
    setState({ shouldShowInviteMemberDialog: true });
  };

  const handleCloseInviteMemberDialog = () => {
    setState({ shouldShowInviteMemberDialog: false });
  };

  const handleOpenHowItWorksDialog = () => {
    setState({ shouldShowHowItWorksDialog: true });
  };

  const handleCloseHowItWorksDialog = () => {
    setState({ shouldShowHowItWorksDialog: false });
  };

  const handleCreateConnectAccount = async () => {
    try {
      const response = await createConnectedAccount();

      if (response?.success) {
        window.location.href = response?.data?.url;
      }
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    }
  };

  const isStudentType = user.accountType === AccountType.STUDENT;

  const loadDashboardData = async () => {
    const data = await getDashboardData();
    const isCompanyLogoUrls = data.data?.companyLogoUrls;
    let temp: {
      projectName: string;
      description: string;
      skillList: [];
      projectStatus: number;
      hourlyRate: number;
      id: string;
      owner: any;
      business: any;
      companyLogo?: any;
    }[] = [];
    data?.data?.onGoingProjects?.map((da: any) => {
      if (isStudentType) {
        if (da.status !== ProjectStatus.IN_ACTIVE)
          temp.push({
            projectName: da.title,
            description: da.type,
            skillList: da.skills,
            projectStatus: da.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            owner: da.owner,
            business: da.business,
          });
      } else {
        temp.push({
          projectName: da.title,
          description: da.type,
          skillList: da.skills,
          projectStatus: da.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          id: da._id,
          companyLogo: isCompanyLogoUrls
            ? data.data.companyLogoUrls[da._id]
            : null,
          owner: da.owner,
          business: da.business,
        });
      }
    });
    setState({ onGoingProjectList: [...temp] });

    temp = [];

    data?.data?.activeProjects?.map((da: any) => {
      if (isStudentType) {
        if (da.status !== ProjectStatus.IN_ACTIVE)
          temp.push({
            projectName: da.title,
            description: da.type,
            skillList: da.skills,
            projectStatus: da.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            owner: da.owner,
            business: da.business,
          });
      } else {
        temp.push({
          projectName: da.title,
          description: da.type,
          skillList: da.skills,
          projectStatus: da.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          id: da._id,
          companyLogo: isCompanyLogoUrls
            ? data.data.companyLogoUrls[da._id]
            : null,
          owner: da.owner,
          business: da.business,
        });
      }
    });
    setState({ activeProjectList: [...temp] });

    temp = [];

    // console.log(data?.data?.requestedPrjects, ",requested");
    data?.data?.requestedProjects?.map((da: any) => {
      if (isStudentType) {
        if (da.status !== ProjectStatus.IN_ACTIVE)
          temp.push({
            projectName: da.title,
            description: da.type,
            skillList: da.skills,
            projectStatus: da.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            owner: da.owner,
            business: da.business,
          });
      } else {
        temp.push({
          projectName: da.title,
          description: da.type,
          skillList: da.skills,
          projectStatus: da.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          id: da._id,
          companyLogo: isCompanyLogoUrls
            ? data.data.companyLogoUrls[da._id]
            : null,
          owner: da.owner,
          business: da.business,
        });
      }
    });
    setState({ requestedProjectList: [...temp] });

    temp = [];

    data?.data?.inProgressProjects?.map((da: any) => {
      if (isStudentType) {
        if (da.status !== ProjectStatus.IN_ACTIVE)
          temp.push({
            projectName: da.title,
            description: da.type,
            skillList: da.skills,
            projectStatus: da.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            owner: da.owner,
            business: da.business,
          });
      } else {
        temp.push({
          projectName: da.title,
          description: da.type,
          skillList: da.skills,
          projectStatus: da.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          id: da._id,
          companyLogo: isCompanyLogoUrls
            ? data.data.companyLogoUrls[da._id]
            : null,
          owner: da.owner,
          business: da.business,
        });
      }
    });
    setState({ inProgressProjectList: [...temp] });

    temp = [];

    data?.data?.appliedProjects?.map((da: any) => {
      if (isStudentType) {
        if (da.status !== ProjectStatus.IN_ACTIVE)
          temp.push({
            projectName: da.title,
            description: da.type,
            skillList: da.skills,
            projectStatus: da.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            owner: da.owner,
            business: da.business,
          });
      } else {
        temp.push({
          projectName: da.title,
          description: da.type,
          skillList: da.skills,
          projectStatus: da.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          id: da._id,
          owner: da.owner,
          business: da.business,
        });
      }
    });
    setState({ appliedProjectList: [...temp] });

    temp = [];

    data?.data?.finishedProjects?.map((da: any) => {
      if (isStudentType) {
        if (da.status !== ProjectStatus.IN_ACTIVE)
          temp.push({
            projectName: da.title,
            description: da.type,
            skillList: da.skills,
            projectStatus: da.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            owner: da.owner,
            business: da.business,
          });
      } else {
        temp.push({
          projectName: da.title,
          description: da.type,
          skillList: da.skills,
          projectStatus: da.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          id: da._id,
          companyLogo: isCompanyLogoUrls
            ? data.data.companyLogoUrls[da._id]
            : null,
          owner: da.owner,
          business: da.business,
        });
      }
    });
    const archivedProjects: {
      projectName: string;
      description: string;
      skillList: [];
      projectStatus: number;
      hourlyRate: number;
      id: string;
      owner: any;
      business: any;
      companyLogo?: any;
    }[] = [];
    data?.data?.archivedProjects?.map((da: any) => {
      if (isStudentType) {
        if (da.status !== ProjectStatus.IN_ACTIVE)
          archivedProjects.push({
            projectName: da.title,
            description: da.type,
            skillList: da.skills,
            projectStatus: da.status,
            hourlyRate: isStudentType
              ? getProjectFees(da)
              : getBusinessProjectFees(da),
            id: da._id,
            companyLogo: isCompanyLogoUrls
              ? data.data.companyLogoUrls[da._id]
              : null,
            owner: da.owner,
            business: da.business,
          });
      } else {
        archivedProjects.push({
          projectName: da.title,
          description: da.type,
          skillList: da.skills,
          projectStatus: da.status,
          hourlyRate: isStudentType
            ? getProjectFees(da)
            : getBusinessProjectFees(da),
          id: da._id,
          companyLogo: isCompanyLogoUrls
            ? data.data.companyLogoUrls[da._id]
            : null,
          owner: da.owner,
          business: da.business,
        });
      }
    });
    setState({ finishedProjectList: [...temp], archivedProjects });
    setState({ isLoading: false });
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      loadDashboardData();
      let payload: any = {};
      const response = await getStats();

      payload = {
        activeProjects: response?.data?.activeProjects,
        finishedProjects: response?.data?.finishedProjects,
        notifications: response?.data?.notifications,
      };

      if (user?.accountType === AccountType.BUSINESS) {
        const membersResponse = await getMembers();

        payload.members = membersResponse?.data;
      }

      setState(payload);
    } catch (error: any) {
      enqueueSnackbar(error?.message, { variant: "error" });
    } finally {
      setState({ isLoading: false });
    }
  };

  const businessServices = `${String(
    user?.business?.name
  ).toUpperCase()} SERVICES`;
  // const swiper = useSwiper();

  const collapseToggle = () => {
    setState({ showAssessmentTasks: !state.showAssessmentTasks });
  };

  useEffect(() => {
    init();
  }, []);

  // useEffect(() => {
  //   if (user.accountType === "BUSINESS") {
  //     setState({ isStudent: false });
  //   } else {
  //     setState({ isStudent: true });
  //   }
  // }, []);

  // const studentSide = [
  //   // {
  //   //   title: "Complete AI Skill Assessment",
  //   //   taskDescription: "REQUIRED TO APPLY",
  //   //   imageLink: require("../../assets/imgs/skill.png"),
  //   //   link: "#",
  //   // },
  //   {
  //     title: "Complete Personality Assessment",
  //     taskDescription: "RECOMMENDED",
  //     imageLink: require("../../assets/imgs/brain.png"),
  //     link: "/personality-tests",
  //   },
  //   {
  //     title: "Verify your Email",
  //     taskDescription: "RECOMMENDED",
  //     imageLink: require("../../assets/imgs/envelop.png"),
  //     link: "#",
  //   },
  // ];
  // const businessSide = [
  //   {
  //     title: "Verify your Email",
  //     taskDescription: "RECOMMENDED",
  //     imageLink: require("../../assets/imgs/envelop.png"),
  //     link: "#",
  //   },
  //   {
  //     title: "Add Company Picture",
  //     taskDescription: "RECOMMENDED",
  //     imageLink: require("../../assets/imgs/camera.png"),
  //     link: "/settings",
  //   },
  //   {
  //     title: "Set-up Payment",
  //     taskDescription: "RECOMMENDED",
  //     imageLink: require("../../assets/imgs/money.png"),
  //     link: "#",
  //   },
  // ];
  useEffect(() => {
    if (user?.business?.paymentMethods?.length > 0) {
      const temp = state.businessSide;
      temp.pop();
      setState({ businessSide: [...temp] });
    }
    // if (Object.keys(user.personality).length !== 0) {
    if (validateUserPersonalityTest(user)) {
      const temp = state.studentSide;
      temp.shift();
      setState({ studentSide: [...temp] });
    }

    if (user?.emailVerified) {
      let temp = state.studentSide;
      temp.pop();
      setState({ studentSide: [...temp] });
      temp = state.businessSide;
      temp.shift();
      setState({ businessSide: [...temp] });
    }
    if (user?.companyLogo !== null) {
      const temp = state.businessSide;
      const index = temp.findIndex(
        (data: any) => data.title === "Add Company Picture"
      );
      temp.splice(index, 1);
      setState({ businessSide: [...temp] });
    }
  }, []);

  const handleSendVerifyEmail = async () => {
    setState({ showVerifyModal: false });
    const response = await sendVerificationEmail(user.email);
    if (response.success) {
      enqueueSnackbar("verification email sent successfully.", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("oops something went wrong.", {
        variant: "error",
      });
    }
  };
  // let assessmentTaskList: {
  //   title: string;
  //   taskDescription: string;
  //   imageLink: string;
  //   link: string;
  // }[] = [];

  // useEffect(() => {
  //   const list =
  //     user?.accountType === "STUDENT" ? state.studentSide : state?.businessSide;
  //   assessmentTaskList = [...list];
  //   console.log(assessmentTaskList.length === 0, ",Task List");
  //   if (assessmentTaskList.length === 0) {
  //     setState({ showTasks: false });
  //   } else {
  //     setState({ showTasks: true });
  //   }
  // }, []);

  // useEffect(() => {
  //   if (assessmentTaskList.length === 0) {
  //     setState({ showTask: false });
  //   } else {
  //     setState({ showTask: true });
  //   }
  // }, [assessmentTaskList]);

  const list =
    user?.accountType === "STUDENT" ? state.studentSide : state?.businessSide;
  const assessmentTaskList: any[] = [...list];

  return (
    <div>
      <div className={`header ${manuBarExpend ? "body_pd" : ""}`} id="header">
        <div className="header_toggle" onClick={() => manuBarToogle()}>
          {" "}
          {/* <img
            src={menuBack}
            style={{ width: 20, height: 20, marginTop: 5, marginLeft: 5 }}
          /> */}
          <i
            className={`bx bx-menu ${!manuBarExpend ? "bx-x" : ""}`}
            id="header_toggle"
          />
        </div>
        <p className="fw-bold fs-5 ms-4 mb-0 my-1">Hi, {user.firstName}</p>
      </div>

      {/* nav bar end */}
      <div className="bg_light p-3 p-md-4 w-100 overflow-hidden mt-0 mt-md-0">
        {/* {state.showTasks ? ( */}
        {assessmentTaskList?.length > 0 && (
          <GetStarted
            isStudent={user?.accountType === "STUDENT"}
            assessmentTaskList={assessmentTaskList}
          />
        )}

        {/* <div
          className="bg_white px-4 py-3 border_r_16px border border_gray mb-4"
          style={{ paddingTop: 26 }}
        >
          <div className="collapseDiv">
            <div>
              <p className="font_14 text_black">GET STARTED</p>
              <p
                className={`text_black fw-bold fs-5 ${
                  state?.showAssessmentTasks ? "mb-3" : ""
                }`}
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  fontFamily: "system-ui",
                }}
              >
                {user?.accountType === "STUDENT"
                  ? "Complete these items to stand out"
                  : "Complete these items to complete your profile."}
              </p>
            </div>
            <div
              className="text_secondary fw_500 fs-6"
              role="button"
              tabIndex={0}
              aria-expanded="false"
              aria-controls="collapseExample"
              style={{
                position: "sticky",
                right: 30,
              }}
              onClick={() => collapseToggle()}
            >
              <p>COLLAPSE</p>
            </div>
          </div>

          <div
            className="assessmentTaskParent"
            style={{
              flexDirection: useWindowSize().width < 700 ? "column" : "row",
              // justifyContent:
              //   user?.accountType === "STUDENT" ? "normal" : "space-between",
            }}
          >
            {state?.showAssessmentTasks
              ? assessmentTaskList.map((data, index) => (
                  <AssessmentTask
                    key={index}
                    title={data.title}
                    taskDescription={data.taskDescription}
                    link={data.link}
                    img={data.imageLink}
                    changeWidth={
                      user?.accountType === "STUDENT" ||
                      assessmentTaskList.length === 2
                        ? true
                        : false
                    }
                  />
                ))
              : null}
          </div>
        </div> */}
        {/* ) : null} */}

        {/* explore div */}
        <div
          className="projectList"
          style={{
            flexDirection: useWindowSize().width < 700 ? "column" : "row",
          }}
        >
          <Link
            className="col-md-6"
            to={`${
              state?.isStudent ? "/projects?explore=true" : "/projects/create"
            }`}
            style={{
              width: useWindowSize().width < 700 ? "100%" : "49.5%",
              textDecoration: "none",
            }}
          >
            <div className="box_style blue_gradient me-2">
              <div>
                <p className="text-white fs-6">
                  <span>
                    {state?.isStudent ? (
                      <i className="far fa-search" aria-hidden="true" />
                    ) : (
                      <AddBoxOutlinedIcon
                        style={{ width: 20, height: 20, marginTop: -3 }}
                      />
                    )}
                  </span>{" "}
                  {state?.isStudent ? "EXPLORE PROJECTS" : "CREATE PROJECT"}
                </p>
                <p
                  className="text-white fs-4 fw-bold"
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    fontFamily: "system-ui",
                  }}
                >
                  {state?.isStudent
                    ? "Search For Projects Just For You"
                    : "Hire Top Student Talent"}
                </p>
              </div>
              <div className="icon_size_lg">
                {state.isStudent ? (
                  <img src={require("../../assets/imgs/img_search.png")} />
                ) : (
                  <img src={require("../../assets/imgs/student.png")} />
                )}
              </div>
            </div>
          </Link>
          <Link
            className="col-md-6"
            to="/"
            style={{
              width: useWindowSize().width < 700 ? "100%" : "49.5%",
              textDecoration: "none",
            }}
          >
            <div className="box_style bg_white">
              <div>
                <p className="text_black fs-6">
                  <span>
                    <i className="fal fa-globe" aria-hidden="true" />
                  </span>{" "}
                  RUNWAY PROJECTS
                </p>
                <p
                  className="text_black fs-4 fw-bold"
                  style={{
                    fontSize: 24,
                    fontWeight: "bold",
                    fontFamily: "system-ui",
                  }}
                >
                  Your Project Manager
                </p>
              </div>
              <div className="icon_size_lg">
                <img src={require("../../assets/imgs/img_project.png")} />
              </div>
            </div>
          </Link>
        </div>
        {state?.onGoingProjectList.length > 0 ||
          (state?.inProgressProjectList.length > 0 && (
            <Carousel
              title={0}
              tittleText="Ongoing projects"
              list={[
                ...state.onGoingProjectList,
                ...state.inProgressProjectList,
              ]}
              // list={onGoingList}
              isStudent={state.isStudent}
            />
          ))}
        {state?.requestedProjectList.length > 0 && (
          <Carousel
            title={1}
            tittleText="Requested to Interview"
            list={state?.requestedProjectList}
            // list={interviewProjectsList}
            isStudent={state.isStudent}
          />
        )}

        {state?.appliedProjectList.length > 0 && (
          <Carousel
            title={2}
            tittleText="Applied Projects"
            list={state?.appliedProjectList}
            isStudent={state.isStudent}
          />
        )}

        {/* <Carousel
          title={3}
          tittleText="Listed projects"
          list={appliedProjectsList}
          isStudent={state.isStudent}
        /> */}
        {state?.activeProjectList.length > 0 && (
          <Carousel
            title={3}
            tittleText="Listed Projects"
            list={state?.activeProjectList}
            isStudent={state.isStudent}
          />
        )}
        {/* {state?.inProgressProjectList.length > 0 && (
          <Carousel
            title={5}
            tittleText="in Progress Projects"
            list={state?.inProgressProjectList}
            isStudent={state.isStudent}
          />
        )} */}
        {state?.finishedProjectList.length > 0 && (
          <Carousel
            title={6}
            tittleText="Finished Projects"
            list={state?.finishedProjectList}
            isStudent={state.isStudent}
          />
        )}
        {state?.archivedProjects.length > 0 && (
          <Carousel
            title={7}
            tittleText="Archived Projects"
            list={state?.archivedProjects}
            isStudent={state.isStudent}
          />
        )}
        {state?.isLoading ? <AppLoader isLoading={state?.isLoading} /> : null}
        {state?.showVerifyModal && (
          <Modal
            show={state.showVerifyModal}
            onHide={() => setState({ showVerifyModal: false })}
            centered
          >
            <Modal.Body style={{ margin: 0, padding: 0 }}>
              <div>
                <div>
                  <div className="modal-content p-3">
                    <div className="modal-body">
                      <div className="text-center mb-4 d-flex justify-content-center">
                        <img src={require("../../assets/imgs/envelop.png")} />
                      </div>
                      <p className="fs-4 fw-bold text-center mb-4 text_black lh-sm">
                        Verify your Email
                      </p>
                      <p className="fs-6 text_primary mt-4 text-center">
                        Click blew to send a new verification email.
                      </p>
                    </div>
                    <div className="modal-footer border-0">
                      <button
                        type="button"
                        className="btn bg_secondary w-100 mb-3"
                        // className="btn bg_light_secondary text_secondary w-100"
                        data-bs-dismiss="modal"
                        onClick={() => handleSendVerifyEmail()}
                      >
                        Resend Verification Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}

        {
          // <div>
          //   {state?.isLoading ? (
          //     <AppLoader isLoading={state?.isLoading} />
          //   ) : (
          //     <div className="flex justify-center py-4 lg:p-4">
          //       <div className="w-10/12 lg:w-10/12 max-w-[1350px]">
          //         <div className="flex justify-between">
          //           <div>
          //             <div className="text-4xl font-bold">Hi, {user?.firstName}</div>
          //             <div className="text-rw-blue font-semibold mt-2">
          //               {user?.accountType === AccountType.BUSINESS
          //                 ? businessServices
          //                 : "STUDENT"}
          //             </div>
          //           </div>
          //           {/* <div className="bg-rw-blue-lightest rounded-full w-16 h-16 flex justify-center items-center text-rw-blue relative cursor-pointer">
          //             <NotificationsOutlinedIcon sx={{ width: 32, height: 32 }} />
          //             {state?.notifications > 0 && (
          //               <div className="bg-[#E70A0A] w-4 h-4 rounded-full absolute top-0 right-0" />
          //             )}
          //           </div> */}
          //         </div>
          //         <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 mt-12">
          //           <Link
          //             className="rounded-md h-[200px] p-4 shadow-md bg-rw-blue text-white"
          //             to={
          //               user?.accountType === AccountType.BUSINESS
          //                 ? "/projects/create"
          //                 : "/projects?explore=true"
          //             }
          //           >
          //             <div className="bg-rw-white-25 rounded-full w-12 h-12 lg:w-16 lg:h-16 flex justify-center items-center">
          //               <AddOutlinedIcon
          //                 sx={{ color: "white", width: 36, height: 36 }}
          //               />
          //             </div>
          //             <div className="text-xl lg:text-2xl font-semibold mt-2 lg:mt-4">
          //               {user?.accountType === AccountType.BUSINESS
          //                 ? "Create new project"
          //                 : "Explore new projects"}
          //             </div>
          //             <div className="text-sm text-rw-white-75 mt-2 lg:mt-4">
          //               {user?.accountType === AccountType.BUSINESS
          //                 ? "Post a new opportunity"
          //                 : "Explore new opportunities"}
          //             </div>
          //           </Link>
          //           <Link
          //             className="rounded-md  p-4 shadow-md border-[1px] border-[#EEEEEE]"
          //             to="/projects"
          //           >
          //             <div className="bg-rw-blue-lightest rounded-full w-12 h-12 lg:w-16 lg:h-16 flex justify-center items-center">
          //               <span className="text-rw-blue font-semibold text-4xl">
          //                 {state?.activeProjects}
          //               </span>
          //             </div>
          //             <div className="text-2xl font-semibold mt-2 lg:mt-4">
          //               Your projects
          //             </div>
          //             <div className="text-sm text-rw-gray-75 mt-2 lg:mt-4">
          //               View all projects
          //             </div>
          //           </Link>
          //           <Link
          //             className="rounded-md  p-4 shadow-md border-[1px] border-[#EEEEEE]"
          //             to="/settings"
          //           >
          //             <div className="bg-rw-blue-lightest rounded-full w-12 h-12 lg:w-16 lg:h-16 flex justify-center items-center">
          //               <span className="text-rw-blue">
          //                 <SettingsOutlinedIcon sx={{ width: 36, height: 36 }} />
          //               </span>
          //             </div>
          //             <div className="text-2xl font-semibold mt-4">Settings</div>
          //             <div className="text-sm text-rw-gray-75 mt-4">
          //               Payment, Account details
          //             </div>
          //           </Link>
          //           <div
          //             className="rounded-md p-4 shadow-md border-[1px] border-[#EEEEEE] cursor-pointer"
          //             onClick={handleOpenHowItWorksDialog}
          //           >
          //             <div className="bg-rw-blue-lightest rounded-full w-12 h-12 lg:w-16 lg:h-16 flex justify-center items-center">
          //               <span className="text-rw-blue">
          //                 <TipsAndUpdatesIcon sx={{ width: 36, height: 36 }} />
          //               </span>
          //             </div>
          //             <div className="text-2xl font-semibold mt-2 lg:mt-4">
          //               How it Works
          //             </div>
          //             <div className="text-sm text-rw-gray-75 mt-2 lg:mt-4">
          //               Confused? Start Here.
          //             </div>
          //           </div>
          //         </div>
          //         <hr className="mt-8" />
          //         <div className="flex flex-col-reverse lg:flex-row justify-between gap-4">
          //           <div className="lg:w-1/2">
          //             <div className="text-2xl font-semibold mt-8">
          //               {user?.accountType === AccountType.BUSINESS
          //                 ? businessServices
          //                 : "Payout"}
          //             </div>
          //             {user?.accountType === AccountType.BUSINESS && (
          //               <div>
          //                 <div
          //                   className="bg-rw-blue-lightest rounded-md p-4 flex items-center cursor-pointer my-4 h-20"
          //                   onClick={handleOpenInviteMemberDialog}
          //                 >
          //                   <div className="flex items-center">
          //                     <div className="bg-white rounded-full w-10 h-8 flex justify-center items-center text-rw-blue">
          //                       <AddOutlinedIcon sx={{ width: 18, height: 18 }} />
          //                     </div>
          //                     <div className="w-full font-semibold ml-2">
          //                       Invite a team member
          //                     </div>
          //                   </div>
          //                 </div>
          //                 {state?.members?.map((member: any) => (
          //                   <div
          //                     key={member?._id}
          //                     className="border-[1px] border-[#D7DBE1] rounded-md p-4 flex items-center cursor-pointer my-4 h-20"
          //                   >
          //                     <div className="flex items-center">
          //                       <div className="bg-rw-blue rounded-full w-10 h-8 flex justify-center items-center text-sm text-white font-semibold">
          //                         {wrapNameTwoLetters(
          //                           member?.firstName,
          //                           member?.lastName
          //                         )}
          //                       </div>
          //                       <div className="w-full font-semibold ml-2">
          //                         {wrapFullName(member?.firstName, member?.lastName)}
          //                       </div>
          //                     </div>
          //                   </div>
          //                 ))}
          //               </div>
          //             )}
          //             {user?.accountType === AccountType.STUDENT &&
          //               !user?.payoutsEnabled && (
          //                 <div className="bg-rw-blue-lightest rounded-md p-4 mt-4">
          //                   <div className="text-lg font-semibold">
          //                     Payout Method Required
          //                   </div>
          //                   <div className="text-sm font-medium text-rw-gray-light mt-2">
          //                     You must include a payout method to accept a project.
          //                   </div>
          //                   <Button
          //                     className="h-12 font-semibold w-full mt-6"
          //                     onClick={handleCreateConnectAccount}
          //                   >
          //                     Connect Payout
          //                   </Button>
          //                 </div>
          //               )}
          //             {user?.accountType === AccountType.STUDENT &&
          //               user?.payoutsEnabled && (
          //                 <div className="bg-rw-blue-lightest rounded-md p-4 mt-4">
          //                   <div className="text-lg font-semibold">
          //                     Payouts Enabled
          //                   </div>
          //                 </div>
          //               )}
          //           </div>
          //           <div className="hidden lg:block m-0">
          //             <Divider orientation="vertical" />
          //           </div>
          //           <div className="lg:w-1/2">
          //             <div className="text-2xl font-semibold mt-8">Need Help?</div>
          //             <a
          //               href={`mailto:${RUNWAY_CONTACT_EMAIL}?subject=Need help`}
          //               className="bg-rw-blue-lightest rounded-md p-4 flex items-center cursor-pointer my-4 h-20"
          //             >
          //               <div className="w-full flex justify-between items-center">
          //                 <div className="flex items-center">
          //                   <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center text-rw-blue">
          //                     <QuestionMarkOutlinedIcon
          //                       sx={{ width: 18, height: 18 }}
          //                     />
          //                   </div>
          //                   <div className="ml-2">
          //                     <div className="w-full font-semibold">Contact</div>
          //                     <div className="text-xs text-rw-gray-light font-medium mt-1">
          //                       Need some help?
          //                     </div>
          //                   </div>
          //                 </div>
          //                 <div className="text-[#48484866]">
          //                   <ChevronRightOutlinedIcon
          //                     sx={{ width: 36, height: 36 }}
          //                   />
          //                 </div>
          //               </div>
          //             </a>
          //           </div>
          //         </div>
          //       </div>
          //       {state?.shouldShowInviteMemberDialog && (
          //         <InviteMemberDialog
          //           open={state?.shouldShowInviteMemberDialog}
          //           onClose={handleCloseInviteMemberDialog}
          //         />
          //       )}
          //       {state?.shouldShowHowItWorksDialog && (
          //         <HowItWorksDialog
          //           open={state?.shouldShowHowItWorksDialog}
          //           onClose={handleCloseHowItWorksDialog}
          //           accountType={user?.accountType}
          //         />
          //       )}
          //     </div>
          //   )}
          // </div>
        }
      </div>
    </div>
  );
}
